import React from 'react';

const useImageUpload = () => {
	const [uploadLoading, setUploadLoading] = React.useState(false);

	const uploadImage = (e, setData, property = null, index = null) => {
		const image = e.target.files[0];

		if (image) {
			const formData = new FormData();
			formData.append('image', image);

			setUploadLoading(true);

			const url = `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMGBB_API_KEY}`;

			fetch(url, {
				method: 'POST',
				body: formData,
			})
				.then((res) => res.json())
				.then((res) => {
					console.log('img upload res : ', res);

					if (res.success) {
						if (property !== null) {
							if (index === null) {
								setData((prev) => ({
									...prev,
									[property]: res.data?.medium?.url || res.data?.url,
								}));
							} else {
								setData((prev) => {
									const allData = [...prev[property]];
									allData[index] = res.data?.medium?.url || res.data?.url;

									return {
										...prev,
										[property]: allData,
									};
								});
							}
						} else {
							setData((prev) => {
								// check if the data is an array
								if (Array.isArray(prev)) {
									return [...prev, res.data?.medium?.url || res.data?.url];
								} else {
									return res.data?.medium?.url || res.data?.url;
								}
							});
						}
					} else {
						alert('Image upload failed');
					}
				})
				.catch((err) => {
					console.log('img upload err : ', err);
					alert('Image upload failed');
				})
				.finally(() => {
					setUploadLoading(false);
				});
		}
	};

	return {
		uploadLoading,
		uploadImage,
	};
};

export default useImageUpload;
