import React from 'react';
import './Footer.scss';
import {
	BsTwitter,
	BsFacebook,
	BsLinkedin,
	BsPinterest,
	BsInstagram,
} from 'react-icons/bs';
import { playStore, android } from '../../assets';
import { MdLanguage } from 'react-icons/md';
import { BiDollar } from 'react-icons/bi';
import { IoIosMan } from 'react-icons/io';
import { FiChevronUp } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<div>
			<div className='footer1'>
				<div className='footer1_content'>
					<h1 className=''>Download the app</h1>
					<p>
						To enjoy personalized health care services with <br /> more features
						& exciting offers
					</p>
					<button>
						<img src={playStore} alt='' />
					</button>
				</div>
				<img className='app_layout' src={android} alt='' />
			</div>
			<div className='footer2'>
				<div className='privacy_policy'>
					<Link to='/about'>
						<button>About Us</button>
					</Link>
					{/* <a href='/service.pdf'>
						<button>Services</button>
					</a> */}
					<Link to='/terms'>
						<button>Terms of Use</button>
					</Link>
					<Link to='/privacy'>
						<button>Privacy Policy</button>
					</Link>
					<Link to='/privacy-agreement'>
						<button>Doctors Privacy Agreement</button>
					</Link>
					<Link to='/contact'>
						<button>Contact Us</button>
					</Link>
				</div>
				<div className='social_tab flex flex-row flex-wrap justify-center items-center gap-x-5 mt-10 mb-5'>
					<button className='social'>
						<a
							target='_blank'
							href='https://twitter.com/Romatoo_AI'
							rel='noreferrer'
						>
							<BsTwitter />
						</a>
					</button>
					<button className='social'>
						<a
							target='_blank'
							href='https://www.facebook.com/RomatooAI'
							rel='noreferrer'
						>
							<BsFacebook />
						</a>
					</button>

					<button className='social'>
						<a
							target='_blank'
							href='https://www.linkedin.com/company/romatoo/'
							rel='noreferrer'
						>
							{' '}
							<BsLinkedin />
						</a>
					</button>
					<button className='social'>
						<a
							target='_blank'
							href='https://www.pinterest.com/romatoo_ai/'
							rel='noreferrer'
						>
							{' '}
							<BsPinterest />
						</a>
					</button>
					<button className='social'>
						<a
							target='_blank'
							href='https://www.instagram.com/romatoo_ai/'
							rel='noreferrer'
						>
							<BsInstagram />
						</a>
					</button>
					<button className='flex flex-center items-center social gap-1'>
						<MdLanguage className='globe' /> English
					</button>
					<button className='flex flex-center items-center social'>
						<BiDollar /> USD
					</button>
					<button className='social border-2 rounded-full border-slate-500'>
						<IoIosMan />
					</button>
				</div>
				<div className='footer_bottom'>
					<div className='footer_bottom_content'>
						<img src='/footer-fav.svg' alt='' />
						<p className='footer_copyright'>
							Copyright © {new Date().getFullYear()} Romatoo. All rights
							reserved
						</p>
					</div>
					<button
						className='up_btn'
						onClick={() => {
							window.scrollTo({
								top: 0,
								behavior: 'smooth',
							});
						}}
					>
						<FiChevronUp className='icon' />
					</button>
				</div>
			</div>
		</div>
	);
};

export default Footer;
