import React from 'react';
import { FaPen } from 'react-icons/fa';
import { IoAdd, IoTrashOutline } from 'react-icons/io5';
import { BsFillGridFill } from 'react-icons/bs';
import './MedBox.scss';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';

const socket = io(process.env.REACT_APP_SOCKET, {
	path: process.env.REACT_APP_SOCKET_CHAT_PATH,
});

const MedBox = ({ item, setMedicines }) => {
	const user = useSelector((state) => state.auth?.info);

	const brandNameRef = React.useRef(null);
	const geneticNameRef = React.useRef(null);

	const [brandName, setBrandName] = React.useState('');
	const [geneticName, setGeneticName] = React.useState('');
	const [selectedDuration, setSelectedDuration] = React.useState({
		value: '1', // 1, 2, 3
		label: 'days', // days, weeks, months
	});
	const [injectionTime, setInjectionTime] = React.useState({
		hour: '3',
		give: 'muscle',
	});
	const [salineTime, setSalineTime] = React.useState('40');
	const [selectedMedicine, setSelectedMedicine] = React.useState(null);
	const [brandResults, setBrandResults] = React.useState([]);
	const [geneticResults, setGeneticResults] = React.useState([]);

	const [medSocketId, setMedSocketId] = React.useState(
		`${user?.id}-${item?.id}}`
	);

	React.useEffect(() => {
		socket.on('resBrand', ({ results, medId }) => {
			if (medId !== medSocketId) {
				return;
			}

			console.log('resBrand -> ', results);

			setBrandResults(results);
		});

		socket.on('resGenetic', ({ results, medId }) => {
			if (medId !== medSocketId) {
				return;
			}

			console.log('resGenetic -> ', results);

			setGeneticResults(results);
		});

		return () => {
			socket.off('resBrand');
			socket.off('resGenetic');
		};
	}, []);

	const searchGenericName = (e) => {
		if (e.target?.value?.length < 3) {
			setGeneticResults([]);
			return;
		}

		console.log('generic name -> ', e.target.value);

		socket.emit('searchGenetic', {
			geneticName: e.target.value,
			medId: medSocketId,
		});
	};

	const searchBrandName = (e) => {
		if (e.target?.value?.length < 3) {
			setBrandResults([]);
			return;
		}

		console.log('brand name -> ', e.target.value);

		socket.emit('searchBrand', {
			brandName: e.target.value,
			medId: medSocketId,
		});
	};

	const handleAddBlankTime = () => {
		const timeTable = [...item.timeTable];

		timeTable.push({
			time: '',
			amount: '',
			editable: true,
		});

		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id ? { ...med, timeTable } : med
			);
		});
	};

	const addTimeRow = () => {
		const timeRows = [...item.timeTable];

		timeRows.push({
			type: timeRows.length + 1,
			duration: '1',
			durationType: 'days',
		});

		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id ? { ...med, timeTable: timeRows } : med
			);
		});
	};

	const removeTimeRow = (type) => {
		const timeRows = [...item.timeTable];
		const timeColumns = [...item.times];

		const updatedTimeRows = timeRows.filter((timeObj) => {
			return timeObj.type !== type;
		});

		updatedTimeRows.forEach((timeObj, index) => {
			timeObj.type = index + 1;
		});

		timeColumns.forEach((timeObj) => {
			delete timeObj[`amount${type !== 1 ? type : ''}`];
		});

		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id
					? { ...med, timeTable: updatedTimeRows, times: timeColumns }
					: med
			);
		});
	};

	const addTimeColumn = () => {
		const timeColumns = [...item.times];

		timeColumns.push({
			id: timeColumns.length + 1,
			time: '',
			amount: '',
			editable: true,
		});

		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id ? { ...med, times: timeColumns } : med
			);
		});
	};

	const removeTimeColumn = (id) => {
		const timeColumns = [...item.times];

		const updatedTimeColumns = timeColumns.filter((timeObj) => {
			return timeObj.id !== id;
		});

		updatedTimeColumns.forEach((timeObj, index) => {
			timeObj.id = index + 1;
		});

		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id ? { ...med, times: updatedTimeColumns } : med
			);
		});
	};

	const handleTimeTable = (timeId, type, value) => {
		const times = [...item.times];

		times.forEach((timeObj) => {
			if (timeObj.id === timeId) {
				timeObj[`amount${type !== 1 ? type : ''}`] = value;
			}
		});

		setMedicines((prev) => {
			return prev.map((med) => (med.id === item.id ? { ...med, times } : med));
		});
	};

	const handleTimeTableDuration = (type, value) => {
		const timeTable = [...item.timeTable];

		const updatedTimeTable = timeTable.map((timeObj) => {
			if (timeObj.type === type) {
				return {
					...timeObj,
					duration: value,
				};
			}
			return timeObj;
		});

		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id ? { ...med, timeTable: updatedTimeTable } : med
			);
		});
	};

	const handleTimeTableDurationType = (type, value) => {
		const timeTable = [...item.timeTable];

		const updatedTimeTable = timeTable.map((timeObj) => {
			if (timeObj.type === type) {
				return {
					...timeObj,
					durationType: value,
				};
			}
			return timeObj;
		});

		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id ? { ...med, timeTable: updatedTimeTable } : med
			);
		});
	};

	const handleCustomTimeLabel = (id, value) => {
		const times = [...item.times];

		times.forEach((timeObj) => {
			if (timeObj.id === id) {
				timeObj.time = value;
			}
		});

		setMedicines((prev) => {
			return prev.map((med) => (med.id === item.id ? { ...med, times } : med));
		});
	};

	// const handleAddBlankTimeType = (type) => {
	// 	const timeTable = [...item.timeTable];

	// 	timeTable.push({
	// 		type,
	// 		day: '1 days',
	// 		morning: '',
	// 		noon: '',
	// 		night: '',
	// 	});

	// 	setMedicines((prev) => {
	// 		return prev.map((med) =>
	// 			med.id === item.id ? { ...med, timeTable } : med
	// 		);
	// 	});
	// };

	// const handleRemoveTimeType = (type) => {
	// 	const timeTable = [...item.timeTable];

	// 	const updatedTimeTable = timeTable.filter((timeObj) => {
	// 		return timeObj.type !== type;
	// 	});

	// 	setMedicines((prev) => {
	// 		return prev.map((med) =>
	// 			med.id === item.id ? { ...med, timeTable: updatedTimeTable } : med
	// 		);
	// 	});
	// };

	// const handleCustomTime = (index, value, type) => {
	// 	const timeTable = [...item.timeTable];

	// 	if (type === 'time') {
	// 		timeTable[index].time = value;
	// 	} else {
	// 		timeTable[index].amount = value;
	// 	}

	// 	setMedicines((prev) => {
	// 		return prev.map((med) =>
	// 			med.id === item.id ? { ...med, timeTable } : med
	// 		);
	// 	});
	// };

	const handleGeneticName = (value) => {
		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id ? { ...med, geneticName: value } : med
			);
		});
		geneticNameRef.current.value = value;
	};

	const handleBrandName = (value) => {
		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id ? { ...med, brandName: value } : med
			);
		});
		brandNameRef.current.value = value;
	};

	// const handleTimeTable = (time, amount) => {
	// 	if (Number(amount) < 1) {
	// 		return;
	// 	}

	// 	setMedicines((prev) => {
	// 		const all = [...prev];
	// 		const index = all.findIndex((med) => med.id === item.id);
	// 		const timeTable = [...all[index].timeTable];

	// 		const updatedTimeTable = timeTable.map((timeObj) => {
	// 			if (timeObj.time === time) {
	// 				return {
	// 					...timeObj,
	// 					amount: amount,
	// 				};
	// 			}
	// 			return timeObj;
	// 		});

	// 		all[index].timeTable = updatedTimeTable;

	// 		return all;
	// 	});
	// };

	// const handleDuration = (value) => {
	// 	setMedicines((prev) => {
	// 		return prev.map((med) =>
	// 			med.id === item.id ? { ...med, duration: value } : med
	// 		);
	// 	});
	// };

	const handleIntake = (value) => {
		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id ? { ...med, intake: value } : med
			);
		});
	};

	const handleNote = (value) => {
		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id ? { ...med, note: value } : med
			);
		});
	};

	React.useEffect(() => {
		setMedicines((prev) => {
			return prev.map((med) =>
				med.id === item.id
					? {
							...med,
							duration: `${selectedDuration.value} ${selectedDuration.label}`,
					  }
					: med
			);
		});
	}, [item.id, selectedDuration.label, selectedDuration.value]);

	React.useEffect(() => {
		if (selectedMedicine && selectedMedicine.dosageForm === 'Injection') {
			setMedicines((prev) => {
				return prev.map((med) =>
					med.id === item.id
						? {
								...med,
								injectionTime: `Give it to the ${injectionTime.give} ${injectionTime.hour} hours late`,
						  }
						: med
				);
			});
		} else {
			setMedicines((prev) => {
				return prev.map((med) =>
					med.id === item.id
						? {
								...med,
								injectionTime: '',
						  }
						: med
				);
			});
		}
	}, [item.id, selectedMedicine, injectionTime.give, injectionTime.hour]);

	React.useEffect(() => {
		if (selectedMedicine && selectedMedicine.dosageForm === 'Saline') {
			setMedicines((prev) => {
				return prev.map((med) =>
					med.id === item.id
						? {
								...med,
								salineTime: `Give ${salineTime} drops per minute in the vein`,
						  }
						: med
				);
			});
		} else {
			setMedicines((prev) => {
				return prev.map((med) =>
					med.id === item.id
						? {
								...med,
								salineTime: '',
						  }
						: med
				);
			});
		}
	}, [item.id, selectedMedicine, salineTime]);

	if (selectedMedicine) {
		console.log('selectedMedicine -> ', selectedMedicine);
	}

	return (
		<div className='medBox'>
			<div className='genetic'>
				<div className='left'>
					<label htmlFor='brandName'>Brand Name</label>
					<div className='input__field'>
						<FaPen className='icon' />
						<input
							type='text'
							name='brandName'
							id='brandName'
							placeholder='Add...'
							onChange={(e) => {
								searchBrandName(e);
							}}
							ref={brandNameRef}
						/>
					</div>

					{brandResults.length > 0 ? (
						<div className='brandDropdown'>
							{brandResults.map((brandItem, index) => (
								<p
									key={index}
									className='brandItem'
									onClick={() => {
										setSelectedMedicine(brandItem);
										handleBrandName(
											`${brandItem.brandName} ${brandItem?.quantity}`
										);
										handleGeneticName(
											`${brandItem.geneticName} ${brandItem?.quantity}`
										);
										setBrandResults([]);
									}}
								>
									{brandItem.brandName} {brandItem?.quantity}
								</p>
							))}
						</div>
					) : null}
				</div>

				<button
					className='right'
					onClick={() => {
						setMedicines((prev) => {
							if (prev.length > 1) {
								return prev.filter((med) => med.id !== item.id);
							} else {
								return prev;
							}
						});
					}}
				>
					<IoTrashOutline className='icon' />
				</button>
			</div>

			<div className='brandName'>
				<div className='left'>
					<label htmlFor='geneticName'>Genetic Name</label>
					<div className='input__field'>
						<FaPen className='icon' />
						<input
							type='text'
							name='geneticName'
							id='geneticName'
							placeholder='Add...'
							onChange={(e) => {
								searchGenericName(e);
							}}
							ref={geneticNameRef}
						/>
					</div>

					{geneticResults.length > 0 ? (
						<div className='geneticDropdown'>
							{geneticResults.map((geneticItem, index) => (
								<p
									key={index}
									className='geneticItem'
									onClick={() => {
										setSelectedMedicine(geneticItem);
										handleGeneticName(
											`${geneticItem.geneticName} ${geneticItem?.quantity}`
										);
										handleBrandName(
											`${geneticItem.brandName} ${geneticItem?.quantity}`
										);
										setGeneticResults([]);
									}}
								>
									{geneticItem.geneticName} {geneticItem?.quantity}
								</p>
							))}
						</div>
					) : null}
				</div>

				<div className='right'>
					<button className='btn-brand'>
						<BsFillGridFill className='icon' />
					</button>
				</div>
			</div>

			<div className='timeTable'>
				<p>Time Table & Amount</p>

				{selectedMedicine?.dosageForm?.toLowerCase() === 'injection' ? (
					// ------------- Injection -------------
					<div className='injection__time__container'>
						<select
							onChange={(e) => {
								setInjectionTime((prev) => ({
									...prev,
									give: e.target.value,
								}));
							}}
						>
							<option value='muscle'>Give it to the muscle</option>
							<option value='vein'>Give it to the vein</option>
						</select>

						<div className='injectionTime'>
							<input
								type='text'
								placeholder='3'
								onChange={(e) => {
									setInjectionTime((prev) => ({
										...prev,
										hour: e.target.value,
									}));
								}}
							/>

							<span>Hours Late</span>
						</div>
					</div>
				) : selectedMedicine?.dosageForm?.toLowerCase() === 'infusion' ? (
					// ------------- Saline -------------
					<div className='saline__time__container'>
						Give
						<input
							type='text'
							placeholder='30'
							onChange={(e) => {
								setSalineTime(e.target.value);
							}}
						/>
						drops per minute in the vein
					</div>
				) : (
					<>
						<div className='new__times__container'>
							<div className='new__times__wrapper'>
								{item.times.map((timeItem, timeItemIndex) =>
									timeItem.editable === false ? (
										// -------------- normal column --------------

										<div className='new__timeItem' key={timeItemIndex}>
											<label className='blockItem'>
												{timeItem.time.charAt(0).toUpperCase() +
													timeItem.time.slice(1)}
											</label>

											{item.timeTable?.map((timeType, timeTypeIndex) => (
												<div
													className='new__time blockItem'
													key={timeTypeIndex}
												>
													<input
														type='number'
														placeholder='0'
														min='0'
														max='5'
														onChange={(e) => {
															handleTimeTable(
																timeItem.id,
																timeType.type,
																e.target.value
															);
														}}
													/>
												</div>
											))}
										</div>
									) : (
										// -------------- custom column --------------

										<div className='new__timeItem' key={timeItemIndex}>
											<label className='blockItem'>
												<input
													type='text'
													placeholder='Add...'
													onChange={(e) => {
														handleCustomTimeLabel(timeItem.id, e.target.value);
													}}
												/>

												<button onClick={() => removeTimeColumn(timeItem?.id)}>
													<IoTrashOutline className='icon' size={18} />
												</button>
											</label>

											{item.timeTable?.map((timeType, timeTypeIndex) => (
												<div
													className='new__time blockItem'
													key={timeTypeIndex}
												>
													<input
														type='number'
														placeholder='0'
														min='0'
														max='5'
														onChange={(e) => {
															handleTimeTable(
																timeItem.id,
																timeType.type,
																e.target.value
															);
														}}
													/>
												</div>
											))}
										</div>
									)
								)}

								{/* ------------- custom column adder ------------- */}

								<div className='new__timeItem extra'>
									<label
										className='blockItem cursor-pointer'
										onClick={addTimeColumn}
									>
										<IoAdd className='icon' size={20} />
									</label>

									{item.timeTable?.map((timeType, timeTypeIndex) => (
										<div className='new__time blockItem' key={timeTypeIndex}>
											-
										</div>
									))}
								</div>

								{/* ------------- row duration column ------------- */}

								<div className='new__timeItem'>
									<label className='blockItem'>Duration</label>

									{item.timeTable?.map((timeType, timeTypeIndex) => (
										<>
											<div className='new__time blockItem2' key={timeTypeIndex}>
												<input
													type='number'
													placeholder='0'
													min='0'
													max='5'
													onChange={(e) => {
														handleTimeTableDuration(
															timeType.type,
															e.target.value
														);
													}}
												/>

												<select
													onChange={(e) => {
														handleTimeTableDurationType(
															timeType.type,
															e.target.value
														);
													}}
												>
													<option value='days'>Day</option>
													<option value='week'>Week</option>
													<option value='months'>Month</option>
												</select>

												{timeTypeIndex === 0 ? (
													<button className='addTimeRow' onClick={addTimeRow}>
														<IoAdd className='icon' size={20} />
													</button>
												) : (
													<button
														className='addTimeRow'
														onClick={() => {
															removeTimeRow(timeType.type);
														}}
													>
														<IoTrashOutline className='icon' />
													</button>
												)}
											</div>
										</>
									))}
								</div>
							</div>
						</div>
					</>
				)}
			</div>

			<div className='intake'>
				<label htmlFor='intake'>Intake</label>
				<div className='input__field'>
					{/* <FaPen className='icon' /> */}
					{/* <input
						type='text'
						name='intake'
						id='intake'
						placeholder='Add...'
						onChange={(e) => handleIntake(e.target.value)}
					/> */}
					<div className='opt'>
						<input
							type='radio'
							name={`intake${item.id}`}
							id={`beforeMeal${item.id}`}
							value='Before Meal'
							onChange={(e) => handleIntake(e.target.value)}
						/>
						<label htmlFor={`beforeMeal${item.id}`}>Before Meal</label>
					</div>
					<div className='opt'>
						<input
							type='radio'
							name={`intake${item.id}`}
							id={`afterMeal${item.id}`}
							value='After Meal'
							onChange={(e) => handleIntake(e.target.value)}
						/>
						<label htmlFor={`afterMeal${item.id}`}>After Meal</label>
					</div>
					<div className='opt'>
						<input
							type='radio'
							name={`intake${item.id}`}
							id={`withMeal${item.id}`}
							value='With Meal'
							onChange={(e) => handleIntake(e.target.value)}
						/>
						<label htmlFor={`withMeal${item.id}`}>With Meal</label>
					</div>
				</div>
			</div>

			<div className='duration'>
				<label htmlFor='duration'>Duration</label>
				{/* <div className='input__field'>
					<FaPen className='icon' />
					<input
						type='text'
						name='duration'
						id='duration'
						placeholder='Add...'
						onChange={(e) => handleDuration(e.target.value)}
					/>
				</div> */}

				<div className='flex gap-4 mt-2'>
					{/* <select
						name='duration'
						id='duration'
						onChange={(e) => {
							// setSelectedDuration({
							// 	value: e.target.value,
							// 	label: e.target.options[e.target.selectedIndex].text,
							// });
							// handleDuration(e.target.value);
						}}
					>
						{Array.from(Array(15).keys()).map((item, index) => (
							<option key={index} value={item + 1}>
								{item + 1}
							</option>
						))}
					</select> */}

					<input
						type='text'
						name='duration'
						id='duration'
						placeholder='1'
						className='inline-block w-16 rounded-md border-[#15e584] focus:border-[#15e584] ring-0 focus:ring-0 text-center text-[#117446]'
						onChange={(e) => {
							setSelectedDuration((prev) => ({
								...prev,
								value: e.target.value,
							}));
						}}
					/>

					<select
						name='duration'
						id='duration'
						onChange={(e) => {
							setSelectedDuration((prev) => ({
								...prev,
								label: e.target.value,
							}));
						}}
						className='rounded-md border-[#15e584] focus:border-[#15e584] ring-0 focus:ring-0 text-[#117446]'
					>
						<option value='days'>Day</option>
						<option value='week'>Week</option>
						<option value='months'>Month</option>
					</select>
				</div>
			</div>

			<div className='note'>
				<label htmlFor='note'>Note</label>
				<div className='input__field'>
					<FaPen className='icon' />
					<input
						type='text'
						name='note'
						id='note'
						placeholder='Add additional notes...'
						onChange={(e) => handleNote(e.target.value)}
					/>
				</div>
			</div>
		</div>
	);
};

export default MedBox;
