import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice/authSlice';


const store = configureStore({
    reducer: {
        auth: authReducer
    },
    devTools: process.env.NODE_ENV === 'development' ? true : false
});


export default store;