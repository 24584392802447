import React from 'react';

const VideoConference = () => {
	return (
		<div className='page'>
			<div className='details__concept'>
				<div className='concept__container'>
					<h1>Cloud-based Video Conference services</h1>
					<p>
						Our cloud-based video conferencing service is intended
						for medical consultation use only. Our cloud-based
						videoconferencing is designed so that a patient can get
						the same service as if they were meeting a doctor in the
						real world, and better than this video conferencing. Our
						video conferencing platform uses cutting-edge
						technologies such as AR so that doctors and patients can
						experience real meetings. <br /> <br />
						Using VR technology, doctors can take steps to diagnose
						and cure diseases faster. Similarly, using VR technology
						will increase the patient's visualization capabilities,
						allowing the patient to achieve faster and more complete
						healing.
					</p>
				</div>
				<img src='/images/meet.jpeg' alt='' />
			</div>
		</div>
	);
};

export default VideoConference;
