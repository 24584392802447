import React from 'react';
import './ContactUs.scss';
import { AiOutlineArrowRight } from 'react-icons/ai';

const ContactUs = () => {
	return (
		<div
			className='contact'
			style={{
				background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/footer-content/contact.jpg') no-repeat center center/cover`,
			}}
		>
			<div className='contact_content'>
				<div className='contact_nav'>
					<AiOutlineArrowRight className='text-4xl' />
				</div>
				<div>
					<h1 className='contact_title'>Contact Us</h1>
				</div>
				<div className='contact_details text-[]'>
					<p>
						Email Address:{' '}
						<a href='mailto:romatoo.inc@gmail.com'>
							romatoo.inc@gmail.com{' '}
						</a>
					</p>
					<p>
						Mobile Number: <span>+880 1881-515189</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default ContactUs;
