import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	IoChatbubbleEllipses,
	IoPersonCircleOutline,
	IoSearchOutline,
	IoMenu,
	IoChatboxOutline,
} from 'react-icons/io5';
import { FaUserCircle } from 'react-icons/fa';
import { logout } from '../../store/authSlice/authSlice';
import './Navbar.scss';
import NavbarModal from './NavbarModal';
import { socket } from '../../page/Messages/SingleMessage';

const Navbar = () => {
	// hooks

	const { pathname } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);

	// states

	const [search, setSearch] = React.useState('');
	const [showMenu, setShowMenu] = React.useState(false);
	const [modalOpen, setModalOpen] = React.useState(false);
	const [searchOpen, setSearchOpen] = React.useState(false);

	const [myNotifications, setMyNotifications] = React.useState([]);

	// effects

	React.useEffect(() => {
		if (showMenu) {
			setShowMenu(false);
		}

		if (pathname === '/notification') {
			if (auth.token) {
				setMyNotifications([]);

				fetch(`${process.env.REACT_APP_API}/doctor/notifications`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': auth.token,
					},
				})
					.then((res) => res.json())
					.then((res) => {
						console.log('seen notifications res ->', res);
					})
					.catch((err) => {
						console.log('notifications error ->', err);
					});
			}
		}
	}, [pathname, auth.token]);

	React.useEffect(() => {
		if (auth && auth.token) {
			socket.emit('userId', auth.info.id);

			fetch(`${process.env.REACT_APP_API}/doctor/notifications`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': auth.token,
				},
			})
				.then((res) => res.json())
				.then((res) => {
					console.log('get notifications ->', res);

					if (res.success) {
						const newNotifications = res.data.filter(
							(notification) => notification.seen === false
						);

						setMyNotifications(newNotifications);
					}
				})
				.catch((err) => {
					console.log('get notifications error ->', err);
				});
		}
	}, [auth.token]);

	React.useEffect(() => {
		socket.on('newNotification', (data) => {
			setMyNotifications((prev) => [...prev, data]);
		});

		return () => {
			socket.off('newNotification');
		};
	}, [socket]);

	const handleSearch = () => {
		if (search) {
			navigate(`/search?query=${search}`);
		}
	};

	return (
		<nav className='navbar'>
			<div className='nav__container'>
				<div className='left__side'>
					<Link
						to='/'
						className={`brand ${
							pathname.includes('social') ? 'social_page' : ''
						}`}
					>
						<img src='/logo.svg' alt='Romatoo Inc.' />
					</Link>

					{pathname === '/' ||
					pathname === '/sign-in' ||
					pathname === '/sign-up' ? (
						<div
							className={
								auth && auth.token ? 'for__users_nav auth' : 'for__users_nav'
							}
						>
							<Link to='/forDoctors'>For Doctors</Link>

							<Link to='/forUsers'>For Users</Link>

							<Link to='/useAdvancedAI'>Use Advanced AI</Link>
						</div>
					) : null}
				</div>

				<div className='actions'>
					{auth && auth.token ? (
						pathname.includes('social') ? (
							<div className='social__nav'>
								<Link to='/home'>
									<img src='/social/nav_1.jpeg' alt='dashboard' />
								</Link>

								<Link to='/social/feed'>
									<img src='/social/nav_2.jpeg' alt='social' />
								</Link>

								<Link
									to={
										pathname.includes('social') ? '/dashboard' : '/social/feed'
									}
								>
									<img src='/social/nav_3.jpeg' alt='social' />
								</Link>

								<Link to='/blood-donation'>
									<img src='/social/nav_4.png' alt='chat' />
								</Link>

								<Link to='/notification'>
									{myNotifications.length > 0 ? (
										<span className='notification__count'>
											{myNotifications.length}
										</span>
									) : null}
									<img src='/social/nav_5.png' alt='chat' />
								</Link>
							</div>
						) : null
					) : null}

					<div
						className={`search__container ${searchOpen ? 'open' : ''} ${
							pathname.includes('social') ? 'social_page' : ''
						}`}
					>
						<div className='search__input'>
							<input
								type='text'
								placeholder='Search something...'
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										handleSearch();
									}
								}}
							/>
						</div>

						<div
							className='search__icon'
							onClick={() => {
								if (search) {
									handleSearch();
								} else {
									setSearchOpen(!searchOpen);
								}
							}}
						>
							<IoSearchOutline className='icon' />
						</div>
					</div>

					{auth && auth.token ? (
						<>
							{pathname.includes('/social') ? (
								<button
									className='menu__icon chat'
									onClick={() => {
										navigate('/messages');
									}}
								>
									<IoChatboxOutline className='icon' />
								</button>
							) : (
								<button
									className='menu__icon'
									onClick={() => {
										if (showMenu) {
											setShowMenu(false);
										}
										setModalOpen(true);
									}}
								>
									<IoMenu className='icon' />
								</button>
							)}

							<div className='profile__action'>
								<button
									className='btn-profile'
									onClick={() => {
										if (modalOpen) {
											setModalOpen(false);
										}
										setShowMenu(!showMenu);
									}}
								>
									{auth.info.profilePicture ? (
										<img
											src={auth.info.profilePicture}
											alt={auth.info.fullName}
										/>
									) : (
										<IoPersonCircleOutline className='icon' />
									)}
								</button>

								<ul
									className={
										showMenu ? 'dropdown__menu show' : 'dropdown__menu'
									}
								>
									<div className='top'>
										<FaUserCircle className='icon' />
										<div className='profile__details'>
											<h3 className='name'>{auth.info.fullName}</h3>
											<p>
												{auth.info.role === 'doctor' && 'Doctor'}
												{auth.info.role === 'patient' && 'Patient'} Account
											</p>
										</div>
									</div>

									<hr />

									{
										// if user is doctor

										auth.info.role === 'doctor' && (
											<>
												<li className='item'>
													<Link to='/data-center'>
														<img src='/nav-content/data-center.svg' alt='' />
														<span>Data Center</span>
													</Link>
												</li>
												<li className='item'>
													<Link to='/dashboard'>
														<img src='/nav-content/dashboard.svg' alt='' />
														<span>Dashboard</span>
													</Link>
												</li>
												{auth.info.profileStatus !== 'Approved' ? (
													<li className='item'>
														<Link to='/create-dashboard'>
															<img src='/nav-content/appointment.svg' alt='' />
															<span>Create Dashboard</span>
														</Link>
													</li>
												) : (
													<li className='item'>
														<Link to='/edit-dashboard'>
															<img src='/nav-content/appointment.svg' alt='' />
															<span>Edit Dashboard</span>
														</Link>
													</li>
												)}
												<li className='item'>
													<Link to='/article'>
														<img src='/nav-content/article.svg' alt='' />
														<span>Article</span>
													</Link>
												</li>
											</>
										)
									}

									{
										// if user is patient

										auth.info.role === 'patient' && (
											<>
												<li className='item'>
													<Link to='/health-analytics'>
														<img src='/nav-content/analytics.svg' alt='' />
														<span>Health Analytics</span>
													</Link>
												</li>

												<li className='item'>
													<Link to='/my-doctor'>
														<img src='/nav-content/doctor.svg' alt='' />
														<span>My Doctor</span>
													</Link>
												</li>

												<li className='item'>
													<Link to='/e-prescription'>
														<img src='/nav-content/prescription.svg' alt='' />
														<span>E-Prescription</span>
													</Link>
												</li>
											</>
										)
									}

									<li className='item msg'>
										<Link to='/messages'>
											<IoChatbubbleEllipses className='icon' />
											<span>Chats</span>
										</Link>
									</li>

									<li className='item'>
										<Link to='/history'>
											<img src='/nav-content/history.svg' alt='' />
											<span>History</span>
										</Link>
									</li>

									<div className='profile__settings py-2'>
										<li className='item'>
											<Link to='/profile'>
												<img src='/nav-content/profile.svg' alt='' />
												<span>Profile</span>
											</Link>
										</li>
										<li className='item'>
											<Link to='/settings'>
												<img src='/nav-content/settings.svg' alt='' />
												<span>Settings</span>
											</Link>
										</li>
									</div>

									<div className='bottom' id='sec1'>
										<button>
											<a href='#sec2'>Frequently Asked Questions</a>
										</button>

										<button
											onClick={() => {
												navigate('/help-center');
												setShowMenu(false);
											}}
										>
											Help Center
										</button>

										<button
											onClick={() => {
												dispatch(logout());
												setShowMenu(false);
											}}
										>
											Log Out
										</button>
									</div>
								</ul>
							</div>
						</>
					) : (
						<>
							<div className='auth__container'>
								<Link to='/sign-in' className='sign-in'>
									Sign In
								</Link>

								<Link to='/sign-up' className='sign-up'>
									Make an Account
								</Link>
							</div>

							<Link to='/sign-in' className='btn-signIn'>
								Account
							</Link>

							<button className='menu__icon' onClick={() => setModalOpen(true)}>
								<IoMenu className='icon' />
							</button>
						</>
					)}
				</div>
			</div>

			{auth.token ? (
				<div
					className={`nav__container2 ${
						pathname.includes('social') ? 'auth-social' : 'auth'
					}`}
				>
					{pathname.includes('social') ? (
						<>
							<div className='profile__action'>
								<button
									className='btn-profile'
									onClick={() => {
										if (modalOpen) {
											setModalOpen(false);
										}
										setShowMenu(!showMenu);
									}}
								>
									{auth.info.profilePicture ? (
										<img
											src={auth.info.profilePicture}
											alt={auth.info.fullName}
										/>
									) : (
										<IoPersonCircleOutline className='icon' />
									)}
								</button>

								<ul
									className={
										showMenu ? 'dropdown__menu show' : 'dropdown__menu'
									}
								>
									<div className='top'>
										<FaUserCircle className='icon' />
										<div className='profile__details'>
											<h3 className='name'>{auth.info.fullName}</h3>
											<p>
												{auth.info.role === 'doctor' && 'Doctor'}
												{auth.info.role === 'patient' && 'Patient'} Account
											</p>
										</div>
									</div>

									<hr />

									{auth.info.role === 'doctor' && (
										<>
											<li className='item'>
												<Link to='/data-center'>
													<img src='/nav-content/data-center.svg' alt='' />
													<span>Data Center</span>
												</Link>
											</li>
											<li className='item'>
												<Link to='/dashboard'>
													<img src='/nav-content/dashboard.svg' alt='' />
													<span>Dashboard</span>
												</Link>
											</li>
											{auth.info.profileStatus !== 'Approved' ? (
												<li className='item'>
													<Link to='/create-dashboard'>
														<img src='/nav-content/appointment.svg' alt='' />
														<span>Create Dashboard</span>
													</Link>
												</li>
											) : (
												<li className='item'>
													<Link to='/edit-dashboard'>
														<img src='/nav-content/appointment.svg' alt='' />
														<span>Edit Dashboard</span>
													</Link>
												</li>
											)}
											<li className='item'>
												<Link to='/article'>
													<img src='/nav-content/article.svg' alt='' />
													<span>Article</span>
												</Link>
											</li>
										</>
									)}

									{auth.info.role === 'patient' && (
										<>
											<li className='item'>
												<Link to='/health-analytics'>
													<img src='/nav-content/analytics.svg' alt='' />
													<span>Health Analytics</span>
												</Link>
											</li>

											<li className='item'>
												<Link to='/my-doctor'>
													<img src='/nav-content/doctor.svg' alt='' />
													<span>My Doctor</span>
												</Link>
											</li>

											<li className='item'>
												<Link to='/e-prescription'>
													<img src='/nav-content/prescription.svg' alt='' />
													<span>E-Prescription</span>
												</Link>
											</li>
										</>
									)}

									<li className='item msg'>
										<Link to='/messages'>
											<IoChatbubbleEllipses className='icon' />
											<span>Chats</span>
										</Link>
									</li>

									<li className='item'>
										<Link to='/history'>
											<img src='/nav-content/history.svg' alt='' />
											<span>History</span>
										</Link>
									</li>

									<div className='profile__settings py-2'>
										<li className='item'>
											<Link to='/profile'>
												<img src='/nav-content/profile.svg' alt='' />
												<span>Profile</span>
											</Link>
										</li>
										<li className='item'>
											<Link to='/settings'>
												<img src='/nav-content/settings.svg' alt='' />
												<span>Settings</span>
											</Link>
										</li>
									</div>

									<div className='bottom' id='sec1'>
										<button>
											<a href='#sec2'>Frequently Asked Questions</a>
										</button>

										<button
											onClick={() => {
												navigate('/help-center');
												setShowMenu(false);
											}}
										>
											Help Center
										</button>

										<button
											onClick={() => {
												dispatch(logout());
												setShowMenu(false);
											}}
										>
											Log Out
										</button>
									</div>
								</ul>
							</div>

							<div className='search__container'>
								<div className='search__input'>
									<input
										type='text'
										placeholder='Search something...'
										value={search}
										onChange={(e) => setSearch(e.target.value)}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleSearch();
											}
										}}
									/>
								</div>

								<div
									className='search__icon'
									onClick={() => {
										if (search) {
											handleSearch();
										} else {
											setSearchOpen(!searchOpen);
										}
									}}
								>
									<IoSearchOutline className='icon' />
								</div>
							</div>

							<div className='chat__container'>
								<button
									className='chat__icon'
									onClick={() => {
										navigate('/messages');
									}}
								>
									<IoChatbubbleEllipses className='icon' />
								</button>
							</div>
						</>
					) : (
						<>
							<Link to='/' className='brand'>
								<img src='/logo.svg' alt='Romatoo Inc.' />
							</Link>

							<div className='right__side'>
								<button
									className='menu__icon'
									onClick={() => setModalOpen(true)}
								>
									<IoMenu className='icon' />
								</button>

								<div className='profile__action'>
									<button
										className='btn-profile'
										onClick={() => {
											if (modalOpen) {
												setModalOpen(false);
											}
											setShowMenu(!showMenu);
										}}
									>
										{auth.info.profilePicture ? (
											<img
												src={auth.info.profilePicture}
												alt={auth.info.fullName}
											/>
										) : (
											<IoPersonCircleOutline className='icon' />
										)}
									</button>

									<ul
										className={
											showMenu ? 'dropdown__menu show' : 'dropdown__menu'
										}
									>
										<div className='top'>
											<FaUserCircle className='icon' />
											<div className='profile__details'>
												<h3 className='name'>{auth.info.fullName}</h3>
												<p>
													{auth.info.role === 'doctor' && 'Doctor'}
													{auth.info.role === 'patient' && 'Patient'} Account
												</p>
											</div>
										</div>

										<hr />

										{auth.info.role === 'doctor' && (
											<>
												<li className='item'>
													<Link to='/data-center'>
														<img src='/nav-content/data-center.svg' alt='' />
														<span>Data Center</span>
													</Link>
												</li>
												<li className='item'>
													<Link to='/dashboard'>
														<img src='/nav-content/dashboard.svg' alt='' />
														<span>Dashboard</span>
													</Link>
												</li>
												{auth.info.profileStatus !== 'Approved' ? (
													<li className='item'>
														<Link to='/create-dashboard'>
															<img src='/nav-content/appointment.svg' alt='' />
															<span>Create Dashboard</span>
														</Link>
													</li>
												) : (
													<li className='item'>
														<Link to='/edit-dashboard'>
															<img src='/nav-content/appointment.svg' alt='' />
															<span>Edit Dashboard</span>
														</Link>
													</li>
												)}
												<li className='item'>
													<Link to='/article'>
														<img src='/nav-content/article.svg' alt='' />
														<span>Article</span>
													</Link>
												</li>
											</>
										)}

										{auth.info.role === 'patient' && (
											<>
												<li className='item'>
													<Link to='/health-analytics'>
														<img src='/nav-content/analytics.svg' alt='' />
														<span>Health Analytics</span>
													</Link>
												</li>

												<li className='item'>
													<Link to='/my-doctor'>
														<img src='/nav-content/doctor.svg' alt='' />
														<span>My Doctor</span>
													</Link>
												</li>

												<li className='item'>
													<Link to='/e-prescription'>
														<img src='/nav-content/prescription.svg' alt='' />
														<span>E-Prescription</span>
													</Link>
												</li>
											</>
										)}

										<li className='item msg'>
											<Link to='/messages'>
												<IoChatbubbleEllipses className='icon' />
												<span>Chats</span>
											</Link>
										</li>

										<li className='item'>
											<Link to='/history'>
												<img src='/nav-content/history.svg' alt='' />
												<span>History</span>
											</Link>
										</li>

										<div className='profile__settings py-2'>
											<li className='item'>
												<Link to='/profile'>
													<img src='/nav-content/profile.svg' alt='' />
													<span>Profile</span>
												</Link>
											</li>
											<li className='item'>
												<Link to='/settings'>
													<img src='/nav-content/settings.svg' alt='' />
													<span>Settings</span>
												</Link>
											</li>
										</div>

										<div className='bottom' id='sec1'>
											<button>
												<a href='#sec2'>Frequently Asked Questions</a>
											</button>

											<button
												onClick={() => {
													navigate('/help-center');
													setShowMenu(false);
												}}
											>
												Help Center
											</button>

											<button
												onClick={() => {
													dispatch(logout());
													setShowMenu(false);
												}}
											>
												Log Out
											</button>
										</div>
									</ul>
								</div>
							</div>
						</>
					)}

					<div className='social__nav'>
						<Link to='/home'>
							<img src='/social/nav_1.jpeg' alt='dashboard' />
						</Link>

						<Link to='/social/feed'>
							<img src='/social/nav_2.jpeg' alt='social' />
						</Link>

						<Link
							to={pathname.includes('social') ? '/dashboard' : '/social/feed'}
						>
							<img src='/social/nav_3.jpeg' alt='social' />
						</Link>

						<Link to='/blood-donation'>
							<img src='/social/nav_4.png' alt='chat' />
						</Link>

						<Link to='/notification'>
							{myNotifications.length > 0 ? (
								<span className='notification__count'>
									{myNotifications.length}
								</span>
							) : null}
							<img src='/social/nav_5.png' alt='chat' />
						</Link>
					</div>
				</div>
			) : (
				<div className='nav__container2 no-auth'>
					<Link to='/' className='brand'>
						<img src='/logo.svg' alt='Romatoo Inc.' />
					</Link>

					<div className='right__side'>
						<Link to='/sign-in' className='btn-signIn'>
							Account
						</Link>

						<button className='menu__icon' onClick={() => setModalOpen(true)}>
							<IoMenu className='icon' />
						</button>
					</div>
				</div>
			)}

			{modalOpen ? <NavbarModal handleChange={setModalOpen} /> : null}
		</nav>
	);
};

export default Navbar;
