import React from 'react';
import { IoVideocamOutline } from 'react-icons/io5';
import { ImAttachment } from 'react-icons/im';
import { FiSend } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import useImageUpload from '../../hooks/useImageUpload';
export const socket = io(process.env.REACT_APP_SOCKET, {
	path: process.env.REACT_APP_SOCKET_CHAT_PATH,
});

const SingleMessage = () => {
	const { uploadLoading, uploadImage } = useImageUpload();

	// socket connection

	// hooks

	const { conversationId } = useParams();
	const auth = useSelector((state) => state.auth);

	// states and refs

	const inputRef = React.useRef(null);
	const [image, setImage] = React.useState(null);

	const [conversation, setConversation] = React.useState(null);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		socket.emit('join', conversationId);

		setLoading(true);

		let url;

		if (auth?.info?.role === 'doctor') {
			url = `${process.env.REACT_APP_API}/doctor/conversations/${conversationId}`;
		} else {
			url = `${process.env.REACT_APP_API}/patient/conversations/${conversationId}`;
		}

		fetch(url, {
			method: 'GET',
			headers: {
				'x-access-token': auth?.token,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.success) {
					setConversation(res.data);
					console.log(res.data);
				} else {
					alert(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [conversationId]);

	React.useEffect(() => {
		socket.on('messages', (data) => {
			console.log('message : ', data);
			setConversation((prev) => ({
				...prev,
				messages: [...prev.messages, data],
			}));
		});

		return () => {
			socket.off('messages');
		};
	}, [socket]);

	React.useEffect(() => {
		// scroll to bottom
		if (conversation?.messages?.length) {
			const lastMessage = document.querySelector(
				'.messages .message:last-child'
			);
			lastMessage.scrollIntoView({ behavior: 'smooth' });
		}
	}, [conversation?.messages?.length]);

	const sendMessage = () => {
		const message = inputRef.current.value;
		const attachment = image ? [image] : [];

		if (message.trim() === '' && attachment.length === 0) {
			return;
		}

		inputRef.current.value = '';
		setImage(null);

		let url;

		if (auth?.info?.role === 'doctor') {
			url = `${process.env.REACT_APP_API}/doctor/conversations/${conversationId}`;
		} else {
			url = `${process.env.REACT_APP_API}/patient/conversations/${conversationId}`;
		}

		fetch(url, {
			method: 'PUT',
			headers: {
				'x-access-token': auth?.token,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ messageText: message, attachment }),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.success) {
					console.log('send msg res : ', res.data);

					socket.emit('sendMessage', {
						chatId: conversationId,
						data: res.data,
					});

					inputRef.current.value = '';

					setConversation((prev) => ({
						...prev,
						messages: [...prev.messages, res.data],
					}));
				} else {
					alert(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	React.useEffect(() => {
		if (image) {
			sendMessage();
		}
	}, [image]);

	if (loading) {
		return <p>Loading...</p>;
	}

	return (
		<div className='full-conversation'>
			<div className='topbar'>
				<div className='left'>
					<div className='image'>
						<img
							src={
								auth?.info?.id === conversation?.doctor?._id
									? conversation?.userId?.profileInfo?.profilePicture ||
									  '/images/avatar.png'
									: conversation?.doctor?.profileInfo?.profilePicture ||
									  '/images/avatar.png'
							}
							alt={
								auth?.info?.id === conversation?.doctor?._id
									? conversation?.userId?.fullName
									: conversation?.doctor?.fullName
							}
						/>
					</div>

					<div className='info'>
						<h4 className='name'>
							{auth?.info?.id === conversation?.doctor?._id
								? conversation?.userId?.fullName
								: conversation?.doctor?.fullName}
						</h4>
						<span className='status'>
							Last seen{' '}
							{(conversation?.messages &&
								conversation?.messages[
									conversation?.messages?.length - 1
								]?.createdAt?.split('T')[0]) ||
								conversation?.createdAt?.split('T')[0]}
						</span>
					</div>
				</div>

				<div className='right'>
					{conversation?.userRole === 'Patient' ? (
						<Link
							className='video__call'
							to={`/video/${conversation._id}`}
							target='_blank'
							rel='noopener noreferrer'
						>
							<IoVideocamOutline className='icon' />
						</Link>
					) : null}
				</div>
			</div>

			<div className='messages'>
				<div className='messages-container'>
					{conversation?.messages?.map((message, index) => (
						<div
							className={`message ${
								message.sender?.toLowerCase() === auth?.info?.id
									? 'right'
									: 'left'
							}`}
							key={index}
						>
							{message.sender !== auth?.info?.id && (
								<div className='image'>
									<img
										src={
											conversation?.userId?._id === message.sender
												? conversation?.userId?.profileInfo?.profilePicture ||
												  '/images/avatar.png'
												: conversation?.doctor?.profileInfo?.profilePicture ||
												  '/images/avatar.png'
										}
										alt={
											conversation?.userId?._id === message.sender
												? conversation?.userId?.fullName
												: conversation?.doctor?.fullName
										}
									/>
								</div>
							)}

							<div className='body'>
								<p>
									{message.messageText ? (
										message.messageText
									) : (
										<img
											src={message.attachment[0]}
											alt={`attachment-${index}`}
											style={{
												width: '100%',
												maxWidth: '300px',
												height: '100%',
												maxHeight: '300px',
												objectFit: 'contain',
											}}
										/>
									)}
								</p>

								<span className='time'>
									{/* {message.createdAt?.split('T')[1]?.split('.')[0].slice(0, 5)} */}

									{new Date(message.createdAt)
										.toLocaleTimeString()
										.slice(0, 5) +
										' ' +
										new Date(message.createdAt)
											.toLocaleTimeString()
											.slice(8, 11)}
								</span>
							</div>

							{message.sender === auth?.info?.id && (
								<div className='image'>
									<img
										src={
											auth.info?.profileInfo?.profilePicture ||
											'/images/avatar.png'
										}
										alt={auth?.info?.fullName}
									/>
								</div>
							)}
						</div>
					))}
				</div>
			</div>

			<div className='footer'>
				<label htmlFor='uploadImage'>
					<ImAttachment className='icon' />
				</label>

				<input
					type='file'
					id='uploadImage'
					hidden
					accept='image/*'
					onChange={(e) => {
						uploadImage(e, setImage);
					}}
				/>

				<input
					type='text'
					ref={inputRef}
					className='message__input'
					placeholder='Type a message'
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							sendMessage();
						}
					}}
				/>

				<button className='btn-send' onClick={sendMessage}>
					<FiSend className='icon' />
				</button>
			</div>
		</div>
	);
};

export default SingleMessage;
