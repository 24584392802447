import React from 'react';
import './DataCenter.scss';

const DataCenter = () => {
	return (
		<div className='data__center'>
			<h1>Data center coming soon...</h1>
		</div>
	);
};

export default DataCenter;
