import React from 'react';
import './History.scss';

const History = () => {
	return (
		<div className='history'>
			<h1>History coming soon...</h1>
		</div>
	);
};

export default History;
