import React from 'react';
import './MyDoctor.scss';

const MyDoctor = () => {
	return (
		<div className='my__doctor'>
			<h1>My doctor coming soon...</h1>
		</div>
	);
};

export default MyDoctor;
