import React from 'react';
import { FaPen } from 'react-icons/fa';
import { BsPlusLg } from 'react-icons/bs';
import {
	IoAdd,
	IoClose,
	IoTrashOutline,
	IoCreateOutline,
	IoCalendarClearOutline,
	IoShareSocial,
} from 'react-icons/io5';
import {
	eDisease,
	eAttach,
	eAI,
	presTest,
	eFolder,
	medData,
	medSkeleton,
	medicalSecSkeleton,
} from '../../assets';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MedBox from '../EPrescription/MedBox';
import '../EPrescription/Prescription.scss';
import '../EPrescription/MedBox.scss';
import { toast } from 'react-toastify';

const PrescriptionPopup = ({ patientId }) => {
	// hooks

	const navigate = useNavigate();
	const token = useSelector((state) => state.auth.token);
	const authInfo = useSelector((state) => state.auth.info);

	// states

	const [selectedCategory, setSelectedCategory] = React.useState(
		presTest[0].category
	);
	const [selectedTest, setSelectedTest] = React.useState([]);
	const [customTest, setCustomTest] = React.useState([]);
	const [medicines, setMedicines] = React.useState([
		{ ...medSkeleton, id: 0 },
		{ ...medSkeleton, id: 1 },
	]);
	const [patientInfo, setPatientInfo] = React.useState({
		patientName: '',
		disease: '',
		age: '',
		gender: '',
		bmi: '',
		weight: '',
	});
	const [presentMedicalSection, setPresentMedicalSection] =
		React.useState(medicalSecSkeleton);
	const [nextMeet, setNextMeet] = React.useState('');
	const [expires, setExpires] = React.useState('');
	const [customTestModal, setCustomTestModal] = React.useState(false);

	// effects

	React.useEffect(() => {
		if (!token) {
			navigate('/sign-in');
		}
	}, []);

	React.useEffect(() => {
		if (!patientId) {
			return;
		}
	}, [patientId]);

	// functions

	const handleCreate = () => {
		if (authInfo?.profileInfo?.signature === '') {
			alert('Please update your profile information');
			return;
		} // check signature

		// const patientData = { ...patientInfo };

		// for (const key in patientData) {
		// 	if (patientData[key] === '') {
		// 		alert(
		// 			`Please enter patient ${
		// 				key === 'patientName' ? 'name' : key
		// 			}`
		// 		);
		// 		return;
		// 	}
		// } // check patient info

		if (patientInfo.patientName === '') {
			alert('Please enter patient name');
			return;
		} // check patient name

		if (patientInfo.age === '') {
			alert('Please enter patient age');
			return;
		} // check patient age

		const allMedicines = [...medicines];
		let validMedicines = [];

		allMedicines.forEach((med) => {
			let valid = true;

			for (const key in med) {
				if (key === 'id' || key === 'note') {
					continue;
				} else if (key === 'timeTable') {
					let timeTable = [];

					med[key].forEach((time) => {
						if (time.editable && time.time !== '' && time.amount !== '') {
							timeTable.push(time);
						}

						if (!time.editable) {
							timeTable.push(time);
						}
					});

					const findEmpty = timeTable.filter((x) => x.amount !== '');

					if (findEmpty.length === 0) {
						valid = false;
					} else {
						med[key] = timeTable;
					}
				} else if (med[key] === '') {
					valid = false;
				} else {
					continue;
				}
			}

			if (valid) {
				validMedicines.push(med);
			}
		}); // find valid medicines

		if (validMedicines.length === 0) {
			alert('Please add some medicines');
			return;
		}

		// if (nextMeet === '') {
		// 	alert('Please enter next meet date');
		// 	return;
		// } // check next meet

		if (expires === '') {
			alert('Please enter expires date');
			return;
		} // check expires

		const prescriptionData = {
			doctorId: authInfo.id,
			patientInfo: {
				patientName: patientInfo.patientName,
				patientAge: patientInfo.age,
			},
			doctorInfo: {
				fullName: authInfo.fullName,
				profilePicture: authInfo.profilePicture,
				gender: authInfo.gender,
				phone: authInfo.phone,
				profileInfo: {
					...authInfo.profileInfo,
				},
			},
			medicines: validMedicines,
			nextMeet,
			expires,
			doctorName: authInfo ? authInfo.fullName : 'test',
			signature: authInfo.profileInfo.signature,
		};

		console.log('prescriptionData ->', prescriptionData);

		const url = `${process.env.REACT_APP_API}/doctor/prescription`;

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(
				patientId ? { ...prescriptionData, patientId } : { ...prescriptionData }
			),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('prescription res ->', res);

				if (res.success) {
					toast.success('Prescription created successfully');
					setTimeout(() => {
						// navigate(`/view/prescription/${res.data._id}`);
						window.open(`/view/prescription/${res.data._id}`, '_blank');
					}, 2000);
				} else {
					toast.error('Something went wrong');
				}
			})
			.catch((err) => {
				console.log('prescription err ->', err);
				toast.error('Something went wrong');
			});
	};

	const handleCreateTest = () => {
		if (authInfo?.profileInfo?.signature === '') {
			alert('Please update your profile information');
			return;
		} // check signature

		if (patientInfo.patientName === '') {
			alert('Please enter patient name');
			return;
		} // check patient name

		if (patientInfo.age === '') {
			alert('Please enter patient age');
			return;
		} // check patient age

		if (selectedTest.length === 0) {
			alert('Please select some tests');
			return;
		} // check tests

		const testsData = {
			doctorId: authInfo.id,
			patientInfo: {
				patientName: patientInfo.patientName,
				patientAge: patientInfo.age,
			},
			doctorInfo: {
				fullName: authInfo.fullName,
				profilePicture: authInfo.profilePicture,
				gender: authInfo.gender,
				phone: authInfo.phone,
				profileInfo: {
					...authInfo.profileInfo,
				},
			},
			presentMedicalSection: [...presentMedicalSection],
			tests: [...selectedTest],
			doctorName: authInfo ? authInfo.fullName : 'test',
			signature: authInfo.profileInfo.signature,
		};

		console.log('testsData ->', testsData);

		const url = `${process.env.REACT_APP_API}/doctor/prescription/tests`;

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': token,
			},
			body: JSON.stringify(
				patientId ? { ...testsData, patientId } : { ...testsData }
			),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('prescription res ->', res);

				if (res.success) {
					toast.success('Tests created successfully');
					setTimeout(() => {
						// navigate(`/tests/${res.data._id}`);
						window.open(`/tests/${res.data._id}`, '_blank');
					}, 2000);
				} else {
					toast.error('Something went wrong');
				}
			})
			.catch((err) => {
				console.log('prescription err ->', err);
				toast.error('Something went wrong');
			});
	};

	const handleAddBlankMedicalSection = () => {
		setPresentMedicalSection((prev) => [
			...prev,
			{
				name: '',
				label: '',
				text: '',
				edit: true,
			},
		]);
	};

	return (
		<div className='ePrescription'>
			<div className='box'>
				<h1 className='sec__header'>Patient Info</h1>

				<div className='sec__one'>
					<div className='left'>
						<div className='cover'>
							<img src='/my-profile/female-avatar.png' alt='avatar' />
						</div>
					</div>

					<div className='right'>
						<label htmlFor='patientName'>
							<FaPen className='icon' />
							<input
								type='text'
								name='patientName'
								id='patientName'
								placeholder='Enter Patient Name...'
								onChange={(e) => {
									setPatientInfo({
										...patientInfo,
										patientName: e.target.value,
									});
								}}
							/>
						</label>

						{/* <div className='disease'>
							<label htmlFor='disease' className='add'>
								<img src={eDisease} alt='disease' />
								<input
									type='text'
									name='disease'
									id='disease'
									placeholder='Add Disease'
									onChange={(e) => {
										setPatientInfo({
											...patientInfo,
											disease: e.target.value,
										});
									}}
								/>
								<FaPen className='icon' />
							</label>
						</div> */}

						<div className='info'>
							<div className='item'>
								<p>Age</p>
								<input
									type='text'
									placeholder='Ex: 27'
									onChange={(e) => {
										setPatientInfo({
											...patientInfo,
											age: e.target.value,
										});
									}}
								/>
							</div>

							<div className='item'>
								<p>Gender</p>
								{/* <input
									type='text'
									placeholder='Ex: Male'
									onChange={(e) => {
										setPatientInfo({
											...patientInfo,
											gender: e.target.value,
										});
									}}
								/> */}
								<select
									onChange={(e) => {
										setPatientInfo({
											...patientInfo,
											gender: e.target.value,
										});
									}}
									className='border-none ring-0 text-sm focus:outline-none focus:ring-0 focus:border-none p-1 pr-2'
								>
									<option value='male'>Male</option>
									<option value='female'>Female</option>
									<option value='other'>Other</option>
								</select>
							</div>

							<div className='item'>
								<p>BMI</p>
								<input
									type='text'
									placeholder='Ex: 24.5'
									onChange={(e) => {
										setPatientInfo({
											...patientInfo,
											bmi: e.target.value,
										});
									}}
								/>
							</div>

							<div className='item'>
								<p>Weight</p>
								<input
									type='text'
									placeholder='Ex: 60'
									onChange={(e) => {
										setPatientInfo({
											...patientInfo,
											weight: e.target.value,
										});
									}}
								/>
							</div>

							<div className='addItem'>
								<IoAdd className='icon' />
								<IoTrashOutline className='icon' />
							</div>
						</div>
					</div>
				</div>

				{/* <h1 className='sec__header'>Past Medical Records</h1>

				<div className='sec__empty'>
					<h1>No Past Medical Records Found</h1>
				</div> */}

				<h1 className='sec__header'>Present Medical Section</h1>

				<div className='sec__two'>
					<div className='left'>
						{presentMedicalSection.map((item, index) => (
							<div className='block' key={index}>
								<div className='block__content'>
									{item.edit ? (
										<h3>
											<input
												type='text'
												name='label'
												id='label'
												placeholder='Enter title...'
												value={item.label}
												onChange={(e) => {
													const newPresentMedicalSection = [
														...presentMedicalSection,
													];
													newPresentMedicalSection[index].label =
														e.target.value;
													newPresentMedicalSection[index].name =
														e.target.value.replace(/\s/g, '');
													setPresentMedicalSection(newPresentMedicalSection);
												}}
											/>
										</h3>
									) : (
										<h3>{item.label}</h3>
									)}
									<textarea
										name={item.name}
										id={item.name}
										placeholder='Write here...'
										value={item.text}
										onChange={(e) => {
											const newPresentMedicalSection = [
												...presentMedicalSection,
											];
											newPresentMedicalSection[index].text = e.target.value;
											setPresentMedicalSection(newPresentMedicalSection);
										}}
									></textarea>
								</div>
							</div>
						))}
					</div>

					<div className='right'>
						<IoAdd className='icon' onClick={handleAddBlankMedicalSection} />
						<IoTrashOutline className='icon' />
					</div>
				</div>

				<h1 className='sec__header'>Investigation Chart</h1>

				<div className='sec__test'>
					<div className='left'>
						{presTest.map((item, index) => (
							<button
								key={index}
								onClick={() => setSelectedCategory(item.category)}
								className={
									selectedCategory === item.category
										? 'category active'
										: 'category'
								}
							>
								{item.category}
							</button>
						))}
					</div>

					<div className='right'>
						<div className='tests'>
							<div className='tests__container'>
								{presTest
									.find((item) => item.category === selectedCategory)
									.tests.map((test, index) => (
										<label htmlFor={test} key={index}>
											<input
												type='checkbox'
												name={test}
												id={test}
												key={index}
												checked={selectedTest.includes(test)}
												onChange={(e) => {
													if (e.target.checked) {
														setSelectedTest([...selectedTest, test]);
													} else {
														setSelectedTest(
															selectedTest.filter((item) => item !== test)
														);
													}
												}}
											/>
											<span>{test}</span>
										</label>
									))}
							</div>

							<div className='actions'>
								<button
									onClick={() => {
										setCustomTestModal(true);
									}}
								>
									Add Custom Test
								</button>
							</div>
						</div>

						{/* <div className='overview'>
							<h1>
								<img src={eAttach} alt='attach' />
								<span>Patient Attachment</span>
							</h1>

							<div className='folder__img'>
								<img src={eFolder} alt='folder' />
							</div>
						</div> */}

						<div className='overview'>
							{selectedTest.length > 0 ? (
								<>
									<h1>{selectedTest.length} Test Selected</h1>

									<div className='selectedTest'>
										{selectedTest.map((test, index) => (
											<p key={index}>
												<span>{test}</span>
												<IoClose
													onClick={() => {
														setSelectedTest((prev) => {
															return prev.filter((item) => item !== test);
														});
														setCustomTest((prev) => {
															return prev.filter((item) => item.name !== test);
														});
													}}
													className='icon'
												/>
											</p>
										))}
									</div>
								</>
							) : (
								<h1>No Test Selected</h1>
							)}
						</div>
					</div>
				</div>

				<div className='sec__two__sub'>
					<button className='btn-printTest' onClick={handleCreateTest}>
						<span>Share with Patient</span>
						<IoShareSocial className='icon' />
					</button>
				</div>

				<h1 className='sec__header'>Patients Attachment</h1>

				<div className='sec__empty'>
					<h1>No Attachment found</h1>
				</div>

				<h1 className='ai__header'>
					<img src={eAI} alt='ai' />
					<p>Prescription</p>
				</h1>

				<div className='sec__three'>
					{medicines.map((item, index) => (
						<MedBox
							key={index}
							item={item}
							medicines={medicines}
							setMedicines={setMedicines}
						/>
					))}
				</div>

				<div className='sec__three__sub'>
					<button
						onClick={() => {
							setMedicines((prev) => {
								return [
									...prev,
									{
										...medSkeleton,
										id: prev[prev.length - 1].id + 1,
									},
								];
							});
						}}
					>
						<span>Add Another</span>
						<BsPlusLg className='icon' />
					</button>
				</div>

				<div className='sec__four'>
					<div className='left'>
						<div className='block'>
							<label htmlFor='nextMeet'>
								<IoCalendarClearOutline className='icon' />
								<span>Next Meet</span>
							</label>

							<div className='input__field'>
								<FaPen className='icon' />
								<input
									type='text'
									name='nextMeet'
									id='nextMeet'
									placeholder='Ex: 30 Days'
									onChange={(e) => {
										setNextMeet(e.target.value);
									}}
								/>
							</div>
						</div>

						<div className='block'>
							<label htmlFor='expires'>
								<IoCalendarClearOutline className='icon' />
								<span>Expires</span>
							</label>

							<div className='input__field'>
								<FaPen className='icon' />
								<input
									type='text'
									name='expires'
									id='expires'
									placeholder='Ex: 2023-01-31'
									onChange={(e) => {
										setExpires(e.target.value);
									}}
								/>
							</div>
						</div>

						{/* <div className='block'>
							<label htmlFor='signature'>
								<IoCreateOutline className='icon' />
								<span>Signature</span>
							</label>

							<div className='input__field'>
								<IoAdd className='icon' />
								<input
									type='file'
									name='signature'
									id='signature'
									placeholder='Add...'
								/>
							</div>
						</div> */}
					</div>
					<div className='right'>
						<button className='btn-create' onClick={handleCreate}>
							Create
						</button>
					</div>
				</div>
			</div>

			<div
				className={
					customTestModal ? 'customTestModal active' : 'customTestModal'
				}
			>
				<div className='modal__container'>
					<button
						className='btn-closeModal'
						onClick={() => {
							setCustomTestModal(false);
						}}
					>
						<IoClose className='icon' />
					</button>

					<h1>
						<span>Add Custom Test</span>
					</h1>

					{Array(8)
						.fill(0)
						.map((_, index) => (
							<div className='input__field' key={index}>
								<FaPen className='icon' />
								<input
									type='text'
									name='customTest'
									id='customTest'
									placeholder={`Enter Test Name ${index + 1}`}
									onChange={(e) => {
										setCustomTest((prev) => {
											const newTest = {
												id: index + 1,
												name: e.target.value,
											};

											const filteredTest = prev.filter(
												(item) => item.id !== index + 1
											);

											return [...filteredTest, newTest];
										});
									}}
									value={
										customTest.find((item) => item.id === index + 1)?.name || ''
									}
								/>
							</div>
						))}

					<div className='actions'>
						<button
							onClick={() => {
								setCustomTestModal(false);
							}}
						>
							Cancel
						</button>

						<button
							onClick={() => {
								setCustomTestModal(false);
								setSelectedTest((prev) => {
									const newTest = customTest.map((item) => item.name);

									return [...new Set([...prev, ...newTest])];
								});
							}}
						>
							Add Tests
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrescriptionPopup;
