import React from 'react';
import './Settings.scss';

const Settings = () => {
	return (
		<div className='settings'>
			<h1>Settings coming soon...</h1>
		</div>
	);
};

export default Settings;
