import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useImageUpload from '../../hooks/useImageUpload';
import {
	IoCloseSharp,
	IoEyeOutline,
	IoEyeOffOutline,
	IoCheckmarkCircle,
} from 'react-icons/io5';
import { toast } from 'react-toastify';
import { countryData } from '../../data';
import './SignUp.scss';
import LoadingSVG from '../../components/LoadingSVG/LoadingSVG';

const SignUp = () => {
	// page title

	window.document.title = 'Sign Up | Romatoo';

	// hooks

	const navigate = useNavigate();
	const { uploadImage, uploadLoading } = useImageUpload();

	// states

	const [userType, setUserType] = React.useState('patient'); // patient or doctor
	const [userData, setUserData] = React.useState(
		userType === 'patient'
			? {
					fullName: '',
					email: '',
					password: '',
					dob: '',
					gender: 'Male',
					phone: '',
			  }
			: {
					role: '', // doctor, intern, medical student, pharmacist
					fullName: '',
					email: '',
					password: '',
					dob: '',
					gender: 'Male',
					phone: '',
					nationality: '',
					nidCardImage: '',
					nidCardNumber: '',
			  }
	);

	const [error, setError] = React.useState('');
	const [showPass, setShowPass] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	// functions

	const handleSubmit = (e) => {
		e.preventDefault();

		// check if all fields are filled

		if (
			userType === 'patient' &&
			(!userData.fullName ||
				!userData.password ||
				!userData.gender ||
				!userData.dob ||
				!userData.phone)
		) {
			setError('Please fill all the fields');
			return;
		}

		if (
			userType === 'doctor' &&
			(!userData.fullName ||
				!userData.password ||
				!userData.gender ||
				!userData.dob ||
				!userData.phone ||
				!userData.nationality)
		) {
			console.log('userData : ', userData);
			setError('Please fill all the fields');
			return;
		}

		setError('');
		setLoading(true);

		if (userType === 'patient') {
			// patient sign up

			const data = {
				fullName: userData.fullName,
				email: userData.email,
				password: userData.password,
				dateOfBirth: userData.dob,
				gender: userData.gender,
				phone: userData.phone,
			};

			const url = `${process.env.REACT_APP_API}/patient/register`;

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((res) => {
					// console.log('patient sign up response : ', res);

					if (res.success) {
						toast.success(res.message);
						setTimeout(() => {
							navigate('/sign-in');
						}, 2000);
					} else {
						toast.error(res.message);
						setError(res.message);
					}
				})
				.catch((err) => {
					console.log(err);
					toast.error('Something went wrong');
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			// doctor sign up

			const data = {
				role: userData.role,
				fullName: userData.fullName,
				email: userData.email,
				password: userData.password,
				dateOfBirth: userData.dob,
				gender: userData.gender,
				phone: userData.phone,
				nationality: userData.nationality,
				nationalID: {
					number: userData.nidCardNumber,
					image: userData.nidCardImage,
				},
			};

			const url = `${process.env.REACT_APP_API}/doctor/register`;

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((res) => {
					// console.log('patient sign up response : ', res);

					if (res.success) {
						toast.success(res.message);
						setTimeout(() => {
							navigate('/sign-in');
						}, 2000);
					} else {
						toast.error(res.message);
						setError(res.message);
					}
				})
				.catch((err) => {
					console.log(err);
					setError(err.message + ' ' + process.env.REACT_APP_API);
					toast.error('Something went wrong');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<section className='signUp page'>
			<div className='container'>
				<div className='user__type'>
					<button
						onClick={() => {
							setUserType('patient');
							setError('');
						}}
					>
						{userType === 'patient' && <IoCheckmarkCircle className='icon' />}
						I'm a User
					</button>
					<button
						onClick={() => {
							setUserType('doctor');
						}}
					>
						{userType === 'doctor' && <IoCheckmarkCircle className='icon' />}
						I'm a Health Care Professional
					</button>
				</div>

				<div className='user__form'>
					<div className='top'>
						<h1>Sign Up</h1>
						<p>
							You are signing up as a
							{userType === 'patient' ? ' User' : ' Health Care Professional'}
						</p>

						<button
							className='btn-close'
							onClick={() => {
								navigate('/');
							}}
						>
							<IoCloseSharp className='icon' />
						</button>
					</div>

					<form className='body' onSubmit={handleSubmit}>
						{userType === 'doctor' && (
							<div className='form__group'>
								<select
									onChange={(e) => {
										setUserData((prev) => ({
											...prev,
											role: e.target.value,
										}));
									}}
								>
									<option value=''>Select Your Role</option>
									<option value='doctor'>Doctor</option>
									<option value='intern'>Intern</option>
									<option value='medical student'>Medical Student</option>
									<option value='pharmacist'>Pharmacist</option>
								</select>
							</div>
						)}

						<div className='form__group'>
							<input
								type='text'
								placeholder='Your Full Name'
								onChange={(e) => {
									setUserData((prev) => ({
										...prev,
										fullName: e.target.value,
									}));
								}}
							/>
						</div>

						<div className='form__group'>
							<input
								type='email'
								placeholder='Your Email Address'
								onChange={(e) => {
									setUserData((prev) => ({
										...prev,
										email: e.target.value,
									}));
								}}
							/>
						</div>

						<div className='form__group pass'>
							<input
								type={showPass ? 'text' : 'password'}
								placeholder='Your Password'
								onChange={(e) => {
									setUserData((prev) => ({
										...prev,
										password: e.target.value,
									}));
								}}
							/>
							{showPass ? (
								<IoEyeOutline
									className='icon'
									onClick={() => setShowPass(false)}
								/>
							) : (
								<IoEyeOffOutline
									className='icon'
									onClick={() => setShowPass(true)}
								/>
							)}
						</div>

						<p className='pb-2 pl-2'>Date of Birth</p>

						<div className='form__group2'>
							<input
								type='date'
								placeholder='Date of Birth'
								onChange={(e) => {
									setUserData((prev) => ({
										...prev,
										dob: e.target.value,
									}));
								}}
								defaultValue='2000-01-01'
							/>

							<select
								onChange={(e) => {
									setUserData((prev) => ({
										...prev,
										gender: e.target.value,
									}));
								}}
							>
								<option value='Male'>Male</option>
								<option value='Female'>Female</option>
								<option value='Other'>Other</option>
							</select>
						</div>

						<div className='form__group'>
							<input
								type='number'
								placeholder='Your Phone Number'
								onChange={(e) => {
									setUserData((prev) => ({
										...prev,
										phone: e.target.value,
									}));
								}}
							/>
						</div>

						{userType === 'doctor' && (
							<>
								<div className='form__group'>
									<select
										onChange={(e) => {
											setUserData((prev) => ({
												...prev,
												nationality: e.target.value,
											}));
										}}
									>
										<option value=''>Select Your Nationality</option>
										{countryData.map((x, index) => (
											<option key={index} value={x.code}>
												{x.name}
											</option>
										))}
									</select>
								</div>

								{userData.role === 'doctor' && (
									<>
										<div className='form__group'>
											<input
												type='nidCardNumber'
												placeholder='Your National ID Card Number'
												onChange={(e) => {
													setUserData((prev) => ({
														...prev,
														nidCardNumber: e.target.value,
													}));
												}}
											/>
										</div>

										<div className='form__upload'>
											<label htmlFor='file'>
												Upload a clear image of your National ID
											</label>

											<input
												type='file'
												placeholder='Upload Your NID Card'
												accept='image/png, image/jpg, image/jpeg'
												onChange={(e) =>
													uploadImage(e, setUserData, 'nidCardImage')
												}
											/>
										</div>
									</>
								)}
							</>
						)}

						{error && <p className='error'>{error}</p>}

						<button
							type='submit'
							className='btn-register'
							disabled={loading || uploadLoading}
						>
							{loading || uploadLoading ? <LoadingSVG /> : 'Register'}
						</button>
					</form>
				</div>

				<p className='already'>
					Already have an account?{' '}
					<Link to='/sign-in' className='link'>
						Sign In
					</Link>
				</p>
			</div>
		</section>
	);
};

export default SignUp;
