import React from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { IoPrint, IoWarning } from 'react-icons/io5';
import LoadingSVG from '../../components/LoadingSVG/LoadingSVG';
import './TestPreview.scss';

const TestPreview = () => {
	// page title

	window.document.title = 'ePrescription | Romatoo';

	// hooks

	const { id } = useParams();
	const authToken = useSelector((state) => state.auth.token);
	const authInfo = useSelector((state) => state.auth.info);

	// states & refs

	const printRef = React.useRef();

	const [prescription, setPrescription] = React.useState(null);
	const [loading, setLoading] = React.useState(true);

	// effects

	React.useEffect(() => {
		const getPrescription = async () => {
			try {
				const url = `${process.env.REACT_APP_API}/doctor/prescription/tests/${id}`;
				const req = await fetch(url, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': authToken,
					},
				});

				const res = await req.json();
				console.log('get pres res', res);
				if (res.success) {
					setPrescription(res.data);
				} else {
					toast.error(res.message);
				}
			} catch (error) {
				console.log('get pres error', error);
				toast.error('Something went wrong');
			} finally {
				setLoading(false);
			}
		};
		getPrescription();
	}, [id]);

	// functions

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	// loading

	if (loading) {
		return (
			<div className='w-full flex items-center justify-center py-10'>
				<LoadingSVG />
			</div>
		);
	}

	return (
		<div className='printPres page'>
			<div className='box'>
				<div id='innerBox' ref={printRef}>
					<div className='info'>
						<div className='left'>
							<div className='logo'>
								<img src='/logo.svg' alt='logo' />
							</div>

							<p className='creationDate'>
								Date: {prescription.createdAt.split('T')[0]}
							</p>

							<h1 className='ePres'>Patient Tests</h1>

							<p className='issuedTo'>
								Issues to
								<br />
								{prescription.patientInfo?.patientName} -{' '}
								{prescription.patientInfo?.patientAge} years old
							</p>

							<p className='warn'>
								<IoWarning className='icon' />
								<span>Please do not skip any test.</span>
							</p>
						</div>

						<div className='right'>
							<p className='prescribedBy'>
								Prescribed by
								<br />
								{prescription.doctorName.startsWith('Dr.') ||
								prescription.doctorName.startsWith('Dr ')
									? prescription.doctorName
									: `Dr. ${prescription.doctorName}`}
							</p>

							{prescription.doctorInfo.profileInfo?.qualification.length > 0 ? (
								<p className='qualification'>
									{prescription.doctorInfo.profileInfo?.qualification.join(
										', '
									)}
								</p>
							) : null}

							<p>
								{prescription.doctorInfo.profileInfo?.specialties ? (
									<span className='specialties'>
										{prescription.doctorInfo.profileInfo?.specialties.join(
											', '
										)}
									</span>
								) : null}

								{prescription.doctorInfo.profileInfo?.title ? (
									<span className='title'>
										{` (${prescription.doctorInfo.profileInfo?.title})`}
									</span>
								) : null}
							</p>

							{prescription.doctorInfo.profileInfo?.currentOrganization.length >
							0 ? (
								<p className='organization'>
									{prescription.doctorInfo.profileInfo?.currentOrganization.join(
										', '
									)}
								</p>
							) : null}

							{prescription.doctorInfo.profileInfo?.medicalCouncilNumber
								.length > 0 ? (
								<p className='councilNumber'>
									BMDC No:{' '}
									{prescription.doctorInfo.profileInfo?.medicalCouncilNumber.join(
										', '
									)}
								</p>
							) : null}

							{prescription.signature ? (
								<img
									src={prescription.signature}
									alt='signature'
									className='signature'
								/>
							) : null}
						</div>
					</div>

					<div className='testsIssueSec'>
						<div className='left'>
							<h2>Medical Records</h2>

							{prescription.presentMedicalSection.map((obj, index) => (
								<p key={index}>
									<span>
										{obj.label === 'BP' ? 'Blood Pressure' : obj.label}:
									</span>{' '}
									{obj.text || 'N/A'}
								</p>
							))}
						</div>

						<div className='right'>
							<h2>Given Tests</h2>

							{prescription.tests.map((item, index) => (
								<p key={index}>
									<span>{index + 1}.</span> {item}
								</p>
							))}
						</div>
					</div>
				</div>

				<button className='btn-print' onClick={handlePrint}>
					<IoPrint className='icon' />
					<span>Print</span>
				</button>
			</div>
		</div>
	);
};

export default TestPreview;
