import React from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { isExpired } from './store/authSlice/authSlice';
import Router from './Router';

const App = () => {
	// hooks

	const dispatch = useDispatch();
	const { pathname } = useLocation();

	// check if token is expired

	React.useEffect(() => {
		setInterval(() => {
			dispatch(isExpired());
		}, 1000);
	});

	React.useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [pathname]);

	return (
		<>
			<Router />

			<ToastContainer
				position='top-center'
				autoClose={1500}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

export default App;
