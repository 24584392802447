import {
    IoHomeOutline,
    IoPeopleOutline,
    IoSettingsOutline
} from 'react-icons/io5';
import { BsFileEarmarkDiff } from 'react-icons/bs';

export const adminNav = [
    {
        name: 'Dashboard',
        path: '/admin/dashboard',
        icon: <IoHomeOutline className='icon' />,
        imageIcon: null,
    },
    {
        name: 'Doctors',
        path: '/admin/doctors',
        icon: null,
        imageIcon: 'https://i.ibb.co/jDJZM0F/medical-doctor.png',
    },
    {
        name: 'Patients',
        path: '/admin/patients',
        icon: <IoPeopleOutline className='icon' />,
        imageIcon: null,
    },
    {
        name: 'Approvals',
        path: '/admin/approvals',
        icon: <BsFileEarmarkDiff className='icon' />,
        imageIcon: null,
    },
    {
        name: 'Payments',
        path: '/admin/payments',
        icon: null,
        imageIcon: 'https://i.ibb.co/7YXnmx6/currency-exchange.png',
    },
    {
        name: 'Settings',
        path: '/admin/settings',
        icon: <IoSettingsOutline className='icon' />,
        imageIcon: null,
    },
];