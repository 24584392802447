import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import Home from './page/Home/Home';
import AboutUs from './page/AboutUs/AboutUs';
import PrivacyAgreement from './page/PrivacyAgreement/PrivacyAgreement';
import PrivacyPolicy from './page/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './page/TermsOfUse/TermsOfUse';
import ContactUs from './page/ContactUs/ContactUs';
import SignIn from './page/SignIn/SignIn';
import SignUp from './page/SignUp/SignUp';
import DoctorCard from './page/Home/HeroSection/DoctorCard/DoctorCard';
import Navbar from './components/Navbar/Navbar';
import { useSelector } from 'react-redux';
import CreateDashboard from './page/CreateDashboard/CreateDashboard';
import Messages from './page/Messages/Messages';
import SingleMessage from './page/Messages/SingleMessage';

import E_prescription from './page/CloudBasedConcept/E_prescription/E_prescription';
import VideoConference from './page/CloudBasedConcept/VideoConference/VideoConference';
import DataComparing from './page/CloudBasedConcept/DataComaring/DataComparing';
import MentalHealth from './page/FeaturedSection/MentalHealth/MentalHealth';
import Diet from './page/FeaturedSection/Diet/Diet';
import Meditation from './page/FeaturedSection/Meditation/Meditation';
import Dashboard from './page/Dashboard/Dashboard';
import MyProfile from './page/MyProfile/MyProfile';
import VideoCall from './page/Messages/VideoCall';
import { ContextProvider } from './context/SocketContext';
import SearchDoctor from './page/SearchDoctor/SearchDoctor';
import UpcomingAppointments from './page/UpcomingAppointments/UpcomingAppointments';
import SpecialistCard from './page/Home/HeroSection/SpecialistCard/SpecialistCard';
import HealthProblems from './page/Home/HealthProblems/HealthProblems';
import DataCenter from './page/DataCenter/DataCenter';
import PatientRecords from './page/PatientRecords/PatientRecords';
import Article from './page/Article/Article';
import History from './page/History/History';
import Settings from './page/Settings/Settings';
import HelpCenter from './page/HelpCenter/HelpCenter';
import HealthAnalysis from './page/HealthAnalysis/HealthAnalysis';
import MyDoctor from './page/MyDoctor/MyDoctor';
import EPrescription from './page/EPrescription/EPrescription';
import AiAssistant from './page/AiAssistant/AiAssistant';
import SecureHealthData from './page/SecureHealthData/SecureHealthData';
import AdminLogin from './admin/Login/Login';
import AdminSidebar from './components/AdminSidebar/AdminSidebar';
import AdminDashboard from './admin/Dashboard/Dashboard';
import DoctorsList from './admin/Doctors/Doctors';
import PatientsList from './admin/Patients/Patients';
import DoctorApprovals from './admin/Approvals/Approvals';
import AdminPayments from './admin/Payments/Payments';
import AdminSettings from './admin/Settings/Settings';
import DoctorProfile from './page/MyProfile/DoctorProfile';
import Prescription from './page/EPrescription/Prescription';
import PresPreview from './page/EPrescription/PresPreview';
import Preview from './page/EPrescription/Preview';
import EditDashboard from './page/EditDashboard/EditDashboard';
import TestPreview from './page/EPrescription/TestPreview';
import Feed from './page/Social/Feed/Feed';
import Video from './page/Messages/Video';
import DummyPage from './page/DummyPage/DummyPage';
import DummyPage2 from './page/DummyPage/Dummy2';
import TelMedicine from './page/Home/TeleMedicine/TeleMedicine';
import SocialProfile from './page/Social/Profile/Profile';
import SinglePost from './page/Social/SinglePost/SinglePost';
import Notifications from './page/Notifications/Notifications';
import DataStore from './page/DataStore/DataStore';
import PatientPrescription from './page/PatientPrescription/PatientPrescription';
import BloodDonation from './page/BloodDonation/BloodDonation';
import DonationRequest from './page/BloodDonation/DonationRequest';
import BlogPost from './page/BlogPost/BlogPost';
import DashboardNew from './page/DashboardNew/DashboardNew';

const Router = () => {
	const { pathname } = useLocation();
	const user = useSelector((state) => state.auth?.info);

	return (
		<>
			{pathname.includes('video-call') ||
			pathname.includes('video') ||
			pathname.includes('admin') ||
			pathname === '/dashboard2' ? null : (
				<>
					<Navbar />
				</>
			)}
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/dummy' element={<DummyPage />} />
				<Route path='/dummy2' element={<DummyPage2 />} />

				<Route path='/sign-in' element={<SignIn />} />
				<Route path='/sign-up' element={<SignUp />} />
				<Route path='/about' element={<AboutUs />} />
				<Route path='/terms' element={<TermsOfUse />} />
				<Route path='/post/:id' element={<BlogPost />} />
				<Route path='/privacy' element={<PrivacyPolicy />} />
				<Route path='/e_prescription' element={<E_prescription />} />
				<Route path='/video_conference' element={<VideoConference />} />
				<Route path='/data_comparing' element={<DataComparing />} />
				<Route path='/privacy-agreement' element={<PrivacyAgreement />} />
				<Route path='/contact' element={<ContactUs />} />
				<Route path='/doctor-card' element={<DoctorCard />} />

				<Route path='/telemedicine' element={<TelMedicine />} />

				{user && user?.role === 'doctor' ? (
					<>
						<Route path='/create-dashboard' element={<CreateDashboard />} />
						<Route path='/edit-dashboard' element={<EditDashboard />} />
						<Route path='/dashboard' element={<Dashboard />} />
						<Route path='/dashboard2' element={<DashboardNew />} />
						<Route
							path='/upcoming-appointments'
							element={<UpcomingAppointments />}
						/>
					</>
				) : user && user?.role === 'patient' ? (
					<>
						<Route
							path='/patient-prescription'
							element={<PatientPrescription />}
						/>
					</>
				) : null}
				{user ? (
					<>
						<Route path='/blood-donation' element={<BloodDonation />} />
						<Route path='/blood-donation/:id' element={<DonationRequest />} />
						<Route path='/data-store' element={<DataStore />} />
						<Route path='/notification' element={<Notifications />} />
						<Route path='/oldProfile' element={<MyProfile />} />
						<Route path='/profile' element={<DoctorProfile />} />
						<Route path='/messages' element={<Messages />}>
							<Route path=':conversationId' element={<SingleMessage />} />
						</Route>
						<Route
							path='/video-call/:conversationId'
							element={
								<ContextProvider>
									<VideoCall />
								</ContextProvider>
							}
						/>
						<Route
							path='/video/:conversationId'
							element={
								<ContextProvider>
									<Video />
								</ContextProvider>
							}
						/>
						<Route path='/social/feed' element={<Feed />} />
						<Route path='/social/profile/:userId' element={<SocialProfile />} />
						<Route path='/social/post/:postId' element={<SinglePost />} />
					</>
				) : (
					<>
						<Route path='/social/feed' element={<Navigate to='/sign-in' />} />
					</>
				)}
				<Route path='/search' element={<SearchDoctor />} />
				<Route path='/diet' element={<Diet />} />
				<Route path='/mental_health' element={<MentalHealth />} />
				<Route path='/meditation' element={<Meditation />} />
				<Route path='/specialists' element={<SpecialistCard />} />
				<Route path='/diseases' element={<HealthProblems />} />
				<Route path='/data-center' element={<DataCenter />} />
				<Route path='/patient-records' element={<PatientRecords />} />
				<Route path='/article' element={<Article />} />
				<Route path='/history' element={<History />} />
				<Route path='/settings' element={<Settings />} />
				<Route path='/help-center' element={<HelpCenter />} />
				<Route path='/health-analytics' element={<HealthAnalysis />} />
				<Route path='/my-doctor' element={<MyDoctor />} />
				<Route path='/e-prescription' element={<EPrescription />} />
				<Route path='/prescription' element={<Prescription />} />
				<Route path='/prescription/:patientId' element={<Prescription />} />
				<Route path='/view/prescription/:id' element={<Preview />} />
				<Route path='/tests/:id' element={<TestPreview />} />
				<Route path='/aiassistance' element={<AiAssistant />} />
				<Route path='/secure_health_data' element={<SecureHealthData />} />

				<Route path='/auth/admin' element={<AdminLogin />} />
				{user && user?.isAdmin ? (
					<Route path='/admin/*' element={<AdminSidebar />}>
						<Route index element={<Navigate to='dashboard' />} />
						<Route path='dashboard' element={<AdminDashboard />} />
						<Route path='doctors' element={<DoctorsList />} />
						<Route path='patients' element={<PatientsList />} />
						<Route path='approvals' element={<DoctorApprovals />} />
						<Route path='payments' element={<AdminPayments />} />
						<Route path='settings' element={<AdminSettings />} />
						<Route path='*' element={<Navigate to='dashboard' />} />
					</Route>
				) : null}

				<Route path='*' element={<Navigate to={user ? '/' : '/sign-in'} />} />
			</Routes>
		</>
	);
};

export default Router;
