import React from 'react';
import { toast } from 'react-toastify';
import { AiOutlinePlusCircle, AiOutlineScan } from 'react-icons/ai';
import {
	FaFilePrescription,
	FaFileUpload,
	FaMicroscope,
	FaSun,
	FaTelegramPlane,
} from 'react-icons/fa';
import { BsHandIndexThumb } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import { createWorker } from 'tesseract.js';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import './DataStore.scss';

const DataStore = () => {
	const [newData, setNewData] = React.useState({
		prescriptionFile: null,
		prescriptionOCR: '',
		labReportFile: null,
		labReportOCR: '',
		otherFile: null,
		otherOCR: '',
		doctorName: '',
	});

	const [imageData, setImageData] = React.useState({
		prescription: '',
		labReport: '',
		other: '',
	});

	const [openedImage, setOpenedImage] = React.useState('');
	const [openModal, setOpenModal] = React.useState(false);

	const [recentFile, setRecentFile] = React.useState(null);
	const [allFiles, setAllFiles] = React.useState([]);

	const [loading, setLoading] = React.useState(true);
	const [scanning, setScanning] = React.useState(false);
	const [generating, setGenerating] = React.useState(false);

	React.useEffect(() => {
		const fetchAllFiles = async () => {
			const url = `${process.env.REACT_APP_API}/doctor/data-store/files`;

			try {
				const req = await fetch(url, {
					headers: {
						'x-access-token': localStorage.getItem('token'),
					},
				});

				const res = await req.json();

				console.log('res -> ', res);

				if (res.success) {
					if (res.data?.length > 0) {
						setRecentFile(res.data[0]);
					}

					setAllFiles(res.data);
				}
			} catch (error) {
				console.log('error -> ', error);
				toast.error('Something went wrong!');
			} finally {
				setLoading(false);
			}
		};

		fetchAllFiles();
	}, []);

	const handleFileChange = async (e, reportType) => {
		const file = e.target.files[0];

		if (!file) {
			return;
		}

		if (file.size > 50 * 1024 * 1024) {
			toast.error('File size must be less than 50 MB!');
			return;
		}

		const reader = new FileReader();

		reader.onload = (e) => {
			const imageDataUri = e.target.result;

			setImageData((prev) => ({
				...prev,
				[reportType]: imageDataUri,
			}));
			setNewData((prev) => ({
				...prev,
				[`${reportType}File`]: file,
			}));
		};

		// do ocr here

		reader.readAsDataURL(file);
	};

	const handleOCR = async (imageURIData, reportType) => {
		if (!imageURIData) {
			return;
		}

		try {
			const worker = await createWorker('eng');
			const { data } = await worker.recognize(imageURIData);
			// console.log('OCR data -> ', data);

			if (data) {
				setNewData((prev) => ({
					...prev,
					[`${reportType}OCR`]: data.text,
				}));
			}
		} catch (error) {
			console.log('error -> ', error);
			toast.error('Unable to scan the file!');
		}
	};

	const scanFiles = async () => {
		setScanning(true);

		try {
			if (imageData.prescription) {
				await handleOCR(imageData.prescription, 'prescription');
			}

			if (imageData.labReport) {
				await handleOCR(imageData.labReport, 'labReport');
			}

			if (imageData.other) {
				await handleOCR(imageData.other, 'other');
			}
		} finally {
			setScanning(false);
		}
	};

	const handleGenerate = async () => {
		const formData = new FormData();

		if (newData.prescriptionFile && newData.prescriptionOCR) {
			formData.append('prescription', newData.prescriptionFile);
			formData.append('prescriptionOCR', newData.prescriptionOCR);
		}

		if (newData.labReportFile && newData.labReportOCR) {
			formData.append('labReport', newData.labReportFile);
			formData.append('labReportOCR', newData.labReportOCR);
		}

		if (newData.otherFile && newData.otherOCR) {
			formData.append('other', newData.otherFile);
			formData.append('otherOCR', newData.otherOCR);
		}

		formData.append('doctorName', newData.doctorName);

		console.log('formData -> ', formData);

		setGenerating(true);

		const url = `${process.env.REACT_APP_API}/doctor/data-store/upload`;

		try {
			const req = await fetch(url, {
				method: 'POST',
				headers: {
					'x-access-token': localStorage.getItem('token'),
				},
				body: formData,
			});

			const res = await req.json();

			console.log('res -> ', res);

			if (res.success) {
				setRecentFile(res.data);
				setAllFiles((prev) => [res.data, ...prev]);
				setImageData({
					prescription: '',
					labReport: '',
					other: '',
				});
				setNewData({
					prescriptionFile: null,
					prescriptionOCR: '',
					labReportFile: null,
					labReportOCR: '',
					otherFile: null,
					otherOCR: '',
					doctorName: '',
				});
				toast.success(res.message);
			} else {
				toast.error(res.message);
			}
		} catch (error) {
			console.log('error -> ', error);
			toast.error('Something went wrong!');
		} finally {
			setGenerating(false);
		}
	};

	return (
		<div className='dataStore page'>
			<div className='container'>
				<div className='my-5 w-full max-w-7xl mx-auto'>
					<img src='/banner/3.jpg' alt='banner' className='w-full h-full' />
				</div>

				<div className='top__summary'>
					<h3>AI Generated Summary</h3>

					<p>
						Lorem ipsum dolor sit, amet consectetur adipisicing elit.
						Voluptatibus quibusdam voluptates impedit neque, omnis numquam eaque
						consectetur modi optio laboriosam reprehenderit maxime, nemo sit
						mollitia alias esse? Modi, tenetur consequuntur? Commodi facilis,
						praesentium pariatur eius, illum enim cupiditate atque itaque vero
						fugit tempore quasi! Sint maxime, ad quibusdam eius enim ipsam alias
						nulla velit cumque est itaque accusamus accusantium officiis autem
						excepturi amet fugiat error, officia sequi fugit, aperiam
						distinctio. Recusandae veniam deleniti dolorum quas, repellendus
						minima placeat esse nemo dolorem! Consequuntur quam quisquam, alias
						unde eum provident saepe facere voluptatibus perferendis nisi
						expedita minima temporibus incidunt at quia officiis earum delectus
						quidem excepturi voluptas dolorum. Adipisci, nostrum quisquam!
						Expedita quaerat sequi corporis ab, nisi, eos distinctio earum saepe
						id excepturi voluptatibus a odit eligendi delectus repudiandae optio
						mollitia perspiciatis dolores corrupti repellat, sit atque minus!
						Vel architecto fugit mollitia, quidem non debitis nulla possimus,
						aliquam assumenda, nobis repellendus alias?
					</p>
				</div>

				<div className='files__lines'>
					<div className='left__side'>
						<h2>Upload File</h2>

						<div className='upload__box'>
							<div className='top__side'>
								<label htmlFor='uploadProfileImage'>
									<AiOutlinePlusCircle className='icon' />
								</label>

								<input
									type='file'
									name='file'
									id='uploadProfileImage'
									accept='image/*,.pdf,.doc,.docx'
									// onChange={handleChange}
									hidden
								/>

								<input
									type='text'
									name='doctorName'
									id='doctorName'
									placeholder='Enter Doctor Name'
									onChange={(e) => {
										setNewData((prev) => ({
											...prev,
											doctorName: e.target.value,
										}));
									}}
								/>
							</div>

							<div className='generate__btn__container'>
								<p>AI Generated Summary</p>

								<div className='generate__btn__body'>
									<button
										onClick={
											(imageData.prescription && !newData.prescriptionOCR) ||
											(imageData.labReport && !newData.labReportOCR) ||
											(imageData.other && !newData.otherOCR)
												? scanFiles
												: handleGenerate
										}
										disabled={scanning}
									>
										{!imageData.prescription &&
										!imageData.labReport &&
										!imageData.other ? (
											<BsHandIndexThumb className='icon' />
										) : (imageData.prescription && !newData.prescriptionOCR) ||
										  (imageData.labReport && !newData.labReportOCR) ||
										  (imageData.other && !newData.otherOCR) ? (
											<AiOutlineScan className='icon' />
										) : (
											<FaTelegramPlane className='icon' />
										)}
									</button>

									<p>
										{scanning ? (
											'Scanning the files...'
										) : generating ? (
											'Generating the summary...'
										) : (imageData.prescription && !newData.prescriptionOCR) ||
										  (imageData.labReport && !newData.labReportOCR) ||
										  (imageData.other && !newData.otherOCR) ? (
											'Scan the files'
										) : !imageData.prescription &&
										  !imageData.labReport &&
										  !imageData.other ? (
											'Select files to scan'
										) : (
											<>
												<strong>Click</strong> the Button for Generated AI
												Summary
											</>
										)}
									</p>
								</div>
							</div>

							<h4>Attachment</h4>

							{/* <div className='blocks'>
								<div className='single__block'>
									<label htmlFor='prescriptionUpload'>
										<FaFilePrescription className='icon' />
										<span>Prescription</span>
									</label>

									<label htmlFor='prescriptionUpload'>
										<FaFileUpload className='icon' />
										<span>Browse you file</span>
									</label>

									<input
										type='file'
										name='file'
										id='prescriptionUpload'
										accept='image/*'
										onChange={(e) => handleFileChange(e, 'prescription')}
										hidden
									/>
								</div>

								<div className='single__block'>
									<label htmlFor='reportUpload'>
										<FaMicroscope className='icon' />
										<span>Lab Report</span>
									</label>

									<label htmlFor='reportUpload'>
										<FaFileUpload className='icon' />
										<span>Browse you file</span>
									</label>

									<input
										type='file'
										name='file'
										id='reportUpload'
										accept='image/*'
										onChange={(e) => handleFileChange(e, 'labReport')}
										hidden
									/>
								</div>

								<div className='single__block'>
									<label htmlFor='otherUpload'>
										<FaSun className='icon' />
										<span>Other</span>
									</label>

									<label htmlFor='otherUpload'>
										<FaFileUpload className='icon' />
										<span>Browse you file</span>
									</label>

									<input
										type='file'
										name='file'
										id='otherUpload'
										accept='image/*'
										onChange={(e) => handleFileChange(e, 'other')}
										hidden
									/>
								</div>
							</div> */}

							<div className='selected__files'>
								{newData.prescriptionFile && (
									<div className='single__block'>
										<FaFilePrescription className='icon' />
										<span>{newData.prescriptionFile.name}</span>

										<button
										// onClick={() => {
										// 	setImageData((prev) => ({
										// 		...prev,
										// 		prescription: '',
										// 	}));
										// 	setNewData((prev) => ({
										// 		...prev,
										// 		prescriptionFile: null,
										// 		prescriptionOCR: '',
										// 	}));
										// }}
										>
											<IoClose className='icon' />
										</button>
									</div>
								)}

								{newData.labReportFile && (
									<div className='single__block'>
										<FaMicroscope className='icon' />
										<span>{newData.labReportFile.name}</span>

										<button
										// onClick={() => {
										// 	setImageData((prev) => ({
										// 		...prev,
										// 		labReport: '',
										// 	}));
										// 	setNewData((prev) => ({
										// 		...prev,
										// 		labReportFile: null,
										// 		labReportOCR: '',
										// 	}));
										// }}
										>
											<IoClose className='icon' />
										</button>
									</div>
								)}

								{newData.otherFile && (
									<div className='single__block'>
										<FaSun className='icon' />
										<span>{newData.otherFile.name}</span>

										<button
										// onClick={() => {
										// 	setImageData((prev) => ({
										// 		...prev,
										// 		labReport: '',
										// 	}));
										// 	setNewData((prev) => ({
										// 		...prev,
										// 		labReportFile: null,
										// 		labReportOCR: '',
										// 	}));
										// }}
										>
											<IoClose className='icon' />
										</button>
									</div>
								)}
							</div>

							<div className='upload__blocks'>
								<label className='single__block'>
									<FaFilePrescription className='icon' />
									<span>Select Prescription</span>

									<input
										type='file'
										name='file'
										id='prescriptionUpload'
										accept='image/*'
										onChange={(e) => handleFileChange(e, 'prescription')}
										hidden
									/>
								</label>

								<label className='single__block'>
									<FaMicroscope className='icon' />
									<span>Select Lab Report</span>

									<input
										type='file'
										name='file'
										id='reportUpload'
										accept='image/*'
										onChange={(e) => handleFileChange(e, 'labReport')}
										hidden
									/>
								</label>

								<label className='single__block'>
									<FaSun className='icon' />
									<span>Select Other Report</span>

									<input
										type='file'
										name='file'
										id='otherUpload'
										accept='image/*'
										onChange={(e) => handleFileChange(e, 'other')}
										hidden
									/>
								</label>
							</div>
						</div>
					</div>

					{recentFile && (
						<div className='right__side'>
							<h2>Recent File</h2>

							<div className='recent__file'>
								<div className='top__side'>
									<div className='info'>
										<img src='/my-profile/male-avatar.png' alt='avatar' />

										<p>{recentFile?.doctorName || 'Dr. Mahbubur Rahman'}</p>
									</div>

									<span>
										{recentFile?.createdAt
											? new Date(recentFile?.createdAt)?.toLocaleDateString()
											: '20/09/2023'}
									</span>
								</div>

								<div className='main__summary'>
									{recentFile?.aiSummary ||
										`Lorem ipsum dolor sit amet consectetur adipisicing elit.
									Distinctio voluptatibus aperiam placeat, numquam quas ea
									consequuntur sed iste fuga optio nesciunt facere provident illo
									excepturi, eius officia laudantium, a sint fugit nobis! Expedita
									dignissimos earum officia maxime eos architecto qui ea dolorem
									fugiat soluta? Eveniet distinctio nam debitis nostrum temporibus
									accusamus aliquam illum. Quam exercitationem dolores sit dolorem
									iste recusandae dicta velit architecto iusto voluptate, enim
									excepturi dolorum quae maxime doloremque veniam nam qui.
									Cupiditate vero perspiciatis incidunt quidem, aut obcaecati
									asperiores tempora rerum aliquam in atque ipsum fugiat dolorem
									ducimus earum numquam? Officia vitae error reiciendis minus enim
									optio!`}
								</div>

								<h4>Attachment</h4>

								<div className='btns__container'>
									<button>
										<FaFilePrescription className='icon' />
										<span>Prescription</span>
									</button>

									<button>
										<FaMicroscope className='icon' />
										<span>Lab Report</span>
									</button>

									<button>
										<FaSun className='icon' />
										<span>Other</span>
									</button>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className='allFiles__container'>
					<h2>All Files</h2>

					<div className='files__container'>
						{allFiles.map((item, i) => (
							<div className='single__file' key={i}>
								<div className='top__side'>
									<div className='info'>
										<img src='/my-profile/male-avatar.png' alt='avatar' />

										<p>{item.doctorName || 'Dr. Mahbubur Rahman'}</p>
									</div>

									<span>
										{item.createdAt
											? new Date(item.createdAt)?.toLocaleDateString()
											: '20/09/2023'}
									</span>
								</div>

								<div className='main__summary'>
									{item.aiSummary ||
										`Lorem ipsum dolor sit amet consectetur adipisicing elit.
											Distinctio voluptatibus aperiam placeat, numquam quas ea
											consequuntur sed iste fuga optio nesciunt facere provident
											illo excepturi, eius officia laudantium, a sint fugit nobis!
											Expedita dignissimos earum officia maxime eos architecto qui
											ea dolorem fugiat soluta? Eveniet distinctio nam debitis
											nostrum temporibus accusamus aliquam illum. Quam
											exercitationem dolores sit dolorem iste recusandae dicta
											velit architecto iusto voluptate, enim excepturi dolorum
											quae maxime doloremque veniam nam qui. Cupiditate vero
											perspiciatis incidunt quidem, aut obcaecati asperiores
											tempora rerum aliquam in atque ipsum fugiat dolorem ducimus
											earum numquam? Officia vitae error reiciendis minus enim
											optio!`}
								</div>

								<h4>Attachment</h4>

								<div className='btns__container'>
									<button
										onClick={() => {
											if (item.prescription) {
												setOpenedImage(item.prescription);
												setOpenModal(true);
											} else {
												toast.error('No prescription found!');
											}
										}}
									>
										<FaFilePrescription className='icon' />
										<span>Prescription</span>
									</button>

									<button
										onClick={() => {
											if (item.labReport) {
												setOpenedImage(item.labReport);
												setOpenModal(true);
											} else {
												toast.error('No lab report found!');
											}
										}}
									>
										<FaMicroscope className='icon' />
										<span>Lab Report</span>
									</button>

									<button
										onClick={() => {
											if (item.other) {
												setOpenedImage(item.other);
												setOpenModal(true);
											} else {
												toast.error('No other report found!');
											}
										}}
									>
										<FaSun className='icon' />
										<span>Other</span>
									</button>
								</div>
							</div>
						))}
					</div>
				</div>

				{/* <label className='drop__files' htmlFor='file'>
					<BiLayerPlus className='icon' />

					<p>
						Drop your files here, or <span>Browse</span>
					</p>
					<span>Maximum file size 50 mb</span>
				</label>

				<input
					type='file'
					name='file'
					id='file'
					accept='image/*,.pdf,.doc,.docx'
					onChange={handleChange}
					hidden
				/>

				<div className='summary__container'>
					<div className='summary__item'>
						<div className='top'>
							<h4>Summary</h4>

							<RiSortDesc className='icon' />
						</div>

						<div className='body'>
							<p>
								Lorem ipsum dolor sit, amet consectetur adipisicing elit.
								Facilis at saepe vero totam distinctio porro incidunt
								architecto, molestiae commodi accusamus libero natus ratione
								quibusdam ipsam. Est maiores quasi iure laborum tempora alias
								odio voluptates atque! Totam aliquam laboriosam enim quisquam!
							</p>
						</div>
					</div>

					<div className='summary__item'>
						<div className='top'>
							<h4>AI Generated</h4>
						</div>

						<div className='body'>
							<p>
								Lorem ipsum dolor sit, amet consectetur adipisicing elit.
								Facilis at saepe vero totam distinctio porro incidunt
								architecto, molestiae commodi accusamus libero natus ratione
								quibusdam ipsam. Est maiores quasi iure laborum tempora alias
								odio voluptates atque! Totam aliquam laboriosam enim quisquam!
							</p>
						</div>
					</div>
				</div>

				<div className='recent__container'>
					<h2>Recent Files</h2>

					<div className='files__container'>
						<div className='file__item'>
							<div className='left'>
								<BsFolder2Open className='icon' />
							</div>

							<div className='right'>
								<h4>
									<span>Summary</span>
									<span>20/06/2023</span>
								</h4>

								<p>
									Lorem, ipsum dolor sit amet consectetur adipisicing elit.
									Deserunt, ipsa praesentium eveniet minus nesciunt delectus
									qui?
								</p>

								<button>Read More</button>
							</div>
						</div>

						<div className='file__item'>
							<div className='left'>
								<BsFolder2Open className='icon' />
							</div>

							<div className='right'>
								<h4>
									<span>Summary</span>
									<span>20/06/2023</span>
								</h4>

								<p>
									Lorem, ipsum dolor sit amet consectetur adipisicing elit.
									Deserunt, ipsa praesentium eveniet minus nesciunt delectus
									qui?
								</p>

								<button>Read More</button>
							</div>
						</div>
					</div>
				</div>

				<div className='allFiles__container'>
					<h2>All Files</h2>

					<div className='files__container'>
						{!loading &&
							allFiles.map((file, index) => (
								<div className='file__item' key={index}>
									<div className='left'>{getFileIcon(file.mimetype)}</div>

									<div className='right'>
										<h4>
											<span>
												{(
													file.customName ||
													file.originalname.split('.').slice(0, -1).join('')
												).slice(0, 20)}
											</span>

											<span>
												{new Date(file.createdAt)?.toLocaleDateString()}
											</span>
										</h4>

										<p>
											Lorem, ipsum dolor sit amet consectetur adipisicing elit.
											Deserunt, ipsa praesentium eveniet minus nesciunt delectus
											qui?
										</p>

										<button>Read More</button>
									</div>
								</div>
							))}
					</div>
				</div> */}
			</div>

			<Dialog
				open={openModal}
				onClose={() => {
					setOpenModal(false);
					setOpenedImage('');
				}}
				className='image__modal'
			>
				<DialogContent
					sx={{
						maxHeight: '90vh',
					}}
				>
					<img src={openedImage.path} alt='summary file' />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default DataStore;
