import React from 'react';
import { toast } from 'react-toastify';
import { IoAdd } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import LoadingSVG from '../../components/LoadingSVG/LoadingSVG';
import useImageUpload from '../../hooks/useImageUpload';
import { login } from '../../store/authSlice/authSlice';
import { dummySpecialty, originalSpecialty } from '../../assets';
import './DoctorProfile.scss';

const bloodTypes = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];

const DoctorProfile = () => {
	// page title

	window.document.title = 'My Profile | Romatoo';

	// hooks

	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth);
	const { uploadLoading, uploadImage } = useImageUpload();

	// states & refs

	const dropdownRef = React.useRef(null);

	const [data, setData] = React.useState(
		user.info?.role === 'patient'
			? {
					// currentCountry: null,
					// fullName: user?.info ? user.info.fullName : null,
					// phone: user?.info ? user.info.phone : null,
					// profession: null,
					// profilePicture: null,
					profilePicture: '',
					fullName: user?.info ? user.info.fullName : '',
					phone: user?.info ? user.info.phone : '',
					email: user?.info ? user.info.email : '',
					currentProfession: '',
					currentPosition: '',
					education: [''],
					currentCountry: '',
					currentCity: '',
					bloodDonation: false,
					bloodGroup: '',
			  }
			: {
					fullName: user?.info ? user.info.fullName : '',
					phone: user?.info ? user.info.phone : '',
					profilePicture: user?.info ? user.info.profilePicture : '',
					title: '',
					qualification: [''],
					medicalCouncilNumber: [''],
					medicalCouncilPhoto: [''],
					joiningCountry: '',
					currentOrganization: [''],
					experience: '',
					briefMyself: '',
					signature: '',
			  }
	);
	const [loading, setLoading] = React.useState(true);
	const [loading2, setLoading2] = React.useState(false);
	const [specialties, setSpecialties] = React.useState([]);
	const [openDropdown, setOpenDropdown] = React.useState(false);

	React.useEffect(() => {
		const handleClickOutside = (e) => {
			if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
				setOpenDropdown(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	React.useEffect(() => {
		setLoading(true);

		let url;

		if (user?.info && user.info?.role === 'doctor') {
			url = `${process.env.REACT_APP_API}/doctor/my-profile`;
		} else {
			url = `${process.env.REACT_APP_API}/patient/my-profile`;
		}

		fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': user.token,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('my profile res : ', res);

				if (res.success) {
					updateDataState(res.data);
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong');
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const handleSpecialtyChange = (e, item) => {
		if (e.target.checked) {
			setSpecialties((prev) => {
				const itemType = typeof item;

				if (itemType === 'string') {
					const exists = prev.includes(item);
					if (exists) return prev;
					return [...prev, item];
				}

				if (itemType === 'object') {
					const subItems = item.sub.map((x) => x.name);
					const newItems = [...subItems, item.name];
					const filtered = prev.filter((x) => !newItems.includes(x));
					return [...filtered, ...newItems];
				}
			});
		} else {
			setSpecialties((prev) => {
				const itemType = typeof item;

				if (itemType === 'string') {
					const filtered = prev.filter((x) => x !== item);
					return [...filtered];
				}

				if (itemType === 'object') {
					const filtered1 = prev.filter((x) => x !== item.name);
					// const filtered2 = filtered1.filter((x) => !item.sub.includes(x));
					const filtered2 = filtered1.filter(
						(x) => !item.sub.map((x) => x.name).includes(x)
					);

					return [...filtered2];
				}
			});
		}
	};

	const updateDataState = (newData) => {
		setData((prev) => {
			if (user.info.role === 'doctor') {
				return {
					fullName: newData.fullName || prev.fullName,
					phone: newData.phone || prev.phone,
					profilePicture:
						newData.profileInfo.profilePicture || prev.profilePicture,
					title: newData.profileInfo.title || prev.title,
					// qualification:
					// 	newData.profileInfo.qualification || prev.qualification,
					qualification:
						newData.profileInfo.qualification?.length > 0
							? newData.profileInfo.qualification
							: prev.qualification,
					medicalCouncilNumber:
						newData.profileInfo.medicalCouncilNumber?.length > 0
							? newData.profileInfo.medicalCouncilNumber
							: prev.medicalCouncilNumber,
					medicalCouncilPhoto:
						newData.profileInfo.medicalCouncilPhoto?.length > 0
							? newData.profileInfo.medicalCouncilPhoto
							: prev.medicalCouncilPhoto,
					joiningCountry:
						newData.profileInfo.joiningCountry || prev.joiningCountry,
					currentOrganization:
						newData.profileInfo.currentOrganization?.length > 0
							? newData.profileInfo.currentOrganization
							: prev.currentOrganization,
					experience: newData.profileInfo.experience || prev.experience,
					briefMyself: newData.profileInfo.briefMyself || prev.briefMyself,
					signature: newData.profileInfo.signature || prev.signature,
				};
			} else {
				return {
					profilePicture:
						newData.profileInfo.profilePicture || prev.profilePicture,
					fullName: newData.fullName || prev.fullName,
					phone: newData.phone || prev.phone,
					email: newData.email || prev.email,
					currentProfession:
						newData.profileInfo.currentProfession || prev.currentProfession,
					currentPosition:
						newData.profileInfo.currentPosition || prev.currentPosition,
					education:
						newData.profileInfo.education?.length > 0
							? newData.profileInfo.education
							: prev.education,
					currentCountry:
						newData.profileInfo.currentCountry || prev.currentCountry,
					currentCity: newData.profileInfo.currentCity || prev.currentCity,
					bloodDonation:
						newData.profileInfo.bloodDonation || prev.bloodDonation,
					bloodGroup: newData.profileInfo.bloodGroup || prev.bloodGroup,
				};
			}
		});
		setSpecialties((prev) => specialties || []);
	};

	const handleSave = () => {
		setLoading2(true);

		let url;
		let allData = null;

		if (user?.info && user.info?.role === 'doctor') {
			url = `${process.env.REACT_APP_API}/doctor/my-profile`;
			allData = {
				...data,
				qualification: data.qualification.filter((x) => x !== ''),
				medicalCouncilNumber: data.medicalCouncilNumber.filter((x) => x !== ''),
				medicalCouncilPhoto: data.medicalCouncilPhoto.filter((x) => x !== ''),
				currentOrganization: data.currentOrganization.filter((x) => x !== ''),
				specialties,
			};
		} else {
			url = `${process.env.REACT_APP_API}/patient/my-profile`;
			allData = {
				...data,
				education: data.education.filter((x) => x !== ''),
			};

			if (
				allData.email.trim() === '' ||
				allData.email.trim() === user.info.email
			) {
				delete allData.email;
			}
		}

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': user.token,
			},
			body: JSON.stringify(allData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('my profile res : ', res);

				if (res.success) {
					dispatch(login(res.data.token));
					updateDataState(res.data.doctor || res.data.patient);
					toast.success(res.message);
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong');
			})
			.finally(() => {
				setLoading2(false);
			});
	};

	// loading

	if (loading) {
		return (
			<div className='myProfile page'>
				<h1>My Profile</h1>

				<div className='loading mt-10'>
					<LoadingSVG />
				</div>
			</div>
		);
	}

	return (
		<div className='editProfile page'>
			<h1>My Profile</h1>

			<div className='box'>
				<div className='group__one'>
					<div className='left'>
						<div className='avatar'>
							<img
								src={
									data.profilePicture
										? data.profilePicture
										: user.info.gender === 'Male'
										? '/my-profile/male-avatar.png'
										: '/my-profile/female-avatar.png'
								}
								alt='avatar'
							/>
						</div>

						<input
							type='file'
							name='profilePicture'
							onChange={(e) => {
								uploadImage(e, setData, 'profilePicture');
							}}
						/>
					</div>

					<div className='right'>
						<div className='form__group'>
							<div className='input__group'>
								<label htmlFor='fullName'>Full Name</label>

								<input
									type='text'
									name='fullName'
									id='fullName'
									placeholder='Enter your full name'
									value={data.fullName}
									onChange={(e) => {
										setData({
											...data,
											fullName: e.target.value,
										});
									}}
								/>
							</div>

							<div className='input__group'>
								<label htmlFor='phone'>Phone Number</label>

								<input
									type='tel'
									name='phone'
									id='phone'
									placeholder='Enter your phone number'
									value={data.phone}
									onChange={(e) => {
										setData({
											...data,
											phone: e.target.value,
										});
									}}
								/>
							</div>
						</div>

						{user.info.role === 'patient' ? (
							<div className='form__group no-specialty'>
								<div className='input__group'>
									<label htmlFor='fullName'>Email</label>

									<input
										type='text'
										name='email'
										id='email'
										placeholder='Enter your Email Address'
										value={data.email}
										onChange={(e) => {
											setData({
												...data,
												email: e.target.value,
											});
										}}
									/>
								</div>
							</div>
						) : null}

						{user.info.role === 'doctor' ? (
							<div className='form__group no-specialty'>
								{/* <div className='input__group'>
									<label htmlFor='specialties'>Choose your specialty</label>

									<div className='specialties' ref={dropdownRef}>
										<p
											className={
												specialties.length === 0 ? 'selected empty' : 'selected'
											}
											onClick={() => setOpenDropdown(!openDropdown)}
											title={
												specialties.length > 0 ? specialties.join(', ') : ''
											}
										>
											{specialties.length === 0
												? 'Select your specialties'
												: specialties.join(', ')}
										</p>

										<div
											className={openDropdown ? 'dropdown open' : 'dropdown'}
										>
											{dummySpecialty.map((item, index) => (
												<div className='item' key={index}>
													<input
														type='checkbox'
														name={item}
														id={item}
														value={item}
														onChange={handleSpecialtyChange}
													/>
													<label htmlFor={item}>{item}</label>
												</div>
											))}
										</div>
									</div>
								</div> */}

								<div className='input__group'>
									<label htmlFor='title'>Your Title</label>

									<input
										type='text'
										name='title'
										id='title'
										placeholder='Consultant, Jr. Consultant, etc.'
										value={data.title}
										onChange={(e) => {
											setData({
												...data,
												title: e.target.value,
											});
										}}
									/>
								</div>
							</div>
						) : null}
					</div>
				</div>

				<div className='group__two'>
					{user.info.role === 'doctor' ? (
						<>
							<div className='input__group'>
								<label htmlFor='specialties'>Choose your specialty</label>

								<div className='specialties' ref={dropdownRef}>
									<p
										className={
											specialties.length === 0 ? 'selected empty' : 'selected'
										}
										onClick={() => setOpenDropdown(!openDropdown)}
										title={specialties.length > 0 ? specialties.join(', ') : ''}
									>
										{specialties.length === 0
											? 'Select your specialties'
											: specialties.join(', ')}
									</p>

									<div className={openDropdown ? 'dropdown open' : 'dropdown'}>
										{originalSpecialty.map((item, index) => (
											<React.Fragment key={index}>
												<div className='item'>
													<input
														type='checkbox'
														name={item.name.replaceAll(' ', '-')}
														id={item.name.replaceAll(' ', '-')}
														value={item.name.replaceAll(' ', '-')}
														onChange={(e) => handleSpecialtyChange(e, item)}
														checked={specialties.includes(item.name)}
													/>
													<label htmlFor={item.name.replaceAll(' ', '-')}>
														{item.name}
													</label>
												</div>

												{
													<div className='pl-10'>
														{item.sub.map((subItem, idx) => (
															<div className='item' key={idx}>
																<input
																	type='checkbox'
																	name={subItem?.name?.replaceAll(' ', '-')}
																	id={subItem?.name?.replaceAll(' ', '-')}
																	value={subItem?.name?.replaceAll(' ', '-')}
																	onChange={(e) => {
																		handleSpecialtyChange(e, subItem?.name);
																	}}
																	checked={specialties.includes(subItem?.name)}
																/>
																<label
																	htmlFor={subItem?.name?.replaceAll(' ', '-')}
																>
																	{subItem?.name}
																</label>
															</div>
														))}
													</div>
												}
											</React.Fragment>
										))}
									</div>
								</div>
							</div>

							<div className='input__group'>
								<label htmlFor='qualification'>
									Your Qualification with Institution Name
								</label>

								{data.qualification.map((item, idx) => (
									<input
										type='text'
										name='qualification'
										id={`qualification${idx}`}
										placeholder='MBBS (Oxford Medical College), FCPS (Harvard Medical School), etc.'
										value={item}
										onChange={(e) => {
											const newData = [...data.qualification];
											newData[idx] = e.target.value;

											setData({
												...data,
												qualification: newData,
											});
										}}
									/>
								))}

								<div className='mt-2 w-full flex items-center justify-end'>
									<button
										className='btn-addInput'
										onClick={() => {
											setData({
												...data,
												qualification: [...data.qualification, ''],
											});
										}}
									>
										<IoAdd className='icon' />
										<span>Add More</span>
									</button>
								</div>
							</div>

							<div className='input__group'>
								<label htmlFor='medicalCouncilNumber'>
									Provide your Medical or Dental Council Registration Number
								</label>

								{data.medicalCouncilNumber.map((item, idx) => (
									<input
										type='text'
										name='medicalCouncilNumber'
										id={`medicalCouncilNumber${idx}`}
										placeholder='A126872'
										value={item}
										onChange={(e) => {
											const newData = [...data.medicalCouncilNumber];
											newData[idx] = e.target.value;

											setData({
												...data,
												medicalCouncilNumber: newData,
											});
										}}
									/>
								))}

								{/* <div className='mt-2 w-full flex items-center justify-end'>
									<button
										className='btn-addInput'
										onClick={() => {
											setData({
												...data,
												medicalCouncilNumber: [
													...data.medicalCouncilNumber,
													'',
												],
											});
										}}
									>
										<IoAdd className='icon' />
										<span>Add More</span>
									</button>
								</div> */}
							</div>

							<div className='input__group'>
								<label htmlFor='medicalCouncilPhoto'>
									Upload your Medical or Dental Council Registration Document
								</label>

								{data.medicalCouncilPhoto.map((item, idx) => (
									<input
										type='file'
										name='medicalCouncilPhoto'
										id={`medicalCouncilPhoto${idx}`}
										onChange={(e) => {
											uploadImage(e, setData, 'medicalCouncilPhoto', idx);
										}}
									/>
								))}
							</div>

							<div className='mt-2 w-full flex items-center justify-end'>
								<button
									className='btn-addInput'
									onClick={() => {
										setData({
											...data,
											medicalCouncilPhoto: [...data.medicalCouncilPhoto, ''],
											medicalCouncilNumber: [...data.medicalCouncilNumber, ''],
										});
									}}
								>
									<IoAdd className='icon' />
									<span>Add More</span>
								</button>
							</div>

							<div className='input__group'>
								<label htmlFor='joiningCountry'>
									From which country you are joining with us
								</label>

								<input
									type='text'
									name='joiningCountry'
									id='joiningCountry'
									placeholder='United Kingdom, United States, etc.'
									value={data.joiningCountry}
									onChange={(e) => {
										setData({
											...data,
											joiningCountry: e.target.value,
										});
									}}
								/>
							</div>

							<div className='input__group'>
								<label htmlFor='currentOrganization'>
									Where are you currently working as a physician
								</label>

								{data.currentOrganization.map((item, idx) => (
									<input
										type='text'
										name='currentOrganization'
										id={`currentOrganization${idx}`}
										placeholder='Singapore General Hospital (SGH), etc.'
										value={item}
										onChange={(e) => {
											const newData = [...data.currentOrganization];
											newData[idx] = e.target.value;

											setData({
												...data,
												currentOrganization: newData,
											});
										}}
									/>
								))}

								<div className='mt-2 w-full flex items-center justify-end'>
									<button
										className='btn-addInput'
										onClick={() => {
											setData({
												...data,
												currentOrganization: [...data.currentOrganization, ''],
											});
										}}
									>
										<IoAdd className='icon' />
										<span>Add More</span>
									</button>
								</div>
							</div>

							<div className='input__group'>
								<label htmlFor='experience'>
									How many years of professional experience do you have
								</label>

								<input
									type='number'
									name='experience'
									id='experience'
									placeholder='0'
									value={data.experience}
									onChange={(e) => {
										setData({
											...data,
											experience: e.target.value,
										});
									}}
								/>
							</div>

							<div className='input__group'>
								<label htmlFor='brief'>
									Briefly describe yourself and the type of healthcare you can
									provide
								</label>

								<textarea
									name='brief'
									id='brief'
									cols='30'
									rows='6'
									placeholder='Say something about yourself'
									value={data.brief}
									onChange={(e) => {
										setData({
											...data,
											brief: e.target.value,
										});
									}}
								></textarea>
							</div>

							<div className='input__group'>
								<label htmlFor='signature'>
									Upload a clear photo of your signature
								</label>

								<input
									type='file'
									name='signature'
									id='signature'
									onChange={(e) => {
										uploadImage(e, setData, 'signature');
									}}
								/>
							</div>
						</>
					) : (
						<>
							<div className='form__group'>
								<div className='input__group'>
									<label htmlFor='currentProfession'>Current Profession</label>

									<input
										type='text'
										name='currentProfession'
										id='currentProfession'
										placeholder='Enter your Current Profession'
										value={data.currentProfession}
										onChange={(e) => {
											setData({
												...data,
												currentProfession: e.target.value,
											});
										}}
									/>
								</div>

								<div className='input__group'>
									<label htmlFor='currentPosition'>Current Position</label>

									<input
										type='text'
										name='currentPosition'
										id='currentPosition'
										placeholder='Enter your Current Position'
										value={data.currentPosition}
										onChange={(e) => {
											setData({
												...data,
												currentPosition: e.target.value,
											});
										}}
									/>
								</div>
							</div>

							<div className='input__group'>
								<label htmlFor='education'>Education</label>

								{data.education.map((item, idx) => (
									<input
										key={idx}
										type='text'
										name='education'
										id={`education${idx}`}
										placeholder='MBBS (Oxford Medical College), FCPS (Harvard Medical School), etc.'
										value={item}
										onChange={(e) => {
											setData((prev) => {
												const oldData = [...prev.education];
												oldData[idx] = e.target.value;

												return {
													...prev,
													education: oldData,
												};
											});
										}}
									/>
								))}

								<div className='mt-2 w-full flex items-center justify-end'>
									<button
										className='btn-addInput'
										onClick={() => {
											setData({
												...data,
												education: [...data.education, ''],
											});
										}}
									>
										<IoAdd className='icon' />
										<span>Add More</span>
									</button>
								</div>
							</div>

							<div className='form__group'>
								<div className='input__group'>
									<label htmlFor='currentCountry'>Country / Region</label>

									<input
										type='text'
										name='currentCountry'
										id='currentCountry'
										placeholder='Enter your Current Country'
										value={data.currentCountry}
										onChange={(e) => {
											setData({
												...data,
												currentCountry: e.target.value,
											});
										}}
									/>
								</div>

								<div className='input__group'>
									<label htmlFor='currentCity'>Current City</label>

									<input
										type='text'
										name='currentCity'
										id='currentCity'
										placeholder='Enter your Current City'
										value={data.currentCity}
										onChange={(e) => {
											setData({
												...data,
												currentCity: e.target.value,
											});
										}}
									/>
								</div>
							</div>

							<div className='input__group'>
								<h4>
									Are you interested in being involved in blood donation
									activities?
								</h4>

								<div className='radio__group'>
									<div className='radio__item'>
										<input
											type='radio'
											name='bloodDonation'
											id='bloodDonationYes'
											value='Yes'
											checked={data.bloodDonation === true}
											onChange={(e) => {
												setData({
													...data,
													bloodDonation: true,
												});
											}}
										/>
										<label htmlFor='bloodDonationYes'>Yes</label>
									</div>

									<div className='radio__item'>
										<input
											type='radio'
											name='bloodDonation'
											id='bloodDonationNo'
											value='No'
											checked={data.bloodDonation === false}
											onChange={(e) => {
												setData({
													...data,
													bloodDonation: false,
												});
											}}
										/>
										<label htmlFor='bloodDonationNo'>No</label>
									</div>
								</div>
							</div>

							<div className='input__group'>
								<h4>Select Your Blood Group</h4>

								<div className='radio__group2'>
									{bloodTypes.map((bloodType, index) => (
										<div className='radio__item' key={index}>
											<input
												type='radio'
												name='bloodGroup'
												id={`bloodGroup${bloodType}`}
												value={bloodType}
												onChange={(e) => {
													setData({
														...data,
														bloodGroup: e.target.value,
													});
												}}
												checked={data.bloodGroup === bloodType}
											/>
											<label htmlFor={`bloodGroup${bloodType}`}>
												{bloodType}
											</label>
										</div>
									))}
								</div>
							</div>
						</>
					)}
				</div>

				<div className='group__three'>
					<button
						className='btn-save'
						onClick={handleSave}
						disabled={loading2 || uploadLoading}
					>
						Save Changes
					</button>
				</div>
			</div>
		</div>
	);
};

export default DoctorProfile;
