import React from 'react';
import './SecureHealthData.scss';

const SecureHealthData = () => {
	return (
		<div className='secure__container'>
			<div className='secure__flex'>
				<div className='secure__content'>
					<h1>Secure health data</h1>
					<p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit.
						Ex perspiciatis quos odit quae rem aut illum nulla
						accusamus soluta, doloribus sequi incidunt error,
						tempore nisi similique magni, sit amet tempora?
					</p>
				</div>
				<div className='secure__img'>
					<img src='./secure-health.svg' alt='' />
				</div>
			</div>
		</div>
	);
};

export default SecureHealthData;
