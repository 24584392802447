import calenderSvg from './svg/calender.svg';
import playStore from './svg/playStore.svg';
import cameraSvg from './svg/camera.svg';
import navIcon from './svg/navIcon.svg';
import usersSvg from './svg/users.svg';
import chartSvg from './svg/chart.svg';
import cloudSvg from './svg/cloud.svg';
import fileSvg from './svg/file.svg';
import heartSvg from './svg/heart.svg';
import loveSvg from './svg/love.svg';
import robotSvg from './svg/robot.svg';
import xx1Svg from './svg/xx1.svg';
import xx2Svg from './svg/xx2.svg';
import xx3Svg from './svg/xx3.svg';
import xxPng from './svg/xx.png';
import xxSvg from './svg/xx.svg';
import conn from './svg/conn.png';
import conn2 from './svg/conn2.png';

import eAI from './svg/eAI.svg';
import eFolder from './svg/eFolder.svg';
import eDisease from './svg/eDisease.svg';
import eAttach from './svg/eAttach.svg';

import camIcon from './svg/camIcon.svg';
import fileIcon from './svg/fileIcon.svg';
import chartIcon from './svg/chartIcon.svg';
import robotIcon from './svg/robotIcon.svg';

import head1 from './images/head1.png';
import head2 from './images/head2.png';
import head3 from './images/head3.png';
import slide1 from './images/slide1.png';
import android from './images/android.png';
import exProblem from './images/ex_problem.png';
import exSpecial from './images/ex_special.png';

import blog1 from './blogs/1.png';
import blog2 from './blogs/2.png';
import blog3 from './blogs/3.png';
import blog4 from './blogs/4.png';
import blog5 from './blogs/5.png';
import blog6 from './blogs/6.png';

/* ------------ dummy data ------------ */

export const homeContent = [
	{
		title: 'BUILT FOR NETWORKING',
		subTitle:
			'Via the social interface people & healthcare professionals can connect with each other.',
		description: `
		Romatoo serves as a platform for healthcare professionals to network, seek career opportunities, share industry insights, and build their personal brand in the professional world. Especially with the help of the networking system, the blood recipient can easily find the blood donor located within a short distance.
		`,
		image: '/home/updated/1.png',
		url: '/social/feed',
	},
	{
		title: 'BUILT FOR EFFECTIVE TELEMEDICINE ',
		subTitle: 'Easily connect with global smart doctors for consultation 24/7.',
		description: `
		Doctors from any part of the world can provide medical services through this platform, and 
		people from any part of the world can receive medical services.
		We are working on two cutting-edge technologies to make cloud-based conferencing for 
		telemedicine more a reality.
		When a doctor provides medical care to a foreigner, Via the system in a short period they will be able to 
		know what kind of tests are used in the country of the foreigner to diagnose and cure the desired 
		disease and what kind of medicine is applied.
		`,
		image: '/home/updated/2.png',
		url: '/telemedicine',
	},
	{
		title: 'ACCELERATE PRODUCTIVITY WITH AI ASSISTENCE',
		subTitle:
			'AI assistance helps doctors to make the most effective decisions in the shortest possible time.',
		description: `
		AI System analyzes different parameters based of the patient’s current past health data at the same time find out any type errors at the laboratory. On the other hand, collection symptoms experienced before & after the disease noted for finding out accuracy. Inside of the designed so that there is opportunity to deal with and learn from medical errors that occur in the short term to avoid such mistakes in the future.		
		`,
		image: '/home/updated/3.svg',
		url: '/aiassistance',
	},
	{
		title: 'ADVANCE AI BASED ERROR FREE E-PRESCRIPTION MAKER',
		subTitle:
			'Via the AI Ensure accurate & most effective drug based on patient’s location.',
		description: `
		AI system helps inside at RX maker to the doctor to prescribe accurate & effective medicine based on patient past to present health data, future health level assumption & location. AI system monitors wrong / restrictions drug intake, overdose, & overuse of antibiotics in early stages, the mental stress of doctors. If anyone consults with foreign doctors, using our platform, doctors can prescribe effective medicine, which is locally available in patients’ location.
		`,
		image: '/home/updated/4.png',
		url: '/prescription',
	},
	{
		title:
			'BUILT FOR QUICK DETECTION OF HEALTH-RELATED ISSUE VIA THE BIO AI 3D HUMAN SHAPE',
		subTitle:
			'BIO AI 3D model saves lives, saves time, and helps healthcare professionals make the right decision when the time is too short.',
		description: `In the emergency thought the BIO AI based 3D model health care professionals can understand patients’ health condition & level just 10 seconds plus time`,
		image: '/home/updated/5-1.png',
		image2: '/home/updated/5-2.jpg',
		url: '/bioai',
	},
	{
		title: 'BUILT FOR HEALTH DATA STORING, INNOVATING & SECURING ',
		subTitle:
			'Store health data for future diagnosis, avoiding health risks & saving money.',
		description: `People can store their health data. Insider generative AI finds innovative & essential data. At the same time, innovative & essential data automatically share with those people who need it, so that people lead a healthy normal life`,
		image: '/home/updated/6.png',
		url: '/data-store',
	},
	{
		title:
			'AI ONLY DOCTOR BUILT FOR TAGEING EMERGENCY & PRIMARY LEVAL HEALTH CARE SITUATION',
		description: `Artificial intelligence-rich chatbot system can provide human medical care as accurately as a doctor in the early stages of disease and emergency situations. At same time via the chatbot provide mental health related support`,
		image: '/home/updated/7.png',
		url: '/aichatbot',
	},
];

export const medData = [
	{
		geneticName: 'Paracetamol',
		brandNames: [
			{
				name: 'Acetram',
			},
			{
				name: 'Doloran Plus',
			},
			{
				name: 'Xcel Max',
			},
			{
				name: 'Napadol',
			},
			{
				name: 'Ultracet',
			},
			{
				name: 'Syndol Plus',
			},
		],
	},
	{
		geneticName: 'Omeprazole',
		brandNames: [
			{
				name: 'Seclo',
			},
			{
				name: 'Xeldrin',
			},
			{
				name: 'Proceptin',
			},
			{
				name: 'Omenix',
			},
			{
				name: 'Nuprazol',
			},
			{
				name: 'Opal 20',
			},
		],
	},
	{
		geneticName: 'Famotidine',
		brandNames: [
			{
				name: 'Famotidine 20 mg',
			},
			{
				name: 'Famotidine 40 mg',
			},
			{
				name: 'Famotack',
			},
			{
				name: 'Famomax',
			},
			{
				name: 'Famotid',
			},
		],
	},
];

export const medSkeleton = {
	id: 0,
	geneticName: '',
	brandName: '',
	// timeTable: [
	// 	{
	// 		time: 'breakfast',
	// 		amount: '',
	// 		editable: false,
	// 	},
	// 	{
	// 		time: 'lunch',
	// 		amount: '',
	// 		editable: false,
	// 	},
	// 	{
	// 		time: 'dinner',
	// 		amount: '',
	// 		editable: false,
	// 	},
	// ],
	times: [
		{
			id: 1,
			time: 'morning',
			amount: '',
			editable: false,
		},
		{
			id: 2,
			time: 'noon',
			amount: '',
			editable: false,
		},
		{
			id: 3,
			time: 'night',
			amount: '',
			editable: false,
		},
	],
	timeTable: [
		{
			type: 1,
			duration: '1',
			durationType: 'days',
		},
	],
	duration: '',
	intake: '',
	note: '',
};

export const medicalSecSkeleton = [
	{
		name: 'chiefComplaints',
		label: 'Chief Complaints',
		text: '',
		edit: false,
	},
	{
		name: 'drugHistory',
		label: 'Drug History',
		text: '',
		edit: false,
	},
	{
		name: 'bloodPressure',
		label: 'BP',
		text: '',
		edit: false,
	},
];

export const serviceData = [
	{
		svg: camIcon,
		title: 'Live Chat',
		text: 'Our virtual environment ensures efficient care by reducing distance.',
	},
	{
		svg: fileIcon,
		title: 'ePrescription',
		text: 'Find most effective drugs, dose & collect easily for curing disease.',
	},
	{
		svg: chartIcon,
		title: 'Data comparison',
		text: 'Analyze data to help doctors & public understand their health conditions.',
	},
	{
		svg: robotIcon,
		title: 'Ai Assistance',
		text: 'In-depth data analysis will help doctors to make better decisions.',
	},
];

export const cloudData = [
	{
		svg: fileSvg,
		title: 'ePrescription',
		text: 'Using the cloud base solution, when a doctor needs a particular group of drugs to cure a patient',
		url: '/e_prescription',
	},
	{
		svg: cameraSvg,
		title: 'Video Conference',
		text: 'This service is intended for medical consultation use only. Our video call conference is designed',
		url: '/video_conference',
	},
	{
		svg: chartSvg,
		title: 'Data comparison',
		text: 'Our app will collect human health data worldwide using intelligence tools. The collected',
		url: '/data_comparing',
	},
];

export const blogsData = [
	{
		image: blog1,
		text: 'A healthy diet is essential for good health and nutrition. It protects you against',
	},
	{
		image: blog2,
		text: 'Mental health is a state of mental well-being that enables people to cope with the',
	},
	{
		image: blog3,
		text: 'Meditation can give you a sense of calm, peace and balance that can benefit your',
	},
	{
		image: blog4,
		text: 'Here are some tips for improving social fitness',
	},
	{
		image: blog5,
		text: 'Here are some tips for maintaining physical fitness',
	},
	{
		image: blog6,
		text: 'Here are some tips for a healthy diet during pregnancy',
	},
];

export const navData1 = {
	name: "Global Doctor's",
	sub: [
		{
			title: 'Psychologist',
			img: 'https://i.ibb.co/k0PSY4C/psychologist.png',
		},
		{
			title: 'Dermatologist',
			img: 'https://i.ibb.co/XsGLNwz/dermatologist.png',
		},
		{
			title: 'Child / Pediatric',
			img: 'https://i.ibb.co/8bwSXzB/child-pediatric.png',
		},
		{
			title: 'General Physician',
			img: 'https://i.ibb.co/hLVthWB/general-physician.png',
		},
		{
			title: 'Dentist',
			img: 'https://i.ibb.co/pnp4xM9/dentist.png',
		},
		{
			title: 'Sexologist & VD',
			img: ' https://i.ibb.co/C1WkgFZ/Sexologist-vd.png',
		},
		{
			title: 'Gastroenterology',
			img: 'https://i.ibb.co/hBs93KV/gastroenterology.png',
		},
		{
			title: ' Obs & Gynecology',
			img: 'https://i.ibb.co/DwY4Szn/obs-gynecology.png',
		},
		{
			title: 'Orthopedic',
			img: ' https://i.ibb.co/qYVR2fb/orthopedic.png',
		},
		{
			title: 'Chest',
			img: ' https://i.ibb.co/RcByRmd/chest.png',
		},
		{
			title: 'Diabetes / Endocrinologist',
			img: ' https://i.ibb.co/r0JcG35/diabetes.png',
		},
		{
			title: 'Cardiology',
			img: ' https://i.ibb.co/n3f9YYq/cardiology.png',
		},
		{
			title: 'Dietitian & Nutritionist',
			img: 'https://i.ibb.co/x8TJ23T/dietitian-nutritionist.png',
		},
		{
			title: 'ENT / Head & Neck',
			img: 'https://i.ibb.co/2tYBsB7/ENT-Head-Neck.png',
		},
		{
			title: 'Neuromedicine',
			img: 'https://i.ibb.co/fYYPLbn/neuromedicine.png',
		},
		{
			title: 'Ophthalmology',
			img: 'https://i.ibb.co/rQnZYmT/ophthalmology.png',
		},
	],
};

export const sliderData = [
	{
		image: slide1,
		subHeader: 'Find out diseases',
		header: 'through AI Assistant',
		text: "With the help of our AI assistant, doctors can quickly and accurately diagnose and take the proper steps to treat their patients. For example: With an AI-based virtual 3D image system, a doctor can know a patient's health problems in just a few seconds.",
	},
	{
		image: slide1,
		subHeader: 'Use our latest',
		header: ' Smart AI bot',
		text: 'In case of a medical crisis due to natural calamities such as epidemics, economic downturns such as lack of funds, times of war, or political unrest, ordinary people can seek medical care at the early stages of the disease with the help of our AI bot. Because our developing artificial intelligence bot can provide proper medical care by deeply analyzing various health-related parameters of people.',
	},
];

export const dummySpecialty = [
	'Neurology',
	'Cardiology',
	'Dermatology',
	'Dentistry',
	'Psychology',
	'Gastroenterology',
	'Gynecology',
	'Endocrinology',
	'Nephrology',
	'Urology',
	'Orthopedics',
	'Pediatrics',
	'Radiology',
	'General Medicine',
	'General Surgery',
	'Anesthesiology',
	'Obstetrics',
	'Pathology',
];

export const originalSpecialty = [
	{
		name: 'Brain & Neurons',
		// sub: ['Neurology', 'Neuromedicine', 'Neurosurgery'],
		sub: [
			{
				name: 'Neurology',
				img: '_1.png',
			},
			{
				name: 'Neuromedicine',
				img: '_2.png',
			},
			{
				name: 'Neurosurgery',
				img: '_3.png',
			},
		],
	},
	{
		name: 'ENT, Head & Neck',
		img: '_4.png',
		sub: [],
	},
	{
		name: 'Eye / Ophthalmology',
		img: '_5.png',
		sub: [],
	},
	{
		name: 'Dermatology & VD',
		sub: [
			// 'Allergy, Hair, Sex, Skin, Venerology',
			// 'Dermatology, Cosmetology & Sexual Medicine',
			// 'Plastic, Reconstructive & Aesthetic surgery',
			{
				name: 'Allergy, Hair, Sex, Skin, Venerology',
				img: '_6.png',
			},
			{
				name: 'Dermatology, Cosmetology & Sexual Medicine',
				img: '_7.png',
			},
			{
				name: 'Plastic, Reconstructive & Aesthetic surgery',
				img: '_8.png',
			},
		],
	},
	{
		name: 'Dental',
		// sub: ['Oral Maxillofacial', 'Periodontics', 'Orthodontist'],
		sub: [
			{
				name: 'Oral Maxillofacial',
				img: '_9.png',
			},
			{
				name: 'Periodontics',
				img: '_11.png',
			},
			{
				name: 'Orthodontist',
				img: '_10.png',
			},
		],
	},
	{
		name: 'Endocrinology',
		// sub: ['Diabetes', 'Diabetes, Thyroid & Hormone'],
		sub: [
			{
				name: 'Diabetes',
				img: '_13.png',
			},
			{
				name: 'Diabetes, Thyroid & Hormone',
				img: '_12.png',
			},
		],
	},
	{
		name: 'Orthopedic',
		// sub: ['Orthopedic & Trauma', 'Orthopaedic Surgery', 'Rheumatology'],
		sub: [
			{
				name: 'Orthopedic & Trauma',
				img: '_15.png',
			},
			{
				name: 'Orthopaedic Surgery',
				img: '_14.png',
			},
			{
				name: 'Rheumatology',
				img: '16.jpeg',
			},
		],
	},
	{
		name: 'Chest',
		// sub: ['Chest Medicine', 'Chest & Asthma'],
		sub: [
			{
				name: 'Chest Medicine',
				img: '18.png',
			},
			{
				name: 'Chest & Asthma',
				img: '17.jpeg',
			},
		],
	},
	{
		name: 'Heart',
		sub: [
			// 'Cardiology',
			// 'Cryosurgery',
			// 'Vascular Surgery',
			// 'Cardiothoracic Anesthesiology',
			{
				name: 'Cardiology',
				img: '19.jpeg',
			},
			{
				name: 'Vascular Surgery',
				img: '21.jpeg',
			},
			{
				name: 'Cardiothoracic Anesthesiology',
				img: '20.jpeg',
			},
		],
	},
	{
		name: 'Psychiatry',
		img: '22.png',
		sub: [],
	},
	{
		name: 'Psychologist',
		img: '23.jpeg',
		sub: [],
	},
	{
		name: 'Hematology',
		img: '24.png',
		sub: [],
	},
	{
		name: 'Medicine Specialist',
		img: '25.png',
		sub: [],
	},
	{
		name: 'General Surgery',
		// sub: ['Surgery Specialist', 'General Surgery, Laparoscopic'],
		sub: [
			{
				name: 'Surgery Specialist',
				img: '27.png',
			},
			{
				name: 'General Surgery, Laparoscopic',
				img: '26.png',
			},
		],
	},
	{
		name: 'Gastroenterology & Hepatology',
		sub: [
			// 'Gastrointestinal Liver & Pancreatic Diseases',
			// 'Medicine & Gastroenterology',
			// 'Medicine, Liver & Gastroenterology',
			// 'Medicine, GI & Endoscopy',
			// 'Hepatobiliary, Liver Biliary',
			{
				name: 'Gastrointestinal Liver & Pancreatic Diseases',
				img: '28.png',
			},
			{
				name: 'Medicine & Gastroenterology',
				img: '30.png',
			},
			{
				name: 'Medicine, Liver & Gastroenterology',
				img: '31.png',
			},
			{
				name: 'Hepatobiliary, Liver Biliary',
				img: '29.png',
			},
		],
	},
	{
		name: 'Obstetrics & Gynecology',
		sub: [
			// 'General Obstetrics & Gynecology Specialist',
			// 'infertility care',
			// 'Gynae Obs Oncology',
			// 'Breast, Colorectal & Laparoscopy',
			{
				name: 'General Obstetrics & Gynecology Specialist',
				img: '33.png',
			},
			{
				name: 'infertility care',
				img: '35.png',
			},
			{
				name: 'Gynae Obs Oncology',
				img: '34.jpeg',
			},
			{
				name: 'Breast, Colorectal & Laparoscopy',
				img: '32.jpeg',
			},
		],
	},
	{
		name: 'Child / Pacdiates',
		sub: [
			{
				name: 'Child Specialist',
				img: '38.jpeg',
			},
			{
				name: 'Pacdiates Surgery',
				img: '39.jpeg',
			},
			{
				name: 'Child Specialist & Pulmonologist',
				img: '37.jpeg',
			},
			{
				name: 'Child Neurology',
				img: '36.png',
			},
		],
	},
	{
		name: 'Nephrology & Urology',
		sub: [
			{
				name: 'Kidney & Medicine Specialist',
				img: '40.jpeg',
			},
			{
				name: 'Urology',
				img: '44.jpeg',
			},
			{
				name: 'Uro surgery',
				img: '41.jpeg',
			},
			{
				name: 'Uro-Oncology',
				img: '45.jpeg',
			},
			{
				name: 'Urology & andrology',
				img: '43.jpeg',
			},
			{
				name: 'Urologist & Laparoscopic',
				img: '42.png',
			},
		],
	},
	{
		name: 'Cancer/Oncology',
		img: '46.png',
		sub: [],
	},
	{
		name: 'Physiotherapy',
		img: '47.png',
		sub: [],
	},
];

export const presTest = [
	{
		category: 'Hematology',
		tests: [
			'Hemoglobin(Hb%)',
			'Periferal Blood Film / PBF',
			'Blood Circulating Total Eosinophil Count (TCE)',
			'Reticulocyte Count',
			'Platelete count',
			'BT.CT',
			'ESR',
			'CBC with ESR',
			'Complete Blood Count (CBC)',
		],
	},
	{
		category: 'Thalassemia',
		tests: [
			'Neonatal Hb Electrophoresis',
			'Beta-Thalassemia Screening',
			'Hb Electrophoresis',
			'MINI THALASSEMIA PROFILE',
			'MAX THALASSEMIA PROFILE',
			'HEMOGLOBIN VARIANT ANALYSIS - HPLC',
			'NBS HEMOGLOBINOPATHIES PROFILE',
		],
	},
	{
		category: 'Diabetes',
		tests: [
			'RBS (Random Blood Sugar)',
			'HbA1c',
			'ACR-Urine',
			'Insulin',
			'C-Peptide',
			'Fructosamine',
			'Blood Ketone (D3HB)',
			'INSULIN ANTIBODIES',
			'FBS (Fasting Blood Sugar)',
			'PPBS (Post Prandial Blood Sugar)',
		],
	},
	{
		category: 'Kidney Disease',
		tests: [
			'Blood Urea Nitrogen (BUN)',
			'CCR-24 Hrs Urine',
			'Urinary Microalbumin',
			'Cystatin C',
			'Direct Renin',
			'eGFR',
			'Serum Creatinine',
		],
	},
	{
		category: 'Heart Disease',
		tests: [
			'Troponin I',
			'Pro BNP',
			'CK-MB',
			'CPK',
			'LDH',
			'Lipid Profile',
			'Total Cholesterol',
			'Triglycerides(TG)',
			'SMALL DENSE LDL',
			'CARDIAC RISK MARKERS',
		],
	},
	{
		category: 'Anemia Profile',
		tests: [
			'Erythropoientin',
			'Ceruloplasmin',
			'Iron Profile',
			'Serum Iron',
			'TIBC',
			'Serum Folic Acid',
			'Serum Vitamin B-12',
			'Serum Ferritin',
		],
	},
	{
		category: 'Liver Disease',
		tests: [
			'Total Protein',
			'Serum Lipase',
			'Liver Function Test (LFT)',
			'HEPATITIS PANEL',
			'HEPATITIS C VIRUS - IGM',
			'Calorie Function Test',
			'Protein Profile',
			'Serum Amylase',
			'Serum Globulin',
		],
	},
	{
		category: 'Cancer Markers',
		tests: [
			'Alpha Fetoprotein (AFP)',
			'Free PSA',
			'Carcinoembryonic antigen (CEA)',
			'TUMOUR PANEL',
			'CA 125',
			'CA 19-9',
			'CA 15-3',
			'BENCE JONES PROTEIN, URINE',
		],
	},
	{
		category: 'Thyroid Disorders',
		tests: [
			'TSH - Ultrasensitive',
			'Anti Microsomal Antibody',
			'Total Triiodothyronine',
			'Total Thyroxine',
			'Free Thyroxine',
			'Thyroglobulin',
		],
	},
	{
		category: 'Reproductive Tests',
		tests: [
			'Free Testosterone',
			'Anti Mullerian Hormone',
			'Inhibin A',
			'17 OH Progesterone',
			'SHBG',
			'Unconjugated Estriol',
			'Beta HCG',
			'Luteinizing Hormone',
			'Progesterone',
			'Testosterone',
		],
	},
	{
		category: 'CYTOGENETICS',
		tests: [
			'AML1/ETO (AML - M2)',
			'AMNIOTIC FLUID FOR KARYOTYPING',
			'ANEUPLOIDY FISH (13,18,21,X & Y)',
			'Antibiogram - MTB (Linezolid)',
			'CHROMOSOME 21',
			'FRAGILE X SYNDROME',
		],
	},
];

/* ------------ svg / icons ------------ */

export {
	calenderSvg,
	playStore,
	cameraSvg,
	usersSvg,
	chartSvg,
	cloudSvg,
	navIcon,
	fileSvg,
	heartSvg,
	loveSvg,
	robotSvg,
	xx1Svg,
	xx2Svg,
	xx3Svg,
	xxPng,
	xxSvg,
	conn,
	conn2,
	camIcon,
	fileIcon,
	chartIcon,
	robotIcon,
	eDisease,
	eAttach,
	eAI,
	eFolder,
};

/* ------------ images ------------ */

export { head1, head2, head3, slide1, android, exProblem, exSpecial };
