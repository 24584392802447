import React from 'react';
import { BiLayerPlus } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './PatientPrescription.scss';

const PatientPrescription = () => {
	window.document.title = 'Patient Prescription | Romatoo';

	const navigate = useNavigate();
	const token = useSelector((state) => state.auth.token);
	const authInfo = useSelector((state) => state.auth.info);

	React.useEffect(() => {
		if (!token) {
			navigate('/login');
		} else {
			if (authInfo.role !== 'patient') {
				navigate('/login');
			}
		}
	}, [token]);

	return (
		<div className='patientPrescription page'>
			<div className='container'>
				<div className='banner__container'>
					<img src='/home/datastore_banner.jpg' alt='banner' />
				</div>

				<div className='drop__files__container'>
					<div className='drop__files'>
						<BiLayerPlus className='icon' />

						<p>
							Drop your files here, or <span>Browse</span>
						</p>
						<span>Maximum file size 50 mb</span>
					</div>

					<div className='drop__files'></div>
				</div>

				<div className='button__container'>
					<div className='block'>
						<button className='btn'>AI Generate</button>
					</div>

					<div className='block'>
						<button className='btn'>AI Generate</button>
					</div>
				</div>

				<div className='red__block'></div>

				<div className='medicines__container'>
					<table>
						<colgroup>
							<col span={1} className='w-72' />
							<col span={1} className='w-[35rem]' />
							<col span={1} className='w-40' />
							<col span={1} className='w-56' />
						</colgroup>

						<thead>
							<tr>
								<th>Medicine Name</th>
								<th>Intake</th>
								<th>Duration</th>
								<th>Note</th>
							</tr>
						</thead>

						<tbody>
							{Array(4)
								.fill(0)
								.map((_, index) => (
									<tr key={index}>
										<td>
											<p>Medicine Name {index}</p>
										</td>
										<td>
											<div className='intake'>
												<div className='time'>
													<p></p>
													<p>09:00 AM</p>
													<p>1</p>
												</div>

												<div className='time'>
													<p></p>
													<p>03:00 PM</p>
													<p>1</p>
												</div>

												<div className='time'>
													<p></p>
													<p>09:00 PM</p>
													<p>1</p>
												</div>
											</div>
										</td>
										<td>
											<p>
												{index} {index > 1 ? 'days' : 'day'}
											</p>
										</td>
										<td>
											<p>Note {index}</p>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default PatientPrescription;
