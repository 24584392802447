import React from 'react';
import { toast } from 'react-toastify';
import { HiPlus, HiDotsHorizontal } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { TiInfoLarge } from 'react-icons/ti';
import { IoAdd, IoSend } from 'react-icons/io5';
import { AiOutlineRetweet } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, useNavigate, useParams } from 'react-router-dom';

import LoadingSVG from '../../../components/LoadingSVG/LoadingSVG';

import '../Feed/Feed.scss';

const SinglePost = () => {
	const { postId } = useParams();
	const navigate = useNavigate();

	const authToken = useSelector((state) => state.auth.token);
	const authInfo = useSelector((state) => state.auth.info);

	if (!postId) {
		navigate('/social/feed');
	}

	const [myProfileData, setMyProfileData] = React.useState(null); // my profile data
	const [userInfo, setUserInfo] = React.useState(null); // user info
	const [comment, setComment] = React.useState('');
	const [openComment, setOpenComment] = React.useState(false);
	const [postDetail, setPostDetail] = React.useState(null);

	const [openOptions, setOpenOptions] = React.useState(false);
	const [openEditPost, setOpenEditPost] = React.useState(false);

	const [loading, setLoading] = React.useState(true);
	const [loading2, setLoading2] = React.useState(false);
	const [profileLoading, setProfileLoading] = React.useState(true);

	React.useEffect(() => {
		const fetchPosts = async () => {
			const url = `${process.env.REACT_APP_API}/doctor/post/${postId}`;

			try {
				const req = await fetch(url);
				const res = await req.json();

				console.log('fetch user Posts red -> ', res);

				if (res.success) {
					setUserInfo(res.data.user);
					setPostDetail(res.data.post);
				} else {
					toast.error(res.message);
				}
			} catch (error) {
				console.log(error);
				toast.error('Something went wrong!');
			} finally {
				setLoading(false);
			}
		};

		const fetchMyProfile = async () => {
			if (authInfo.role === 'doctor') {
				const url = `${process.env.REACT_APP_API}/doctor/my-profile`;
				const options = {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': authToken,
					},
				};

				try {
					const req = await fetch(url, options);
					const res = await req.json();

					console.log('fetchMyProfile red -> ', res);

					if (res.success) {
						setMyProfileData(res.data);
					} else {
						toast.error(res.message);
					}
				} catch (error) {
					console.log(error);
					toast.error('Something went wrong!');
				} finally {
					setProfileLoading(false);
				}
			} else if (authInfo.role === 'patient') {
				const url = `${process.env.REACT_APP_API}/patient/my-profile`;
				const options = {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': authToken,
					},
				};

				try {
					const req = await fetch(url, options);
					const res = await req.json();

					console.log('fetchMyProfile red -> ', res);

					if (res.success) {
						setMyProfileData(res.data);
					} else {
						toast.error(res.message);
					}
				} catch (error) {
					console.log(error);
					toast.error('Something went wrong!');
				} finally {
					setProfileLoading(false);
				}
			}
		};

		fetchMyProfile();
		fetchPosts();
	}, [postId]);

	const toggleLike = () => {
		setLoading2(true);

		const bodyData = {
			userId: authInfo.id,
			fullName: authInfo.fullName,
			profilePicture: authInfo.profilePicture,
		};

		const url = `${process.env.REACT_APP_API}/doctor/post/${postId}/like`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
			body: JSON.stringify(bodyData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('toggleLike red -> ', res);

				if (res.success) {
					// const newPosts = allPosts.map((post) => {
					// 	if (post._id === postId) {
					// 		post.likeReact = res.data.like;
					// 		post.loveReact = res.data.love;
					// 		post.laughReact = res.data.laugh;
					// 		return post;
					// 	} else {
					// 		return post;
					// 	}
					// });

					// setAllPosts(newPosts);

					setPostDetail((prev) => {
						return {
							...prev,
							likeReact: res.data.like,
							loveReact: res.data.love,
							laughReact: res.data.laugh,
						};
					});
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const toggleLove = () => {
		setLoading2(true);

		const bodyData = {
			userId: authInfo.id,
			fullName: authInfo.fullName,
			profilePicture: authInfo.profilePicture,
		};

		const url = `${process.env.REACT_APP_API}/doctor/post/${postId}/love`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
			body: JSON.stringify(bodyData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('toggleLove red -> ', res);

				if (res.success) {
					// const newPosts = allPosts.map((post) => {
					// 	if (post._id === postId) {
					// 		post.likeReact = res.data.like;
					// 		post.loveReact = res.data.love;
					// 		post.laughReact = res.data.laugh;
					// 		return post;
					// 	} else {
					// 		return post;
					// 	}
					// });

					// setAllPosts(newPosts);

					setPostDetail((prev) => {
						return {
							...prev,
							likeReact: res.data.like,
							loveReact: res.data.love,
							laughReact: res.data.laugh,
						};
					});
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const toggleLaugh = () => {
		setLoading2(true);

		const bodyData = {
			userId: authInfo.id,
			fullName: authInfo.fullName,
			profilePicture: authInfo.profilePicture,
		};

		const url = `${process.env.REACT_APP_API}/doctor/post/${postId}/laugh`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
			body: JSON.stringify(bodyData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('togglelaugh red -> ', res);

				if (res.success) {
					// const newPosts = allPosts.map((post) => {
					// 	if (post._id === postId) {
					// 		post.likeReact = res.data.like;
					// 		post.loveReact = res.data.love;
					// 		post.laughReact = res.data.laugh;
					// 		return post;
					// 	} else {
					// 		return post;
					// 	}
					// });

					// setAllPosts(newPosts);

					setPostDetail((prev) => {
						return {
							...prev,
							likeReact: res.data.like,
							loveReact: res.data.love,
							laughReact: res.data.laugh,
						};
					});
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const postComment = () => {
		setLoading2(true);

		const bodyData = {
			author: authInfo.id,
			post: postId,
			text: comment,
			userRole: authInfo.role === 'doctor' ? 'Doctor' : 'Patient',
		};

		const url = `${process.env.REACT_APP_API}/doctor/post/comment/${postId}`;

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
			body: JSON.stringify(bodyData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('postComment red -> ', res);

				if (res.success) {
					// setAllPosts((prev) => {
					// 	const newPosts = prev.map((post) => {
					// 		if (post._id === postId) {
					// 			post.comments.push(res.data);
					// 			return post;
					// 		} else {
					// 			return post;
					// 		}
					// 	});

					// 	return newPosts;
					// });
					// setComments((prev) => {
					// 	const newComments = prev.filter((x) => x.postId !== postId);
					// 	return newComments;
					// });

					setPostDetail((prev) => {
						return {
							...prev,
							comments: [...prev.comments, res.data],
						};
					});
					setComment('');
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const followUser = (userId, userRole) => {
		setLoading2(true);

		const bodyData = {
			userRole: userRole,
			userId: userId,
		};

		const url = `${process.env.REACT_APP_API}/doctor/follow`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
			body: JSON.stringify(bodyData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('followUser red -> ', res);

				if (res.success) {
					// setAllPosts((prev) => {
					// 	const newPosts = prev.map((post) => {
					// 		if (post.author._id === userId) {
					// 			post.author.social.followers = res.data.user;
					// 		}

					// 		return post;
					// 	});

					// 	return newPosts;
					// });
					toast.success(res.message);
					setPostDetail((prev) => {
						return {
							...prev,
							author: {
								...prev.author,
								social: {
									...prev.author.social,
									followers: res.data.user,
								},
							},
						};
					});
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const deletePost = () => {
		setLoading2(true);

		const url = `${process.env.REACT_APP_API}/doctor/post/${postId}`;

		fetch(url, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('deletePost red -> ', res);

				if (res.success) {
					toast.success(res.message);
					navigate('/social/feed');
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const hidePost = () => {
		setLoading2(true);

		const url = `${process.env.REACT_APP_API}/doctor/post/hide/${postId}`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('hidePost red -> ', res);

				if (res.success) {
					setMyProfileData((prev) => {
						const newHiddenPosts = [...res.data];
						return { ...prev, hiddenPosts: newHiddenPosts };
					});
					toast.success(res.message);
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	if (loading || profileLoading) {
		return <LoadingSVG />;
	}

	return (
		<div className='feed page'>
			<div className='feed__container'>
				<div className='w-full col-span-3 hidden sm:block'>
					<div className='my__profile'>
						<div className='top'>
							<div className='avatar'>
								<img
									src={
										userInfo.profileInfo?.profilePicture
											? userInfo.profileInfo?.profilePicture
											: userInfo.gender === 'Male'
											? '/my-profile/male-avatar.png'
											: '/my-profile/female-avatar.png'
									}
									alt='avatar'
								/>
							</div>

							<div className='info'>
								<h1>
									<Link
										to={`/social/profile/${userInfo._id}`}
										className='hover:underline'
									>
										{userInfo?.fullName}
									</Link>
								</h1>
								{userInfo.profileInfo?.title && (
									<p>{userInfo.profileInfo.title}</p>
								)}
								{userInfo?.profileInfo?.currentOrganization && (
									<p className='text-sm text-'>
										{userInfo.profileInfo.currentOrganization}
									</p>
								)}
								{userInfo?.profileInfo?.qualification && (
									<p className='text-sm'>
										{userInfo?.profileInfo?.qualification}
									</p>
								)}
							</div>
						</div>

						<div className='connections'>
							<p>
								<span>Connections</span>
								<span>
									{userInfo?.social?.followers?.length +
										userInfo?.social?.following?.length}
								</span>
							</p>
							<p>
								<span>Pending Connections</span>
								<span>{userInfo?.social?.following?.length}</span>
							</p>
						</div>
					</div>
				</div>

				<div className='w-full col-span-12 px-2 sm:px-0 sm:col-span-6'>
					<div className='posts'>
						{myProfileData.hiddenPosts.includes(postId) ? (
							<div className='post'>
								<div className='flex items-center justify-between p-4 rounded-md'>
									<p>Hidden Post</p>

									<button
										className='bg-emerald-100 px-4 py-1.5 rounded text-sm'
										onClick={hidePost}
									>
										Undo
									</button>
								</div>
							</div>
						) : (
							<div className='post'>
								<div className='top'>
									<div
										className='avatar cursor-pointer'
										onClick={() =>
											navigate(`/social/profile/${postDetail.author._id}`)
										}
									>
										<img
											src={
												postDetail.author?.profileInfo?.profilePicture ||
												'/my-profile/male-avatar.png'
											}
											alt='avatar'
										/>
									</div>

									<div className='info cursor-pointer'>
										<h1
											onClick={() =>
												navigate(`/social/profile/${postDetail.author._id}`)
											}
										>
											{postDetail.author?.fullName}
										</h1>
										<p>{postDetail.author?.profileInfo?.title}</p>

										<p>
											<span>
												{new Date(postDetail.createdAt).toLocaleDateString(
													'en-US',
													{
														year: 'numeric',
														month: 'long',
														day: 'numeric',
													}
												)}
											</span>
											{' • '}
											<span>Public</span>
										</p>
									</div>

									{postDetail.author._id !== authInfo.id ? (
										postDetail.author.social.followers.find(
											(x) => x.userId === authInfo.id
										) ? null : (
											<button
												onClick={() =>
													followUser(postDetail.author._id, postDetail.userRole)
												}
											>
												<HiPlus className='icon' />
												<span>Follow</span>
											</button>
										)
									) : null}

									<div className='relative flex'>
										<button
											onClick={() => {
												setOpenOptions((prev) => {
													const newOpen =
														prev.postId === postDetail._id ? !prev.open : true;

													const newPostId =
														postDetail._id === prev.postId
															? ''
															: postDetail._id;

													console.log({ open: newOpen, postId: newPostId });
													return { open: newOpen, postId: newPostId };
												});
											}}
										>
											<HiDotsHorizontal className='icon' />
										</button>

										{openOptions.postId === postDetail._id &&
										openOptions.open ? (
											<div className='absolute top-full right-0 w-36 flex flex-col bg-white rounded shadow-md'>
												{postDetail.author._id === authInfo.id ? (
													<>
														{/* <button
																className='px-2 py-1.5 hover:bg-slate-200 text-sm'
																onClick={() => {
																	setOpenOptions((prev) => {
																		return { open: false, postId: '' };
																	});
																	setOpenEditPost(false);
																}}
															>
																Edit Post
															</button> */}

														<button
															className='px-2 py-1.5 hover:bg-slate-200 text-sm'
															onClick={() => {
																deletePost();
																setOpenOptions(false);
															}}
														>
															Delete Post
														</button>
													</>
												) : (
													<>
														{postDetail.author.social.followers.find(
															(x) => x.userId === authInfo.id
														) ? (
															<button
																className='px-2 py-1.5 hover:bg-slate-200 text-sm'
																onClick={() => {
																	followUser(
																		postDetail.author._id,
																		postDetail.userRole
																	);
																	setOpenOptions(false);
																}}
															>
																Unfollow
															</button>
														) : null}

														<button
															className='px-2 py-1.5 hover:bg-slate-200 text-sm'
															onClick={() => {
																hidePost();
																setOpenOptions(false);
															}}
														>
															Hide Post
														</button>
													</>
												)}
												<CopyToClipboard
													text={`https://romatoo.com/social/post/${postDetail._id}`}
													onCopy={() => {
														setOpenOptions(false);
														toast.success('Copied to clipboard');
													}}
												>
													<button className='px-2 py-1.5 hover:bg-slate-200 text-sm'>
														Copy Link
													</button>
												</CopyToClipboard>
											</div>
										) : null}
									</div>
								</div>

								<div className='content'>
									{postDetail.text.split('\n').map((item, key) => {
										if (key === postDetail.text.split('\n').length - 1) {
											return (
												<p key={key}>
													{item}
													<br />
												</p>
											);
										} else {
											return <p key={key}>{item}</p>;
										}
									})}

									{postDetail.images?.length > 0 ? (
										<div className='image'>
											<img src={postDetail.images[0]} alt='post' />
										</div>
									) : null}

									{postDetail?.video ? (
										<div className='video'>
											<video src={`/${postDetail.video}`} controls></video>
										</div>
									) : null}
								</div>

								<div className='bottom'>
									<div className='stats'>
										<div className='left'>
											{/* <img src='/social/like.svg' alt='like' />
													<img src='/social/love.svg' alt='love' />
													<img src='/social/insightful.svg' alt='insightful' /> */}

											{postDetail.likeReact.length > 0 ? (
												<img src='/social/like.svg' alt='like' />
											) : null}
											{postDetail.loveReact.length > 0 ? (
												<img src='/social/love.svg' alt='love' />
											) : null}
											{postDetail.laughReact.length > 0 ? (
												<img src='/social/laugh.png' alt='laugh' />
											) : null}
											<p>
												{/* {post.reactorName || 'Md Rakibul'} and{' '}
														{randomGenerator()} others */}

												{/* {post.likeReact.length > 0 ||
														post.loveReact.length > 0 ? (
															<>
																{post.likeReact[0]?.fullName ||
																	post.loveReact[0]?.fullName}{' '}
																{post.likeReact.length +
																	post.loveReact.length +
																	post.laughReact.length -
																	1}{' '}
																others
															</>
														) : (
															'Be the first to react'
														)} */}

												{postDetail.likeReact.find(
													(x) => x.userId === authInfo.id
												) ||
												postDetail.loveReact.find(
													(x) => x.userId === authInfo.id
												) ||
												postDetail.laughReact.find(
													(x) => x.userId === authInfo.id
												) ? (
													<>
														You{' '}
														{postDetail.likeReact.length +
															postDetail.loveReact.length +
															postDetail.laughReact.length -
															1 >
														0 ? (
															<>
																and{' '}
																{postDetail.likeReact.length +
																	postDetail.loveReact.length +
																	postDetail.laughReact.length -
																	1}{' '}
																others
															</>
														) : null}
													</>
												) : postDetail.likeReact.length > 0 ||
												  postDetail.loveReact.length > 0 ||
												  postDetail.laughReact.length > 0 ? (
													<>
														{postDetail.likeReact[0]?.fullName ||
															postDetail.loveReact[0]?.fullName ||
															postDetail.laughReact[0]?.fullName}{' '}
														{postDetail.likeReact.length +
															postDetail.loveReact.length +
															postDetail.laughReact.length -
															1 >
														0 ? (
															<>
																and{' '}
																{postDetail.likeReact.length +
																	postDetail.loveReact.length +
																	postDetail.laughReact.length -
																	1}{' '}
																others
															</>
														) : null}
													</>
												) : (
													'Be the first to react'
												)}
											</p>
										</div>

										<div className='right'>
											<p>{postDetail.comments?.length} comments</p>
											{' • '}
											<p>{postDetail.retoo?.length || '0'} Retoo</p>
										</div>
									</div>

									<div className='actions'>
										<button
											onClick={toggleLike}
											className={
												postDetail.likeReact.find(
													(x) => x.userId === authInfo.id
												) ||
												postDetail.loveReact.find(
													(x) => x.userId === authInfo.id
												) ||
												postDetail.laughReact.find(
													(x) => x.userId === authInfo.id
												)
													? 'btn-action active'
													: 'btn-action'
											}
										>
											{/* <RiThumbUpLine className='icon' /> */}
											{postDetail.likeReact.find(
												(x) => x.userId === authInfo.id
											) ? (
												<img src='/social/like.png' alt='like' />
											) : postDetail.loveReact.find(
													(x) => x.userId === authInfo.id
											  ) ? (
												<img src='/social/love.png' alt='love' />
											) : postDetail.laughReact.find(
													(x) => x.userId === authInfo.id
											  ) ? (
												<img src='/social/laugh.png' alt='laugh' />
											) : (
												<img src='/social/like.png' alt='like' />
											)}
											<span>
												{postDetail.likeReact.find(
													(x) => x.userId === authInfo.id
												)
													? 'Like'
													: postDetail.loveReact.find(
															(x) => x.userId === authInfo.id
													  )
													? 'Love'
													: postDetail.laughReact.find(
															(x) => x.userId === authInfo.id
													  )
													? 'Laugh'
													: 'Like'}
											</span>

											<div className='all-reacts'>
												<span
													onClick={(e) => {
														e.stopPropagation();
														toggleLike();
													}}
													className={
														postDetail.likeReact.find(
															(x) => x.userId === authInfo.id
														)
															? 'btn-reaction active'
															: 'btn-reaction'
													}
												>
													<img src='/social/like.png' alt='like' />
												</span>

												<span
													onClick={(e) => {
														e.stopPropagation();
														toggleLove();
													}}
													className={
														postDetail.loveReact.find(
															(x) => x.userId === authInfo.id
														)
															? 'btn-reaction active'
															: 'btn-reaction'
													}
												>
													<img src='/social/love.png' alt='love' />
												</span>

												<span
													onClick={(e) => {
														e.stopPropagation();
														toggleLaugh();
													}}
													className={
														postDetail.laughReact.find(
															(x) => x.userId === authInfo.id
														)
															? 'btn-reaction active'
															: 'btn-reaction'
													}
												>
													<img src='/social/laugh.png' alt='laugh' />
												</span>
											</div>
										</button>

										<button
											className='btn-action'
											onClick={() => {
												setOpenComment(true);
											}}
										>
											{/* <MdOutlineModeComment className='icon' /> */}
											<img src='/social/comment.png' alt='comment' />
											<span>Comment</span>
										</button>

										<button className='btn-action'>
											<AiOutlineRetweet className='icon' />
											{/* <img src='/social/retoo.png' alt='retweet' /> */}
											<span>Retoo</span>
										</button>

										<button className='btn-action'>
											{/* <RiSendPlaneLine className='icon' /> */}
											<img src='/social/send.png' alt='send' />
											<span>Send</span>
										</button>
									</div>
								</div>

								<div className='comment__part'>
									{postDetail.comments?.length > 0 ? (
										<div className='comment__list'>
											{postDetail.comments.map((comment) => (
												<div className='comment__item' key={comment._id}>
													<div
														className='comment__avatar cursor-pointer'
														onClick={() =>
															navigate(
																`/social/profile/${postDetail.author._id}`
															)
														}
													>
														<img
															src={
																comment.author?.profileInfo?.profilePicture ||
																'/my-profile/male-avatar.png'
															}
															alt='avatar'
														/>
													</div>

													<div className='comment__info'>
														<div className='author-info cursor-pointer'>
															<h1
																onClick={() =>
																	navigate(
																		`/social/profile/${postDetail.author._id}`
																	)
																}
															>
																{comment?.author?.fullName}
															</h1>
															<p>
																{new Date(comment.createdAt).toLocaleDateString(
																	'en-US',
																	{
																		year: 'numeric',
																		month: 'long',
																		day: 'numeric',
																	}
																)}
															</p>
														</div>

														<p>{comment?.text}</p>
													</div>
												</div>
											))}
										</div>
									) : null}

									{openComment ? (
										<div className='comment__bottom'>
											<div className='comment__avatar'>
												<img
													src={
														authInfo?.profileInfo?.profilePicture ||
														'/my-profile/male-avatar.png'
													}
													alt='avatar'
												/>
											</div>

											<div className='comment__input'>
												<input
													type='text'
													placeholder='Write a comment...'
													autoFocus
													onChange={(e) => {
														setComment(e.target.value);
													}}
													value={comment || ''}
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															postComment();
														}
													}}
												/>
											</div>

											<div className='comment__actions'>
												<button onClick={postComment}>
													<IoSend className='icon' />
												</button>
											</div>
										</div>
									) : null}
								</div>

								{/* {openEditPost.open && openEditPost.postId === post._id ? (
										<EditPostPopup
											post={post}
											allPosts={allPosts}
											setAllPosts={setPostDetail}
											popupState={openEditPost}
											setPopupState={setOpenEditPost}
										/>
									) : null} */}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SinglePost;
