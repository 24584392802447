import React from 'react';
import { useSelector } from 'react-redux';
import LoadingSVG from '../../components/LoadingSVG/LoadingSVG';
import useImageUpload from '../../hooks/useImageUpload';
import { IoCloseOutline } from 'react-icons/io5';
import './MyProfile.scss';

const MyProfile = () => {
	// page title

	window.document.title = 'My Profile | Romatoo';

	// hooks

	const user = useSelector((state) => state.auth);
	const { uploadLoading, uploadImage } = useImageUpload();

	// states & refs

	const [data, setData] = React.useState(
		user.info?.role === 'patient'
			? {
					currentCountry: null,
					fullName: user?.info ? user.info.fullName : null,
					hobby: null,
					nationality: null,
					phone: user?.info ? user.info.phone : null,
					profession: null,
					profilePicture: null,
			  }
			: {
					fullName: user?.info ? user.info.fullName : null,
					phone: user?.info ? user.info.phone : null,
					email: user?.info ? user.info.email : null, //
					nationality: null,
					profilePicture: user?.info
						? user.info.profilePicture
						: null,
					joiningCountry: null,
					currentWorkingOrganization: null,
					experience: 0,
					qualification: [],
					certification: [],
					title: null,
					medicalOrDentalCouncil: {
						registrationImage: null,
						registrationNumber: null,
					},
			  }
	);
	const [loading, setLoading] = React.useState(true);
	const [loading2, setLoading2] = React.useState(false);

	// effects

	React.useEffect(() => {
		setLoading(true);

		let url;

		if (user?.info && user.info?.role === 'doctor') {
			url = `${process.env.REACT_APP_API}/doctor/my-profile`;
		} else {
			url = `${process.env.REACT_APP_API}/patient/my-profile`;
		}

		fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': user.token,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('my profile res : ', res);

				if (res.success) {
					updateData(res.data);
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	// functions

	const updateData = (newData) => {
		const {
			fullName,
			phone,
			nationality,
			profileInfo: {
				profilePicture,
				joiningCountry,
				currentWorkingOrganization,
				experience,
				qualification,
				certification,
				title,
				medicalDentalCouncil,
			},
		} = newData;

		setData((prev) => ({
			fullName,
			phone,
			nationality,
			profilePicture,
			joiningCountry,
			currentWorkingOrganization,
			experience,
			qualification,
			certification,
			title,
			medicalDentalCouncil,
		}));
	};

	const handleSave = () => {
		setLoading2(true);

		let url;

		if (user?.info && user.info?.role === 'doctor') {
			url = `${process.env.REACT_APP_API}/doctor/my-profile`;
		} else {
			url = `${process.env.REACT_APP_API}/patient/my-profile`;
		}

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': user.token,
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('my profile res : ', res);

				if (res.success) {
					updateData(res.data);
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading2(false);
			});
	};

	// loading

	if (loading) {
		return (
			<div className='myProfile page'>
				<h1>My Profile</h1>

				<div className='loading mt-10'>
					<LoadingSVG />
				</div>
			</div>
		);
	}

	return (
		<div className='myProfile page'>
			<h1>My Profile</h1>

			<div className='box'>
				<div className='left'>
					<div className='avatar'>
						{uploadLoading ? (
							<LoadingSVG />
						) : (
							<img
								src={
									data.profilePicture
										? data.profilePicture
										: user.info.gender === 'Male'
										? '/my-profile/male-avatar.png'
										: '/my-profile/female-avatar.png'
								}
								alt='avatar'
							/>
						)}
					</div>

					<input
						type='file'
						name='profilePicture'
						onChange={(e) => {
							uploadImage(e, setData, 'profilePicture');
						}}
					/>
				</div>

				<div className='right'>
					<div className='form__group'>
						<div className='input__group'>
							<label htmlFor='fullName'>Full Name</label>

							<input
								type='text'
								name='fullName'
								id='fullName'
								defaultValue={data.fullName}
								placeholder='Enter your full name'
								onChange={(e) => {
									setData({
										...data,
										fullName: e.target.value,
									});
								}}
							/>
						</div>

						<div className='input__group'>
							<label htmlFor='phone'>Phone Number</label>

							<input
								type='text'
								name='phone'
								id='phone'
								defaultValue={data.phone}
								placeholder='Enter your phone number'
								onChange={(e) => {
									setData({
										...data,
										phone: e.target.value,
									});
								}}
							/>
						</div>
					</div>

					{user?.info?.role === 'patient' ? (
						<div className='form__group'>
							<div className='input__group'>
								<label htmlFor='profession'>
									Current Profession
								</label>

								<input
									type='text'
									name='profession'
									id='profession'
									defaultValue={data.profession || ''}
									placeholder='Enter your profession'
									onChange={(e) => {
										setData({
											...data,
											profession: e.target.value,
										});
									}}
								/>
							</div>

							<div className='input__group'>
								<label htmlFor='hobby'>Favorite Hobby</label>

								<input
									type='text'
									name='hobby'
									id='hobby'
									defaultValue={data.hobby || ''}
									placeholder='Enter your hobby'
									onChange={(e) => {
										setData({
											...data,
											hobby: e.target.value,
										});
									}}
								/>
							</div>
						</div>
					) : null}

					{user?.info?.role === 'doctor' ? (
						<div className='form__group'>
							<div className='input__group'>
								<label htmlFor='currentWorkingOrganization'>
									Where are you currently working as a
									physician
								</label>

								<input
									type='text'
									name='currentWorkingOrganization'
									id='currentWorkingOrganization'
									defaultValue={
										data.currentWorkingOrganization || ''
									}
									placeholder='Enter your organization'
									onChange={(e) => {
										setData({
											...data,
											currentWorkingOrganization:
												e.target.value,
										});
									}}
								/>
							</div>

							<div className='input__group'>
								<label htmlFor='title'>Title</label>

								<input
									type='text'
									name='title'
									id='title'
									defaultValue={data.title || ''}
									placeholder='Jr. Consultant, Sr. Consultant, etc.'
									onChange={(e) => {
										setData({
											...data,
											title: e.target.value,
										});
									}}
								/>
							</div>
						</div>
					) : null}

					<div className='form__group'>
						<div className='input__group'>
							<label htmlFor='nationality'>Nationality</label>

							<input
								type='text'
								name='nationality'
								id='nationality'
								defaultValue={data.nationality || ''}
								placeholder='Enter your nationality'
								onChange={(e) => {
									setData({
										...data,
										nationality: e.target.value,
									});
								}}
							/>
						</div>

						{user?.info?.role === 'patient' ? (
							<div className='input__group'>
								<label htmlFor='currentCountry'>
									Current Country
								</label>

								<input
									type='text'
									name='currentCountry'
									id='currentCountry'
									defaultValue={data.currentCountry || ''}
									placeholder='Enter your current country'
									onChange={(e) => {
										setData({
											...data,
											currentCountry: e.target.value,
										});
									}}
								/>
							</div>
						) : (
							<div className='input__group'>
								<label htmlFor='joiningCountry'>
									From which country you are joining
								</label>

								<input
									type='text'
									name='joiningCountry'
									id='joiningCountry'
									defaultValue={data.joiningCountry || ''}
									placeholder='Enter your joining country'
									onChange={(e) => {
										setData({
											...data,
											joiningCountry: e.target.value,
										});
									}}
								/>
							</div>
						)}
					</div>

					{user?.info?.role === 'doctor' ? (
						<div className='form__group'>
							<div className='input__group'>
								<label htmlFor='experience'>
									Professional Experience
								</label>

								<input
									type='number'
									name='experience'
									id='experience'
									defaultValue={data.experience || ''}
									placeholder='Enter your experience in years'
									onChange={(e) => {
										setData({
											...data,
											experience: e.target.value,
										});
									}}
								/>
							</div>

							<div className='input__group'>
								<label htmlFor='qualification'>
									Qualification
								</label>

								<input
									type='text'
									name='qualification'
									id='qualification'
									placeholder='Enter your qualification'
									onChange={(e) => {
										setData({
											...data,
											qualification: [e.target.value],
										});
									}}
									defaultValue={data.qualification[0] || ''}
								/>

								{/* <div className='qualification__list'>
									{data.qualification.map((item, index) => (
										<div
											className='qualification__item'
											key={index}
										>
											<span>{item}</span>
											<button
												onClick={() => {
													const newData = [
														...data.qualification,
													];

													newData.splice(index, 1);

													setData({
														...data,
														qualification: newData,
													});
												}}
											>
												<IoCloseOutline className='icon' />
											</button>
										</div>
									))}
								</div> */}
							</div>
						</div>
					) : null}
				</div>
			</div>

			<button className='btn-save' onClick={handleSave}>
				{loading2 ? <LoadingSVG /> : 'Save Changes'}
			</button>
		</div>
	);
};

export default MyProfile;
