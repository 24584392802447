import React from 'react';
import './Diet.scss';

const Diet = () => {
	return (
		<div className='diet'>
			<div className='diet__title'>
				<h1>10 tips for a healthy diet</h1>
			</div>
			<div className='diet__content'>
				<img
					src='https://i.ibb.co/BqghGTQ/healthy-food-medical-equipment.jpg'
					alt='diet'
				/>
				<div className='diet__paragraph'>
					<p>
						Watching what you eat is one of the most important
						things you can do to prevent heart disease and stroke.
						What you eat, how much you eat and how often you eat can
						affect your cholesterol, blood pressure and blood sugar
						levels. Equally, what you eat has a direct effect on
						your weight. Read our 10 tips for a healthy diet.
					</p>

					<p>
						Eating less of certain foods (e.g. foods high in
						saturated fat, salt and sugar) and eating more of the
						other foods (e.g. high-fibre foods, fruits, vegetables
						and fish) will help protect your heart and keep your
						body healthy.
					</p>
					<p>
						Healthy eating is important for everyone in the family.
						The key thing is to have a balanced diet with everything
						in moderation. A Mediterranean style of eating is known
						to be cardio protective (i.e. protects your heart and
						blood vessels). A Mediterranean style of eating is based
						on lots of fruit and vegetables, wholegrains, olive oil,
						fish, alcohol in moderation, eating with family and
						friends and reducing stress. Below are some of the main
						principles of the Mediterranean diet that can be
						followed for overall health as well as heart health.
					</p>
					<h3>10 tips for a healthy diet</h3>
					<div className='tips__content'>
						<img
							src='https://i.ibb.co/v1jz2Xh/farhad-ibrahimzade-qg-Gc-1a6x-Gc-unsplash-1.jpg'
							alt=''
						/>
						<div>
							<p>
								1. Stick to 3 square meals a day (breakfast,
								lunch, dinner)
							</p>
							<p>
								2. Reduce your portion size, especially at
								dinner. Use a standard 9 inch plate and aim to
								increase your portion of vegetables to at least
								half the dinner plate.
							</p>
							<p>
								3. Increase your fruit intake – keep it at work,
								in the car and in the handbag!
							</p>
							<p>
								4. Limit red meat intake to a maximum of 3 times
								per week
							</p>
							<p>
								5. Try to increase your fish intake, especially
								oily fish like salmon, mackerel, trout and
								sardines
							</p>
							<p>
								6. Choose low fat dairy – limit your cheese
								intake to a max of two matchstick box sizes per
								week
							</p>
							<p>
								7. Limit your salt intake – avoid using salt at
								the table and in cooking
							</p>
							<p>8. Avoid adding sugar to your food or drink</p>
							<p>
								9. Watch your alcohol intake – aim for two
								alcohol free days per week
							</p>
							<p>10. Always keep hydrated</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Diet;
