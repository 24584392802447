import React from 'react';
import './PatientRecords.scss';

const PatientRecords = () => {
	return (
		<div className='patient__records'>
			<h1>Patient records coming soon...</h1>
		</div>
	);
};

export default PatientRecords;
