import React from 'react';
import './Article.scss';

const Article = () => {
	return (
		<div className='article'>
			<h1>Article coming soon...</h1>
		</div>
	);
};

export default Article;
