import React from 'react';
import { adminNav } from '../../admin/constant';
import { NavLink, Outlet } from 'react-router-dom';
import { IoLogOutOutline } from 'react-icons/io5';
import './AdminSidebar.scss';

const AdminSidebar = () => {
	return (
		<div className='admin__layout'>
			<aside className='sidebar'>
				<div className='sidebar__logo'>
					<img src='/logo.svg' alt='romatoo' />
				</div>

				<div className='sidebar__menu'>
					{adminNav.map((item, index) => (
						<NavLink
							key={index}
							to={item.path}
							className={({ isActive }) => {
								return isActive
									? 'menu-item active'
									: 'menu-item';
							}}
						>
							{item.icon ? (
								item.icon
							) : (
								<img
									src={item.imageIcon}
									className='icon'
									alt={item.name}
								/>
							)}
							<span>{item.name}</span>
						</NavLink>
					))}
				</div>

				<div className='sidebar__footer'>
					<button className='btn-logout'>
						<IoLogOutOutline className='icon' />
						<span>Logout</span>
					</button>
				</div>
			</aside>

			<main className='main'>
				<Outlet />
			</main>
		</div>
	);
};

export default AdminSidebar;
