import React from 'react';
import './PrivacyPolicy.scss';
import { AiOutlineArrowRight } from 'react-icons/ai';

const PrivacyPolicy = () => {
	return (
		<div
			className='privacy'
			style={{
				background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/footer-content/privacy.jpg') no-repeat center center/cover`,
			}}
		>
			<div className='privacy_content'>
				<div className='privacy_nav'>
					<AiOutlineArrowRight className='text-4xl' />
				</div>
				<div>
					<h1 className='privacy_title'>Privacy Policy</h1>
				</div>
				<div className='details'>
					<p>
						Thank you for using Romatoo. We are extremely delighted
						for putting your trust in us, and we are committed to
						protecting the privacy and security of your personal
						information. The information you share with us helps us
						to give you a great experience with Romatoo. This
						Privacy Policy refers to our privacy practices for all
						of the services we offer. The term "Romatoo," "us," or
						"we" refers to Romatoo, Inc., and “User,” “you,” and
						“yours” refer to you as a user of the Romatoo services.
						The Privacy Policy, along with the Terms of Use,
						includes the general rules and policies that govern your
						use of our Platform. You may be asked to agree to
						additional terms and conditions based on your activity
						when visiting our Platform:
					</p>

					<h2 className='font-bold text-2xl'>
						Information We Collect
					</h2>

					<p>
						Romatoo is committed to protecting the privacy of its
						users. Our Privacy Policy is designed to help you
						understand how we collect, use and protect the
						information you provide to us. When you access Romatoo,
						we automatically collect certain information about your
						device, including information about the web browser you
						are using, your internet protocol address, your time
						zone, and some of the cookies that are installed on your
						device. Further, as you browse the site, we collect
						information about the individual web pages or doctors
						you view, the websites or search terms that referred you
						to Romatoo, and information about how you interact with
						Romatoo.
					</p>

					<p>
						We also collect information from you when you register
						on Romatoo, book an appointment with a doctor or
						subscribe to our newsletter. When booking or registering
						on our website, as appropriate, you may be asked to
						enter your name, email address, postal address,
						telephone number and date of birth ("Personal
						Information").
					</p>

					<p>
						We may also ask you to provide us with information about
						collecting payments. After a transaction, this private
						information (credit cards, social security numbers,
						financial data, etc.) will not be stored on our servers.
					</p>

					<p>
						You can ask us to delete this information at any time,
						or to unsubscribe from emails by accessing your account
						settings or by following the step-by-step instructions
						at the bottom of each email.
					</p>

					<h2 className='font-bold text-2xl'>How We Use Your Data</h2>

					<p>
						We use some of the information we collect to help us
						detect potential risks and fraud (in particular your IP
						address), and more generally to improve and optimize the
						Romatoo site (for example, by generating analytics on
						how you browse the site or application our customers and
						their interaction with them, as well as to evaluate the
						success of our marketing and advertising campaigns).
					</p>

					<p>
						We generally use the personal information we collect to
						fulfill any reservations made through Romatoo,
						communicate with you, detect potential risks or fraud,
						and provide you with information or advertising about
						our products or services when the information is in line
						with the preferences you have shared with us.
					</p>

					<h2 className='font-bold text-2xl'>
						Protect Your Information
					</h2>

					<p>
						We strive to protect your information by applying
						international protection standards and taking a variety
						of security measures to maintain the safety of your
						personal information when you make a reservation or
						enter, transmit or access your personal information.
					</p>

					<p>
						We regularly scan our website and app for security holes
						and vulnerabilities to ensure that your visit to our
						Website is as safe as possible. We protect our website
						with a web application firewall that filters and
						monitors the traffic between the web application and the
						internet. This wall detects and blocks common weaknesses
						in the application layers at the edge of the network.
					</p>

					<p>
						We offer a secure server feature. All submitted
						sensitive/credit information is transmitted via Secure
						Socket Layer technology and then recorded in our Payment
						Gateway Provider database so that such information is
						only available to individuals who are granted special
						access rights to these systems and who are required to
						keep the information confidential.
					</p>

					<p>
						We also ensure that all of our data is password
						protected, and that data can be accessed through
						multi-factor authentication. Not all members of the
						Romatoo team have access to any data collected, and only
						individuals with specific roles are allowed to view the
						data collected. When your data is stored on our servers,
						we present it under a pseudonym and encrypt it to
						maintain anonymity and provide an extra layer of
						security in the event of a data breach.
					</p>

					<h2 className='font-bold text-2xl'>
						How Long Do We Keep Your Data
					</h2>

					<p>
						The criteria used to determine the period of storage of
						personal data is the relevant statutory retention
						period. After that period, the corresponding data is
						routinely deleted, for as long as it is no longer
						necessary to carry out our services.
					</p>

					<p>
						We only process and store your personal data for the
						period necessary to fulfill the purpose of the storage.
						Accordingly, if we have your email address because we
						provide certain services to you, we will retain the
						email address for as long as we provide those services
						to you.
					</p>

					<p>
						Basically, we keep your data until your account is
						deleted. You can view the data we collect from you at
						any time, and you may request that it be deleted at any
						time by accessing your account settings.
					</p>

					<p>
						Cookies are small files that a website or service
						provider transfers to your computer's hard drive through
						your web browser (if you allow it) that enables a
						website or service provider's systems to recognize your
						browser and capture and remember certain information.
					</p>

					<p>
						We use cookies to improve your analytics experience and
						to show you offers tailored to your previous visits to
						our Website. We also use cookies to help us compile
						aggregate data about site visits and interactions so
						that we can offer a better experience and better tools
						in the future.
					</p>

					<p>
						If you prefer, you can choose to activate the warning
						feature on your computer each time a cookie is sent, or
						you can choose to turn off all cookies by accessing your
						browser settings. Like most websites, if you turn
						cookies off, some of our services may not function
						properly.
					</p>

					<h2 className='font-bold text-2xl'>
						{' '}
						Not to Disclose Information to Third Parties
					</h2>

					<p>
						We do not sell, trade or otherwise transfer your
						personally identifiable information to outside parties.
						This does not include trusted third parties who assist
						us in operating our website, conducting our business or
						providing you with services, so long as those parties
						have agreed to keep this information confidential. We
						may also release your information when we believe it is
						in compliance with the law, to enforce our site
						policies, to protect ours or the rights or property of
						others, or for marketing, advertising, or other
						purposes.
					</p>

					<p>
						All payment details and personally identifiable
						information will not be stored, sold, accessed or
						registered with any third parties.
					</p>

					<p>
						Disclosure regarding the Google Display Advertising
						platform
					</p>

					<p>
						We implement Google Analytics, or Google Analytics,
						features that use Display Advertising information in
						order to generate demographics and interests reports
						through the Google Analytics tool.
					</p>

					<p>
						You can opt out of Google Analytics for Google Display
						Advertising to prevent your data from being used by
						Google Analytics by going to the Google Analytics
						opt-out page.
					</p>

					<p>
						We use first-party cookies, in conjunction with
						third-party vendors (including Google), (such as Google
						Analytics cookies) and third-party cookies (such as
						DoubleClick cookies) to To report how ad impressions,
						other uses of advertising services, and interactions
						with ad appearances and advertising services relate to a
						visit to our website. You can read more about the
						cookies used by Google Analytics advertising features by
						going to the Google Analytics Privacy Policy page.
					</p>

					<h2 className='font-bold text-2xl'>Third Party Links</h2>

					<p>
						We may include or offer third party products or services
						on our Website. Third party websites have separate and
						independent privacy policies. Therefore, we have no
						responsibility or liability for the content and
						activities of these linked sites. However, we strive to
						protect the integrity of our site, and we welcome any
						feedback about those sites. We strongly advise you to
						review the privacy policy and the terms and conditions
						on each website you visit.
					</p>

					<h2 className='font-bold text-2xl'>Your Rights</h2>

					<p>
						You have the right to obtain confirmation as to whether
						or not your personal data is being processed. You may
						contact us at any time to claim this right.
					</p>

					<p>
						You have the right to request information that we are
						currently processing. You may also request the following
						for processing purposes; the categories of personal data
						concerned; the recipients or categories of beneficiaries
						to whom personal data has been or will be disclosed, in
						particular recipients located in third countries or
						international organizations; the prescribed period for
						which personal data will be stored, where possible, or
						the criteria used to determine that period if this is
						not possible; the right to request correction or erasure
						of personal data from the controller, to restrict the
						processing of personal data relating to the data
						subject, or to object to the data processing process;
						the right to lodge a complaint with a supervisory body;
						In the event that personal data is not collected from
						the data subject, and any information available from its
						source.
					</p>

					<p>
						You have the right to notify us when your information
						has changed and when the information we have on file is
						no longer accurate. In view of the purposes of the
						processing, you have the right to supplement incomplete
						personal data, including to provide a supplementary
						statement.
					</p>

					<p>
						You have every right to request erasure of personal data
						without undue delay.
					</p>

					<p>
						You have the right to receive personal data relating to
						you that has been submitted to the Controller, in a
						structured, commonly used and machine-readable format.
					</p>

					<p>
						You have the right to object, at any time, to the
						processing of your personal data. We will not process
						personal data in the event of an objection, unless we
						find legitimate and compelling reasons to process the
						data that would override your interests, rights and
						freedoms, or to file, exercise or defend legal claims.
					</p>

					<p>
						You have the right to withdraw your consent to the
						processing of your personal data at any time.
					</p>

					<h2 className='font-bold text-2xl'>Terms of Use</h2>

					<p>
						Please see the Terms of Use that define the use,
						disclaimers and limitations of liability governing the
						use of our Website, by visiting the following link:{' '}
						<a
							className='text-[#22B573]'
							href='romatoo.com/doc/terms-of-use'
						>
							romatoo.com/doc/terms-of-use
						</a>{' '}
					</p>

					<h2 className='font-bold text-2xl'>Your consent</h2>

					<p>
						You agree to our Privacy Policy when you visit our
						Website.
					</p>

					<p>
						The Terms of Use and Privacy Policy on the Website may
						be changed or updated from time to time to meet legal
						requirements and standards. Therefore, we would
						encourage you to visit these sections frequently in
						order to be aware of changes to the site. Amendments are
						considered effective on the day they are published.
					</p>

					<h2 className='font-bold text-2xl'>Contact us</h2>

					<p>
						If you have any questions regarding the privacy policy,
						you can contact us via e-mail at the following address{' '}
						<a
							className='text-[#22B573]'
							href='mailto:info@romatoo.com'
						>
							info@romatoo.com
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
