import React from 'react';
import { useSelector } from 'react-redux';
import { SocketContext } from '../../context/SocketContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MdOutlineScreenShare, MdOutlineStopScreenShare } from 'react-icons/md';
import {
	IoMicOutline,
	IoCloseOutline,
	IoMicOffOutline,
	IoVideocamOutline,
	IoVideocamOffOutline,
	IoDocumentTextOutline,
	IoCallOutline,
} from 'react-icons/io5';
import './Video.scss';
import PrescriptionPopup from './PrescriptionPopup';

const Video = () => {
	const {
		call,
		callAccepted,
		myVideo,
		userVideo,
		name,
		setName,
		callEnded,
		callUser,
		leaveCall,
		answerCall,
		setRoomId,
		toggleMyVideo,
		myVideoOn,
		toggleMyAudio,
		myAudioOn,
		toggleScreenShare,
		screenShareOn,
	} = React.useContext(SocketContext);

	const { conversationId } = useParams();
	const auth = useSelector((state) => state.auth);
	const user = useSelector((state) => state.auth.info);

	const [prescriptionPopup, setPrescriptionPopup] = React.useState(false);
	const [conversation, setConversation] = React.useState(null);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		setName(user.fullName);
		setRoomId(conversationId);

		let url;

		if (auth?.info?.role === 'doctor') {
			url = `${process.env.REACT_APP_API}/doctor/conversations/${conversationId}`;
		} else {
			url = `${process.env.REACT_APP_API}/patient/conversations/${conversationId}`;
		}

		fetch(url, {
			method: 'GET',
			headers: {
				'x-access-token': auth?.token,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.success) {
					setConversation(res.data);
					console.log(res.data);
				} else {
					alert(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div className='videoCallPage'>
			<div className='videos'>
				{callAccepted && !callEnded ? (
					<div className='user-video'>
						<video playsInline ref={userVideo} autoPlay />

						<h3>
							{user.role === 'doctor'
								? conversation?.patient?.fullName
								: conversation?.doctor?.fullName}
						</h3>
					</div>
				) : null}

				<div className='my-video'>
					<video playsInline muted ref={myVideo} autoPlay />

					<h3>{name || 'Me'}</h3>
				</div>
			</div>

			{user?.role === 'patient' ? (
				call.isReceivingCall && !callAccepted ? (
					<div className='incoming-call'>
						<h1>{call.name} is calling...</h1>

						<button onClick={answerCall} className='btn-answer'>
							Answer
						</button>
					</div>
				) : null
			) : null}

			<div className='buttons'>
				<button
					onClick={toggleMyAudio}
					className={`btn-all ${!myAudioOn ? 'toggle' : ''}`}
				>
					{myAudioOn ? (
						<IoMicOutline className='icon' />
					) : (
						<IoMicOffOutline className='icon' />
					)}
				</button>

				<button
					onClick={toggleMyVideo}
					className={`btn-all ${!myVideoOn ? 'toggle' : ''}`}
				>
					{myVideoOn ? (
						<IoVideocamOutline className='icon' />
					) : (
						<IoVideocamOffOutline className='icon' />
					)}
				</button>

				<button
					onClick={toggleScreenShare}
					className={`btn-all ${screenShareOn ? 'toggle' : ''}`}
				>
					{screenShareOn ? (
						<MdOutlineStopScreenShare className='icon' />
					) : (
						<MdOutlineScreenShare className='icon' />
					)}
				</button>

				{/* {auth.info?.role === 'doctor' ? (
					<Link
						to={`/prescription/${conversation?.userId?._id}`}
						target='_blank'
						rel='noopener noreferrer'
						className='btn-all'
					>
						<IoDocumentTextOutline className='icon' />
					</Link>
				) : null} */}

				{auth.info?.role === 'doctor' ? (
					<button
						onClick={() => {
							setPrescriptionPopup(true);
						}}
						className='btn-all'
					>
						<IoDocumentTextOutline className='icon' />
					</button>
				) : null}

				{callAccepted && !callEnded ? (
					<button onClick={leaveCall} className='btn-endCall'>
						<IoCallOutline className='icon' />
					</button>
				) : user.role === 'doctor' ? (
					<button onClick={() => callUser()} className='btn-call'>
						Call {conversation?.patient?.fullName}
					</button>
				) : null}
			</div>

			<div
				className={
					prescriptionPopup ? 'prescription-popup active' : 'prescription-popup'
				}
			>
				<button
					onClick={() => {
						setPrescriptionPopup(false);
					}}
					className='btn-close'
				>
					<IoCloseOutline className='icon' />
				</button>

				<PrescriptionPopup patientId={conversation?.userId?._id} />
			</div>
		</div>
	);
};

export default Video;
