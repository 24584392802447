import React from 'react';
import './E_prescription.scss';

const E_prescription = () => {
	return (
		<div className='page'>
			<div className='details__concept'>
				<img src='/images/cloud.jpeg' alt='' />
				<div className='concept__container'>
					<h1>Cloud base E-prescrption solution</h1>
					<p>
						Using the cloud base solution, when a doctor needs a
						particular group of drugs to cure a patient, that
						patient can easily collect the group of drugs according
						to his/her location, i.e., country. In addition to the
						group names of those drugs, doctors can see the brand
						names of drugs readily available in that country. N:B:
						Even a doctor not associated with our platform can
						prescribe medicines to patients using this cloud-based
						e- prescription service on our platform so that the
						patient can easily collect the medication from the
						country where they stay.
					</p>
				</div>
			</div>
		</div>
	);
};

export default E_prescription;
