export const healthProblemsData = [
	{
		title: 'Mental Health',
		cardData: [
			{
				title: 'Anxiety',
				img: 'https://i.ibb.co/6WRcXdR/anxiety.webp',
			},
			{
				title: 'Depression',
				img: 'https://i.ibb.co/jv9DKq5/depression.webp',
			},
			{
				title: 'Insomnia',
				img: 'https://i.ibb.co/4NStCgF/insomnia.webp',
			},
			{
				title: 'Drug Addiction',
				img: 'https://i.ibb.co/Sn7Tt3K/drug-addiction.webp ',
			},
		],
	},
	{
		title: 'Skin & Hair Health',
		cardData: [
			{
				title: 'Pimple & Skin Infection',
				img: 'https://i.ibb.co/QknH6Bs/pimple-skin-infection.webp',
			},
			{
				title: 'Skin Allergy',
				img: 'https://i.ibb.co/kKsVMhb/skin-allergy.webp',
			},
			{
				title: 'Hair Loss',
				img: 'https://i.ibb.co/prVg7BH/hair-loss.webp',
			},
		],
	},
	{
		title: 'Pain',
		cardData: [
			{
				title: 'Migraine',
				img: 'https://i.ibb.co/Yknxcsf/migraine.webp',
			},
			{
				title: 'Back & Joint Pain',
				img: 'https://i.ibb.co/CvzMYz8/back-joint-pain.webp',
			},
			{
				title: 'Muscle & Sports Pain',
				img: 'https://i.ibb.co/3z3kCSk/muscle-sports-pain.webp',
			},
		],
	},
	{
		title: 'General Health',
		cardData: [
			{
				title: 'Cold & Fever',
				img: 'https://i.ibb.co/PxbK0tr/cold-fever.webp',
			},
			{
				title: 'Gastric & Vomiting',
				img: 'https://i.ibb.co/S5rLhr2/gastric-vomiting.webp',
			},
			{
				title: 'Defecation',
				img: 'https://i.ibb.co/9cGkLKq/defecation.webp',
			},
			{
				title: 'Weak & Tired',
				img: 'https://i.ibb.co/9pkvtxT/weak-tired.webp',
			},
		],
	},
	{
		title: "Women's Health",
		cardData: [
			{
				title: 'UTI',
				img: 'https://i.ibb.co/RzYzqfB/uti.webp',
			},
			{
				title: ' Mensuration',
				img: 'https://i.ibb.co/2q2M9JY/menstruation.webp',
			},
			{
				title: 'Birth Control',
				img: 'https://i.ibb.co/fHfqZ7f/birth-control.webp',
			},
			{
				title: 'Infertility Care',
				img: 'https://i.ibb.co/zNyhVSc/infertility-care.webp',
			},
		],
	},
	{
		title: "Sex & men's health",
		cardData: [
			{
				title: 'Erectile Dysfunction',
				img: 'https://i.ibb.co/0qSYN6R/erectile-dysfunction.webp',
			},
			{
				title: 'Premature Ejaculation',
				img: 'https://i.ibb.co/QHGmr4w/premature-ejaculation.webp',
			},
		],
	},
	{
		title: 'Heat & Diabetes',
		cardData: [
			{
				title: 'Blood Pressure',
				img: 'https://i.ibb.co/0Kbz0k0/blood-pressure.webp',
			},
			{
				title: 'Diabetes',
				img: 'https://i.ibb.co/m5KXyG9/diabetes.webp',
			},
			{
				title: 'Heat',
				img: 'https://i.ibb.co/kMjm18v/heat.webp',
			},
			{
				title: ' Nutrition & Diet',
				img: 'https://i.ibb.co/h1kv6vC/nutrition-diet.webp',
			},
		],
	},
	{
		title: 'Other',
		cardData: [
			{
				title: 'Child / Pediatric',
				img: 'https://i.ibb.co/HCLhP0m/child-pediatric.webp',
			},
			{
				title: 'Chest & Asthma',
				img: 'https://i.ibb.co/JvMcjwm/chest-asthma.webp',
			},
			{
				title: 'Dental',
				img: 'https://i.ibb.co/f41pWj3/dentail.webp',
			},
			{
				title: 'Eye',
				img: 'https://i.ibb.co/wghc884/eye.webp',
			},
			{
				title: 'ENT / Head / Neck',
				img: 'https://i.ibb.co/Vmcxb2F/ent-head-neck.webp',
			},
		],
	},
];
