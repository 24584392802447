import React from "react";
import "./DoctorCard.scss";

const DoctorCard = () => {
    return (
        <div className="doctor_card">
            <div className="doc_info1">
                <div className="doc_img info">
                    <img src="./profile.svg" alt="" />
                    <div>
                        <h3>Name</h3>
                        <p>Shahriar</p>
                        <h3>Speciality</h3>
                        <span className="specialty">Dental</span>
                        <h3>Qualification</h3>
                        <p>MBBS</p>
                    </div>
                </div>

                <div>
                    <h3>Title</h3>
                    <p>Doctor</p>
                    <h3>Education country</h3>
                    <p>Country</p>
                    <h3>institute name</h3>
                    <p>institute</p>
                </div>
            </div>
            <div className="doc_info2">
                <div>
                    <h3>Rating</h3>
                    <p>*****</p>
                </div>
                <div>
                    <h3>Professional Experience</h3>
                    <p>Experience</p>
                </div>
                <div>
                    <h3>Fee</h3>
                    <p className="text-[#22B573] font-bold">100$</p>
                </div>
            </div>
            <div className="doc_info3">
                <div>
                    <h3>Current work station</h3>
                    <p>Bogura</p>
                </div>
                <div>
                    <h3>Joining Location</h3>
                    <p>Bogura</p>
                </div>
            </div>

            <div className="doc_info4">
                <div className="diseases_solve">
                    <h3>Diseases solve</h3>
                    <span className="diseases">Headache</span>
                </div>
                <div>
                    <h3>Available slot</h3>
                    <p>2</p>
                </div>
            </div>
            <div className="doc_card_btn">
                <button>Book Appointment</button>
            </div>
        </div>
    );
};

export default DoctorCard;
