import React from 'react';
import './Meditation.scss';

const Meditation = () => {
	return (
		<div className='diet'>
			<div className='diet__title'>
				<h1>How to Meditate</h1>
			</div>
			<div className='diet__content'>
				<img
					src='https://i.ibb.co/5cK6jpS/one-hiking-lifestyle-summer-yoga.jpg'
					alt='diet'
				/>
				<div className='diet__paragraph'>
					<p>
						When we meditate, we inject far-reaching and
						long-lasting benefits into our lives: We lower our
						stress levels, we get to know our pain, we connect
						better, we improve our focus, and we're kinder to
						ourselves. Let us walk you through the basics in our new
						mindful guide on how to meditate.
					</p>
					<h1>What is Meditation?</h1>
					<h2>A brief explanation of the practice.</h2>

					<p>
						How do you learn to meditate? In mindfulness meditation,
						we’re learning how to pay attention to the breath as it
						goes in and out, and notice when the mind wanders from
						this task. This practice of returning to the breath
						builds the muscles of attention and mindfulness.
					</p>

					<p>
						When we pay attention to our breath, we are learning how
						to return to, and remain in, the present moment—to
						anchor ourselves in the here and now on purpose, without
						judgement.
					</p>
					<p>
						The idea behind mindfulness seems simple—the practice
						takes patience. Indeed, renowned meditation teacher
						Sharon Salzberg recounts that her first experience with
						meditation showed her how quickly the mind gets caught
						up in other tasks. “I thought, okay, what will it be,
						like, 800 breaths before my mind starts to wander? And
						to my absolute amazement, it was one breath, and I’d be
						gone,” says Salzberg.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Meditation;
