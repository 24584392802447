import React from 'react';
import './Payments.scss';

const AdminPayments = () => {
	return (
		<div className='admin__payments'>
			<h1>Payments Page</h1>
		</div>
	);
};

export default AdminPayments;
