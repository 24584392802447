import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { toast } from 'react-toastify';
import './SearchDoctor.scss';
import DoctorCard from './DoctorCard';
import LoadingSVG from '../../components/LoadingSVG/LoadingSVG';

const SearchDoctor = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const query = searchParams.get('query');

	const [search, setSearch] = React.useState(query || '');
	const [doctors, setDoctors] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		setLoading(true);
		setSearch(query || '');

		const fetchDoctors = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API}/doctor/search?query=${query}`
				);
				const data = await response.json();

				// console.log('search doctor : ', data);

				if (data.success) {
					setDoctors(data.data);
				} else {
					toast.error(data.message);
				}
			} catch (error) {
				toast.error('Something went wrong!');
			} finally {
				setLoading(false);
			}
		};
		const timeout = setTimeout(() => {
			if (query) {
				fetchDoctors();
			} else {
				setLoading(false);
			}
		}, 1000);

		return () => clearTimeout(timeout);
	}, [query]);

	return (
		<div className='searchDoctor page'>
			<div className='search__box'>
				<div className='input__group'>
					<input
						type='text'
						placeholder='Search By Disease or Specialty'
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						onKeyPress={(e) => {
							if (e.key === 'Enter') {
								if (search) {
									navigate(`/search?query=${search}`);
								}
							}
						}}
					/>
					<BsSearch
						className='icon'
						onClick={() => {
							if (search) {
								navigate(`/search?query=${search}`);
							}
						}}
					/>
				</div>

				<p className='py-5 text-2xl font-bold mb-4 text-center'>
					{loading ? (
						'Searching for doctors...'
					) : (
						<>
							Search results for '{query}' -
							<span
								className={
									doctors.length === 0
										? 'text-rose-500'
										: 'text-emerald-500'
								}
							>
								{' '}
								{doctors.length}{' '}
							</span>
							doctors found
						</>
					)}
				</p>
			</div>

			{loading ? (
				<div className='loading__svg'>
					<LoadingSVG />
				</div>
			) : doctors.length === 0 ? null : (
				<div className='search__result'>
					{doctors.map((doctorInfo, index) => (
						<DoctorCard key={index} doctorInfo={doctorInfo} />
					))}
				</div>
			)}
		</div>
	);
};

export default SearchDoctor;
