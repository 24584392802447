import { v4 as uuid } from 'uuid';

export const uuidv4 = () => {
	const id = uuid();
	console.log(`Generated UUID: ${id}`);
	return id;
};

const daysWithIndex = {
	sun: 0,
	mon: 1,
	tue: 2,
	wed: 3,
	thu: 4,
	fri: 5,
	sat: 6,
};

export const countSlots = (schedule) => {
	let slots = 0;

	// Loop through each day of the week
	for (let day in schedule) {
		let start = schedule[day]['start'];
		let end = schedule[day]['end'];

		// Check if the doctor is available on this day
		if (start !== '00:00' && end !== '00:00') {
			// Calculate the total duration in minutes
			let startTime = start.split(':');
			let startMinutes = parseInt(startTime[0]) * 60 + parseInt(startTime[1]);
			let endTime = end.split(':');
			let endMinutes = parseInt(endTime[0]) * 60 + parseInt(endTime[1]);
			let duration = endMinutes - startMinutes;

			// Calculate the number of slots based on the duration and the length of each slot
			let slotLength = 20;
			slots += Math.floor(duration / slotLength);
		}
	}

	return slots;
};

export const validImageUrl = (url) => {
	const regex = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/;

	return regex.test(url);
};

export const getTimeRange = (selectedDate, timeTable) => {
	const date = new Date(selectedDate.split(' ')[0]);
	const day = date.getDay();

	const dayName = Object.keys(daysWithIndex).find(
		(key) => daysWithIndex[key] === day
	);

	const start = timeTable[dayName].start;
	const end = timeTable[dayName].end;

	return {
		start,
		end,
	};
};

export const getOffDays = (timeTable) => {
	const offDays = [];

	for (const day in timeTable) {
		if (timeTable[day].start === '00:00' && timeTable[day].end === '00:00') {
			offDays.push(daysWithIndex[day]);
		}
	}

	return offDays;
};

export const validAppointmentTime = (dateTimeStr, timeTable) => {
	// Parse the date and time from the input string
	const dateTime = new Date(dateTimeStr);

	// Get the day of the week as a lowercase string (e.g. "sun", "mon", etc.)
	const dayOfWeek = dateTime
		.toLocaleString('en-US', { weekday: 'short' })
		.toLowerCase();

	// Get the start and end times for the day of the week from the time table
	const start = timeTable[dayOfWeek].start;
	const end = timeTable[dayOfWeek].end;

	// If either the start or end time is "00:00", that means the day is unavailable
	if (start === '00:00' || end === '00:00') {
		return false;
	}

	// Otherwise, compare the input time with the start and end times to see if it falls within the available range
	const inputHours = dateTime.getHours();
	const inputMinutes = dateTime.getMinutes();
	const inputTime = inputHours * 60 + inputMinutes; // convert hours and minutes to total minutes

	const startHours = parseInt(start.split(':')[0], 10);
	const startMinutes = parseInt(start.split(':')[1], 10);
	const startTime = startHours * 60 + startMinutes; // convert start hours and minutes to total minutes

	const endHours = parseInt(end.split(':')[0], 10);
	const endMinutes = parseInt(end.split(':')[1], 10);
	const endTime = endHours * 60 + endMinutes; // convert end hours and minutes to total minutes

	return inputTime >= startTime && inputTime <= endTime - 20;
};
