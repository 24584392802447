import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LineChart from './LineChart/LineChart';
import './Dashboard.scss';

const AdminDashboard = () => {
	// page title

	window.document.title = 'Romatoo | Admin Dashboard';

	// hooks

	const user = useSelector((state) => state.auth?.info);
	const token = useSelector((state) => state.auth?.token);

	// states

	const [usersChartData, setUsersChartData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	const [chartFilter, setChartFilter] = React.useState('lastMonth'); // allTime, lastYear, lastMonth

	// fetch users

	React.useEffect(() => {
		const fetchUsersChartData = async () => {
			const url = `${process.env.REACT_APP_API}/admin/dashboard/users`;

			try {
				const req = await fetch(url, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': token,
					},
				});
				const res = await req.json();

				console.log('users chart data : ', res);

				if (res.success) {
					setUsersChartData(res.data);
				} else {
					toast.error(res.message);
				}
			} catch (error) {
				console.log('users chart data error : ', error);
				toast.error('Something went wrong');
			} finally {
				setLoading(false);
			}
		};

		fetchUsersChartData();
	}, []);

	if (loading) return <h1>Loading...</h1>;

	return (
		<div className='admin__dashboard'>
			<h1>Welcome to Romatoo Admin Dashboard</h1>

			<div className='main__content'>
				<div className='flex items-center justify-end'>
					<select
						name='chartFilter'
						id='chartFilter'
						value={chartFilter}
						onChange={(e) => setChartFilter(e.target.value)}
						className='rounded-md px-4 py-2 text-sm'
					>
						<option value='allTime'>All Time</option>
						<option value='lastYear'>Last Year</option>
						<option value='lastMonth'>Last Month</option>
					</select>
				</div>

				<LineChart data={usersChartData} filter={chartFilter} />
			</div>
		</div>
	);
};

export default AdminDashboard;
