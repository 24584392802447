import React from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoadingSVG from '../../components/LoadingSVG/LoadingSVG';

const UpcomingTable = ({ appointments, setAppointments }) => {
	const auth = useSelector((state) => state.auth);

	const [loading, setLoading] = React.useState(false);
	const [actions, setActions] = React.useState({
		appointmentId: '',
		action: '',
	});

	const handleAccept = (appointmentId) => {
		setLoading(true);
		setActions({ appointmentId, action: 'accept' });

		const url = `${process.env.REACT_APP_API}/doctor/appointments/accept/${appointmentId}`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': auth.token,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('accept appointment : ', res);

				if (res.success) {
					toast.success(res.message);

					const newAppointments = appointments.filter(
						(appointment) => appointment._id !== appointmentId
					);

					setAppointments([...newAppointments, res.data]);
				} else {
					console.log(res.message);
					toast.error(res.message);
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error('Something went wrong!');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleReject = (appointmentId) => {
		setLoading(true);
		setActions({ appointmentId, action: 'reject' });

		const url = `${process.env.REACT_APP_API}/doctor/appointments/reject/${appointmentId}`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': auth.token,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('reject appointment : ', res);

				if (res.success) {
					toast.success(res.message);

					const newAppointments = appointments.filter(
						(appointment) => appointment._id !== appointmentId
					);

					setAppointments([...newAppointments, res.data]);
				} else {
					console.log(res.message);
					toast.error(res.message);
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error('Something went wrong!');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleCancel = (appointmentId) => {
		setLoading(true);
		setActions({ appointmentId, action: 'cancel' });

		const url = `${process.env.REACT_APP_API}/doctor/appointments/cancel/${appointmentId}`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': auth.token,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('cancel appointment : ', res);

				if (res.success) {
					toast.success(res.message);

					const newAppointments = appointments.filter(
						(appointment) => appointment._id !== appointmentId
					);

					setAppointments([...newAppointments, res.data]);
				} else {
					console.log(res.message);
					toast.error(res.message);
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error('Something went wrong!');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleComplete = (appointmentId) => {
		setLoading(true);
		setActions({ appointmentId, action: 'complete' });

		const url = `${process.env.REACT_APP_API}/doctor/appointments/complete/${appointmentId}`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': auth.token,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('complete appointment : ', res);

				if (res.success) {
					toast.success(res.message);

					const newAppointments = appointments.filter(
						(appointment) => appointment._id !== appointmentId
					);

					setAppointments([...newAppointments, res.data]);
				} else {
					console.log(res.message);
					toast.error(res.message);
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error('Something went wrong!');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<table className='upcoming__appointment__table'>
			<colgroup>
				<col span={1} className='w-44' />
				<col span={1} className='w-44' />
				<col span={1} className='w-52' />
				<col span={1} className='w-[12rem]' />
				<col span={1} className='w-60' />
			</colgroup>

			<thead>
				<tr>
					<th>Patient Name</th>
					<th>Appointment Date</th>
					<th>Appointment Time</th>
					<th>Appointment Status</th>
					<th>Actions</th>
				</tr>
			</thead>

			<tbody>
				{appointments.map((appointment, index) => (
					<tr key={index}>
						<td>{appointment.patient.fullName}</td>
						<td>{appointment.date.split('T')[0]}</td>
						<td>
							{dayjs(
								`${appointment.date.split('T')[0]} ${
									appointment.time.start
								}`
							).format('hh:mm A')}
							{' to '}
							{dayjs(
								`${appointment.date.split('T')[0]} ${
									appointment.time.end
								}`
							).format('hh:mm A')}
						</td>
						<td>{appointment.status}</td>
						<td className='actions'>
							{appointment.status === 'Pending' ? (
								<>
									<button
										className='btn-accept'
										disabled={
											appointment.status === 'Accepted' ||
											appointment.status === 'Rejected'
										}
										onClick={() =>
											handleAccept(appointment._id)
										}
									>
										{loading &&
										actions.appointmentId ===
											appointment._id &&
										actions.action === 'accept' ? (
											<LoadingSVG />
										) : (
											'Accept'
										)}
									</button>
									<button
										className='btn-reject'
										disabled={
											appointment.status === 'Accepted' ||
											appointment.status === 'Rejected'
										}
										onClick={() =>
											handleReject(appointment._id)
										}
									>
										{loading &&
										actions.appointmentId ===
											appointment._id &&
										actions.action === 'reject' ? (
											<LoadingSVG />
										) : (
											'Reject'
										)}
									</button>
								</>
							) : null}
							{appointment.status === 'Accepted' ? (
								<>
									<button
										className='btn-complete'
										disabled={
											appointment.status ===
												'Completed' ||
											appointment.status === 'Canceled'
										}
										onClick={() =>
											handleComplete(appointment._id)
										}
									>
										{loading &&
										actions.appointmentId ===
											appointment._id &&
										actions.action === 'complete' ? (
											<LoadingSVG />
										) : (
											'Complete'
										)}
									</button>
									<button
										className='btn-cancel'
										disabled={
											appointment.status ===
												'Completed' ||
											appointment.status === 'Canceled'
										}
										onClick={() =>
											handleCancel(appointment._id)
										}
									>
										{loading &&
										actions.appointmentId ===
											appointment._id &&
										actions.action === 'cancel' ? (
											<LoadingSVG />
										) : (
											'Cancel'
										)}
									</button>
								</>
							) : null}

							{appointment.status === 'Completed' ? (
								<button
									className='btn-complete'
									disabled={
										appointment.status === 'Completed'
									}
								>
									Completed
								</button>
							) : null}

							{appointment.status === 'Canceled' ? (
								<button
									className='btn-cancel'
									disabled={appointment.status === 'Canceled'}
								>
									Canceled
								</button>
							) : null}

							{appointment.status === 'Rejected' ? (
								<button
									className='btn-reject'
									disabled={appointment.status === 'Rejected'}
								>
									Rejected
								</button>
							) : null}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default UpcomingTable;
