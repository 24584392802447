import React from 'react';
import { io } from 'socket.io-client';

const socket = io(process.env.REACT_APP_SOCKET, {
	path: process.env.REACT_APP_SOCKET_CHAT_PATH,
});

const Dummy2 = () => {
	const [brandName, setBrandName] = React.useState('');
	const [genericName, setGenericName] = React.useState('');

	React.useEffect(() => {
		socket.on('searchResultByBrandName', (data) => {
			console.log('searchResultByBrandName -> ', data);
		});

		socket.on('searchResultByGeneticName', (data) => {
			console.log('searchResultByGeneticName -> ', data);
		});

		return () => {
			socket.off('searchResultByBrandName');
			socket.off('searchResultByGeneticName');
		};
	}, [socket]);

	const searchGenericName = (e) => {
		if (e.target?.value?.length < 3) {
			return;
		}

		console.log('generic name -> ', e.target.value);

		socket.emit('searchByGeneticName', { geneticName: e.target.value });
	};

	const searchBrandName = (e) => {
		if (e.target?.value?.length < 3) {
			return;
		}

		console.log('brand name -> ', e.target.value);

		socket.emit('searchByBrandName', { brandName: e.target.value });
	};

	return (
		<div className='dummy-page'>
			<h1 className='mb-5'>Dummy Page 2</h1>

			<input
				type='text'
				placeholder='Generic Name...'
				className='mb-4 rounded'
				onChange={searchGenericName}
			/>

			<input
				type='text'
				placeholder='Brand Name...'
				className='mb-2 rounded'
				onChange={searchBrandName}
			/>
		</div>
	);
};

export default Dummy2;
