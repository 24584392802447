import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HealthProblemCard.scss';

const HealthProblemCard = ({ specialty, data }) => {
	const navigate = useNavigate();

	const handleClick = (title) => {
		navigate(`/search?query=${title}`);
	};

	return (
		<div className='cursor-pointer'>
			<h2 className='text-center text-2xl font-bold pt-8 pb-4'>{specialty}</h2>
			<div className='card'>
				{data.map((item, index) => (
					<div
						className='card_content'
						key={index}
						onClick={() => handleClick(item.title)}
					>
						<img className='card_img' src={item.img} alt='' />
						<p>{item.title}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default HealthProblemCard;
