import React from 'react';
import './MentalHealth.scss';

const MentalHealth = () => {
	return (
		<div className='diet'>
			<div className='diet__title'>
				<h1>Mental Health</h1>
			</div>
			<div className='diet__content'>
				<img
					src='https://i.ibb.co/QjP57fq/mental-health-care-sketch-diagram.jpg'
					alt='diet'
				/>
				<div className='diet__paragraph'>
					<p>
						Mental health includes our emotional, psychological, and
						social well-being. It affects how we think, feel, and
						act. It also helps determine how we handle stress,
						relate to others, and make choices. Mental health is
						important at every stage of life, from childhood and
						adolescence through adulthood.
					</p>

					<p>
						Over the course of your life, if you experience mental
						health problems, your thinking, mood, and behavior could
						be affected. Many factors contribute to mental health
						problems, including:
					</p>
					<ul>
						<li>
							Biological factors, such as genes or brain chemistry
						</li>
						<li>Life experiences, such as trauma or abuse</li>
						<li>Family history of mental health problems</li>
					</ul>
					<p>
						Mental health problems are common but help is available.
						People with mental health problems can get better and
						many recover completely.
					</p>
				</div>
			</div>
		</div>
	);
};

export default MentalHealth;
