import React from 'react';
import { useSelector } from 'react-redux';
import './UpcomingAppointments.scss';
import { toast } from 'react-toastify';
import LoadingSVG from '../../components/LoadingSVG/LoadingSVG';
import UpcomingTable from './UpcomingTable';
import './UpcomingAppointments.scss';

const UpcomingAppointments = () => {
	// hooks

	const auth = useSelector((state) => state.auth);

	// states

	const [appointments, setAppointments] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	// effects

	React.useEffect(() => {
		setLoading(true);

		const fetchUpcomingAppointments = async () => {
			try {
				const req = await fetch(
					`${process.env.REACT_APP_API}/doctor/appointments/upcoming`,
					{
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
							'x-access-token': auth.token,
						},
					}
				);

				const res = await req.json();
				console.log('upcoming appointments : ', res);

				if (res.success) {
					setAppointments(res.data);
				} else {
					console.log(res.message);
					toast.error(res.message);
				}
			} catch (error) {
				console.log(error);
				toast.error('Something went wrong!');
			} finally {
				setLoading(false);
			}
		};

		setTimeout(() => {
			fetchUpcomingAppointments();
		}, 1000);
	}, [auth.token]);

	return (
		<div className='upcoming__appointments page'>
			<h1>Upcoming Appointments</h1>

			{loading ? (
				<div className='loading'>
					<LoadingSVG />
				</div>
			) : (
				<div className='appointments__list'>
					{appointments.length === 0 ? (
						<p className='noUpcoming'>
							You have no upcoming appointments.
						</p>
					) : (
						<UpcomingTable
							appointments={appointments}
							setAppointments={setAppointments}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default UpcomingAppointments;
