const days = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

const months = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export const currentDate = () => {
	const date = new Date();
	const day = days[date.getDay()];
	const month = months[date.getMonth()];
	const dayNumber = date.getDate();
	const year = date.getFullYear();

	return `${day}, ${
		dayNumber < 9 ? `0${dayNumber}` : dayNumber
	} ${month} ${year}`;
};

export const currentTime = () => {
	const date = new Date();

	const timeline = date.toLocaleTimeString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
		second: 'numeric',
	});

	const hour = timeline.split(':')[0];
	const minute = timeline.split(':')[1];
	const second = timeline.split(':')[2].split(' ')[0];
	const amPm = timeline.split(':')[2].split(' ')[1];

	return `${hour < 9 ? `0${hour}` : hour}:${
		minute < 9 ? `0${minute}` : minute
	}:${second} ${amPm}`;
};

export const last6Days = () => {
	const date = new Date();
	const day = date.getDate();

	const last6Days = [];

	for (let i = 0; i < 6; i++) {
		const newDate = new Date(date.setDate(day - i - 1));
		const month = months[newDate.getMonth()];
		const dayNumber = newDate.getDate();

		last6Days.push(`${dayNumber} ${month}`);
	}

	return last6Days.reverse();
};

export const filteredLabelsForChart = (filter) => {
	const date = new Date();

	const currentMonth = date.getMonth() + 1;
	const currentYear = date.getFullYear();

	const startingMonth = 1;
	const startingYear = 2023;

	const labels = [];

	if (filter === 'allTime') {
		const differenceInMonths =
			(currentYear - startingYear) * 12 + (currentMonth - startingMonth);

		if (differenceInMonths <= 12) {
			for (let i = 0; i <= differenceInMonths; i++) {
				const newMonth = startingMonth + i;
				const newYear = startingYear;

				labels.push(`${months[newMonth - 1]} ${newYear}`);
			}

			// output : ['Jan 2023', 'Feb 2023', 'Mar 2023', 'Apr 2023', 'May 2023', 'Jun 2023', 'Jul 2023', 'Aug 2023']
		} else if (differenceInMonths <= 24) {
			for (let i = 0; i < differenceInMonths; i += 2) {
				const newMonth = startingMonth + i;
				if (newMonth > 12) {
					const newMonth = startingMonth + i - 12;
					const newYear = startingYear + 1;
					labels.push(`${months[newMonth - 1]} ${newYear}`);
				} else {
					const newYear = startingYear;
					labels.push(`${months[newMonth - 1]} ${newYear}`);
				}
			}

			if (
				labels[labels.length - 1].split(' ')[0] !== months[currentMonth - 1]
			) {
				labels.push(`${months[currentMonth - 1]} ${currentYear}`);
			}

			// output : ['Jan 2022', 'Mar 2022', 'May 2022', 'Jul 2022', 'Sep 2022', 'Nov 2022', 'Jan 2023', 'Mar 2023', 'May 2023', 'Jul 2023', 'Aug 2023']
		} else {
			for (let i = 0; i < differenceInMonths; i += 6) {
				let newMonth = startingMonth + i;
				let newYear = startingYear;

				while (newMonth > 12) {
					newMonth -= 12;
					newYear += 1;
				}

				labels.push(`${months[newMonth - 1]} ${newYear}`);
			}

			if (
				labels[labels.length - 1].split(' ')[0] !== months[currentMonth - 1]
			) {
				labels.push(`${months[currentMonth - 1]} ${currentYear}`);
			}

			// output : ['Jan 2020', 'Jul 2020', 'Jan 2021', 'Jul 2021', 'Jan 2022', 'Jul 2022', 'Jan 2023', 'Jul 2023', 'Aug 2023']
		}
	} else if (filter === 'lastYear') {
		let newMonth = currentMonth;
		let newYear = currentYear;

		for (let i = 0; i < 12; i++) {
			if (newMonth === 0) {
				newMonth = 12;
				newYear -= 1;
			}

			labels.unshift(`${months[newMonth - 1]} ${newYear}`);

			if (newMonth === startingMonth && newYear === startingYear) {
				break;
			}

			newMonth -= 1;
		}

		// output : ['Jan 2023', 'Feb 2023', 'Mar 2023', 'Apr 2023', 'May 2023', 'Jun 2023', 'Jul 2023', 'Aug 2023']
	} else if (filter === 'lastMonth') {
		for (let i = 0; i < 31; i++) {
			const d = new Date();
			d.setDate(d.getDate() - i);
			labels.unshift(
				`${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
			);
		}

		// output : ['4 Jul', '7 Jul', '10 Jul', '13 Jul', '16 Jul', '19 Jul', '22 Jul', '25 Jul', '28 Jul', '31 Jul', '3 Aug']
	}

	return labels;
};

export const filteredDataForChart = (filter, data) => {
	const labels = filteredLabelsForChart(filter);
	let filteredData = Array(labels.length).fill(0);

	if (filter === 'allTime' || filter === 'lastYear') {
		labels.forEach((label, index) => {
			const month =
				months.indexOf(label.split(' ')[0]) + 1 < 10
					? `0${months.indexOf(label.split(' ')[0]) + 1}`
					: months.indexOf(label.split(' ')[0]) + 1;
			const year = label.split(' ')[1];

			let count = 0;

			data.forEach((item) => {
				const itemMonth = item.createdAt.split('-')[1];
				const itemYear = item.createdAt.split('-')[0];

				if (itemMonth === month && itemYear === year) {
					count += 1;
				}
			});

			filteredData[index] = count;
		});
	} else if (filter === 'lastMonth') {
		const dataDates = data.map((item) => item.createdAt);

		// console.log(dataDates);

		for (let i = 0; i < labels.length - 1; i++) {
			const labelDate = new Date(labels[i]);

			// console.log('labelDate', labelDate);

			let count = 0;

			dataDates.forEach((dataDate) => {
				const dDataDate = new Date(dataDate);

				// console.log('dDataDate', dDataDate);

				if (
					dDataDate.getDate() === labelDate.getDate() &&
					dDataDate.getMonth() === labelDate.getMonth() &&
					dDataDate.getFullYear() === labelDate.getFullYear()
				) {
					count += 1;
				}
			});

			filteredData[i] = count;
		}
	}

	// console.log(visual);
	console.log({
		labels,
		filteredData,
	});
	return filteredData;
};
