import React from 'react';
import { useNavigate } from 'react-router-dom';
import { specialistData } from '../SpecialistData';
import './SpecialistCard.scss';
import { originalSpecialty } from '../../../../assets';

const SpecialistCard = () => {
	const navigate = useNavigate();

	const handleSearch = (title) => {
		navigate(`/search?query=${title}`);
	};

	return (
		<div className='specialistRoute page'>
			<div className='w-full max-w-7xl mx-auto mb-20'>
				<img src='/tele-medicine/find.png' alt='banner' className='w-full' />
			</div>

			<div className='content__area'>
				<div className='left__side'>
					<h1>Find a Doctor</h1>

					<div className='items'>
						{originalSpecialty.map((item, index) => {
							if (item.sub.length > 0) {
								return (
									<details key={index} className='parent__item'>
										<summary>{item.name}</summary>
										{item.sub.map((subItem, subIndex) => {
											return (
												<div
													key={`${index}-${subIndex}`}
													className='sub__item'
													onClick={() => handleSearch(subItem.name)}
												>
													{subItem.name}
												</div>
											);
										})}
									</details>
								);
							} else {
								return (
									<div
										key={index}
										className='parent__item'
										onClick={() => handleSearch(item.name)}
									>
										{item.name}
									</div>
								);
							}
						})}
					</div>
				</div>

				<div className='right__side'>
					<div className='all__items'>
						{originalSpecialty.map((item, index) => {
							if (item.sub.length > 0) {
								return item.sub.map((subItem, subIndex) => (
									<div
										key={`${index}-${subIndex}`}
										className='single__item'
										onClick={() => handleSearch(subItem.name)}
									>
										<img src={`/find/${subItem.img}`} alt='cover' />

										<p>{subItem.name}</p>
									</div>
								));
							} else {
								return (
									<div
										key={`${index}-${item.name}`}
										className='single__item'
										onClick={() => handleSearch(item.name)}
									>
										<img src={`/find/${item.img}`} alt='cover' />

										<p>{item.name}</p>
									</div>
								);
							}
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpecialistCard;
