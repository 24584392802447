import React from 'react';
import { IoSearchOutline, IoPersonOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { currentDate, currentTime } from '../../utilities/date';
import './Dashboard.scss';
import LineChart from './LineChart/LineChart';

const Dashboard = () => {
	// page title

	window.document.title = 'Dashboard | Doctor Dashboard';

	// hooks

	const navigate = useNavigate();
	const user = useSelector((state) => state.auth?.info);

	// states

	const [time, setTime] = React.useState(currentTime());
	const [date, setDate] = React.useState(currentDate());

	// effects

	React.useEffect(() => {
		const interval = setInterval(() => {
			setTime(currentTime());
			setDate(currentDate());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className='dashboard page'>
			<div className='box'>
				<div className='left'>
					<div className='topbar'>
						<h1>Dashboard</h1>

						<div className='search'>
							<input type='text' placeholder='Search' />
							<IoSearchOutline className='icon' />
						</div>
					</div>

					<div className='welcome'>
						<h2>Welcome to Romatoo.</h2>
						<h1>
							Hello {user?.role === 'doctor' ? 'Dr. ' : ''}{' '}
							<span>{user?.fullName}</span>
						</h1>
						<p>{date}</p>
						<p>{time}</p>
					</div>

					<div className='actions__btn'>
						<button className='action'>
							<img src='/dashboard/appointment.png' alt='' />
							<span>Today's Appointments</span>
						</button>
						<button
							className='action'
							onClick={() => navigate('/upcoming-appointments')}
						>
							<img src='/dashboard/appointment2.png' alt='' />
							<span>Upcoming Appointments</span>
						</button>
						<button className='action'>
							<img src='/dashboard/analytics.png' alt='' />
							<span>Analytics</span>
						</button>
						<button className='action'>
							<img src='/dashboard/finance.png' alt='' />
							<span>Finance</span>
						</button>
					</div>
				</div>

				<div className='right'>
					<div className='avatar'>
						<IoPersonOutline className='icon' />
					</div>

					<div className='balance'>
						<p>
							276.00 <span>USD</span>
						</p>

						<p>
							{(276 * 101.63).toFixed(2)} <span>BDT</span>
						</p>
					</div>

					<LineChart />
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
