import React from 'react';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
	countSlots,
	validImageUrl,
	getOffDays,
	getTimeRange,
	validAppointmentTime,
} from '../../utilities/extra';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoStar } from 'react-icons/io5';
import './DoctorCard.scss';
import { toast } from 'react-toastify';
import LoadingSVG from '../../components/LoadingSVG/LoadingSVG';

const DoctorCard = ({ doctorInfo }) => {
	const navigate = useNavigate();
	const auth = useSelector((state) => state.auth);

	// console.log('doctorInfo : ', doctorInfo);

	const [selectedValue, setSelectedValue] = React.useState(dayjs(new Date()));
	const [validTime, setValidTime] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const disableDay = (day) => {
		const date = new Date(day);

		return getOffDays(doctorInfo.timeTable).includes(date.getDay());
	};

	const disableTime = (timeValue, clockType) => {
		const { start, end } = getTimeRange(
			selectedValue.format('YYYY-MM-DD HH:mm'),
			doctorInfo.timeTable
		);

		const selectedHour = parseInt(selectedValue.format('HH'));

		const startHour = parseInt(start.split(':')[0]);
		const startMinute = parseInt(start.split(':')[1]);

		const endHour = parseInt(end.split(':')[0]);
		const endMinute = parseInt(end.split(':')[1]);

		if (clockType === 'hours') {
			return timeValue < startHour || timeValue > endHour;
		} else if (clockType === 'minutes') {
			const startHourMins = startHour * 60 + startMinute;
			const endHourMins = endHour * 60 + endMinute;
			const selectedHourMins = selectedHour * 60 + timeValue;

			return (
				selectedHourMins < startHourMins || selectedHourMins > endHourMins - 20
			);
		}
	};

	const handleChange = (newValue) => {
		setSelectedValue(newValue);
		setValidTime(validAppointmentTime(newValue, doctorInfo.timeTable));
	};

	const bookAppointment = () => {
		if (!auth.token) {
			toast.error('Please login first');
			setTimeout(() => {
				navigate('/sign-in');
			}, 2200);
			return;
		}

		if (auth.info?.role === 'doctor') {
			toast.error('You are a doctor');
			return;
		} else {
			setLoading(true);

			const date = selectedValue.format('YYYY-MM-DD');
			const time = selectedValue.format('HH:mm');

			const data = {
				doctorId: doctorInfo.doctor._id,
				date,
				time,
			};

			const url = `${process.env.REACT_APP_API}/patient/appointments`;

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': auth.token,
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then((res) => {
					console.log('booking res : ', res);

					if (res.success) {
						toast.success(res.message);

						setSelectedValue(dayjs(new Date()));
						setValidTime(false);
						setIsOpen(false);
					} else {
						toast.error(res.message);
					}
				})
				.catch((err) => {
					console.log('booking err : ', err);
					toast.error('Something went wrong');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const handleCreateConversation = (doctorId) => {
		if (!auth.token) {
			toast.error('Please login first');
			setTimeout(() => {
				navigate('/sign-in');
			}, 2200);
			return;
		}

		if (auth.info?.role === 'doctor') {
			setLoading(true);

			const url = `${process.env.REACT_APP_API}/doctor/conversations/create/${doctorId}`;

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': auth.token,
				},
			})
				.then((res) => res.json())
				.then((res) => {
					console.log('create conversation res : ', res);

					if (res.success) {
						toast.success(res.message);
						navigate(`/messages/${res.data._id}`);
					} else {
						if (res.message === 'Conversation already exists') {
							navigate(`/messages`);
						} else {
							toast.error(res.message);
						}
					}
				})
				.catch((err) => {
					console.log('create conversation err : ', err);
					toast.error('Something went wrong');
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setLoading(true);

			const url = `${process.env.REACT_APP_API}/patient/conversations/create/${doctorId}`;

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': auth.token,
				},
			})
				.then((res) => res.json())
				.then((res) => {
					console.log('create conversation res : ', res);

					if (res.success) {
						toast.success(res.message);
						navigate(`/messages/${res.data._id}`);
					} else {
						if (res.message === 'Conversation already exists') {
							navigate(`/messages`);
						} else {
							toast.error(res.message);
						}
					}
				})
				.catch((err) => {
					console.log('create conversation err : ', err);
					toast.error('Something went wrong');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<div className='doctor__card'>
			<div className='top'>
				<div className='left'>
					{validImageUrl(doctorInfo.doctor.profileInfo.profilePicture) ? (
						<img
							src={doctorInfo.doctor.profileInfo.profilePicture}
							alt='profile'
						/>
					) : (
						<img src='./profile.svg' alt='profile' />
					)}
				</div>

				<div className='right'>
					<div className='group'>
						<p>Name</p>

						<h1>{doctorInfo.doctor.fullName}</h1>
					</div>

					<div className='group'>
						<p>Title</p>

						<h1>{doctorInfo.doctor.profileInfo.title || 'Sr. Consultant'}</h1>
					</div>

					<div className='group'>
						<p>Qualification</p>

						<h1>
							{doctorInfo.doctor.profileInfo.qualification.join(', ') || 'N/A'}
						</h1>
					</div>

					<div className='group'>
						<p>Experience</p>

						<h1>
							{doctorInfo.doctor.profileInfo.experience
								? `${doctorInfo.doctor.profileInfo.experience} years`
								: '5 years'}
						</h1>
					</div>
				</div>
			</div>

			<div className='bottom'>
				<div className='group1'>
					<div className='group__item'>
						<p>Current Working Organization</p>

						<h1>
							{doctorInfo.doctor.profileInfo.currentOrganization.join(', ') ||
								'N/A'}
						</h1>
					</div>

					<div className='group__item'>
						<p>Joining Country</p>

						<h1>{doctorInfo.doctor.profileInfo.joiningCountry || 'N/A'}</h1>
					</div>
				</div>

				<div className='specialties'>
					<p>Specialties</p>

					<h1>
						{doctorInfo.specialties.map((specialty, index) => (
							<span key={index}>{specialty}</span>
						))}
					</h1>
				</div>

				<div className='group'>
					<div className='group__item'>
						<p>Available Slot in next 7 days</p>

						<h1>{countSlots(doctorInfo.timeTable)} Slots</h1>
					</div>

					<div className='group__item'>
						<p>Rating</p>

						<h1 className='stars'>
							{Array(5)
								.fill(0)
								.map((_, index) => (
									<IoStar key={index} className='icon' />
								))}
						</h1>
					</div>

					<div className='group__item'>
						<p>Appointment Fee</p>

						<h1>{doctorInfo.appointmentFee.local} BDT</h1>
					</div>
				</div>

				<DateTimePicker
					open={isOpen}
					onClose={() => setIsOpen(false)}
					label='Date&Time picker'
					value={selectedValue}
					onChange={handleChange}
					shouldDisableDate={disableDay}
					shouldDisableTime={disableTime}
					minDate={dayjs().add(1, 'day')}
					renderInput={({ ref, inputRef, disabled, onChange, value }) => (
						<div
							ref={ref}
							style={{
								position: 'relative',
							}}
							className='footer'
						>
							<input
								style={{
									position: 'absolute',
									zIndex: -1,
									visibility: 'hidden',
									pointerEvents: 'none',
								}}
								value={value}
								onChange={onChange}
								disabled={disabled}
								ref={inputRef}
							/>

							<div className='flex items-center gap-4'>
								<button
									onClick={() => {
										if (auth?.info?.role === 'doctor') {
											alert(
												'As a doctor you can not book appointment. Please login as a patient'
											);
										} else {
											setIsOpen((prev) => !prev);
										}
									}}
								>
									{validTime
										? dayjs(selectedValue).format('lll')
										: 'Set Appointment Time'}
								</button>

								<button
									onClick={() => {
										handleCreateConversation(doctorInfo.doctor._id);
									}}
								>
									Chat Now
								</button>

								<button
									onClick={() => {
										navigate(`/social/profile/${doctorInfo.doctor._id}`);
									}}
								>
									Social
								</button>
							</div>

							{validTime && (
								<button className='btn-book' onClick={bookAppointment}>
									{loading ? <LoadingSVG /> : 'Book Appointment'}
								</button>
							)}
						</div>
					)}
				/>
			</div>
		</div>
	);
};

export default DoctorCard;
