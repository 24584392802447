import React from 'react';
import './Approvals.scss';

const DoctorApprovals = () => {
	return (
		<div className='admin__approvals'>
			<h1>Doctor Approvals Page</h1>
		</div>
	);
};

export default DoctorApprovals;
