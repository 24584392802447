import React from 'react';
import useImageUpload from '../../hooks/useImageUpload';
import { useNavigate } from 'react-router-dom';
import { BsPlusLg, BsCheckLg } from 'react-icons/bs';
import { IoCloseOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import './EditDashboard.scss';

const days = [
	{ remove: false, day: 'Saturday', key: 'sat' },
	{ remove: false, day: 'Sunday', key: 'sun' },
	{ remove: false, day: 'Monday', key: 'mon' },
	{ remove: false, day: 'Tuesday', key: 'tue' },
	{ remove: false, day: 'Wednesday', key: 'wed' },
	{ remove: false, day: 'Thursday', key: 'thu' },
	{ remove: false, day: 'Friday', key: 'fri' },
];

const EditDashboard = () => {
	// page title

	window.document.title = 'Edit Dashboard | Dashboard';

	// hooks

	const navigate = useNavigate();
	const auth = useSelector((state) => state.auth);
	const { uploadImage, uploadLoading } = useImageUpload();

	// states & refs

	const specialtiesRef = React.useRef(null);

	const [dashboardData, setDashboardData] = React.useState({
		appointmentType: 'Video', //
		appointmentFee: {
			local: 0, //
			international: 0, //
		},
		duration: 0, //
		timeTable: {}, //
		specialties: [], //
		signatureImage: '', //
	});
	const [loading, setLoading] = React.useState(false);

	// functions

	const handleStartTimeChange = (e, day) => {
		const timeTable = dashboardData.timeTable;

		timeTable[day] = timeTable[day] || {};

		if (!timeTable[day].end) {
			Object.assign(timeTable[day], {
				start: e.target.value,
				end: '00:00',
			});
		} else {
			Object.assign(timeTable[day], {
				start: e.target.value,
			});
		}

		setDashboardData({
			...dashboardData,
			timeTable,
		});
	};

	const handleEndTimeChange = (e, day) => {
		const timeTable = dashboardData.timeTable;

		timeTable[day] = timeTable[day] || {};

		if (!timeTable[day].start) {
			Object.assign(timeTable[day], {
				start: '00:00',
				end: e.target.value,
			});
		} else {
			Object.assign(timeTable[day], {
				end: e.target.value,
			});
		}

		setDashboardData({
			...dashboardData,
			timeTable,
		});
	};

	const handleSpecialtiesChange = () => {
		if (specialtiesRef.current.value) {
			setDashboardData({
				...dashboardData,
				specialties: [
					...dashboardData.specialties,
					specialtiesRef.current.value,
				],
			});
			specialtiesRef.current.value = '';
		}
	};

	const handleSubmit = () => {
		console.log('dashboard data : ', dashboardData);

		setLoading(true);

		setTimeout(() => {
			const url = `${process.env.REACT_APP_API}/doctor/doctor-info`;

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': auth.token,
				},
				body: JSON.stringify(dashboardData),
			})
				.then((res) => res.json())
				.then((res) => {
					console.log('doctor info res : ', res);

					if (res.success) {
						toast.success(res.message);
						setTimeout(() => {
							navigate('/dashboard');
						}, 2300);
					} else {
						toast.error(res.message);
					}
				})
				.catch((err) => {
					console.log('err : ', err);
					toast.error('Something went wrong');
				})
				.finally(() => {
					setLoading(false);
				});
		}, 2000);
	};

	return (
		<section className='editDashboard page'>
			<div className='box'>
				<h1>Edit Dashboard</h1>

				<div className='form__body'>
					{/* <div className='form__group'>
						<h4>Division</h4>

						<div className='input__group'>
							<BsPlusLg className='icon' />
							<input
								type='text'
								name='division'
								placeholder='Add Division'
								onChange={(e) => {
									setDashboardData({
										...dashboardData,
										division: e.target.value,
									});
								}}
							/>
						</div>
					</div>

					<div className='form__group'>
						<h4>City</h4>

						<input
							type='text'
							name='city'
							placeholder='Enter Your City'
							onChange={(e) => {
								setDashboardData({
									...dashboardData,
									city: e.target.value,
								});
							}}
						/>
					</div> */}

					<div className='form__group'>
						<h4>Appointment Type</h4>

						<label className='appointmentType_label'>
							<input
								type='radio'
								name='appointmentType'
								value='Video'
								checked={dashboardData.appointmentType === 'Video'}
								readOnly
							/>
							<span>Video</span>
						</label>
					</div>

					{/* <div className='form__group'>
						<h4>Clinic or Hospital</h4>

						<input
							type='text'
							name='clinicOrHospital'
							placeholder='Enter your Clinic or Hospital'
							onChange={(e) => {
								setDashboardData({
									...dashboardData,
									clinicOrHospital: e.target.value,
								});
							}}
						/>
					</div> */}

					<div className='form__group'>
						<h4>Choose Your Time Table</h4>

						<div className='timeTable'>
							{days.map((item, index) => (
								<div className='timeTable__day' key={index}>
									<div className='timeTable__day__time'>
										<div className='block'>
											<p>{item.day.slice(0, 3).toUpperCase()}</p>
											<input
												type='time'
												name='startTime'
												placeholder='Start Time'
												defaultValue='00:00'
												onChange={(e) => handleStartTimeChange(e, item.key)}
											/>
										</div>

										<span>to</span>

										<div className='block'>
											<input
												type='time'
												name='endTime'
												placeholder='End Time'
												defaultValue='00:00'
												onChange={(e) => handleEndTimeChange(e, item.key)}
											/>

											<div className='timeTable__day__checkbox'>
												{dashboardData.timeTable[item.key] ? (
													<BsCheckLg className='icon' />
												) : (
													<BsPlusLg className='icon' />
												)}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>

					<div className='form__group'>
						<h4>What diseases you solve?</h4>

						<div className='input__group'>
							<BsPlusLg
								className='icon'
								onClick={() => {
									if (specialtiesRef.current.value) {
										handleSpecialtiesChange();
									}
								}}
							/>

							<input
								type='text'
								name='specialties'
								placeholder='Add Specialties you solve'
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										handleSpecialtiesChange();
									}
								}}
								ref={specialtiesRef}
							/>
						</div>

						{dashboardData.specialties.length > 0 && (
							<div className='specialties__list'>
								{dashboardData.specialties.map((item, index) => (
									<p className='selected__specialties' key={index}>
										<span>{item}</span>
										<IoCloseOutline
											className='icon'
											onClick={() => {
												const specialties = dashboardData.specialties;
												specialties.splice(index, 1);
												setDashboardData({
													...dashboardData,
													specialties,
												});
											}}
										/>
									</p>
								))}
							</div>
						)}
					</div>

					<div className='form__group'>
						<h4>Session duration for patient</h4>

						<div className='session__duration'>
							{[
								{ key: 10, value: '10 min' },
								{ key: 15, value: '15 min' },
								{ key: 20, value: '20 min' },
							].map((item, index) => (
								<label
									className={
										dashboardData.sessionDuration === item.key
											? 'session-duration__item active'
											: 'session-duration__item'
									}
									key={index}
								>
									<input
										type='radio'
										name='sessionDuration'
										value={item.key}
										key={index}
										onChange={(e) => {
											if (e.target.checked) {
												setDashboardData({
													...dashboardData,
													duration: item.key,
												});
											}
										}}
									/>
									<span>{item.value}</span>
								</label>
							))}
						</div>
					</div>

					<div className='form__group'>
						<h4>Appointment fee for your country</h4>

						<input
							type='number'
							name='appointmentFee'
							placeholder='In your local currency'
							onChange={(e) => {
								setDashboardData({
									...dashboardData,
									appointmentFee: {
										...dashboardData.appointmentFee,
										local: e.target.value,
									},
								});
							}}
						/>
					</div>

					<div className='form__group'>
						<h4>Appointment fee for foreigner</h4>

						<input
							type='number'
							name='appointmentFeeForeigner'
							placeholder='In USD'
							onChange={(e) => {
								setDashboardData({
									...dashboardData,
									appointmentFee: {
										...dashboardData.appointmentFee,
										international: e.target.value,
									},
								});
							}}
						/>
					</div>

					{/* <div className='form__group'>
						<h4>
							Provide a image of a signature with your
							professional seal
						</h4>

						<input
							type='file'
							name='signature'
							onChange={(e) => {
								uploadImage(
									e,
									setDashboardData,
									'signatureImage'
								);
							}}
						/>
					</div> */}
				</div>

				<div className='form__footer'>
					<button className='btn-submit' onClick={handleSubmit}>
						Edit Dashboard
					</button>
				</div>
			</div>
		</section>
	);
};

export default EditDashboard;
