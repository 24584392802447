import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IoPersonAddOutline } from 'react-icons/io5';
import { BiCommentDetail } from 'react-icons/bi';
import { GoThumbsup } from 'react-icons/go';
import { MdOutlineBloodtype } from 'react-icons/md';
import './Notifications.scss';

const Notifications = () => {
	const userToken = useSelector((state) => state.auth?.token);

	const [allNotifications, setAllNotifications] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		const getNotifications = async () => {
			const options = {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': userToken,
				},
			};
			const url = `${process.env.REACT_APP_API}/doctor/notifications`;

			try {
				const req = await fetch(url, options);
				const res = await req.json();

				console.log('notifications res ->', res);

				if (res.success) {
					setAllNotifications(res.data);
				} else {
					toast.error(res.message);
				}
			} catch (error) {
				console.log('notifications error ->', error);
				toast.error('Something went wrong');
			} finally {
				setLoading(false);
			}
		};

		getNotifications();
	}, []);

	return (
		<div className='notifications__page page'>
			<h1>Notifications</h1>

			<div className='all__notifications'>
				{allNotifications.length > 0 ? (
					allNotifications.map((notification, index) => (
						<Link
							to={notification.link}
							key={index}
							className='notification__link'
						>
							<span>{notification.title}</span>

							{notification?.title?.toLowerCase()?.includes('followed') ? (
								<IoPersonAddOutline className='icon' />
							) : notification?.title?.toLowerCase()?.includes('commented') ? (
								<BiCommentDetail className='icon' />
							) : notification?.title?.toLowerCase()?.includes('reacted') ? (
								<GoThumbsup className='icon' />
							) : notification?.title?.toLowerCase()?.includes('blood') ? (
								<MdOutlineBloodtype className='icon' />
							) : (
								''
							)}
						</Link>
					))
				) : (
					<h2>No Notifications</h2>
				)}
			</div>
		</div>
	);
};

export default Notifications;
