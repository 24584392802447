import React from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { IoPrint, IoWarning } from 'react-icons/io5';
import LoadingSVG from '../../components/LoadingSVG/LoadingSVG';
import './Preview.scss';

const Preview = () => {
	// page title

	window.document.title = 'ePrescription | Romatoo';

	// hooks

	const { id } = useParams();
	const authToken = useSelector((state) => state.auth.token);
	const authInfo = useSelector((state) => state.auth.info);

	// states & refs

	const printRef = React.useRef();

	const [prescription, setPrescription] = React.useState(null);
	const [loading, setLoading] = React.useState(true);

	// effects

	React.useEffect(() => {
		const getPrescription = async () => {
			try {
				const url = `${process.env.REACT_APP_API}/doctor/prescription/${id}`;
				const req = await fetch(url, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': authToken,
					},
				});

				const res = await req.json();
				console.log('get pres res', res);
				if (res.success) {
					setPrescription(res.data);
				} else {
					toast.error(res.message);
				}
			} catch (error) {
				console.log('get pres error', error);
				toast.error('Something went wrong');
			} finally {
				setLoading(false);
			}
		};
		getPrescription();
	}, [id]);

	// functions

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	// loading

	if (loading) {
		return (
			<div className='w-full flex items-center justify-center py-10'>
				<LoadingSVG />
			</div>
		);
	}
	return (
		<div className='printPres page'>
			<div className='box'>
				<div id='innerBox' ref={printRef}>
					<div className='info'>
						<div className='left'>
							<div className='logo'>
								<img src='/logo.svg' alt='logo' />
							</div>

							<p className='creationDate'>
								Date: {prescription.createdAt.split('T')[0]}
							</p>

							{/* <h1 className='ePres'>ePRESCRIPTION</h1> */}

							<p className='issuedTo'>
								Issues to
								<br />
								{prescription.patientInfo?.patientName} -{' '}
								{prescription.patientInfo?.patientAge} years old
							</p>

							<p className='warn'>
								<IoWarning className='icon' />
								<span>Do not sell drugs if prescription is expired!</span>
							</p>

							<p className='expireDate'>
								<span>Expired on : {prescription.expires}</span>
							</p>
						</div>

						<div className='right'>
							<p className='prescribedBy'>
								Prescribed by
								<br />
								{prescription.doctorName.startsWith('Dr.') ||
								prescription.doctorName.startsWith('Dr ')
									? prescription.doctorName
									: `Dr. ${prescription.doctorName}`}
							</p>

							{prescription.doctorInfo.profileInfo?.qualification ? (
								<p className='qualification'>
									{prescription.doctorInfo.profileInfo?.qualification}
								</p>
							) : null}

							<p>
								{prescription.doctorInfo.profileInfo?.specialties ? (
									<span className='specialties'>
										{prescription.doctorInfo.profileInfo?.specialties.join(
											', '
										)}
									</span>
								) : null}

								{prescription.doctorInfo.profileInfo?.title ? (
									<span className='title'>
										{` (${prescription.doctorInfo.profileInfo?.title})`}
									</span>
								) : null}
							</p>

							{prescription.doctorInfo.profileInfo?.currentOrganization ? (
								<p className='organization'>
									{prescription.doctorInfo.profileInfo?.currentOrganization}
								</p>
							) : null}

							{prescription.doctorInfo.profileInfo?.medicalCouncilNumber ? (
								<p className='councilNumber'>
									BMDC No:{' '}
									{prescription.doctorInfo.profileInfo?.medicalCouncilNumber}
								</p>
							) : null}

							{prescription.signature ? (
								<img
									src={prescription.signature}
									alt='signature'
									className='signature'
								/>
							) : null}
						</div>
					</div>

					<div className='bottom_sec'>
						{prescription?.presentMedicalSection?.filter((x) => x.text !== '')
							?.length > 0 ? (
							<div className='left'>
								<h4>Medical Records</h4>

								{prescription.presentMedicalSection?.length > 0
									? prescription.presentMedicalSection.map((item, index) => (
											<div className='medicalRecord' key={index}>
												<p>
													<span className='title'>{item.label}</span>
													<span className='value'>{item.text}</span>
												</p>
											</div>
									  ))
									: null}
							</div>
						) : null}

						<div className='med__sec'>
							<div className='givenMedicines'>
								{prescription.medicines.map((item, index) => (
									<div className='medicineItem' key={index}>
										<p>
											<span>Generic Name : </span>

											<span>{item.geneticName || 'N/A'}</span>
										</p>

										<p>
											<span>Brand Name : </span>

											<span>{item.brandName}</span>
										</p>

										{item?.injectionTime ? (
											<p>
												<span>Injection Time : </span>

												<span>{item.injectionTime}</span>
											</p>
										) : item?.salineTime ? (
											<p>
												<span>Saline Name : </span>

												<span>{item.salineTime}</span>
											</p>
										) : (
											<>
												{item.timeTable?.map((type, typeIdx) => (
													<div className='medTimeTable' key={typeIdx}>
														{item.times.map((time, timeIdx) => (
															<span key={timeIdx}>
																{time.time === 'morning' ||
																time.time === 'noon' ||
																time.time === 'night'
																	? time.time.charAt(0).toUpperCase() +
																	  time.time.slice(1)
																	: time.time}{' '}
																:{' '}
																{time[
																	`amount${type.type > 1 ? type.type : ''}`
																] || 0}
															</span>
														))}

														<span className='type'>
															Duration : {type.duration} {type.durationType}
														</span>
													</div>
												))}
											</>
										)}

										<div className='intakeDuration'>
											{item.intake && (
												<p>
													<span>Intake : </span>

													<span>{item.intake || 'N/A'}</span>
												</p>
											)}

											{(item?.injectionTime !== '' ||
												item?.salineTime !== '') &&
												item.duration && (
													<p>
														<span>Duration : </span>

														<span>{item.duration}</span>
													</p>
												)}
										</div>

										{item.note && (
											<p>
												<span>Note : </span>

												<span>{item.note}</span>
											</p>
										)}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>

				<button className='btn-print' onClick={handlePrint}>
					<IoPrint className='icon' />
					<span>Print</span>
				</button>
			</div>
		</div>
	);
};

export default Preview;
