import React from 'react';
import {
	conn2,
	loveSvg,
	homeContent,
	heartSvg,
	blogsData,
	sliderData,
} from '../../assets';
import { FiExternalLink } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import './Home.scss';
import SvgComponent from './Anim';

const Home = () => {
	// route title

	window.document.title = 'Romatoo - AI For Healthcare';

	// hooks

	const navigate = useNavigate();

	// states

	const [sliderIndex, setSliderIndex] = React.useState(0);
	const [prevImg, setPrevImg] = React.useState('/home/updated/5-1.png');
	const [prevImg2, setPrevImg2] = React.useState('/home/2-1.png');

	React.useEffect(() => {
		setInterval(() => {
			setPrevImg((prev) => {
				if (prev === '/home/updated/5-1.png') {
					return '/home/updated/5-2.png';
				} else {
					return '/home/updated/5-1.png';
				}
			});

			setPrevImg2((prev) => {
				if (prev === '/home/2-1.png') {
					return '/home/2-2.png';
				} else {
					return '/home/2-1.png';
				}
			});
		}, 2000);
	}, []);

	// functions

	const handleSlider = () => {
		if (sliderIndex < sliderData.length - 1) {
			setSliderIndex(sliderIndex + 1);
		} else {
			setSliderIndex(0);
		}
	};

	return (
		<div className='home page'>
			<section className='header__section'>
				<img src={conn2} alt='peoples' />

				<div className='header__container'>
					<div className='left'>
						<h4>
							<img src={heartSvg} alt='heart icon' />
							<p>
								<span>Are you the new generation or think like them?</span>
								<span>No worries - we offer services</span>
							</p>
						</h4>

						<h2>Ensure accurate smart</h2>
						<h1>
							<span>Healthcare with AI</span>
							<span></span>
						</h1>

						<div className='action'>
							<button className='btn-access'>Get Access</button>
							<p>FOR FREE</p>

							<span>
								No Monthly <br />
								Price
							</span>
						</div>
					</div>
				</div>
			</section>

			{/* <section className='service__section'>
				<div className='left'>
					<div className='users'>
						<h1>
							<p>1 thousand+</p>
							<span>Monthly patient worldwide and growing...</span>
						</h1>

						<img src={usersSvg} alt='users svg' />
					</div>
				</div>

				<div className='right'>
					<div className='xx__img'>
						<img src={conn} alt='images' />
					</div>

					<div className='services'>
						{serviceData.map((item, index) => (
							<div
								className={
									item.title === 'ePrescription'
										? 'service cursor-pointer'
										: 'service'
								}
								key={index}
								onClick={() => {
									if (item.title === 'ePrescription') {
										navigate('/prescription');
									}
								}}
							>
								<div className='top'>
									<img src={item.svg} alt={item.title} />
									<h3>{item.title}</h3>
								</div>

								<p>{item.text}</p>
							</div>
						))}
					</div>
				</div>
			</section> */}

			{/* <section className='explore__section'>
				<div className='left'>
					<div className='top'>
						<p>Thinking about consulting with</p>
						<h2>
							<span>Global Doctors</span> <span>not range</span>
						</h2>
					</div>

					<div className='bottom'>
						<p className='globe__blur'>
							<span>“</span>
							<span>
								Through improved e-health services with the help of AR
								<br />
								We have broken down the barriers to accessing healthcare
								services in developing countries. Now you don't have to leave
								your beloved homeland anymore and go abroad in case of your
								sickness. Expert Doctors are here round the clock, 24/7 to
								provide you with exact treatment worldwide. All that for your
								healthy life
							</span>
						</p>
					</div>
				</div>

				<div className='right'>
					<div className='explore__card'>
						<div className='cover'>
							<img src={exProblem} alt='explore problem' />
						</div>

						<button onClick={() => navigate('/diseases')}>
							Explore Problem
						</button>
					</div>

					<div className='explore__card'>
						<div className='cover'>
							<img src={exSpecial} alt='explore specialties' />
						</div>

						<button onClick={() => navigate('/specialists')}>
							Explore Specialties
						</button>
					</div>
				</div>
			</section>

			<section className='cloud__section'>
				<div className='section__header'>
					<h1>
						<img src={cloudSvg} alt='cloud' />
						<span>Cloud Solutions</span>
					</h1>
					<p>Made it easy to provide and receive healthcare</p>
				</div>

				<div className='clouds'>
					{cloudData.map((item, index) => (
						<div className='cloud' key={index}>
							<div className='block__icon'>
								<img src={item.svg} alt='' />
							</div>

							<div className='block__content'>
								<h3>
									Cloud base solution for
									<br />
									<span>{item.title}</span>
								</h3>
								<p>
									{item.text}...{' '}
									<Link to={item.url} className='btn-readMore'>
										Read More
									</Link>
								</p>
							</div>
						</div>
					))}
				</div>
			</section> */}

			<section className='content__section'>
				<div className='content__container'>
					{homeContent.slice(0, 4).map((_, index) => (
						<div className='content__card' key={index}>
							<div className='content__card__img'>
								{index === 1 ? (
									<img src={prevImg2} alt='content img' />
								) : (
									<img src={_.image} alt='content img' />
								)}
							</div>

							<div className='content__card__content'>
								<h3>{_.title}</h3>

								<p>
									{_.subTitle && (
										<span className='sub-title-desc'>{_.subTitle}</span>
									)}

									<span className='title-desc'>{_.description}</span>
								</p>

								<Link to={_.url}>
									<span>Explore</span>

									<FiExternalLink className='icon' />
								</Link>
							</div>
						</div>
					))}
				</div>
			</section>

			<section className='content__section'>
				<div className='content__container'>
					{homeContent.slice(4, 7).map((_, index) => (
						<div className='content__card' key={index}>
							<div className='content__card__img'>
								{index === 0 ? (
									<img src={prevImg} alt='content img' />
								) : (
									<img src={_.image} alt='content img' />
								)}
							</div>

							<div className='content__card__content'>
								<h3>{_.title}</h3>

								<p>
									{_.subTitle && (
										<span className='sub-title-desc'>{_.subTitle}</span>
									)}

									<span className='title-desc'>{_.description}</span>
								</p>

								<Link to={_.url}>
									<span>Explore</span>

									<FiExternalLink className='icon' />
								</Link>
							</div>
						</div>
					))}
				</div>
			</section>

			<section className='anim__section'>
				<div className='anim__container'>
					<div className='box__container'>
						<SvgComponent />
					</div>

					<div className='text__container'>
						We are working on a deep learning AI model that can handle
						emergencies and primary health care situations
						{/* We are <br /> working <br /> on a <br /> deep <br /> learning <br />{' '}
						AI <br /> model <br /> that can <br /> handle <br /> emergencies{' '}
						<br /> and <br /> primary <br /> health <br /> care <br />{' '}
						situations */}
					</div>
				</div>
			</section>

			{/* <section className='slider__section'>
				<div className='section__header'>
					<h1>
						<img src={robotSvg} alt='robot svg' />
						<span>Artificial Intelligence</span>
					</h1>
					<p>Custom tailored AI integration to ensure better health care</p>
				</div>

				<div className='slider'>
					<div className='left'>
						<img src={sliderData[sliderIndex].image} alt='slider item' />
					</div>

					<div className='right'>
						<div className='slider__content'>
							<h3>{sliderData[sliderIndex].subHeader}</h3>
							<h2>{sliderData[sliderIndex].header}</h2>
							<p>{sliderData[sliderIndex].text}</p>
						</div>

						<div className='slider__controls'>
							<div className='left__side'>
								<button onClick={handleSlider}>
									<FaChevronLeft className='icon' />
								</button>
								<button onClick={handleSlider}>
									<FaChevronRight className='icon' />
								</button>
							</div>

							<div className='right__side'>
								<button
									onClick={() => setSliderIndex(0)}
									className={sliderIndex === 0 ? 'active' : ''}
								></button>
								<button
									onClick={() => setSliderIndex(1)}
									className={sliderIndex === 1 ? 'active' : ''}
								></button>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			<section className='blogs__section'>
				<div className='section__header'>
					<h1>
						<img src={loveSvg} alt='blogs' />
						<span>Happy Life</span>
					</h1>
					<p>Comes with a healthy body and peaceful mind</p>
				</div>

				<div className='blogs'>
					{blogsData.map((item, index) => (
						<div className='blog' key={index}>
							<div className='cover'>
								<img src={item.image} alt='postCover' />
							</div>

							<p className='text'>
								{item.text}...{' '}
								<Link to={`/post/${index + 1}`} className='btn-readMore'>
									Read More
								</Link>
							</p>
						</div>
					))}
				</div>
			</section>

			{/* <section className='booking__section'>
				<div className='box__container'>
					<div className='box'>
						<img src={calenderSvg} alt='calender icon' />

						<h1>Want to make an appointment easily?</h1>
						<p>
							Are you suffering from any disease or have been
							suffering from one or more diseases for a long time
							and are unable to recover with local medical care?
							<br />
							<span>
								Use Romatoo to keep yourself free from all such
								diseases and stay healthy
							</span>
						</p>
					</div>

					<button>Book an Appointment</button>
				</div>
			</section> */}

			<Footer />
		</div>
	);
};

export default Home;
