import React from 'react';
import HealthProblemCard from './HealthProblemCard/HealthProblemCard';
import { healthProblemsData } from './HealthProblemsData';

const HealthProblems = () => {
	return (
		<div className='problemRoute page'>
			<div className='w-full max-w-7xl mx-auto mb-10'>
				<img src='/banner/4.jpg' alt='banner' className='w-full rounded-md' />
			</div>

			{/* <h1 className='text-center text-3xl font-bold py-5'>
				Find your health Problems
			</h1> */}
			{healthProblemsData.map((item, index) => (
				<HealthProblemCard
					key={index}
					specialty={item.title}
					data={item.cardData}
				/>
			))}
		</div>
	);
};

export default HealthProblems;
