import React from 'react';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Filler,
} from 'chart.js';
import {
	last6Days,
	filteredLabelsForChart,
	filteredDataForChart,
} from '../../../utilities/date';

ChartJS.register(
	ArcElement,
	Tooltip,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Filler,
	Legend
);

const LineChart = ({ data, filter }) => {
	const dummyData = {
		labels: filteredLabelsForChart(filter).map((label) =>
			filter === 'lastMonth' ? label.split(' ')[0] : label
		),
		datasets: [
			{
				label: 'Doctors',
				data: filteredDataForChart(filter, data.doctors),
				fill: true,
				borderWidth: 1,
				backgroundColor: 'rgb(16, 185, 129, 0.1)',
				borderColor: '#10b981',
				tension: 0.1,
			},
			{
				label: 'Patients',
				data: filteredDataForChart(filter, data.patients),
				fill: true,
				borderWidth: 1,
				backgroundColor: 'rgb(59, 130, 246, 0.1)',
				borderColor: '#3b82f6',
				tension: 0.1,
			},
		],
	};

	// console.log(last6Days());

	// const resData = {
	// 	doctors: [
	// 		{
	// 			_id: '6432e9410713093217eea355',
	// 			createdAt: '2023-04-09T16:35:13.591Z',
	// 		},
	// 		{
	// 			_id: '644f354d70a02c4642346bdd',
	// 			createdAt: '2023-05-01T03:43:09.560Z',
	// 		},
	// 		{
	// 			_id: '64587105c5607cc17be8c3f5',
	// 			createdAt: '2023-05-08T03:48:21.935Z',
	// 		},
	// 		{
	// 			_id: '64be538394a50c672d492946',
	// 			createdAt: '2023-07-24T10:33:39.994Z',
	// 		},
	// 	],
	// 	patients: [
	// 		{
	// 			_id: '6432f0f97abe209a450de399',
	// 			createdAt: '2023-04-09T17:08:09.487Z',
	// 		},
	// 		{
	// 			_id: '6447680703f6dafbd1a0921a',
	// 			createdAt: '2023-04-25T05:41:27.317Z',
	// 		},
	// 		{
	// 			_id: '6447684103f6dafbd1a0921d',
	// 			createdAt: '2023-04-25T05:42:25.782Z',
	// 		},
	// 		{
	// 			_id: '644bdd4d03f6dafbd1a0936b',
	// 			createdAt: '2023-04-28T14:50:53.301Z',
	// 		},
	// 		{
	// 			_id: '64568b3370a02c4642346ea9',
	// 			createdAt: '2023-05-06T17:15:31.963Z',
	// 		},
	// 		{
	// 			_id: '645c6d5dc5607cc17be8c4e2',
	// 			createdAt: '2023-05-11T04:21:49.538Z',
	// 		},
	// 		{
	// 			_id: '6488062fcc23f471c0739a9f',
	// 			createdAt: '2023-06-13T06:01:19.714Z',
	// 		},
	// 		{
	// 			_id: '64ac39331049d9b38ed9914f',
	// 			createdAt: '2023-07-10T17:00:35.739Z',
	// 		},
	// 		{
	// 			_id: '64ac3ce51049d9b38ed991cf',
	// 			createdAt: '2023-07-10T17:16:21.619Z',
	// 		},
	// 		{
	// 			_id: '64b3bded1049d9b38ed99219',
	// 			createdAt: '2023-07-16T09:52:45.925Z',
	// 		},
	// 		{
	// 			_id: '64b514e894a50c672d4925ab',
	// 			createdAt: '2023-07-17T10:16:08.365Z',
	// 		},
	// 		{
	// 			_id: '64b8bc1594a50c672d492729',
	// 			createdAt: '2023-07-20T04:46:13.914Z',
	// 		},
	// 		{
	// 			_id: '64be532094a50c672d492943',
	// 			createdAt: '2023-07-24T10:32:00.981Z',
	// 		},
	// 		{
	// 			_id: '64cb64d39a8eee367468d720',
	// 			createdAt: '2023-08-03T08:26:59.538Z',
	// 		},
	// 	],
	// };

	// console.log(filteredLabelsForChart('allTime'));
	// console.log(filteredDataForChart('allTime', data.patients));

	return (
		<div className='mt-4 p-3 w-full h-[300px]'>
			<Line
				data={dummyData}
				options={{
					responsive: true,
					maintainAspectRatio: false,
					elements: {
						point: {
							radius: 0,
						},
					},
					scales: {
						y: {
							beginAtZero: true,
							grid: {
								display: false,
							},
							ticks: {
								stepSize: 1,
							},
						},
						x: {
							grid: {
								display: false,
							},
						},
					},
				}}
			/>
		</div>
	);
};

export default LineChart;
