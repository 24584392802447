import React from 'react';
import './AboutUs.scss';
import { AiOutlineArrowRight } from 'react-icons/ai';

const AboutUs = () => {
	return (
		<div className='about'>
			<div className='content'>
				<div className='about_nav'>
					<AiOutlineArrowRight className='text-4xl' />
				</div>
				<div>
					<h1 className='about_title'>About Us</h1>
				</div>
				<div className='details'>
					<p>
						"<b>Romatoo</b> provides healthcare solutions and
						advanced technology (AI and cloud-based solutions) for
						the new generation of people and healthcare
						professionals worldwide."
					</p>

					<p>
						It acts as a bridge between doctors and patients across
						the globe. As a result, any person can get the desired
						medical care through our platform anytime, anywhere.
						Similarly, a doctor can provide accurate and correct
						medical care using our platform.
					</p>

					<p>
						Our next step is to make our app's existing AI bot more
						efficient. So that in case of a medical crisis due to
						natural calamities such as epidemics, economic downturns
						such as lack of money, war or political unrest, etc.,
						ordinary people can seek medical care with the help of
						our AI bot instead of doctors in the early stages of the
						disease. Similarly, they can use this same bot as their
						personal doctor.
					</p>

					<p>
						N:B: The artificial intelligence bot we developed can
						analyze people's various health-related parameters
						in-depth and provide them with proper medical care.
					</p>
					<p>
						Romatoo's central vision is to use technology to provide
						fast and accurate healthcare to people worldwide.
					</p>

					<p>
						<b>Romatoo</b> is your best-health companion.
					</p>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;
