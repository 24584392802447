import React from 'react';
import './PrivacyAgreement.scss';
import { AiOutlineArrowRight } from 'react-icons/ai';

const PrivacyAgreement = () => {
	return (
		<div
			className='doc_privacy'
			style={{
				background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/footer-content/doc-privacy.jpg') no-repeat center center/cover`,
			}}
		>
			<div className='doc_privacy_content'>
				<div className='doc_privacy_nav'>
					<AiOutlineArrowRight className='text-4xl' />
				</div>
				<div>
					<h1 className='doc_privacy_title'>
						Doctor's Privacy Agreement
					</h1>
				</div>
				<div className='details'>
					<h2 className='font-bold text-2xl'>Terms and Conditions</h2>

					<p>
						Thank you for using Romatoo services. These terms and
						conditions ("Terms") constitute a legally binding
						agreement between Romatoo ("Romatoo" and/or "we") and
						you as an independent individual, or on behalf of the
						entity you legally represent as applicable, or from you
						are using this Application (together referred to as
						"Service Provider", "You", "Your" or "Your").
					</p>

					<p>
						All references to the Terms include any conditions,
						guidelines and rules adopted/specified by Romatoo. BY
						USING THESE SERVICES, YOU ACKNOWLEDGE AND AGREE THAT
						Romatoo will treat your use of the Services as
						acceptance of the Terms and your obligation to comply
						with them.
					</p>

					<p>
						These Terms govern your access to and use of the
						website: www.romatoo.com, as well as any other website
						owned, operated, licensed or controlled by Romatoo
						(collectively, the "Website") and products, services or
						applications ("Application") provided or developed by
						Romatoo (together the "Services"). Furthermore, these
						Terms, as amended from time to time, govern your use of
						any electronic services that may be provided and
						obtained by Romatoo, as well as any service that
						requires internet access, the original account of
						Romatoo or any other account you use to obtain
						electronic products or services Offered by Romatoo.
					</p>

					<h2 className='font-bold text-2xl'>Our Services</h2>

					<p>
						Our service consists of a technology platform that
						allows users of the Romatoo app or website to search for
						and book appointments for you as an independent
						healthcare provider and service providers subscribed to
						our Platform, including clinics, hospitals, medical
						personnel, laboratories and other health services. In
						other words, we allow users to easily access service
						providers for medical treatment.
					</p>

					<p>
						It is essential that you understand that you are
						entirely and exclusively responsible for providing users
						with the best medical treatment advice appropriate to
						their medical needs, and for providing the correct and
						effective treatment and medical advice they seek from
						you. We do not practice or intend to practice medicine,
						but rather we provide assistance in establishing contact
						between you and users who wish to obtain medical advice
						and appropriate treatment. You can communicate with a
						wide range of users who are seeking medical advice from
						you.
					</p>

					<p>
						These services are not intended for use by you in the
						event that you or the entity to which you belong or the
						entity you represent is not legally authorized to
						practice your profession/professional or to engage in
						your activities(s). By agreeing to these Terms, you
						represent and warrant to Romatoo that you and the entity
						to which you belong are legally authorized to carry on
						your professional activities/activities.
					</p>

					<p>
						You agree that the materials available through our
						services are for informational purposes or to facilitate
						user activities only, and are not a substitute for
						professional medical advice. The information and
						services on the website are provided only for activities
						related to the medical profession and are not intended
						to be provided directly to patients. Romatoo, its
						licensor and its suppliers are not responsible for any
						breaches of disclosures of content available on the
						Website to any third parties, including but not limited
						to patients.
					</p>

					<h2>License</h2>

					<p>
						By using our services, we hereby grant you a limited,
						revocable, non-exclusive, non-sublicensable right to:
						(i) access, download and use our Website for the sole
						purpose of using our Services, and (ii) view the
						information and materials provided or available or
						otherwise contained on our content and its use.
					</p>

					<p>
						This License is not intended to be assigned, sold,
						leased, exported, imported, distributed, transferred or
						otherwise granted to third parties. It is not intended
						for commercial use. You are authorized to use our
						Services for personal, non-profit, non-commercial use
						only.
					</p>

					<p>
						You may not detect, scan, or test the vulnerability of
						any system or network or otherwise use any means to
						circumvent security or verification procedures; obtain,
						tamper with, or use non-public or common service areas
						that you have not been invited to, or the computer
						systems of Romatoo or of Romatoo's service providers;
						disrupt or disrupt any user, host or network, for
						example by sending a virus, overloading, flooding,
						spamming, or email bombing of any part of the services;
						plant malicious software or otherwise using the services
						to spread malware or access or search for the services
						by any means other than our publicly supported
						interfaces (eg "web scraping"); send unsolicited
						messages, promotions, sponsored ads or spam emails, or
						send false, misleading or altered information, including
						phishing (spoofing) messages. Post any material that is
						false, misleading or infringes the rights of others:
						promote or advertise products or services other than
						your own without obtaining the necessary permission.
						Impersonate or misrepresent your affiliation with a
						person or entity: Post or share material that is
						illegal, pornographic, indecent, advocates intolerance
						or religious, racial or ethnic hatred, or otherwise
						violates the law or the privacy or reputation of others.
						promote or advertise products or services other than
						your own without the necessary authorization.
						Impersonate or misrepresent your affiliation with a
						person or entity: Post or share material that is
						illegal, pornographic, indecent, advocates intolerance
						or religious, racial or ethnic hatred, or otherwise
						violates the law or the privacy or reputation of others.
						promote or advertise products or services other than
						your own without the necessary authorization.
						Impersonate or misrepresent your affiliation with a
						person or entity: Post or share material that is
						illegal, pornographic, indecent, advocates intolerance
						or religious, racial or ethnic hatred, or otherwise
						violates the law or the privacy or reputation of others.
					</p>

					<h2>Limitations</h2>

					<p>
						Service providers may not detect, scan, or test the
						vulnerability of any system or network, or otherwise use
						any means to circumvent security or verification
						procedures; obtain, manipulate, or use non-public
						service areas or common service areas, or the computer
						systems of Romatoo or of Romatoo's service providers;
						jam or disrupt any host or network, or overload,
						overflow, spam or email any part of the Services; plant
						malware or otherwise by using the Services to spread
						malware or access or search for the Services by any
						means other than our publicly supported interfaces; send
						unsolicited messages, promotions, sponsored ads or spam
						emails, or sow false or misleading information,
						including phishing messages (sarcasm); or post any
						material that is false, misleading or infringing the
						rights of others: promote or advertise products or
						services other than their own; or share illegal,
						indecent or defamatory material, or share content that
						promotes religious, racial or ethnic hatred or
						intolerance.
					</p>

					<p>
						Subject to these terms, you are hereby granted a
						limited, revocable, non-exclusive right to use the
						Content and Materials available on the application in
						the normal course of using the application. You may not
						use the intellectual property rights of others without
						the express written permission of the third party,
						except as permitted by law.
					</p>

					<h2>Service provider registration and passwords</h2>

					<p>
						In order to obtain the service provider's features
						relating to the services, you must register with Romatoo
						and create an account by following the online
						registration process on the website. Your account
						enables you to access services and operations that we
						may establish and operate from time to time and in our
						sole discretion. By creating an account, you must
						provide Romatoo with accurate and complete information
						as set out in the registration form. You must notify
						Romatoo immediately if such information changes. If you
						do not provide or update this information, Romatoo may
						in its sole discretion terminate your right to use the
						application or services.
					</p>

					<p>
						If you are dealing with Romatoo for the first time and
						have duly entered your data, please contact Romatoo to
						open an account for your practice, and in order to use
						our services, you must sign ("Service Provider
						Agreement") where you undertake to legally comply with
						these terms and download our mobile application In
						addition, you must provide permits, certificates and
						licenses evidencing that you are duly authorized to
						practice medicine, along with your personal information
						(as well as your "file") to Romatoo. Additional fees may
						require.
					</p>

					<p>
						When you open your account, you will be asked to choose
						a password. You must keep your password confidential and
						under no circumstances should you respond to a request
						for your password, especially if the request is from an
						individual claiming to be an employee of Romatoo.
						Romatoo employees will never ask for your password. You
						must notify Romatoo if you receive such a request.
					</p>

					<p>
						You are responsible for any use of your password and
						account, including without limitation any use by an
						unauthorized third party. You must notify Romatoo
						immediately if you believe that an unauthorized person
						or entity has obtained, accessed or used your password
						or account. Furthermore, you must notify Romatoo
						immediately if you become aware of any breach or
						attempted breach of the security of the application.
					</p>

					<p>
						You are responsible for any activity using your account,
						whether or not you have authorized such activity. You
						acknowledge that if you wish to protect the transmission
						of your data or files to Romatoo, you are responsible
						for using an encrypted and secure connection to obtain
						and use the services. Failure to log in three times in a
						row will result in your login information being
						suspended, which will require you to contact Romatoo via
						email to re-verify your account.
					</p>

					<p>
						We have taken commercially reasonable technical and
						organizational measures designed to protect the security
						of your personal information from accidental loss,
						unauthorized access, use, modification or disclosure.
						However, we cannot guarantee that unauthorized third
						parties will not be able to breach these measures or use
						your personal information for illegal purposes. You
						acknowledge that you are providing your personal
						information at your own risk.
					</p>

					<p>You should take into account the following:</p>

					<small>
						Your personal data, permits and licenses must be
						accurate, complete and up-to-date at all times. If you
						fail to do so, you acknowledge that Romatoo has the
						right to terminate your use of the services.
					</small>

					<small>
						You must choose a password to access your user account,
						and you must keep the password confidential at all
						times.
					</small>

					<small>
						You agree that you are responsible for any
						actions/activities of unauthorized third parties on your
						Service Provider account. You must notify us if you have
						a good faith belief that your account has been hacked.
					</small>

					<small>
						Under no circumstances should you respond to a request
						for your password, especially if the request is from a
						person claiming to be a Romatoo employee.
					</small>

					<small>
						You are not entitled to authorize, assign or transfer
						your service provider's account to a third party.
					</small>

					<small>
						You agree that you will be prevented from accessing your
						account if you fail to enter your password twice in a
						row.
					</small>

					<h2>Approval of Messages Received from Romatoo</h2>

					<p>
						By registering as a service provider and providing
						Romatoo with your email address, you consent to us using
						your email address to send you notices related to the
						services, including any notices required by law, changes
						to features and special offers in lieu of communication
						by mail. You may not accept emails related to the
						Services by opting out of this feature.
					</p>

					<h2>Third Party Sites</h2>

					<p>
						The Application may contain links to third party
						websites, advertising companies or services that are not
						owned or controlled by Romatoo. Romatoo does not control
						and is not responsible for the content, privacy
						policies, or practices of any third party websites or
						services, and Romatoo's privacy policy does not apply to
						your use of such websites. If you access a website from
						the Romatoo website, you will be responsible for it. You
						expressly release Romatoo from any liability arising out
						of your use of any third party websites or services or
						content owned by such third parties. In addition, any
						dealings with or participation in the promotions of
						advertising companies on the Website, including payment,
						delivery of goods and any other terms (such as
						warranties), are solely between you and the advertising
						companies. You agree that Romatoo.
					</p>

					<h2>Content Produced by the Service Provider</h2>

					<p>
						By using our services, you provide us with information,
						files and folders that you provide to Romatoo (together,
						your "file"). By posting, storing, or transmitting any
						content on or to the application, including by
						submitting Provider-Generated Content on the website,
						you accept to grant to Romatoo or to represent or
						warrant that the owner of such content has expressly
						granted Romatoo a right and an exclusive, transferable,
						sub-licensable, royalty-free, perpetual, irrevocable
						license to use, reproduce, publish, translate,
						sublicense, copy, modify, delete, develop and publish
						such Content for inclusion of the same content on the
						Romatoo platform, including but not limited to the
						website of Romatoo. Such inclusion includes the
						processing of content, including but not limited to
						products, features, thumbnails or document previews, or
						design choices we make to technically manage our
						services, for example how we frequently backup data to
						protect its security. You give us the permissions we
						need to do all of the above for the sole and exclusive
						purpose of providing and developing the Services. This
						authorization also extends to trusted third parties.
					</p>

					<p>
						You acknowledge that Romatoo's use of the content on the
						Romatoo platform does not constitute a violation of your
						intellectual property rights, copyrights or trademarks.
						Romatoo acknowledges that you have obtained your prior
						consent to disclose any content on any platforms other
						than Romatoo. If the user refuses to consent to such
						disclosure, this refusal prevents Romatoo from
						disclosing the content. You retain full ownership of
						your file or the format in which it is created by
						Romatoo as the content produced by the service provider.
					</p>

					<p>
						You understand and agree that the relationship between
						you and Romatoo is a management agreement for data
						collection, acquisition, acquisition, transfer,
						processing, merging and migration of data and that you
						have provided such data to Romatoo for this purpose.
						Aside from the rare exceptions that we make clear in our
						privacy policy, and no matter how much the services
						change, we do not share your content with law
						enforcement authorities for any purpose, unless you
						instruct us to do so. You are solely responsible for the
						accuracy, completeness, correctness, and legality of the
						files, postings, or other information you provide to
						Romatoo. The services offer features that allow you to
						share your content with other people who work under your
						supervision, which allows them to view the content file,
						copy, modify or even share the content. Therefore, we
						advise you to be careful with the content you share with
						your employees. You hereby acknowledge and agree that
						Romatoo has the right to.
					</p>

					<h2>Content Generated by Romatoo</h2>

					<p>
						Content created by Romatoo includes all materials
						available on the application through the services,
						including but not limited to logos, design, text and
						graphics, along with their selection, arrangement, and
						organization. The content also includes Romatoo's
						product names, logos, designs and labels.
					</p>

					<p>
						Further, the content generated by Romatoo covers all
						page headers, custom graphics, icons, text, trademarks
						or trade dress of Romatoo. If you use this content, it
						must include appropriate personalization. All other
						trademarks, trade names and the like that appear on the
						application are the property of their respective owners.
						You may not use any of the trademarks, trade dress,
						trade names, or any other confusingly similar marks,
						dress or names without the express prior written consent
						or ownership of Romatoo.
					</p>

					<p>
						You may choose or we may invite you to provide comments,
						testimonials, feedback, suggestions, ideas and other
						opinions about the services, including without
						limitation how to develop the services or our products
						("Ideas"). By submitting an idea, you acknowledge that
						your disclosure of such idea is free and unsolicited,
						will not place Romatoo under any fiduciary and other
						related obligations, and that Romatoo is entitled to
						disclose ideas based on non-confidentiality to any
						person or this is for the use of the ideas without any
						additional compensation being paid to you. Disclosure,
						submission or display of any ideas constitutes a
						perpetual, royalty-free, royalty-free, worldwide and
						irrevocable license, ownership, interest in all patents,
						copyrights, trademarks, and all other intellectual
						property rights and other rights relating to the Ideas
						of any nature, and a waiver of any claim based on moral
						rights, unfair competition and any other legal basis.
						You do not have to provide any ideas to us if you do not
						wish to grant us a license to these rights. You are and
						will remain solely responsible for the content of any
						ideas you create.
					</p>

					<h2>Your responsibilities</h2>

					<p>
						Other content on the services may be protected by the
						intellectual property rights of others, and such content
						is confidential. Please do not copy, upload or share
						these files. You are solely responsible for materials
						that you copy, share, upload, upload or otherwise use
						during your use of the services.
					</p>

					<p>
						You are fully responsible for maintaining, protecting
						and ensuring the integrity of all your data. Romatoo
						shall not be liable for any loss or damage to your data,
						any costs or expenses related to making backup copies or
						restoring your data, or compensation for any damages
						incurred by you due to the lack of protection for your
						data.
					</p>

					<p>
						You may not use the services to enter into, attempt to
						enter into, perform or attempt to perform any agreement,
						coordinate the prices, frequency or quantities of any
						product or service offered by Romatoo, or otherwise
						interfere or attempt to interfere with setting prices,
						restricting production or sharing clients or markets.
						You may not use the services, whether directly or
						indirectly, to engage in any unfair, misleading or
						anti-competitive practices, or otherwise violate
						applicable customer protection, competition or antitrust
						laws or regulations.
					</p>

					<p>
						Romatoo provides accessible services on various machines
						and devices, including but not limited to certain mobile
						phones and computers that you will make available.
						Romatoo is not responsible for providing the required
						equipment or telecommunication facilities, ie internet
						connection or telecommunication service.
					</p>

					<h2>Refunds</h2>

					<p>
						The fees paid by you are final and non-refundable unless
						Romatoo decides otherwise.
					</p>

					<p>
						Refunds will be made through the same payment method, if
						applicable.
					</p>

					<p>
						Romatoo reserves the right to refund any amounts to
						users account for use in other services at its sole
						discretion.
					</p>

					<p>
						Refund does not apply if the user is not satisfied with
						the medical service provided.
					</p>

					<p>
						The User is entitled to a full refund of the amount paid
						in the following cases:
					</p>

					<small>
						If the User cancels the reservation before the
						reservation is due.
					</small>

					<small>
						In the event that the Service Provider cancels the
						reservation, or does not attend the date for which the
						User has paid the fees.
					</small>

					<h2>User Confidentiality</h2>

					<p>
						We hold the privacy and confidentiality of the personal
						data of our users sacred. However, we understand the
						nature of the service you provide and how important and
						necessary it is for you to identify certain personal
						information.
					</p>

					<p>
						Therefore, we hereby provide you with access to data
						that is necessary to carry out your services, in
						particular, usernames, phone numbers and email
						addresses. However, the right to access the data is
						exercised for the sole purpose of promoting internal
						operations and not for promotional purposes.
					</p>

					<p>
						In the event of a breach of the foregoing, you
						acknowledge that you are responsible, defend, protect,
						indemnify and hold Romatoo from any liability for all
						claims, actions or suits arising from damages, losses,
						injuries, costs or expenses of any kind incurred by
						users due to a disclosure made service about their
						personal information.
					</p>

					<h2>Intellectual Property</h2>

					<p>
						Romatoo respects the intellectual property rights of
						third parties and expects its users to do the same.
						Romatoo may remove content that it, in its sole
						discretion, deems to infringe the intellectual property
						rights of others. In addition, Romatoo may, in its sole
						discretion, terminate the accounts of users who infringe
						the intellectual property rights of others. If you
						believe that a user of the application or the services
						has infringed the copyrights or trademarks of you or a
						third party, please notify Romatoo by email or by
						contacting Romatoo's Legal Department.
					</p>

					<p>
						Romatoo retains ownership of its intellectual property
						rights, and you may not acquire any rights in this
						respect except as expressly provided in written consent.
						You may not use, copy, display, perform, create
						derivative works, distribute, transfer or sublicense any
						materials or content available on the application except
						as may be reasonably necessary to use the services for
						their intended purposes and within the limits of what
						these terms expressly state. You may not attempt to
						reverse engineer the technology used to provide the
						services. If you consider any action in violation of
						these Terms, please contact Romatoo and notify them.
					</p>

					<p>
						Our Platform may contain links to third party websites,
						advertisements or services that are not owned by
						Romatoo. You acknowledge and agree that such content may
						be subject to different terms of use and privacy
						policies than the terms of use and privacy policies of
						Romatoo. Therefore, these Terms do not apply to you when
						obtaining or using third party services. If you access
						third party websites from our Services, you do so at
						your own risk, and you agree to release Romatoo from all
						liability arising from such use.
					</p>

					<h2>REPRESENTATIONS AND WARRANTIES</h2>

					<p>
						(i) you have full power to enter into and perform the
						obligations under these Terms; (ii) that your acceptance
						of and performance of the obligations you have under
						these Terms do not constitute a breach of, or a conflict
						with, any other legal obligations, agreements,
						arrangements, or any applicable laws, regulations or
						rules, or any judgments or judicial decisions that we
						are bound to enforce ; (iii) These Terms constitute
						legal, valid and binding obligations to you and are
						valid in accordance with the Terms and Conditions; (iv)
						you may not infringe the copyright, trademark, trade
						secret, right of publicity or other intellectual
						property rights of any third party while using the
						Website or the Services, or the right to privacy of your
						customers/patients; (v) you undertake to comply with all
						applicable laws, regulations and rules in connection
						with your use of the Services and the Website, including
						these Terms; (6) that you are a physician duly qualified
						in your field(s) of practice.
					</p>

					<h2>Disclaimer of Warranty</h2>

					<p>
						You agree that you are solely responsible for your use
						of the application. Romatoo, its officers, directors,
						employees, agents and shareholders disclaim all
						warranties, express or implied, with respect to the
						Services and your use of them.
					</p>

					<p>
						Romatoo, to the fullest extent permitted by law, does
						not warrant, accept or be responsible for any products
						or services advertised or made available by third
						parties through the hyperlinked services or services
						displayed in any banners or other advertising means, and
						that Romatoo will not be a party or otherwise
						responsible for monitoring any transactions between you
						and any third party.
					</p>

					<h2>Limitation of Liability</h2>

					<p>
						You agree that you are responsible for providing
						accurate and professional medical treatment to the user
						by qualified and highly skilled healthcare providers.
					</p>

					<p>
						Accordingly, under no circumstances shall Romatoo be
						liable for the user's dissatisfaction with the
						consultation and medical examination conducted by you,
						nor his/her dissatisfaction with your experience in
						general.
					</p>

					<p>
						Others, and whether the company has been warned about
						the possibility of such damages. The foregoing
						limitation of liability is valid to the extent permitted
						by law.
					</p>

					<p>
						You expressly acknowledge that Romatoo is not
						responsible for any defamatory, abusive or illegal
						actions of third parties, and that all liability for
						risks or damages arising from the foregoing lies
						entirely with you.
					</p>

					<p>
						Furthermore, Romatoo provides and operates the services
						from its facilities. Romatoo makes no representations
						that the service is appropriate or available for use in
						other locations. Persons who obtain or use the service
						from other locations do so at their own risk and are
						responsible for compliance with their local law.
					</p>

					<h2>Compensation</h2>

					<p>
						You agree, to the extent permitted by applicable law, to
						defend, indemnify and hold Romatoo, its representatives,
						officers, directors, employees, agents, and shareholders
						("Indemnity Receiving Parties") harmless from any
						damages, losses, costs or expenses (including without
						limitation (but not limited to attorneys' fees and
						costs) incurred in connection with any claims, demands
						or actions brought or brought against any of the
						indemnity-receiving parties, arising out of or related
						to: (i) your access to and use of the Services; (ii)
						your violation of any provision of these Terms; (iii)
						claim facts or circumstances that constitute your breach
						of any provisions of these Terms, including violations
						of law or allegations of breach of privacy, publicity,
						or intellectual property rights relating to User
						Generated Content you provide or Romatoo generated
						content ; (iv) arising or in connection with your use of
						the Website or the Services which constitutes a
						violation of any third party rights, including without
						limitation any copyright, proprietary right, or right of
						privacy; (5) Third party use of and access to the
						Services through the use of your user name, password, or
						any other secret code.
					</p>

					<p>
						In the event that you are required to pay damages under
						this provision, Romatoo may, in its sole and absolute
						discretion, control the disposition of any Claim at your
						expense. Without prejudice to the foregoing, you may not
						settle, compromise, or otherwise dispose of any claim
						without the prior written consent of Romatoo.
					</p>

					<p>
						You represent and warrant to defend, protect, indemnify
						and hold Romatoo, its officers and employees harmless
						from all claims, injuries, damages, losses, or legal
						claims, including attorneys' fees, arising out of or in
						connection with any fraudulent, deceptive, misleading,
						forgery, or fraudulent conduct; It is generally
						considered illegal to commit it for whatever reasons.
					</p>

					<p>
						The service provider shall defend, indemnify and hold
						Romatoo, its officers and employees, and hold them
						harmless from all claims, injuries, damages, losses, or
						legal claims, including attorneys' fees, arising out of
						or in connection with any actions, errors or omissions
						by the User.
					</p>

					<p>
						In addition, you acknowledge that you are entirely
						responsible for the accuracy of the diagnosis of a
						patient with whom you interact with Romatoo services or
						the treatment you provide to them. Romatoo may not be
						held accountable, under any circumstances, for any
						medical error, malpractice, or gross negligence
						committed by the doctor, since Romatoo is the only one
						who plays the role of mediator between the doctor and
						the patient.
					</p>

					<h2>Force Measure</h2>

					<p>
						Romatoo shall not be liable for failure to fulfill its
						obligations under these terms in the event of any event
						occurring outside its reasonable control, including but
						not limited to fire, terrorist acts, natural disasters,
						wars, or boycotts, labor disturbances, internet or
						communications interruption, service interruption, or
						failure of any of Romatoo's service providers to fulfill
						its obligations.
					</p>

					<h2>Termination and Annulment</h2>

					<p>
						Romatoo may, in its sole discretion and for any reason,
						terminate your account or your access to the application
						or services, without any liability to you or any third
						party. These reasons may include, but are not limited
						to: (i) your breach of any part of these Terms; (ii)
						your violation of the rights of others; (iii) your
						credit card is not valid, the credit card limit is
						exceeded, or you "charge" any other fees or payments;
						(4) Inactivity of your Service Provider account for an
						extended period of time.
					</p>

					<div class='content'>
						<p>
							<span>Effect of Account Termination:</span>If your
							account is terminated, Romatoo may, in its sole
							discretion, delete any sites, files, graphics, or
							other content or materials relating to your use of
							the Website or the Services available on servers
							owned or operated by Romatoo, or otherwise held by
							Romatoo. Another way, and Romatoo assumes no
							responsibility for doing this to you or any third
							party. After account termination, you will not be
							permitted to use the website or the services. In the
							event that your account or access to the website or
							services is terminated, Romatoo reserves the right
							to use any means it deems necessary to prevent
							unauthorized access to the website or services,
							including but not limited to technical barriers and
							IP address redirection. and direct communication
							with your internet service provider.
						</p>
					</div>

					<h2>Miscellaneous Provisions</h2>

					<p>
						These Terms constitute the entire agreement and
						understanding between you and Romatoo regarding the use
						of the application, and supersede any prior and
						contemporaneous agreements and understandings between
						you and Romatoo regarding the subject matter of the
						agreement. These Terms are binding on each party and its
						successors and authorized assigns. The failure or delay
						of either party to exercise any right, power or
						privilege set forth in these terms shall not be deemed a
						waiver thereof, and no exercise in whole or in part of
						any right, power or privilege of the other shall prevent
						the further exercise of it or the exercise of any right
						or power or other privilege provided by these terms.
					</p>

					<p>
						You and Romatoo are independent contractors. Your
						relationship with Romatoo in no way constitutes an
						agency, partnership, joint venture, or employer-employee
						relationship.
					</p>

					<p>
						The invalidity or un-enforceability of any of the
						provisions of these terms shall not affect the validity
						or enforceability of any other provisions of these
						terms, and all provisions shall be in full force and
						effect.
					</p>

					<p>
						These Terms and Conditions have been agreed and drawn up
						in the English and Bangla languages, and the Contract
						and the Terms are in identical copies; An original copy
						was given to each party to act upon. In the event of a
						difference or conflict between the English and Bangla
						versions or between the contract and the terms, the
						terms and the English version shall prevail.
					</p>

					<p>
						No agreement or contract will be allowed before two
						years of this agreement or contract between Romatoo and
						any other respective party or client.
					</p>

					<p>
						Romatoo reserves the right to update, change or modify
						these terms at any time and for any reason, by posting
						the revised version on the Application so that the
						revised terms apply to all Romatoo services, and become
						effective immediately after its publication on the
						Application. In other words, the service provided to you
						is subject to the terms existing on the date the service
						was provided. Your acceptance of the revised Terms shall
						be deemed to continue your access and use of the
						Application and the Services contained therein as of the
						effective date of such modification.
					</p>

					<h2>Regulating Law and Jurisdiction</h2>

					<p>
						These Terms shall be governed by and construed in
						accordance with the laws of Bangladesh. Any dispute
						arising out of or relating to the interpretation and/or
						implementation of this Agreement shall be finally
						resolved by the laws of Bangladesh in accordance with
						the in force at the time of the dispute. Romatoo
						reserves the right to exercise its discretion to
						initiate proceedings before one of the courts whose
						jurisdiction extends to the service provider.
					</p>
				</div>
			</div>
		</div>
	);
};

export default PrivacyAgreement;
