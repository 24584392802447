import React from 'react';
import { navIcon, navData1 } from '../../assets';
import { Link, useNavigate } from 'react-router-dom';
import { IoClose, IoChevronUp, IoSearchOutline } from 'react-icons/io5';
import './NavbarModal.scss';

const navData2 = {
	item: "Explore Disease's",
	sub: [
		{
			name: 'Mental Health',
			sub: ['Anxiety', 'Depression', 'Insomnia', 'Drug Addiction'],
		},
		{
			name: 'Skin & Hair Health',
			sub: ['Pimple & Skin Infection', 'Skin Allergy', 'Hair Loss'],
		},
		{
			name: 'Pain',
			sub: ['Migraine', 'Back & Joint Pain', 'Muscles & Sports Pain'],
		},
		{
			name: 'General Health',
			sub: ['Cold & Fever', 'Gastric & Vomiting', 'Defecation', 'Weak & Tired'],
		},
		{
			name: "Women's Health",
			sub: ['UTI', 'Mensuration', 'Birth Control', 'Infertility Care'],
		},
		{
			name: "Sex & Men's health",
			sub: ['Erectile Dysfunction', 'Premature Ejaculation'],
		},
		{
			name: 'Heat & Diabetes',
			sub: ['Blood Pressure', 'Diabetes', 'Heat', 'Nutrition & Diet'],
		},
		{
			name: 'Other',
			sub: [
				'Child / Pediatric',
				'Chest & Asthma',
				'Dental',
				'Eye',
				'ENT / Head / Neck',
			],
		},
	],
};

const NavbarModal = ({ handleChange }) => {
	const navigate = useNavigate();

	const handleSearch = (query) => {
		handleChange(false);
		navigate(`/search?query=${query}`);
	};

	return (
		<div className='navbar__modal'>
			<button className='btn-close' onClick={() => handleChange(false)}>
				<IoClose className='icon' />
			</button>

			<div className='modal__container'>
				<div className='search__container'>
					<input
						type='text'
						name='search'
						id='search'
						placeholder='Search anything health related...'
					/>
					<IoSearchOutline className='icon' />
				</div>

				<div className='for__users'>
					<Link to='/forDoctors'>For Doctors</Link>

					<Link to='/forUsers'>For Users</Link>

					<Link to='/useAdvancedAI'>Use Advanced AI</Link>
				</div>

				{/* <div className='nav__one'>
					<div className='header__bar'>
						<h1>{navData1.name}</h1>

						<IoChevronUp className='icon' />
					</div>

					<div className='nav__links'>
						{navData1.sub.map((x, i) => (
							<button
								className='nav__link'
								key={i}
								onClick={() => handleSearch(x.title)}
							>
								<img src={x.img} alt='icon' />
								<span>{x.title}</span>
							</button>
						))}
					</div>
				</div>

				<div className='nav__two'>
					<div className='header__bar'>
						<h1>{navData2.item}</h1>

						<IoChevronUp className='icon' />
					</div>

					{navData2.sub.map((nav, i) => (
						<div className='nav__links__container' key={i}>
							<div className='title__bar'>
								<h1>{nav.name}</h1>

								<IoChevronUp className='icon' />
							</div>

							<div className='nav__links'>
								{nav.sub.map((item, i) => (
									<button
										className='nav__link'
										key={i}
										onClick={() => handleSearch(item)}
									>
										{item}
									</button>
								))}
							</div>
						</div>
					))}
				</div> */}
			</div>
		</div>
	);
};

export default NavbarModal;
