import * as React from 'react';

const SvgComponent = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 1200 1200'
	>
		<defs>
			<clipPath id='clip-path'>
				<circle className='cls-1' cx='228.113' cy='931.787' r='130' />
			</clipPath>
			<clipPath id='clip-path-2'>
				<circle className='cls-1' cx='939.035' cy='983.983' r='160' />
			</clipPath>
			<clipPath id='clip-path-3'>
				<circle className='cls-1' cx='973.25' cy='329.751' r='130' />
			</clipPath>
			<clipPath id='clip-path-4'>
				<circle className='cls-1' cx='280.418' cy='258.832' r='160' />
			</clipPath>
		</defs>
		<g id='artwork'>
			<g id='circle-4'>
				<g>
					<circle className='cls-1' cx='228.113' cy='931.787' r='130' />
					<g className='cls-2'>
						<image
							width='1600'
							height='1200'
							transform='translate(78.113 778.787) scale(0.238)'
							xlinkHref='/circle_animation/circle-4.jpg'
						/>
					</g>
				</g>
				<g id='dot-circle'>
					<circle className='cls-3' cx='239.245' cy='782.195' r='3' />
					<circle className='cls-3' cx='251.121' cy='783.542' r='3' />
					<circle className='cls-3' cx='262.968' cy='785.859' r='3' />
					<circle className='cls-3' cx='274.461' cy='789.086' r='3' />
					<circle className='cls-3' cx='285.562' cy='793.183' r='2.999' />
					<circle className='cls-3' cx='296.232' cy='798.111' r='2.999' />
					<circle className='cls-3' cx='306.43' cy='803.831' r='2.999' />
					<circle className='cls-3' cx='316.117' cy='810.303' r='2.999' />
					<circle className='cls-3' cx='325.254' cy='817.487' r='2.999' />
					<circle className='cls-3' cx='333.8' cy='825.344' r='2.999' />
					<circle className='cls-3' cx='341.67' cy='833.779' r='2.999' />
					<circle className='cls-3' cx='348.869' cy='842.788' r='2.999' />
					<circle className='cls-3' cx='355.371' cy='852.343' r='2.999' />
					<circle className='cls-3' cx='361.137' cy='862.405' r='2.999' />
					<circle className='cls-3' cx='366.128' cy='872.936' r='2.998' />
					<circle className='cls-3' cx='370.306' cy='883.898' r='2.998' />
					<circle className='cls-3' cx='373.632' cy='895.251' r='2.998' />
					<circle className='cls-3' cx='376.068' cy='906.958' r='2.998' />
					<circle className='cls-3' cx='377.574' cy='918.978' r='2.998' />
					<circle className='cls-3' cx='378.113' cy='931.275' r='2.998' />
					<circle className='cls-3' cx='377.656' cy='943.595' r='2.998' />
					<circle className='cls-3' cx='376.227' cy='955.644' r='2.998' />
					<circle className='cls-3' cx='373.865' cy='967.381' r='2.998' />
					<circle className='cls-3' cx='370.609' cy='978.768' r='2.998' />
					<circle className='cls-3' cx='366.498' cy='989.766' r='2.998' />
					<circle className='cls-3' cx='361.569' cy='1000.337' r='2.998' />
					<circle className='cls-3' cx='355.863' cy='1010.442' r='2.998' />
					<circle className='cls-3' cx='349.416' cy='1020.042' r='2.998' />
					<circle className='cls-3' cx='342.269' cy='1029.099' r='2.998' />
					<circle className='cls-3' cx='334.46' cy='1037.573' r='2.998' />
					<circle className='cls-3' cx='326.027' cy='1045.427' r='2.997' />
					<circle className='cls-3' cx='317.009' cy='1052.621' r='2.997' />
					<circle className='cls-3' cx='307.446' cy='1059.117' r='2.997' />
					<circle className='cls-3' cx='297.374' cy='1064.875' r='2.997' />
					<circle className='cls-3' cx='286.834' cy='1069.859' r='2.997' />
					<circle className='cls-3' cx='275.864' cy='1074.028' r='2.997' />
					<circle className='cls-3' cx='264.502' cy='1077.344' r='2.997' />
					<circle className='cls-3' cx='252.788' cy='1079.769' r='2.997' />
					<circle className='cls-3' cx='240.76' cy='1081.263' r='2.997' />
					<circle className='cls-3' cx='228.456' cy='1081.788' r='2.997' />
					<circle className='cls-3' cx='216.139' cy='1081.317' r='2.997' />
					<circle className='cls-3' cx='204.094' cy='1079.875' r='2.997' />
					<circle className='cls-3' cx='192.361' cy='1077.501' r='2.997' />
					<circle className='cls-3' cx='180.979' cy='1074.233' r='2.997' />
					<circle className='cls-3' cx='169.987' cy='1070.11' r='2.997' />
					<circle className='cls-3' cx='159.422' cy='1065.171' r='2.997' />
					<circle className='cls-3' cx='149.324' cy='1059.453' r='2.997' />
					<circle className='cls-3' cx='139.731' cy='1052.997' r='2.997' />
					<circle className='cls-3' cx='130.682' cy='1045.841' r='2.997' />
					<circle className='cls-3' cx='122.216' cy='1038.023' r='2.997' />
					<circle className='cls-3' cx='114.371' cy='1029.581' r='2.998' />
					<circle className='cls-3' cx='107.187' cy='1020.556' r='2.998' />
					<circle className='cls-3' cx='100.701' cy='1010.985' r='2.998' />
					<circle className='cls-3' cx='94.952' cy='1000.907' r='2.998' />
					<circle className='cls-3' cx='89.98' cy='990.361' r='2.998' />
					<circle className='cls-3' cx='85.822' cy='979.385' r='2.998' />
					<circle className='cls-3' cx='82.518' cy='968.018' r='2.998' />
					<circle className='cls-3' cx='80.106' cy='956.299' r='2.998' />
					<circle className='cls-3' cx='78.625' cy='944.267' r='2.998' />
					<circle className='cls-3' cx='78.114' cy='931.96' r='2.998' />
					<circle className='cls-3' cx='78.598' cy='919.645' r='2.998' />
					<circle className='cls-3' cx='80.053' cy='907.605' r='2.998' />
					<circle className='cls-3' cx='82.44' cy='895.877' r='2.998' />
					<circle className='cls-3' cx='85.72' cy='884.5' r='2.998' />
					<circle className='cls-3' cx='89.854' cy='873.513' r='2.998' />
					<circle className='cls-3' cx='94.805' cy='862.954' r='2.998' />
					<circle className='cls-3' cx='100.532' cy='852.863' r='2.999' />
					<circle className='cls-3' cx='106.998' cy='843.277' r='2.999' />
					<circle className='cls-3' cx='114.164' cy='834.236' r='2.999' />
					<circle className='cls-3' cx='121.991' cy='825.778' r='2.999' />
					<circle className='cls-3' cx='130.441' cy='817.942' r='2.999' />
					<circle className='cls-3' cx='139.474' cy='810.767' r='2.999' />
					<circle className='cls-3' cx='149.053' cy='804.291' r='2.999' />
					<circle className='cls-3' cx='159.137' cy='798.553' r='2.999' />
					<circle className='cls-3' cx='169.69' cy='793.592' r='2.999' />
					<circle className='cls-3' cx='180.671' cy='789.446' r='2.999' />
					<circle className='cls-3' cx='192.043' cy='786.154' r='3' />
					<circle className='cls-3' cx='203.766' cy='783.754' r='3' />
					<circle className='cls-3' cx='215.803' cy='782.286' r='3' />
					<circle className='cls-3' cx='228.114' cy='781.788' r='3' />
				</g>
				<g id='big-dash'>
					<ellipse
						className='cls-4'
						cx='228.113'
						cy='928.735'
						rx='180.528'
						ry='180.465'
						transform='translate(-433.795 238.473) rotate(-29.999)'
					/>
					<path
						className='cls-5'
						d='M318.367,1084.939c-86.346,49.85-196.741,20.288-246.574-66.028'
					/>
				</g>
			</g>
			<g id='circle-3'>
				<g>
					<circle className='cls-1' cx='939.035' cy='983.983' r='160' />
					<g className='cls-6'>
						<image
							width='2000'
							height='1334'
							transform='translate(688.035 810.983) scale(0.26)'
							xlinkHref='/circle_animation/circle-3.jpg'
						/>
					</g>
				</g>
				<path
					id='stroke-path'
					className='cls-7'
					d='M813.6,848.7a184.519,184.519,0,0,0-34.306,227.507c50.936,88.223,163.746,118.45,251.968,67.515s118.45-163.745,67.515-251.967A184.518,184.518,0,0,0,884.6,807.709'
				/>
				<g id='dot-circle-2' data-name='dot-circle'>
					<g>
						<ellipse
							className='cls-3'
							cx='855.445'
							cy='1183.239'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='844.594'
							cy='1178.253'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='834.076'
							cy='1172.689'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='823.911'
							cy='1166.566'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='814.119'
							cy='1159.905'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='804.72'
							cy='1152.726'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='795.735'
							cy='1145.049'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='787.184'
							cy='1136.895'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='779.087'
							cy='1128.284'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='771.464'
							cy='1119.237'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='764.334'
							cy='1109.772'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='757.719'
							cy='1099.912'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='751.639'
							cy='1089.676'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='746.113'
							cy='1079.084'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='741.162'
							cy='1068.157'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='736.805'
							cy='1056.915'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='733.064'
							cy='1045.379'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='729.957'
							cy='1033.567'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='727.506'
							cy='1021.502'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='725.73'
							cy='1009.203'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='724.65'
							cy='996.69'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='724.285'
							cy='983.983'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='724.65'
							cy='971.277'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='725.73'
							cy='958.764'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='727.506'
							cy='946.465'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='729.957'
							cy='934.399'
							rx='3'
							ry='3.021'
						/>
						<ellipse
							className='cls-3'
							cx='733.064'
							cy='922.588'
							rx='3'
							ry='3.021'
						/>
					</g>
					<ellipse
						className='cls-8'
						cx='939.035'
						cy='983.983'
						rx='214.75'
						ry='216.25'
					/>
				</g>
				<g id='dash-circle'>
					<circle className='cls-8' cx='939.035' cy='983.983' r='217.5' />
					<g>
						<rect
							className='cls-9'
							x='936.107'
							y='773.558'
							width='16'
							height='4'
							rx='2'
							transform='matrix(0.039, -0.999, 0.999, 0.039, 131.877, 1688.307)'
						/>
						<rect
							className='cls-9'
							x='947.432'
							y='774.308'
							width='16'
							height='4'
							rx='2'
							transform='translate(93.991 1655.753) rotate(-84.687)'
						/>
						<rect
							className='cls-9'
							x='958.627'
							y='775.649'
							width='16'
							height='4'
							rx='2'
							transform='matrix(0.145, -0.989, 0.989, 0.145, 56.916, 1621.171)'
						/>
						<rect
							className='cls-9'
							x='969.673'
							y='777.567'
							width='16'
							height='4'
							rx='2'
							transform='translate(20.81 1584.541) rotate(-78.64)'
						/>
						<rect
							className='cls-9'
							x='980.551'
							y='780.05'
							width='16'
							height='4'
							rx='2'
							transform='translate(-14.175 1545.841) rotate(-75.645)'
						/>
						<rect
							className='cls-9'
							x='991.242'
							y='783.084'
							width='16'
							height='4'
							rx='2'
							transform='translate(-47.889 1505.054) rotate(-72.667)'
						/>
						<rect
							className='cls-9'
							x='1001.726'
							y='786.656'
							width='16'
							height='4'
							rx='2'
							transform='translate(-80.188 1462.164) rotate(-69.706)'
						/>
						<rect
							className='cls-9'
							x='1011.985'
							y='790.753'
							width='16'
							height='4'
							rx='2'
							transform='translate(-110.922 1417.153) rotate(-66.759)'
						/>
						<rect
							className='cls-9'
							x='1022'
							y='795.361'
							width='16'
							height='4'
							rx='2'
							transform='translate(-139.942 1370.004) rotate(-63.825)'
						/>
						<rect
							className='cls-9'
							x='1031.751'
							y='800.467'
							width='16'
							height='4'
							rx='2'
							transform='translate(-167.089 1320.707) rotate(-60.9)'
						/>
						<rect
							className='cls-9'
							x='1041.221'
							y='806.058'
							width='16'
							height='4'
							rx='2'
							transform='translate(-192.2 1269.25) rotate(-57.982)'
						/>
						<rect
							className='cls-9'
							x='1050.389'
							y='812.121'
							width='16'
							height='4'
							rx='2'
							transform='translate(-215.098 1215.631) rotate(-55.067)'
						/>
						<rect
							className='cls-9'
							x='1059.237'
							y='818.642'
							width='16'
							height='4'
							rx='2'
							transform='translate(-235.599 1159.858) rotate(-52.152)'
						/>
						<rect
							className='cls-9'
							x='1067.746'
							y='825.608'
							width='16'
							height='4'
							rx='2'
							transform='translate(-253.502 1101.952) rotate(-49.234)'
						/>
						<rect
							className='cls-9'
							x='1075.897'
							y='833.006'
							width='16'
							height='4'
							rx='2'
							transform='translate(-268.598 1041.952) rotate(-46.309)'
						/>
						<rect
							className='cls-9'
							x='1083.671'
							y='840.823'
							width='16'
							height='4'
							rx='2'
							transform='translate(-280.663 979.919) rotate(-43.375)'
						/>
						<rect
							className='cls-9'
							x='1091.049'
							y='849.045'
							width='16'
							height='4'
							rx='2'
							transform='translate(-289.466 915.939) rotate(-40.428)'
						/>
						<rect
							className='cls-9'
							x='1098.011'
							y='857.659'
							width='16'
							height='4'
							rx='2'
							transform='translate(-294.768 850.134) rotate(-37.467)'
						/>
						<rect
							className='cls-9'
							x='1104.54'
							y='866.652'
							width='16'
							height='4'
							rx='2'
							transform='translate(-296.327 782.655) rotate(-34.489)'
						/>
						<rect
							className='cls-9'
							x='1110.615'
							y='876.01'
							width='16'
							height='4'
							rx='2'
							transform='translate(-293.901 713.698) rotate(-31.494)'
						/>
						<rect
							className='cls-9'
							x='1116.219'
							y='885.721'
							width='16'
							height='4'
							rx='2'
							transform='translate(-287.26 643.501) rotate(-28.479)'
						/>
						<rect
							className='cls-9'
							x='1121.331'
							y='895.77'
							width='16'
							height='4'
							rx='2'
							transform='translate(-276.186 572.343) rotate(-25.447)'
						/>
						<rect
							className='cls-9'
							x='1125.933'
							y='906.146'
							width='16'
							height='4'
							rx='2'
							transform='translate(-260.485 500.554) rotate(-22.397)'
						/>
						<rect
							className='cls-9'
							x='1130.007'
							y='916.834'
							width='16'
							height='4'
							rx='2'
							transform='translate(-239.997 428.507) rotate(-19.331)'
						/>
					</g>
				</g>
			</g>
			<g id='circle-2'>
				<g>
					<circle className='cls-1' cx='973.25' cy='329.751' r='130' />
					<g className='cls-10'>
						<image
							width='1024'
							height='681'
							transform='translate(805.25 189.751) scale(0.402)'
							xlinkHref='/circle_animation/circle-2.jpg'
						/>
					</g>
				</g>
				<g id='dot-circle-3' data-name='dot-circle'>
					<circle className='cls-9' cx='984.381' cy='180.158' r='3' />
					<circle className='cls-9' cx='996.258' cy='181.504' r='3' />
					<circle className='cls-9' cx='1008.104' cy='183.821' r='3' />
					<circle className='cls-9' cx='1019.598' cy='187.048' r='3' />
					<circle className='cls-9' cx='1030.699' cy='191.146' r='2.999' />
					<circle className='cls-9' cx='1041.368' cy='196.074' r='2.999' />
					<circle className='cls-9' cx='1051.566' cy='201.794' r='2.999' />
					<circle className='cls-9' cx='1061.254' cy='208.266' r='2.999' />
					<circle className='cls-9' cx='1070.39' cy='215.45' r='2.999' />
					<circle className='cls-9' cx='1078.937' cy='223.307' r='2.999' />
					<circle className='cls-9' cx='1086.806' cy='231.742' r='2.999' />
					<circle className='cls-9' cx='1094.005' cy='240.75' r='2.999' />
					<circle className='cls-9' cx='1100.507' cy='250.305' r='2.999' />
					<circle className='cls-9' cx='1106.273' cy='260.367' r='2.999' />
					<circle className='cls-9' cx='1111.265' cy='270.899' r='2.998' />
					<circle className='cls-9' cx='1115.443' cy='281.861' r='2.998' />
					<circle className='cls-9' cx='1118.769' cy='293.214' r='2.998' />
					<circle className='cls-9' cx='1121.205' cy='304.92' r='2.998' />
					<circle className='cls-9' cx='1122.711' cy='316.941' r='2.998' />
					<circle className='cls-9' cx='1123.249' cy='329.237' r='2.998' />
					<circle className='cls-9' cx='1122.792' cy='341.558' r='2.998' />
					<circle className='cls-9' cx='1121.364' cy='353.606' r='2.998' />
					<circle className='cls-9' cx='1119.002' cy='365.344' r='2.998' />
					<circle className='cls-9' cx='1115.746' cy='376.731' r='2.998' />
					<circle className='cls-9' cx='1111.634' cy='387.729' r='2.998' />
					<circle className='cls-9' cx='1106.706' cy='398.3' r='2.998' />
					<circle className='cls-9' cx='1100.999' cy='408.405' r='2.998' />
					<circle className='cls-9' cx='1094.553' cy='418.005' r='2.998' />
					<circle className='cls-9' cx='1087.406' cy='427.061' r='2.998' />
					<circle className='cls-9' cx='1079.596' cy='435.536' r='2.998' />
					<circle className='cls-9' cx='1071.164' cy='443.389' r='2.997' />
					<circle className='cls-9' cx='1062.146' cy='450.583' r='2.997' />
					<circle className='cls-9' cx='1052.582' cy='457.079' r='2.997' />
					<circle className='cls-9' cx='1042.511' cy='462.838' r='2.997' />
					<circle className='cls-9' cx='1031.971' cy='467.821' r='2.997' />
					<circle className='cls-9' cx='1021.001' cy='471.99' r='2.997' />
					<circle className='cls-9' cx='1009.639' cy='475.307' r='2.997' />
					<circle className='cls-9' cx='997.925' cy='477.731' r='2.997' />
					<circle className='cls-9' cx='985.896' cy='479.225' r='2.997' />
					<circle className='cls-9' cx='973.593' cy='479.75' r='2.997' />
					<circle className='cls-9' cx='961.275' cy='479.28' r='2.997' />
					<circle className='cls-9' cx='949.231' cy='477.838' r='2.997' />
					<circle className='cls-9' cx='937.498' cy='475.464' r='2.997' />
					<circle className='cls-9' cx='926.116' cy='472.196' r='2.997' />
					<circle className='cls-9' cx='915.123' cy='468.073' r='2.997' />
					<circle className='cls-9' cx='904.559' cy='463.133' r='2.997' />
					<circle className='cls-9' cx='894.46' cy='457.416' r='2.997' />
					<circle className='cls-9' cx='884.868' cy='450.96' r='2.997' />
					<circle className='cls-9' cx='875.819' cy='443.803' r='2.997' />
					<circle className='cls-9' cx='867.353' cy='435.985' r='2.997' />
					<circle className='cls-9' cx='859.508' cy='427.544' r='2.998' />
					<circle className='cls-9' cx='852.323' cy='418.518' r='2.998' />
					<circle className='cls-9' cx='845.837' cy='408.947' r='2.998' />
					<circle className='cls-9' cx='840.089' cy='398.869' r='2.998' />
					<circle className='cls-9' cx='835.117' cy='388.323' r='2.998' />
					<circle className='cls-9' cx='830.959' cy='377.347' r='2.998' />
					<circle className='cls-9' cx='827.655' cy='365.981' r='2.998' />
					<circle className='cls-9' cx='825.243' cy='354.262' r='2.998' />
					<circle className='cls-9' cx='823.762' cy='342.229' r='2.998' />
					<circle className='cls-9' cx='823.25' cy='329.922' r='2.998' />
					<circle className='cls-9' cx='823.735' cy='317.608' r='2.998' />
					<circle className='cls-9' cx='825.189' cy='305.567' r='2.998' />
					<circle className='cls-9' cx='827.576' cy='293.839' r='2.998' />
					<circle className='cls-9' cx='830.856' cy='282.462' r='2.998' />
					<circle className='cls-9' cx='834.991' cy='271.475' r='2.998' />
					<circle className='cls-9' cx='839.941' cy='260.917' r='2.998' />
					<circle className='cls-9' cx='845.669' cy='250.825' r='2.999' />
					<circle className='cls-9' cx='852.135' cy='241.24' r='2.999' />
					<circle className='cls-9' cx='859.301' cy='232.199' r='2.999' />
					<circle className='cls-9' cx='867.128' cy='223.741' r='2.999' />
					<circle className='cls-9' cx='875.578' cy='215.905' r='2.999' />
					<circle className='cls-9' cx='884.611' cy='208.73' r='2.999' />
					<circle className='cls-9' cx='894.189' cy='202.254' r='2.999' />
					<circle className='cls-9' cx='904.274' cy='196.516' r='2.999' />
					<circle className='cls-9' cx='914.826' cy='191.554' r='2.999' />
					<circle className='cls-9' cx='925.808' cy='187.408' r='2.999' />
					<circle className='cls-9' cx='937.18' cy='184.116' r='3' />
					<circle className='cls-9' cx='948.903' cy='181.717' r='3' />
					<circle className='cls-9' cx='960.939' cy='180.249' r='3' />
					<circle className='cls-9' cx='973.25' cy='179.751' r='3' />
				</g>
				<g id='small-dash'>
					<circle className='cls-4' cx='973.25' cy='329.749' r='175' />
					<path
						className='cls-11'
						d='M831.321,231.748a174.786,174.786,0,0,1,142.929-74'
					/>
				</g>
				<g id='big-dash-2' data-name='big-dash'>
					<circle className='cls-4' cx='973.25' cy='329.749' r='175' />
					<path className='cls-12' d='M1146.25,327.749a175,175,0,0,1-175,175' />
				</g>
			</g>
			<g id='circle-1'>
				<g>
					<circle className='cls-1' cx='280.418' cy='258.832' r='160' />
					<g className='cls-13'>
						<image
							width='780'
							height='520'
							transform='translate(9.418 93.832) scale(0.651)'
							xlinkHref='/circle_animation/circle-1.jpg'
						/>
					</g>
				</g>
				<rect
					id='outer-circle'
					className='cls-14'
					x='93.507'
					y='71.915'
					width='373.822'
					height='373.834'
					rx='186.83'
				/>
				<g id='big-dash-3' data-name='big-dash'>
					<rect
						id='elipsis'
						className='cls-8'
						x='93.507'
						y='71.915'
						width='373.822'
						height='373.834'
						rx='186.83'
					/>
					<path
						id='dash'
						className='cls-15'
						d='M199.683,90.173a186.083,186.083,0,0,1,80.654-18.258h.162c103.184,0,186.83,83.646,186.83,186.829v.175a186.428,186.428,0,0,1-66.34,142.791'
					/>
				</g>
				<line className='cls-16' x1='400.918' y1='401.832' x2='535' y2='534' />
				<rect
					className='cls-3'
					x='387.918'
					y='388.832'
					width='26'
					height='26'
					rx='13'
				/>
				<g id='dash-circle-2' data-name='dash-circle'>
					<g>
						<rect
							className='cls-3'
							x='216.373'
							y='463.73'
							width='16'
							height='4'
							rx='2'
							transform='translate(-283.815 560.561) rotate(-74.847)'
						/>
						<rect
							className='cls-3'
							x='201.93'
							y='459.263'
							width='16'
							height='4'
							rx='2'
							transform='translate(-294.735 507.608) rotate(-70.776)'
						/>
						<rect
							className='cls-3'
							x='187.941'
							y='453.825'
							width='16'
							height='4'
							rx='2'
							transform='translate(-300.213 455.815) rotate(-66.738)'
						/>
						<rect
							className='cls-3'
							x='174.447'
							y='447.456'
							width='16'
							height='4'
							rx='2'
							transform='translate(-300.652 405.715) rotate(-62.733)'
						/>
						<rect
							className='cls-3'
							x='161.488'
							y='440.197'
							width='16'
							height='4'
							rx='2'
							transform='matrix(0.519, -0.855, 0.855, 0.519, -296.488, 357.755)'
						/>
						<rect
							className='cls-3'
							x='149.102'
							y='432.086'
							width='16'
							height='4'
							rx='2'
							transform='translate(-288.183 312.297) rotate(-54.807)'
						/>
						<rect
							className='cls-3'
							x='137.329'
							y='423.163'
							width='16'
							height='4'
							rx='2'
							transform='translate(-276.209 269.632) rotate(-50.876)'
						/>
						<rect
							className='cls-3'
							x='126.21'
							y='413.469'
							width='16'
							height='4'
							rx='2'
							transform='translate(-261.037 229.979) rotate(-46.957)'
						/>
						<rect
							className='cls-3'
							x='115.783'
							y='403.042'
							width='16'
							height='4'
							rx='2'
							transform='translate(-243.14 193.502) rotate(-43.042)'
						/>
						<rect
							className='cls-3'
							x='106.089'
							y='391.922'
							width='16'
							height='4'
							rx='2'
							transform='translate(-222.98 160.308) rotate(-39.123)'
						/>
						<rect
							className='cls-3'
							x='97.166'
							y='380.15'
							width='16'
							height='4'
							rx='2'
							transform='translate(-201.018 130.456) rotate(-35.192)'
						/>
						<rect
							className='cls-3'
							x='89.055'
							y='367.764'
							width='16'
							height='4'
							rx='2'
							transform='translate(-177.704 103.959) rotate(-31.242)'
						/>
						<rect
							className='cls-3'
							x='81.796'
							y='354.804'
							width='16'
							height='4'
							rx='2'
							transform='translate(-153.486 80.785) rotate(-27.267)'
						/>
						<rect
							className='cls-3'
							x='75.427'
							y='341.31'
							width='16'
							height='4'
							rx='2'
							transform='translate(-128.801 60.855) rotate(-23.262)'
						/>
						<rect
							className='cls-3'
							x='69.989'
							y='327.321'
							width='16'
							height='4'
							rx='2'
							transform='translate(-104.083 44.042) rotate(-19.224)'
						/>
						<rect
							className='cls-3'
							x='65.522'
							y='312.878'
							width='16'
							height='4'
							rx='2'
							transform='translate(-79.751 30.166) rotate(-15.153)'
						/>
						<rect
							className='cls-3'
							x='62.064'
							y='298.019'
							width='16'
							height='4'
							rx='2'
							transform='translate(-56.205 18.992) rotate(-11.05)'
						/>
						<rect
							className='cls-3'
							x='59.656'
							y='282.785'
							width='16'
							height='4'
							rx='2'
							transform='translate(-33.823 10.227) rotate(-6.921)'
						/>
						<rect
							className='cls-3'
							x='58.336'
							y='267.215'
							width='16'
							height='4'
							rx='2'
							transform='translate(-12.942 3.523) rotate(-2.772)'
						/>
						<rect
							className='cls-3'
							x='64.146'
							y='245.379'
							width='4'
							height='16'
							rx='2'
							transform='translate(-188.76 313.375) rotate(-88.614)'
						/>
						<rect
							className='cls-3'
							x='65.093'
							y='229.706'
							width='4'
							height='16'
							rx='2'
							transform='translate(-175.98 281.539) rotate(-84.46)'
						/>
						<rect
							className='cls-3'
							x='67.143'
							y='214.356'
							width='4'
							height='16'
							rx='2'
							transform='translate(-161.671 253.14) rotate(-80.324)'
						/>
						<rect
							className='cls-3'
							x='70.255'
							y='199.367'
							width='4'
							height='16'
							rx='2'
							transform='translate(-146.358 228.116) rotate(-76.211)'
						/>
						<rect
							className='cls-3'
							x='74.39'
							y='184.781'
							width='4'
							height='16'
							rx='2'
							transform='translate(-130.531 206.328) rotate(-72.129)'
						/>
						<rect
							className='cls-3'
							x='79.509'
							y='170.637'
							width='4'
							height='16'
							rx='2'
							transform='translate(-114.641 187.568) rotate(-68.081)'
						/>
						<rect
							className='cls-3'
							x='85.572'
							y='156.974'
							width='4'
							height='16'
							rx='2'
							transform='translate(-99.087 171.574) rotate(-64.065)'
						/>
						<rect
							className='cls-3'
							x='92.539'
							y='143.831'
							width='4'
							height='16'
							rx='2'
							transform='translate(-84.212 158.039) rotate(-60.08)'
						/>
						<rect
							className='cls-3'
							x='100.37'
							y='131.249'
							width='4'
							height='16'
							rx='2'
							transform='translate(-70.302 146.62) rotate(-56.122)'
						/>
						<rect
							className='cls-3'
							x='109.027'
							y='119.268'
							width='4'
							height='16'
							rx='2'
							transform='translate(-57.586 136.951) rotate(-52.186)'
						/>
						<rect
							className='cls-3'
							x='118.468'
							y='107.926'
							width='4'
							height='16'
							rx='2'
							transform='translate(-46.234 128.648) rotate(-48.263)'
						/>
						<rect
							className='cls-3'
							x='128.655'
							y='97.264'
							width='4'
							height='16'
							rx='2'
							transform='translate(-36.358 121.318) rotate(-44.348)'
						/>
						<rect
							className='cls-3'
							x='139.548'
							y='87.321'
							width='4'
							height='16'
							rx='2'
							transform='translate(-28.015 114.562) rotate(-40.431)'
						/>
						<rect
							className='cls-3'
							x='151.107'
							y='78.137'
							width='4'
							height='16'
							rx='2'
							transform='translate(-21.203 107.981) rotate(-36.505)'
						/>
						<rect
							className='cls-3'
							x='163.293'
							y='69.751'
							width='4'
							height='16'
							rx='2'
							transform='translate(-15.864 101.184) rotate(-32.562)'
						/>
						<rect
							className='cls-3'
							x='176.066'
							y='62.203'
							width='4'
							height='16'
							rx='2'
							transform='translate(-11.881 93.79) rotate(-28.595)'
						/>
						<rect
							className='cls-3'
							x='189.386'
							y='55.533'
							width='4'
							height='16'
							rx='2'
							transform='translate(-9.076 85.439) rotate(-24.601)'
						/>
					</g>
					<circle className='cls-8' cx='280.582' cy='258.668' r='222.5' />
				</g>
				<g id='leaf'>
					<ellipse
						className='cls-8'
						cx='280.582'
						cy='258.668'
						rx='254.852'
						ry='254.863'
					/>
					<g>
						<path
							className='cls-17'
							d='M482.458,161.5A224.6,224.6,0,0,0,345.4,44.2'
						/>
						<g>
							<path
								className='cls-18'
								d='M369.846,71.638s24.224-9.085,47.945,9.669'
							/>
							<circle className='cls-9' cx='362' cy='74' r='5' />
						</g>
						<g>
							<path
								className='cls-18'
								d='M387.5,43.038s3.235,25.669,30.874,37.935'
							/>
							<circle className='cls-9' cx='385.937' cy='34.995' r='5' />
						</g>
						<circle className='cls-9' cx='487' cy='170' r='5' />
						<g>
							<path
								className='cls-18'
								d='M453.124,92.552s-5.166,25.351,17.081,45.831'
							/>
							<circle className='cls-9' cx='454.957' cy='84.206' r='5' />
						</g>
						<g>
							<path
								className='cls-18'
								d='M428.7,112.051s25.867.51,40.979,26.7'
							/>
							<circle className='cls-9' cx='420.154' cy='112.012' r='5' />
						</g>
						<circle className='cls-9' cx='338' cy='42' r='5' />
					</g>
				</g>
			</g>
			<g id='circle-0'>
				<g id='dash-circle-3' data-name='dash-circle'>
					<path
						className='cls-19'
						d='M600,470.036a129.863,129.863,0,1,0,21.862,1.83q-3.465-.586-6.992-.989A131.559,131.559,0,0,0,600,470.036Z'
					/>
					<path
						className='cls-20'
						d='M691.339,507.521a129.678,129.678,0,0,0-69.477-35.655q-3.465-.586-6.992-.989a131.559,131.559,0,0,0-14.87-.841,129.964,129.964,0,1,0,0,259.928'
					/>
				</g>

				<g id='dash-circle-4' data-name='dash-circle'>
					<path
						className='cls-8'
						d='M600,388.059c-117.084,0-212,94.889-212,211.941s94.916,211.941,212,211.941S812,717.052,812,600c0-104.9-76.239-192.009-176.348-208.957q-5.652-.956-11.4-1.612A214.327,214.327,0,0,0,600,388.059Z'
					/>
					<g>
						<rect
							className='cls-9'
							x='395.315'
							y='643.986'
							width='20'
							height='4'
							rx='2'
							transform='matrix(0.973, -0.229, 0.229, 0.973, -137.253, 110.078)'
						/>
						<rect
							className='cls-9'
							x='392.297'
							y='628.402'
							width='20'
							height='4'
							rx='2'
							transform='translate(-90.492 67.9) rotate(-8.676)'
						/>
						<rect
							className='cls-9'
							x='390.512'
							y='612.414'
							width='20'
							height='4'
							rx='2'
							transform='translate(-42.657 30.021) rotate(-4.076)'
						/>
						<rect
							className='cls-9'
							x='398.009'
							y='588.075'
							width='4'
							height='20'
							rx='2'
							transform='translate(-201.813 992.425) rotate(-89.46)'
						/>
						<rect
							className='cls-9'
							x='398.816'
							y='571.811'
							width='4'
							height='20'
							rx='2'
							transform='translate(-214.651 928.737) rotate(-84.846)'
						/>
						<rect
							className='cls-9'
							x='400.895'
							y='555.913'
							width='4'
							height='20'
							rx='2'
							transform='translate(-223.069 867.162) rotate(-80.251)'
						/>
						<rect
							className='cls-9'
							x='404.194'
							y='540.432'
							width='4'
							height='20'
							rx='2'
							transform='translate(-227.571 807.939) rotate(-75.687)'
						/>
						<rect
							className='cls-9'
							x='408.663'
							y='525.418'
							width='4'
							height='20'
							rx='2'
							transform='translate(-228.681 751.187) rotate(-71.161)'
						/>
						<rect
							className='cls-9'
							x='414.251'
							y='510.923'
							width='4'
							height='20'
							rx='2'
							transform='translate(-226.91 696.905) rotate(-66.676)'
						/>
						<rect
							className='cls-9'
							x='420.908'
							y='496.996'
							width='4'
							height='20'
							rx='2'
							transform='translate(-222.736 644.984) rotate(-62.231)'
						/>
						<rect
							className='cls-9'
							x='428.583'
							y='483.689'
							width='4'
							height='20'
							rx='2'
							transform='translate(-216.581 595.224) rotate(-57.822)'
						/>
						<rect
							className='cls-9'
							x='437.226'
							y='471.051'
							width='4'
							height='20'
							rx='2'
							transform='translate(-208.798 547.347) rotate(-53.444)'
						/>
						<rect
							className='cls-9'
							x='480.461'
							y='428.211'
							width='4'
							height='20'
							rx='2'
							transform='translate(-165.469 367.614) rotate(-36.03)'
						/>
						<rect
							className='cls-9'
							x='493.183'
							y='419.682'
							width='4'
							height='20'
							rx='2'
							transform='translate(-151.816 323.733) rotate(-31.649)'
						/>
						<rect
							className='cls-9'
							x='506.569'
							y='412.125'
							width='4'
							height='20'
							rx='2'
							transform='translate(-136.808 279.573) rotate(-27.238)'
						/>
						<rect
							className='cls-9'
							x='520.569'
							y='405.593'
							width='4'
							height='20'
							rx='2'
							transform='translate(-120.186 234.867) rotate(-22.79)'
						/>
						<rect
							className='cls-9'
							x='535.131'
							y='400.134'
							width='4'
							height='20'
							rx='2'
							transform='translate(-101.622 189.421) rotate(-18.302)'
						/>
						<rect
							className='cls-9'
							x='550.206'
							y='395.801'
							width='4'
							height='20'
							rx='2'
							transform='translate(-80.736 143.14) rotate(-13.773)'
						/>
						<rect
							className='cls-9'
							x='565.743'
							y='392.643'
							width='4'
							height='20'
							rx='2'
							transform='translate(-57.113 96.035) rotate(-9.208)'
						/>
						<rect
							className='cls-9'
							x='581.691'
							y='390.711'
							width='4'
							height='20'
							rx='2'
							transform='translate(-30.334 48.237) rotate(-4.613)'
						/>
						<rect
							className='cls-9'
							x='598'
							y='390.056'
							width='4'
							height='20'
							rx='2'
						/>
					</g>
				</g>
				<g id='dot-circle-4' data-name='dot-circle'>
					<g id='paths'>
						<circle className='cls-9' cx='636.279' cy='491.384' r='3' />
						<circle className='cls-9' cx='647.223' cy='495.677' r='3' />
						<circle className='cls-9' cx='657.585' cy='501.026' r='3' />
						<circle className='cls-9' cx='667.299' cy='507.367' r='3' />
						<circle className='cls-9' cx='676.298' cy='514.631' r='3' />
						<circle className='cls-9' cx='684.514' cy='522.752' r='3' />
						<circle className='cls-9' cx='691.88' cy='531.662' r='3' />
						<circle className='cls-9' cx='698.331' cy='541.295' r='3' />
						<circle className='cls-9' cx='703.798' cy='551.585' r='3' />
						<circle className='cls-9' cx='708.215' cy='562.463' r='3' />
						<circle className='cls-9' cx='711.514' cy='573.863' r='3' />
						<circle className='cls-9' cx='713.63' cy='585.719' r='3' />
						<circle className='cls-9' cx='714.494' cy='597.962' r='3' />
						<circle className='cls-9' cx='714.058' cy='610.267' r='3' />
						<circle className='cls-9' cx='712.359' cy='622.209' r='3' />
						<circle className='cls-9' cx='709.464' cy='633.721' r='3' />
						<circle className='cls-9' cx='705.438' cy='644.739' r='3' />
						<circle className='cls-9' cx='700.348' cy='655.195' r='3' />
						<circle className='cls-9' cx='694.26' cy='665.024' r='3' />
						<circle className='cls-9' cx='687.24' cy='674.159' r='3' />
						<circle className='cls-9' cx='679.355' cy='682.535' r='3' />
						<circle className='cls-9' cx='670.669' cy='690.086' r='3' />
						<circle className='cls-9' cx='661.25' cy='696.745' r='3' />
						<circle className='cls-9' cx='651.164' cy='702.446' r='3' />
						<circle className='cls-9' cx='640.476' cy='707.123' r='3' />
						<circle className='cls-9' cx='629.252' cy='710.711' r='3' />
						<circle className='cls-9' cx='617.56' cy='713.143' r='3' />
						<circle className='cls-9' cx='605.464' cy='714.352' r='3' />
						<circle className='cls-9' cx='593.127' cy='714.277' r='3' />
						<circle className='cls-9' cx='581.074' cy='712.924' r='3' />
						<circle className='cls-9' cx='569.432' cy='710.356' r='3' />
						<circle className='cls-9' cx='558.267' cy='706.64' r='3' />
						<circle className='cls-9' cx='547.645' cy='701.841' r='3' />
						<circle className='cls-9' cx='537.632' cy='696.027' r='3' />
					</g>
					<path
						className='cls-8'
						d='M600,485.52a114.392,114.392,0,1,0,19.257,1.612q-3.052-.518-6.158-.871A115.639,115.639,0,0,0,600,485.52Z'
					/>
				</g>
				<g id='bold-dash'>
					<path
						className='cls-8'
						d='M600,422.05c-98.307,0-178,79.671-178,177.95s79.693,177.95,178,177.95S778,698.279,778,600c0-88.081-64.012-161.215-148.066-175.445q-4.745-.8-9.573-1.354A180.122,180.122,0,0,0,600,422.05Z'
					/>
					<path
						className='cls-22'
						d='M600,766.453A166.464,166.464,0,0,0,628,435.89'
					/>
				</g>
			</g>
			<g id='ai-circle'>
				<circle className='cls-21' cx='600' cy='600' r='100' />
				<g>
					<path
						className='cls-9'
						d='M632.433,559.015H567.567a8.561,8.561,0,0,0-8.552,8.552v64.866a8.561,8.561,0,0,0,8.552,8.552h64.866a8.561,8.561,0,0,0,8.552-8.552V567.567A8.561,8.561,0,0,0,632.433,559.015Z'
					/>
					<path
						id='chip'
						className='cls-19'
						d='M636.949,632.433a4.516,4.516,0,0,1-4.516,4.516H567.567a4.516,4.516,0,0,1-4.516-4.516h0V567.567a4.516,4.516,0,0,1,4.516-4.516h64.866a4.516,4.516,0,0,1,4.516,4.516Z'
					/>
					<path
						className='cls-9'
						d='M565.514,552.541h68.972a2.024,2.024,0,1,0,0-4.048h-6.639V531.2a2.024,2.024,0,0,0-4.048,0v17.3H614.78V522.111a2.024,2.024,0,0,0-4.048,0v26.382H601.7V531.2a2.024,2.024,0,0,0-4.048,0v17.3H588.62V522.111a2.024,2.024,0,1,0-4.048,0v26.382H575.54V531.2a2.024,2.024,0,0,0-4.048,0v17.3h-5.978a2.024,2.024,0,1,0,0,4.048Z'
					/>
					<path
						className='cls-9'
						d='M634.486,647.459H565.514a2.024,2.024,0,0,0,0,4.048h6.643v17.3a2.024,2.024,0,1,0,4.048,0h0v-17.3h9.032v26.382a2.024,2.024,0,1,0,4.048,0h0V651.507h9.032v17.3a2.024,2.024,0,0,0,4.048,0v-17.3H611.4v26.382a2.024,2.024,0,0,0,4.048,0V651.507h9.032v17.3a2.024,2.024,0,0,0,4.048,0v-17.3h5.961a2.024,2.024,0,0,0,.007-4.048Z'
					/>
					<path
						className='cls-9'
						d='M677.885,610.715H651.5v-9.032h17.3a2.024,2.024,0,0,0,0-4.044H651.5v-9.032h26.382a2.024,2.024,0,1,0,.182-4.044c-.061,0-.121,0-.182,0H651.5v-9.032h17.3a2.024,2.024,0,0,0,0-4.043H651.5v-5.974a2.024,2.024,0,0,0-4.048,0v68.972a2.024,2.024,0,0,0,4.048,0v-6.639h17.3a2.024,2.024,0,0,0,0-4.043H651.5V614.78h26.382a2.024,2.024,0,1,0,.182-4.044c-.061,0-.121,0-.182,0Z'
					/>
					<path
						className='cls-9'
						d='M550.517,563.486a2.02,2.02,0,0,0-2.024,2.016v6.651h-17.3a2.024,2.024,0,0,0,0,4.043h17.3v9.033H522.115a2.024,2.024,0,1,0-.182,4.043c.061,0,.121,0,.182,0h26.378V598.3h-17.3a2.024,2.024,0,0,0,0,4.044h17.3v9.032H522.115a2.024,2.024,0,1,0-.182,4.044c.061,0,.121,0,.182,0h26.378v9.032h-17.3a2.024,2.024,0,0,0,0,4.044h17.3v5.986a2.024,2.024,0,0,0,4.044,0V565.514a2.021,2.021,0,0,0-2.012-2.028Z'
					/>
					<path
						className='chip-txt cls-9'
						d='M594.166,580.113a2.108,2.108,0,0,0-3.814,0l-9.032,25.61-1.006,2.853v.029l-3.5,9.935a2.016,2.016,0,0,0,1.223,2.575l.009,0a2.012,2.012,0,0,0,2.582-1.232l3.034-8.622h17.2l3.038,8.622a2.024,2.024,0,0,0,3.814-1.347Zm-9.077,27.1,1.338-3.793a.417.417,0,0,1,0-.05l5.834-16.5,7.172,20.355Z'
					/>
					<path
						className='chip-txt cls-9'
						d='M621.283,578.762a2.024,2.024,0,0,0-2.024,2.024v38.428a2.024,2.024,0,0,0,4.044.182c0-.061,0-.122,0-.182V580.786A2.025,2.025,0,0,0,621.283,578.762Z'
					/>
				</g>
			</g>
			<g id='ring-3'>
				<path
					className='cls-8'
					d='M600,375.063c-124.264,0-225,100.708-225,224.937S475.736,824.937,600,824.937,825,724.229,825,600c0-111.338-80.914-203.783-187.162-221.77q-6-1.016-12.1-1.711A227.364,227.364,0,0,0,600,375.063Z'
				/>
				<path
					className='cls-7'
					d='M600,814.94c118.741,0,215-96.232,215-214.94'
				/>
				<circle className='cls-9' cx='815' cy='600' r='10' />
				<circle className='cls-23' cx='600' cy='814' r='10' />
			</g>
			<g id='ring-2'>
				<path
					className='cls-8'
					d='M600,330.076c-149.117,0-270,120.849-270,269.924S450.883,869.924,600,869.924,870,749.075,870,600c0-133.606-97.1-244.54-224.594-266.124q-7.2-1.218-14.522-2.054A272.915,272.915,0,0,0,600,330.076Z'
				/>
				<g>
					<ellipse
						className='cls-9'
						cx='629.168'
						cy='346.721'
						rx='2.853'
						ry='2.875'
						transform='translate(213.507 932.634) rotate(-83.505)'
					/>
					<ellipse
						className='cls-9'
						cx='640.805'
						cy='348.318'
						rx='2.853'
						ry='2.875'
						transform='translate(195.178 925.703) rotate(-80.866)'
					/>
					<ellipse
						className='cls-9'
						cx='652.545'
						cy='350.49'
						rx='2.853'
						ry='2.875'
						transform='translate(175.745 917.34) rotate(-78.172)'
					/>
					<ellipse
						className='cls-9'
						cx='664.15'
						cy='353.206'
						rx='2.853'
						ry='2.875'
						transform='translate(155.679 907.559) rotate(-75.477)'
					/>
					<ellipse
						className='cls-9'
						cx='675.546'
						cy='356.445'
						rx='2.853'
						ry='2.875'
						transform='translate(135.225 896.322) rotate(-72.794)'
					/>
					<ellipse
						className='cls-9'
						cx='686.717'
						cy='360.191'
						rx='2.853'
						ry='2.875'
						transform='translate(114.53 883.565) rotate(-70.126)'
					/>
					<ellipse
						className='cls-9'
						cx='697.652'
						cy='364.432'
						rx='2.853'
						ry='2.875'
						transform='translate(93.74 869.229) rotate(-67.473)'
					/>
					<ellipse
						className='cls-9'
						cx='708.336'
						cy='369.155'
						rx='2.853'
						ry='2.875'
						transform='translate(73.001 853.268) rotate(-64.833)'
					/>
					<ellipse
						className='cls-9'
						cx='718.757'
						cy='374.346'
						rx='2.853'
						ry='2.875'
						transform='translate(52.459 835.642) rotate(-62.208)'
					/>
					<ellipse
						className='cls-9'
						cx='728.9'
						cy='379.992'
						rx='2.853'
						ry='2.875'
						transform='translate(32.261 816.322) rotate(-59.594)'
					/>
					<ellipse
						className='cls-9'
						cx='738.754'
						cy='386.079'
						rx='2.853'
						ry='2.875'
						transform='translate(12.554 795.283) rotate(-56.993)'
					/>
					<ellipse
						className='cls-9'
						cx='748.303'
						cy='392.594'
						rx='2.853'
						ry='2.875'
						transform='translate(-6.516 772.51) rotate(-54.401)'
					/>
					<ellipse
						className='cls-9'
						cx='757.535'
						cy='399.524'
						rx='2.853'
						ry='2.875'
						transform='translate(-24.8 747.994) rotate(-51.816)'
					/>
					<ellipse
						className='cls-9'
						cx='766.437'
						cy='406.855'
						rx='2.853'
						ry='2.875'
						transform='translate(-42.147 721.735) rotate(-49.238)'
					/>
					<ellipse
						className='cls-9'
						cx='774.995'
						cy='414.574'
						rx='2.853'
						ry='2.875'
						transform='translate(-58.404 693.74) rotate(-46.663)'
					/>
					<ellipse
						className='cls-9'
						cx='783.195'
						cy='422.667'
						rx='2.853'
						ry='2.875'
						transform='translate(-73.418 664.028) rotate(-44.09)'
					/>
					<ellipse
						className='cls-9'
						cx='791.025'
						cy='431.121'
						rx='2.853'
						ry='2.875'
						transform='translate(-87.031 632.626) rotate(-41.516)'
					/>
					<ellipse
						className='cls-9'
						cx='798.47'
						cy='439.923'
						rx='2.853'
						ry='2.875'
						transform='translate(-99.081 599.574) rotate(-38.939)'
					/>
					<ellipse
						className='cls-9'
						cx='805.518'
						cy='449.06'
						rx='2.853'
						ry='2.875'
						transform='translate(-109.408 564.925) rotate(-36.356)'
					/>
					<ellipse
						className='cls-9'
						cx='812.156'
						cy='458.517'
						rx='2.853'
						ry='2.875'
						transform='translate(-117.846 528.745) rotate(-33.766)'
					/>
					<ellipse
						className='cls-9'
						cx='818.369'
						cy='468.282'
						rx='2.853'
						ry='2.875'
						transform='translate(-124.231 491.119) rotate(-31.167)'
					/>
					<ellipse
						className='cls-9'
						cx='824.144'
						cy='478.341'
						rx='2.853'
						ry='2.875'
						transform='translate(-128.398 452.149) rotate(-28.556)'
					/>
					<ellipse
						className='cls-9'
						cx='829.468'
						cy='488.68'
						rx='2.853'
						ry='2.875'
						transform='translate(-130.188 411.956) rotate(-25.933)'
					/>
					<ellipse
						className='cls-9'
						cx='834.328'
						cy='499.288'
						rx='2.853'
						ry='2.875'
						transform='translate(-129.442 370.683) rotate(-23.297)'
					/>
					<ellipse
						className='cls-9'
						cx='838.71'
						cy='510.149'
						rx='2.853'
						ry='2.875'
						transform='matrix(0.936, -0.353, 0.353, 0.936, -126.011, 328.493)'
					/>
					<ellipse
						className='cls-9'
						cx='842.601'
						cy='521.251'
						rx='2.853'
						ry='2.875'
						transform='translate(-119.759 285.575) rotate(-17.981)'
					/>
					<ellipse
						className='cls-9'
						cx='845.987'
						cy='532.58'
						rx='2.853'
						ry='2.875'
						transform='translate(-110.559 242.139) rotate(-15.302)'
					/>
					<ellipse
						className='cls-9'
						cx='848.856'
						cy='544.123'
						rx='2.853'
						ry='2.875'
						transform='translate(-98.306 198.419) rotate(-12.609)'
					/>
					<ellipse
						className='cls-9'
						cx='851.193'
						cy='555.867'
						rx='2.853'
						ry='2.875'
						transform='translate(-82.915 154.669) rotate(-9.903)'
					/>
					<ellipse
						className='cls-9'
						cx='852.985'
						cy='567.797'
						rx='2.853'
						ry='2.875'
						transform='translate(-64.328 111.163) rotate(-7.186)'
					/>
					<ellipse
						className='cls-9'
						cx='854.219'
						cy='579.902'
						rx='2.853'
						ry='2.875'
						transform='translate(-42.515 68.194) rotate(-4.461)'
					/>
					<ellipse
						className='cls-9'
						cx='854.882'
						cy='592.167'
						rx='2.853'
						ry='2.875'
						transform='translate(-17.48 26.066) rotate(-1.729)'
					/>
					<ellipse
						className='cls-9'
						cx='854.96'
						cy='604.541'
						rx='2.875'
						ry='2.853'
						transform='translate(235.578 1448.81) rotate(-88.999)'
					/>
				</g>
				<polygon
					className='cls-9'
					points='849.675 640.48 869.896 615.644 838.277 610.55 849.675 640.48'
				/>
			</g>
			<g id='ring-4'>
				<path
					className='cls-8'
					d='M600,328.076c-150.221,0-272,121.745-272,271.924S449.779,871.924,600,871.924,872,750.179,872,600c0-134.6-97.816-246.351-226.258-268.1q-7.251-1.228-14.629-2.069A274.839,274.839,0,0,0,600,328.076Z'
				/>
				<path
					className='cls-7'
					d='M345,600c0,103.443,61.629,192.5,150.183,232.465'
				/>
				<circle className='cls-9' cx='495' cy='833' r='10' />
				<polygon
					className='cls-3'
					points='345.376 573.156 329.363 600.891 361.39 600.891 345.376 573.156'
				/>
			</g>
			<g id='ring-4-dot'>
				<circle className='cls-3' cx='402.82' cy='778.67' r='3' />
				<circle className='cls-3' cx='394.18' cy='786.33' r='3' />
				<circle className='cls-3' cx='385.541' cy='793.989' r='3' />
				<circle className='cls-3' cx='376.902' cy='801.648' r='3' />
				<circle className='cls-3' cx='368.263' cy='809.307' r='3' />
				<circle className='cls-3' cx='359.624' cy='816.966' r='3' />
				<circle className='cls-3' cx='350.984' cy='824.625' r='3' />
			</g>
			<g id='ring-2-dot'>
				<circle className='cls-9' cx='807.466' cy='433.855' r='3' />
				<circle className='cls-9' cx='817.158' cy='427.582' r='3' />
				<circle className='cls-9' cx='826.851' cy='421.309' r='3' />
				<circle className='cls-9' cx='836.543' cy='415.036' r='3' />
			</g>
			<g id='ring-3-dot'>
				<circle className='cls-3' cx='742.279' cy='775.467' r='3' />
				<circle className='cls-3' cx='749.967' cy='784.08' r='3' />
				<circle className='cls-3' cx='757.656' cy='792.693' r='3' />
				<circle className='cls-3' cx='765.344' cy='801.307' r='3' />
				<circle className='cls-3' cx='773.033' cy='809.92' r='3' />
				<circle className='cls-3' cx='780.721' cy='818.533' r='3' />
				<circle className='cls-3' cx='788.41' cy='827.146' r='3' />
				<circle className='cls-3' cx='796.098' cy='835.759' r='3' />
				<circle className='cls-3' cx='803.787' cy='844.372' r='3' />
			</g>
		</g>
	</svg>
);
export default SvgComponent;
