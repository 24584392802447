import React from 'react';
import { createWorker } from 'tesseract.js';
import { BiLayerPlus } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { FaTelegramPlane } from 'react-icons/fa';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import './AiAssistant.scss';

const AiAssistant = () => {
	const [newData, setNewData] = React.useState(null);

	const [imageData, setImageData] = React.useState('');
	const [formData, setFormData] = React.useState({
		name: '',
		file: null,
	});
	const [ocrText, setOcrText] = React.useState('');
	const [showModal, setShowModal] = React.useState(false);
	const [allData, setAllData] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [uploading, setUploading] = React.useState(false);

	React.useEffect(() => {
		const fetchAllData = async () => {
			try {
				const req = await fetch(`${process.env.REACT_APP_API}/ai`, {
					headers: {
						'x-access-token': localStorage.getItem('token'),
					},
				});

				const res = await req.json();

				console.log('api res -> ', res);

				if (res.success) {
					setAllData(res.data);
				} else {
					toast.error(res.message);
				}
			} catch (error) {
				console.log('error -> ', error);
				toast.error('Something went wrong!');
			} finally {
				setLoading(false);
			}
		};

		fetchAllData();
	}, []);

	const handleImageChange = async (e) => {
		const file = e.target.files[0];

		if (!file) {
			setShowModal(false);
			return;
		}

		if (file.size > 50 * 1024 * 1024) {
			toast.error('File size must be less than 50 MB!');
			return;
		}

		const reader = new FileReader();

		reader.onload = (e) => {
			const imageDataUri = e.target.result;

			// console.log('imageDataUri -> ', imageDataUri);

			setImageData(imageDataUri);
			setFormData({ ...formData, file });
			setShowModal(true);
		};

		reader.readAsDataURL(file);
	};

	const handleUpload = async () => {
		if (!imageData) return;

		if (!formData.name) {
			toast.error('Please enter a file name!');
			return;
		}

		try {
			const worker = await createWorker('eng');
			const { data } = await worker.recognize(imageData);
			console.log('OCR data -> ', data);

			if (data) {
				setOcrText(data.text);
			}
		} catch (error) {
			console.log('error -> ', error);
			toast.error('Something went wrong!');
		} finally {
			setShowModal(false);
		}
	};

	const handleGenerate = async () => {
		if (!ocrText || !formData.file || !formData.name) return;

		setUploading(true);

		try {
			const bodyData = new FormData();

			bodyData.append('file', formData.file);
			bodyData.append('name', formData.name);
			bodyData.append('text', ocrText);

			const req = await fetch(`${process.env.REACT_APP_API}/ai`, {
				method: 'POST',
				headers: {
					'x-access-token': localStorage.getItem('token'),
				},
				body: bodyData,
			});

			const res = await req.json();

			console.log('api res -> ', res);

			if (res.success) {
				toast.success(res.message);
				setNewData(res.data);
				setAllData((prev) => [res.data, ...prev]);
			} else {
				toast.error(res.message);
			}
		} catch (error) {
			console.log('error -> ', error);
			toast.error('Something went wrong!');
		} finally {
			setUploading(false);
			setShowModal(false);
			setFormData({ name: '', file: null });
			// setImageData('');
			setOcrText('');
		}
	};

	return (
		<div className='aiAssistant page'>
			<div className='container'>
				<div className='my-5 w-full max-w-7xl mx-auto'>
					<img src='/banner/3.jpg' alt='banner' className='w-full h-full' />
				</div>

				<label className='drop__files' htmlFor='file'>
					<BiLayerPlus className='icon' />

					<p>
						Drop your files here, or <span>Browse</span>
					</p>
					<span>Maximum file size 50 mb</span>
				</label>

				<input
					type='file'
					name='file'
					id='file'
					accept='image/*'
					onChange={handleImageChange}
					hidden
				/>

				<div className='generate__section'>
					<div className='file__preview'>
						<div className='top__side'>
							<p>{formData.name ? formData.name : 'Ultra Sonogram'}</p>
							<span>{new Date().toLocaleDateString()}</span>
						</div>

						<div className={`file__view ${!imageData && 'dummy'}`}>
							<img src={imageData || '/home/file.png'} alt='file icon' />
						</div>
					</div>

					<div className='generate__summary'>
						<div className='top__side'>
							<p>AI Generated Summary</p>
						</div>

						<div className={`summary__body ${!newData && 'dummy text-center'}`}>
							{newData ? (
								<p>{newData.summary}</p>
							) : (
								<>
									<button
										onClick={handleGenerate}
										disabled={uploading || !ocrText}
									>
										<FaTelegramPlane className='icon' />
									</button>

									<p>
										<strong>Click</strong> the Button for Generated AI Summary
									</p>
								</>
							)}
						</div>
					</div>

					<div className='generate__advice'>
						<div className='top__side'>
							<p>AI Adviser</p>
						</div>

						<div className={`advice__body ${!newData && 'dummy text-center'}`}>
							{newData ? (
								<p>{newData.advice}</p>
							) : (
								<p>
									Not Generated
									<br />
									AI Summary
								</p>
							)}
						</div>
					</div>
				</div>

				<div className='allFiles__container'>
					<h2>All Files</h2>

					<div className='files__container'>
						{loading ? (
							<p>Loading...</p>
						) : (
							allData?.map((item, i) => (
								<div key={i} className='generated__section'>
									<div className='file__preview'>
										<div className='top__side'>
											<p>{item.name}</p>
											<span>
												{new Date(item.createdAt).toLocaleDateString()}
											</span>
										</div>

										<div className='file__view'>
											<img src={item.image.path} alt='file icon' />
										</div>
									</div>

									<div className='generate__summary'>
										<div className='top__side'>
											<p>AI Generated Summary</p>
										</div>

										<div className='summary__body'>
											<p>{item.summary}</p>
										</div>
									</div>

									<div className='generate__advice'>
										<div className='top__side'>
											<p>AI Adviser</p>
										</div>

										<div className='advice__body'>
											<p>{item.advice}</p>
										</div>
									</div>
								</div>
							))
						)}
					</div>
				</div>
			</div>

			<Dialog
				open={showModal}
				onClose={() => setShowModal(false)}
				maxWidth='xs'
				fullWidth
			>
				<DialogContent sx={{ px: 4, pt: 4, pb: 2.5 }}>
					<label
						htmlFor='customFileName'
						className='block text-sm font-medium text-gray-700 mb-2'
					>
						Custom File Name (Optional)
					</label>
					<input
						type='text'
						id='customFileName'
						placeholder='Custom File Name'
						onChange={(e) => setFormData({ ...formData, name: e.target.value })}
						className='w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
					/>
				</DialogContent>

				<DialogActions sx={{ pb: 3, px: 4 }}>
					<button
						onClick={() => {
							setShowModal(false);
							setFormData({ name: '', file: null });
						}}
						className='bg-transparent px-4 py-2 text-rose-500 border border-rose-500 rounded-md hover:bg-rose-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500'
						disabled={uploading}
					>
						Cancel
					</button>
					<button
						onClick={handleUpload}
						className='bg-emerald-500 px-4 py-2 text-white border border-emerald-500 rounded-md hover:bg-emerald-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500'
						disabled={uploading || !formData.name || !formData.file}
					>
						Upload
					</button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AiAssistant;
