import React from 'react';
import './DashboardNew.scss';

const DashboardNew = () => {
	return (
		<div className='dashboardNew'>
			<aside className='sideBar'>
				<h1>Sidebar</h1>
			</aside>
		</div>
	);
};

export default DashboardNew;
