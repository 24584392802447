import React from 'react';
import { Link } from 'react-router-dom';
import { exProblem, exSpecial } from '../../../assets';
import './TeleMedicine.scss';

const TelMedicine = () => {
	return (
		<div className='telMedicine'>
			<div className='top'>
				<img src='/banner/1.jpg' alt='banner' />
			</div>

			<div className='tel__container'>
				<div className='bottom'>
					<Link to='/specialists' className='card'>
						<div className='cover-img'>
							<img src='/tele-medicine/1.png' alt='1' />
						</div>

						<p>Find The Doctors</p>
					</Link>
					<Link to='/diseases' className='card'>
						<div className='cover-img'>
							<img src='/tele-medicine/2.png' alt='2' />
						</div>

						<p>Explore Diseases</p>
					</Link>
					<div className='card'>
						<div className='cover-img'>
							<img src='/tele-medicine/3.png' alt='3' />
						</div>

						<p>Explore Healthcare Center</p>
					</div>
					<div className='card'>
						<div className='cover-img'>
							<img src='/tele-medicine/4.png' alt='4' />
						</div>

						<p>New Arena for Mental Health</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TelMedicine;
