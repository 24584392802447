import jwt_decode from 'jwt-decode';
import { createSlice } from "@reduxjs/toolkit";


// check if token is saved in local storage and if it is, decode it and return true if it is not expired. If it is expired, remove it from local storage and return false

const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
        const decoded = jwt_decode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            localStorage.removeItem("token");
            return false;
        }
        return true;
    }
    return false;
}


const initialState = {
    info: checkToken() ? jwt_decode(localStorage.getItem("token")) : null,
    token: checkToken() ? localStorage.getItem("token") : null,
};


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            const token = action.payload;
            const info = jwt_decode(token);

            state.info = info;
            state.token = token;
            localStorage.setItem('token', token);
        },
        logout: (state) => {
            state.info = null;
            state.token = null;
            localStorage.removeItem('token');
        },
        isLoggedIn: (state) => {
            const token = localStorage.getItem('token');
            if (token) {
                state.token = token;
                state.info = jwt_decode(token);
            } else {
                state.info = null;
                state.token = null;
            }
        },
        isExpired: (state) => {
            const token = localStorage.getItem('token');
            if (token) {
                const decoded = jwt_decode(token);
                const currentTime = Date.now() / 1000;
                if (decoded.exp < currentTime) {
                    state.info = null;
                    state.token = null;
                    localStorage.removeItem('token');
                }
            }
        }
    }
});


export const { login, logout, isLoggedIn, isExpired } = authSlice.actions;

export default authSlice.reducer;