import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './Patients.scss';
import Table from './Table';

const PatientsList = () => {
	// page title

	window.document.title = 'Patients List | Romatoo';

	// hooks

	const navigate = useNavigate();

	// states

	const [patients, setPatients] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState('');

	// effects

	React.useEffect(() => {
		const fetchPatients = async () => {
			setLoading(true);
			const url = `${process.env.REACT_APP_API}/admin/list/patients`;

			try {
				const req = await fetch(url, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': localStorage.getItem('token'),
					},
				});
				const res = await req.json();

				if (res.success) {
					console.log('patients list : ', res);
					setPatients(res.data);
				} else {
					console.log('patients list error : ', res);
					setError(res.message);
				}
			} catch (error) {
				toast.error('Something went wrong');
			} finally {
				setLoading(false);
			}
		};
		fetchPatients();
	}, []);

	if (loading) return <p>Loading...</p>;

	return (
		<div className='admin__doctors'>
			<h1>Patients List Page</h1>

			<div className='table__container'>
				<Table rows={patients} />
			</div>
		</div>
	);
};

export default PatientsList;
