import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import './BloodDonation.scss';

const DonationRequest = () => {
	const boxStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		// border: '2px solid',
		boxShadow: 24,
		borderRadius: 1,
		p: 4,
	};

	const { id } = useParams();

	const user = useSelector((state) => state.auth);

	const [requestData, setRequestData] = React.useState(null);
	const [formData, setFormData] = React.useState({
		name: '',
		phone: '',
		profession: '',
		position: '',
		country: '',
		city: '',
	});

	const [acceptModal, setAcceptModal] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [loading2, setLoading2] = React.useState(false);

	const fetchDonationRequest = async () => {
		const url = `${process.env.REACT_APP_API}/doctor/blood-donations/${id}`;

		try {
			const req = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': user.token,
				},
			});

			const res = await req.json();

			console.log('get blood donation request res -> ', res);

			if (res.success) {
				setRequestData(res.data);
			} else {
				toast.error(res.message);
			}
		} catch (err) {
			console.log('err -> ', err);
			toast.error('Something went wrong');
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		fetchDonationRequest();
	}, []);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async () => {
		setLoading2(true);

		if (
			!formData.name ||
			!formData.phone ||
			!formData.country ||
			!formData.city
		) {
			toast.error('Please fill all required fields');
			return;
		}

		console.log('formData -> ', formData);

		const url = `${process.env.REACT_APP_API}/doctor/blood-donations/${id}`;

		try {
			const req = await fetch(url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': user.token,
				},
				body: JSON.stringify(formData),
			});

			const res = await req.json();

			console.log('req blood donation -> ', res);

			if (res.success) {
				toast.success('Blood donation request accepted successfully');
				fetchDonationRequest();
				setAcceptModal(false);
			} else {
				toast.error(res.message);
			}
		} catch (err) {
			console.log('err -> ', err);
			toast.error('Something went wrong');
		} finally {
			setLoading2(false);
		}
	};

	return (
		<div className='donationRequest page'>
			<h1>Blood Donation Request</h1>

			<div className='request__details'>
				<div className='grid grid-cols-1 sm:grid-cols-2'>
					<p>
						<span>Blood Group:</span> {requestData?.bloodGroup}
					</p>

					<p>
						<span>Location:</span> {requestData?.location}
					</p>
				</div>

				<div className='grid grid-cols-1 sm:grid-cols-2'>
					<p>
						<span>Name:</span> {requestData?.name}
					</p>

					<p>
						<span>Contact No:</span> {requestData?.phone}
					</p>
				</div>

				<div className='grid grid-cols-1 sm:grid-cols-2'>
					<p>
						<span>Country:</span> {requestData?.country}
					</p>

					<p>
						<span>City:</span> {requestData?.city}
					</p>
				</div>

				<div className='grid grid-cols-1 sm:grid-cols-2'>
					<p>
						<span>Date:</span> {requestData?.date}
					</p>

					<p>
						<span>Time:</span> {requestData?.time}
					</p>
				</div>

				<div className='grid grid-cols-1 sm:grid-cols-2'>
					{requestData?.profession && (
						<p>
							<span>Profession:</span> {requestData?.profession}
						</p>
					)}

					{requestData?.position && (
						<p>
							<span>Current Position:</span> {requestData?.position}
						</p>
					)}
				</div>

				{requestData?.notesForDonor && (
					<p>
						<span>Notes for Donor:</span> {requestData?.notesForDonor}
					</p>
				)}

				{requestData?.status === 'Accepted' ? (
					<>
						<h2>Donor Details</h2>

						<div className='grid grid-cols-1 sm:grid-cols-2'>
							<p>
								<span>Name:</span> {requestData?.donorInfo?.name}
							</p>

							<p>
								<span>Phone:</span> {requestData?.donorInfo?.phone}
							</p>
						</div>

						<div className='grid grid-cols-1 sm:grid-cols-2'>
							<p>
								<span>Country:</span> {requestData?.donorInfo?.country}
							</p>

							<p>
								<span>City:</span> {requestData?.donorInfo?.city}
							</p>
						</div>

						<div className='grid grid-cols-1 sm:grid-cols-2'>
							{requestData?.donorInfo?.profession && (
								<p>
									<span>Profession:</span> {requestData?.donorInfo?.profession}
								</p>
							)}

							{requestData?.donorInfo?.position && (
								<p>
									<span>Current Position:</span>{' '}
									{requestData?.donorInfo?.position}
								</p>
							)}
						</div>
					</>
				) : (
					<p>
						<span>Status:</span> Pending
					</p>
				)}
			</div>

			{user.info?.id !== requestData?.author && (
				<button
					className='btn-accept'
					onClick={() => setAcceptModal(true)}
					disabled={loading || loading2 || requestData?.status === 'Accepted'}
				>
					{requestData?.status === 'Accepted'
						? 'Already Accepted'
						: 'Accept Request'}
				</button>
			)}

			<Modal
				open={acceptModal}
				onClose={() => setAcceptModal(false)}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={boxStyle}>
					<div className='blood__modal__container'>
						<h1>Accept Blood Donation Request</h1>

						<div className='form__group'>
							<div className='input__group'>
								<label htmlFor='name'>Name</label>

								<input
									type='text'
									name='name'
									id='name'
									onChange={handleChange}
								/>
							</div>

							<div className='input__group'>
								<label htmlFor='phone'>Current Phone Number</label>

								<input
									type='tel'
									name='phone'
									id='phone'
									onChange={handleChange}
								/>
							</div>
						</div>

						<div className='form__group'>
							<div className='input__group'>
								<label htmlFor='profession'>Profession</label>

								<input
									type='text'
									name='profession'
									id='profession'
									onChange={handleChange}
								/>
							</div>

							<div className='input__group'>
								<label htmlFor='position'>Current Position</label>

								<input
									type='text'
									name='position'
									id='position'
									onChange={handleChange}
								/>
							</div>
						</div>

						<div className='form__group'>
							<div className='input__group'>
								<label htmlFor='country'>Country</label>

								<input
									type='text'
									name='country'
									id='country'
									onChange={handleChange}
								/>
							</div>

							<div className='input__group'>
								<label htmlFor='city'>City</label>

								<input
									type='text'
									name='city'
									id='city'
									onChange={handleChange}
								/>
							</div>
						</div>

						<button className='btn-accept' onClick={handleSubmit}>
							Accept Request
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default DonationRequest;
