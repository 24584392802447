import React from 'react';
import { ImAttachment } from 'react-icons/im';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import './BloodDonation.scss';

const bloodTypes = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];

const BloodDonation = () => {
	const user = useSelector((state) => state.auth);

	const [activity, setActivity] = React.useState('Yes'); // ['Yes', 'No'
	const [formData, setFormData] = React.useState({
		name: '',
		phone: '',
		currentProfession: '',
		currentPosition: '',
		country: '',
		city: '',
		bloodGroup: '',
		location: '',
		date: '',
		time: '',
		notesForDonor: '',
		attachments: '',
	});

	const [loading, setLoading] = React.useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async () => {
		setLoading(true);

		if (
			!formData.name ||
			!formData.phone ||
			!formData.country ||
			!formData.city ||
			!formData.bloodGroup ||
			!formData.location ||
			!formData.date ||
			!formData.time
		) {
			toast.error('Please fill all required fields');
			return;
		}

		console.log('formData -> ', formData);

		const url = `${process.env.REACT_APP_API}/doctor/blood-donations`;

		try {
			const req = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': user.token,
				},
				body: JSON.stringify(formData),
			});

			const res = await req.json();

			console.log('req blood donation -> ', res);

			if (res.success) {
				toast.success('Blood donation request submitted successfully');
				setFormData({
					name: '',
					phone: '',
					currentProfession: '',
					currentPosition: '',
					country: '',
					city: '',
					bloodGroup: '',
					location: '',
					date: '',
					time: '',
					notesForDonor: '',
					attachments: '',
				});
			} else {
				toast.error(res.message);
			}
		} catch (error) {
			console.log('error -> ', error);
			toast.error('Something went wrong');
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className='bloodDonation page'>
			<h1>Request Blood Donation</h1>

			<div className='form__group'>
				<div className='input__group'>
					<label htmlFor='name'>Name</label>

					<input type='text' name='name' id='name' onChange={handleChange} />
				</div>

				<div className='input__group'>
					<label htmlFor='phone'>Current Phone Number</label>

					<input type='tel' name='phone' id='phone' onChange={handleChange} />
				</div>
			</div>

			<div className='form__group'>
				<div className='input__group'>
					<label htmlFor='currentProfession'>Profession</label>

					<input
						type='text'
						name='currentProfession'
						id='currentProfession'
						onChange={handleChange}
					/>
				</div>

				<div className='input__group'>
					<label htmlFor='currentPosition'>Current Position</label>

					<input
						type='text'
						name='currentPosition'
						id='currentPosition'
						onChange={handleChange}
					/>
				</div>
			</div>

			<div className='form__group'>
				<div className='input__group'>
					<label htmlFor='country'>Country</label>

					<input
						type='text'
						name='country'
						id='country'
						onChange={handleChange}
					/>
				</div>

				<div className='input__group'>
					<label htmlFor='city'>City</label>

					<input type='text' name='city' id='city' onChange={handleChange} />
				</div>
			</div>

			<div className='radio__input__group first'>
				<h4>
					Are you interested in being involved in blood donation activities?
				</h4>

				<div className='radio__group'>
					<div className='radio__item'>
						<input
							type='radio'
							name='bloodDonation'
							id='bloodDonationYes'
							value='Yes'
							defaultChecked
							onChange={(e) => setActivity(e.target.value)}
						/>
						<label htmlFor='bloodDonationYes'>Yes</label>
					</div>

					<div className='radio__item'>
						<input
							type='radio'
							name='bloodDonation'
							id='bloodDonationNo'
							value='No'
							onChange={(e) => setActivity(e.target.value)}
						/>
						<label htmlFor='bloodDonationNo'>No</label>
					</div>
				</div>
			</div>

			<div className='radio__input__group'>
				<h4>Select Your Blood Group</h4>

				<div className='radio__group'>
					{bloodTypes.map((bloodType, index) => (
						<div className='radio__item' key={index}>
							<input
								type='radio'
								name='bloodGroup'
								id={`bloodGroup${bloodType}`}
								value={bloodType}
								onChange={handleChange}
							/>
							<label htmlFor={`bloodGroup${bloodType}`}>{bloodType}</label>
						</div>
					))}
				</div>
			</div>

			<div className='input__group'>
				<label htmlFor='location'>
					Current Location Where Blood transversion
				</label>

				<input
					type='text'
					name='location'
					id='location'
					onChange={handleChange}
				/>
			</div>

			<div className='form__group'>
				<div className='input__group'>
					<label htmlFor='date'>Date</label>

					<input type='text' name='date' id='date' onChange={handleChange} />
				</div>

				<div className='input__group'>
					<label htmlFor='time'>Time</label>

					<input type='text' name='time' id='time' onChange={handleChange} />
				</div>
			</div>

			<div className='form__group'>
				<div className='input__group'>
					<label htmlFor='notesForDonor'>Any Type of Message for Donor</label>

					<textarea
						name='notesForDonor'
						id='notesForDonor'
						onChange={handleChange}
					/>
				</div>

				<div className='input__group'>
					<label htmlFor='attachments'>
						Attachment (It's optional but it's share any proved help you very
						fast process)
					</label>

					<input type='file' name='attachments' id='attachments' hidden />

					<label htmlFor='attachments' className='file__label'>
						<ImAttachment className='icon' />
					</label>
				</div>
			</div>

			<button
				className='btn-submit'
				onClick={handleSubmit}
				disabled={loading || activity === 'No'}
			>
				Submit
			</button>
		</div>
	);
};

export default BloodDonation;
