import React from 'react';
import { BsPlayBtnFill } from 'react-icons/bs';
import { IoClose, IoImage } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useImageUpload from '../../../hooks/useImageUpload';

const CreatePostPopup = (props) => {
	const { allPosts, setAllPosts, popupState, setPopupState } = props;

	const authInfo = useSelector((state) => state.auth.info);
	const authToken = useSelector((state) => state.auth.token);
	const { uploadImage, uploadLoading } = useImageUpload();

	const divRef = React.useRef(null);
	const videoRef = React.useRef(null);

	const [postText, setPostText] = React.useState('');
	const [postImages, setPostImages] = React.useState([]);
	const [postVideo, setPostVideo] = React.useState(null);
	const [vidPreview, setVidPreview] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		const handleClickOutside = (event) => {
			if (divRef.current && !divRef.current.contains(event.target)) {
				setPopupState(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleVideoChange = (e) => {
		// check if file is video

		if (e.target.files[0].type !== 'video/mp4') {
			// alert('Please upload a video file');
			toast.error('Please upload a video file');
			return;
		}

		// check if file size is less than 20mb

		// if (e.target.files[0].size > 1024 * 1024 * 20) {
		// 	// alert('Please upload a video less than 20mb');
		// 	toast.error('Please upload a video less than 20mb');
		// 	return;
		// }

		setPostVideo(e.target.files[0]);
		setVidPreview(URL.createObjectURL(e.target.files[0]));
	};

	const handlePost = async () => {
		setLoading(true);

		// const postData = {
		// 	text: postText,
		// 	images: postImages,
		// 	userRole: authInfo.role === 'doctor' ? 'Doctor' : 'Patient',
		// 	author: authInfo.id,
		// 	video: postVideo,
		// };

		const formData = new FormData();

		formData.append('text', postText);
		formData.append(
			'userRole',
			authInfo.role === 'doctor' ? 'Doctor' : 'Patient'
		);
		formData.append('author', authInfo.id);
		formData.append('file', postVideo);
		formData.append('images', JSON.stringify(postImages));

		const url = `${process.env.REACT_APP_API}/doctor/post/create`;

		try {
			const req = await fetch(url, {
				method: 'POST',
				// headers: {
				// 	'Content-Type': 'application/json',
				// 	'x-access-token': authToken,
				// },
				// body: JSON.stringify(postData),
				headers: {
					'x-access-token': authToken,
				},
				body: formData,
			});

			const res = await req.json();

			console.log('createPost red -> ', res);

			if (res.success) {
				setAllPosts((prev) => [res.data, ...prev]);
				setPostText('');
				setPostImages([]);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setPopupState(false);
		}
	};

	return (
		<div className='createPostPopup'>
			<div className='createPostPopup__container' ref={divRef}>
				<div className='createPost__header'>
					<h1>Create Post</h1>
					<button onClick={() => setPopupState(false)}>
						<IoClose className='icon' />
					</button>
				</div>

				<div className='createPost__body'>
					<div className='body__top'>
						<div className='body_avatar'>
							<img
								src={
									authInfo.profilePicture
										? authInfo.profilePicture
										: authInfo.gender === 'Male'
										? '/my-profile/male-avatar.png'
										: '/my-profile/female-avatar.png'
								}
								alt='avatar'
							/>
						</div>

						<div className='info'>
							<h1>{authInfo.fullName}</h1>
							{authInfo.profileInfo?.title && (
								<p>{authInfo.profileInfo.title}</p>
							)}
						</div>
					</div>

					<div className='body__middle'>
						<textarea
							name='post'
							id='post'
							placeholder="What's on your mind?"
							value={postText}
							onChange={(e) => setPostText(e.target.value)}
						></textarea>
					</div>

					{postImages.length > 0 ? (
						<div className='postImages'>
							{postImages.map((img, index) => (
								<div className='postImages__img' key={index}>
									<img src={img} alt='post' />

									<button
										className='postImages__img__overlay'
										onClick={() => {
											setPostImages((prev) => {
												const newImages = [...prev];
												newImages.splice(index, 1);
												return newImages;
											});
										}}
									>
										<IoClose className='icon' />
									</button>
								</div>
							))}
						</div>
					) : null}

					{vidPreview ? (
						<div className='vidPreview'>
							<video src={vidPreview} controls></video>

							<button
								className='vidPreview__overlay'
								onClick={() => {
									setPostVideo(null);
									setVidPreview(null);
									videoRef.current.value = null;
								}}
							>
								<IoClose className='icon' />
							</button>
						</div>
					) : null}
				</div>

				<div className='createPost__footer'>
					<div className='footer__left'>
						<label htmlFor='uploadPostImg'>
							<IoImage className='icon' />
						</label>

						<input
							type='file'
							name='uploadPostImg'
							id='uploadPostImg'
							accept='image/jpeg, image/png, image/jpg'
							multiple={false}
							onChange={(e) => {
								uploadImage(e, setPostImages);
							}}
							className='hidden'
						/>

						<label htmlFor='uploadPostVideo'>
							<BsPlayBtnFill className='icon' />
						</label>

						<input
							type='file'
							ref={videoRef}
							name='uploadPostVideo'
							id='uploadPostVideo'
							accept='video/mp4'
							multiple={false}
							className='hidden'
							onChange={handleVideoChange}
						/>
					</div>

					<button
						disabled={
							(postText.trim().length === 0 &&
								postImages.length === 0 &&
								postVideo === null) ||
							loading
						}
						className='btn-post'
						onClick={handlePost}
					>
						Post
					</button>
				</div>
			</div>
		</div>
	);
};

export default CreatePostPopup;
