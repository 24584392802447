import React from 'react';

const HealthAnalysis = () => {
	return (
		<div className='health__analysis'>
			<h1>Health analysis coming soon...</h1>
		</div>
	);
};

export default HealthAnalysis;
