import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import './Messages.scss';

const Messages = () => {
	const { pathname } = useLocation();
	const token = useSelector((state) => state.auth?.token);
	const user = useSelector((state) => state.auth?.info);

	const [chats, setChats] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		setLoading(true);

		let url = '';

		if (user?.role === 'doctor') {
			url = `${process.env.REACT_APP_API}/doctor/conversations`;
		} else {
			url = `${process.env.REACT_APP_API}/patient/conversations`;
		}

		fetch(url, {
			method: 'GET',
			headers: {
				'x-access-token': token,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.success) {
					setChats(res.data);
					console.log('all chats : ', res.data);
				} else {
					alert(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<div className='messagesPage page'>
			<div className='box'>
				<div
					className={`conversations ${pathname.length < 10 ? 'active' : ''}`}
				>
					{loading ? (
						<p>Loading...</p>
					) : chats.length === 0 ? (
						<p>No Messages</p>
					) : (
						chats.map((item, index) => (
							<NavLink
								className={({ isActive }) =>
									isActive ? 'conversation active' : 'conversation'
								}
								to={`/messages/${item._id}`}
								key={index}
							>
								<div className='image'>
									<img
										src={
											user?.id === item?.doctor?._id
												? item?.userId?.profileInfo?.profilePicture ||
												  '/images/avatar.png'
												: item?.doctor?.profileInfo?.profilePicture ||
												  '/images/avatar.png'
										}
										alt={
											user?.id === item?.doctor?._id
												? item?.userId?.fullName
												: item?.doctor?.fullName
										}
									/>
								</div>

								<div className='body'>
									<div className='top'>
										<h4 className='name'>
											{user?.id === item?.doctor?._id
												? item?.userId?.fullName
												: item?.doctor?.fullName}
										</h4>

										<span className='time'>
											{item?.lastMessage?.createdAt?.split('T')[0] ||
												item?.createdAt?.split('T')[0]}
										</span>
									</div>

									<p>
										{item?.lastMessage?.messageText
											? item?.lastMessage?.messageText
											: item?.lastMessage?.attachment.length > 0
											? 'Image'
											: 'You can chat now'}
									</p>
								</div>
							</NavLink>
						))
					)}
				</div>

				<div
					className={`opened-conversation ${
						pathname.length > 10 ? 'active' : ''
					}`}
				>
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default Messages;
