import React from 'react';
import './Settings.scss';

const AdminSettings = () => {
	return (
		<div className='admin__settings'>
			<h1>Settings Page</h1>
		</div>
	);
};

export default AdminSettings;
