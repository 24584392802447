import React, { createContext, useState, useRef, useEffect } from 'react';
import { io } from 'socket.io-client';
import Peer from 'simple-peer';
import * as process from 'process';
import { useNavigate } from 'react-router-dom';

window.global = window;
window.process = process;
window.Buffer = [];

const SocketContext = createContext();

const socket = io(process.env.REACT_APP_SOCKET, {
	path: process.env.REACT_APP_SOCKET_VIDEO_PATH,
});

const ContextProvider = ({ children }) => {
	const navigate = useNavigate();

	const [roomId, setRoomId] = useState('');
	const [myVideoOn, setMyVideoOn] = useState(true);
	const [myAudioOn, setMyAudioOn] = useState(true);
	const [screenShareOn, setScreenShareOn] = useState(false);
	const [callAccepted, setCallAccepted] = useState(false);
	const [callEnded, setCallEnded] = useState(false);
	const [stream, setStream] = useState();
	const [name, setName] = useState('');
	const [call, setCall] = useState({});
	const [me, setMe] = useState('');

	const myVideo = useRef();
	const userVideo = useRef();
	const connectionRef = useRef();

	useEffect(() => {
		navigator.mediaDevices
			.getUserMedia({ video: true, audio: true })
			.then((currentStream) => {
				setStream(currentStream);

				if (myVideo.current) {
					myVideo.current.srcObject = currentStream;
				}
			});

		socket.on('me', (id) => setMe(id));

		if (roomId) {
			socket.emit('join', roomId);
		}
	}, [roomId]);

	useEffect(() => {
		socket.on('call-made', (data) => {
			const { from, name: callerName, signal } = data;

			setCall({ isReceivingCall: true, from, name: callerName, signal });
		});

		socket.on('callEnded', (data) => {
			console.log('callEnded', data);

			if (connectionRef.current) {
				setCallAccepted(false);
				setCall({});

				connectionRef.current.destroy();
				connectionRef.current = null;
				navigate(0);
			}
		});

		return () => {
			socket.off('call-made');
			socket.off('callEnded');

			socket.off('callAccepted');
		};
	}, [socket]);

	const answerCall = () => {
		setCallAccepted(true);

		const peer = new Peer({
			initiator: false,
			trickle: false,
			stream,
			config: {
				iceServers: [
					{
						urls: 'stun:95.111.194.65:3478',
						username: 'test',
						credential: 'secret',
					},
					{
						urls: 'turn:95.111.194.65:3478',
						username: 'test',
						credential: 'secret',
					},
				],
			},
		});

		peer.on('signal', (data) => {
			socket.emit('answerCall', { signal: data, roomId });
		});

		peer.on('stream', (currentStream) => {
			try {
				userVideo.current.srcObject = currentStream;
			} catch (e) {
				console.log(e);
			}
		});

		peer.signal(call.signal);

		connectionRef.current = peer;
	};

	const callUser = () => {
		const peer = new Peer({
			initiator: true,
			trickle: false,
			stream,
			config: {
				iceServers: [
					{
						urls: 'stun:95.111.194.65:3478',
						username: 'test',
						credential: 'secret',
					},
					{
						urls: 'turn:95.111.194.65:3478',
						username: 'test',
						credential: 'secret',
					},
				],
			},
		});

		peer.on('signal', (data) => {
			socket.emit('callUser', {
				roomId,
				signalData: data,
				from: me,
				name,
			});
		});

		peer.on('stream', (currentStream) => {
			userVideo.current.srcObject = currentStream;
		});

		socket.on('callAccepted', (signal) => {
			setCallAccepted(true);

			peer.signal(signal);
		});

		connectionRef.current = peer;
	};

	const leaveCall = () => {
		socket.emit('call-ended');
		setCallAccepted(false);
		setCall({});
		connectionRef.current.destroy();
		connectionRef.current = null;
		userVideo.current.srcObject = null;

		socket.off('callAccepted');
		socket.off('answerCall');

		socket.off('callUser');
		socket.off('callEnded');
		navigate(0);
	};

	const toggleMyVideo = () => {
		const videoTrack = stream
			.getTracks()
			.find((track) => track.kind === 'video');

		if (videoTrack.enabled) {
			videoTrack.enabled = false;
			setMyVideoOn(false);
		} else {
			videoTrack.enabled = true;
			setMyVideoOn(true);
		}
	};

	const toggleMyAudio = () => {
		const audioTrack = stream
			.getTracks()
			.find((track) => track.kind === 'audio');

		if (audioTrack.enabled) {
			audioTrack.enabled = false;
			setMyAudioOn(false);
		} else {
			audioTrack.enabled = true;
			setMyAudioOn(true);
		}
	};

	const toggleScreenShare = () => {
		if (!screenShareOn) {
			navigator.mediaDevices
				.getDisplayMedia({ cursor: true })
				.then((screenStream) => {
					connectionRef.current.replaceTrack(
						stream.getVideoTracks()[0],
						screenStream.getVideoTracks()[0],
						stream
					);
					myVideo.current.srcObject = screenStream;
					screenStream.getTracks()[0].onended = () => {
						connectionRef.current.replaceTrack(
							screenStream.getVideoTracks()[0],
							stream.getVideoTracks()[0],
							stream
						);
						myVideo.current.srcObject = stream;
					};
				});
			setScreenShareOn(true);
		} else {
			navigator.mediaDevices
				.getUserMedia({ video: true, audio: true })
				.then((currentStream) => {
					connectionRef.current.replaceTrack(
						stream.getVideoTracks()[0],
						currentStream.getVideoTracks()[0],
						stream
					);
					myVideo.current.srcObject = currentStream;
				});
			setScreenShareOn(false);
		}
	};

	return (
		<SocketContext.Provider
			value={{
				call,
				callAccepted,
				myVideo,
				userVideo,
				stream,
				name,
				setName,
				callEnded,
				me,
				callUser,
				leaveCall,
				answerCall,
				setRoomId,
				toggleMyVideo,
				myVideoOn,
				toggleMyAudio,
				myAudioOn,
				toggleScreenShare,
				screenShareOn,
			}}
		>
			{children}
		</SocketContext.Provider>
	);
};

export { ContextProvider, SocketContext };
