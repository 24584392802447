import React from 'react';
import './HelpCenter.scss';

const HelpCenter = () => {
	return (
		<div className='help__center'>
			<h1>Help center coming soon...</h1>
		</div>
	);
};

export default HelpCenter;
