import React from 'react';
import { useParams } from 'react-router-dom';
import './BlogPost.scss';

const posts = [
	{
		id: '1',
		text: `
		A healthy diet is essential for good health and nutrition. It protects you against many chronic noncommunicable diseases, such as heart disease, diabetes and cancer. Eating a variety of foods and consuming less salt, sugars and saturated and industrially-produced trans-fats, are essential for a healthy diet.

		Some of the benefits of a healthy diet are:
		
		- It may help you live longer. According to the World Health Organization (WHO), a healthy diet can reduce the risk of premature death from noncommunicable diseases³.
		- It keeps your skin, teeth and eyes healthy. A healthy diet provides you with the vitamins, minerals and antioxidants that nourish your body and protect it from infections and aging².
		- It supports your muscles, bones and joints. A healthy diet provides you with the protein, calcium and other nutrients that are essential for building and maintaining strong muscles, bones and joints².
		- It boosts your immunity. A healthy diet strengthens your immune system and helps you fight off diseases and infections².
		- It improves your mood and mental health. A healthy diet can enhance your brain function, memory and mood. It can also help prevent or manage depression, anxiety and other mental disorders¹.
		- It supports healthy pregnancies and breastfeeding. A healthy diet is vital for the health of both the mother and the baby during pregnancy and breastfeeding. It can prevent birth defects, low birth weight, preterm delivery and other complications².
		- It helps your digestive system function properly. A healthy diet includes plenty of fiber, which helps regulate your bowel movements and prevent constipation, diarrhea, irritable bowel syndrome and other digestive problems¹.
		- It helps you achieve and maintain a healthy weight. A healthy diet can help you balance your energy intake and expenditure, which can prevent obesity and overweight. It can also help you lose weight if you are overweight or obese.
		`,
	},
	{
		id: '2',
		text: `
		There are many different things you can do to maintain good mental health, such as:

		- Staying positive. It's important to try to have a positive outlook and find balance between positive and negative emotions. You can practice gratitude, which means being thankful for the good things in your life. You can also take a break from negative information, such as the news or social media, and focus on the world around you¹².
		- Taking care of your physical health. Your physical and mental health are connected, so it's important to eat well, exercise regularly, get enough sleep, and avoid harmful substances such as drugs, alcohol or tobacco¹²³.
		- Doing activities that you enjoy. Try to continue doing the activities that you find meaningful and enjoyable, such as cooking, playing, reading, watching, or gardening. Having a regular routine with activities that make you feel happy will help you maintain good mental health¹².
		- Talking to someone you trust. Talking to someone you trust, such as a friend, a family member, or a colleague, can help you cope with stress and express your feelings. You can stay connected with your loved ones through a video call, phone call or messaging app if face-to-face interactions are limited¹².
		- Seeking professional help if needed. If you feel overwhelmed, depressed, anxious, or suicidal, you should not hesitate to seek professional help. There are many mental health services and resources available that can provide you with support and treatment. You can also call a helpline or chat online with a counselor if you need someone to talk to.
		`,
	},
	{
		id: '3',
		text: `
		Meditation can give you a sense of calm, peace and balance that can benefit both your emotional well-being and your overall health⁴. You can also use it to relax and cope with stress by refocusing your attention on something calming. Meditation can help you learn to stay centered and keep inner peace.

		Some of the benefits of meditation according to science are:
		
		- It reduces stress and anxiety by lowering the levels of the stress hormone cortisol and the inflammatory chemicals called cytokines¹.
		- It improves your mood and mental health by enhancing your brain function, memory and positive emotions. It can also help prevent or manage depression, anxiety and other mental disorders¹².
		- It slows down brain aging and boosts cognitive skills, such as attention, innovation and problem-solving².
		- It supports healthy pregnancies and breastfeeding by providing nutrients and hormones that are vital for the mother and the baby¹.
		- It helps your digestive system function properly by increasing the fiber intake and regulating your bowel movements¹.
		- It helps you achieve and maintain a healthy weight by balancing your energy intake and expenditure¹.
		
		If you want to start meditating, here are some tips for beginners³:
		
		- Find a quiet and comfortable place where you won't be disturbed.
		- Choose a time of day when you are alert and relaxed, such as in the morning or evening.
		- Start with a short session of 5 to 10 minutes and gradually increase the duration as you feel more comfortable.
		- Sit or lie down in a comfortable posture, with your back straight and your shoulders relaxed.
		- Close your eyes or lower your gaze, whichever feels more natural for you.
		- Focus on your breathing, noticing how it flows in and out of your body. You can also use a sound, a visual image, or a mantra to help you focus.
		- If your mind wanders, gently bring it back to your focus without judging yourself.
		- At the end of your session, open your eyes slowly and take a moment to appreciate how you feel.
		
		I hope this information helps you understand the benefits of meditation and how to practice it. Meditation is a wonderful tool for improving your health and well-being. I encourage you to try it and see how it works for you.
		`,
	},
	{
		id: '4',
		text: `
		Social fitness is the ability to engage in productive personal and professional relationships, positively interact with unit and command networks, and use resources that promote overall well-being¹. It is an important aspect of your health and well-being, as it can affect your physical, mental, and emotional fitness. Here are some tips for improving your social fitness:

		- Be a good listener and show empathy. Listening and empathizing are essential skills for building trust, understanding, and rapport with others. They can also help you resolve conflicts, support others, and express your feelings².
		- Spend quality time with your friends and family. Having strong social ties can boost your happiness, reduce your stress, and enhance your immune system. Try to make time for fun activities, hobbies, or recreation with your loved ones³.
		- Join a group or a class that interests you. This can help you meet new people who share your passions, learn new skills, and expand your social network. You can also benefit from the positive feedback and encouragement from others⁴.
		- Be authentic and honest. Being yourself and expressing your true thoughts and feelings can help you build genuine and meaningful relationships. It can also increase your self-esteem, confidence, and respect⁵.
		- Seek professional help if needed. If you struggle with social anxiety, loneliness, depression, or other mental health issues that affect your social fitness, don't hesitate to reach out for support. There are many resources and services available that can help you cope and improve your social well-being.
		`,
	},
	{
		id: '5',
		text: `
		Here are some tips for maintaining physical fitness:

		- Set realistic and specific goals that are meaningful to you. For example, instead of saying "I want to lose weight", you could say "I want to lose 10 pounds in 3 months by walking 30 minutes a day and eating more vegetables". This will help you track your progress and stay motivated¹.
		- Find activities that you enjoy and that suit your lifestyle. You don't have to go to the gym or run a marathon to be fit. You can try dancing, gardening, hiking, swimming, playing with your kids or pets, or joining a sports team or a class. The key is to have fun and be consistent²³.
		- Vary your routine and challenge yourself. Doing the same thing every day can get boring and make your body adapt and plateau. Try to mix up different types of exercises, such as cardio, strength, flexibility, and balance. You can also increase the intensity, duration, frequency, or complexity of your workouts as you get fitter⁴⁵.
		- Listen to your body and rest when needed. Physical fitness is not about pushing yourself to the limit or ignoring pain. It is about respecting your limits and taking care of yourself. If you feel tired, sore, or sick, take a break and let your body recover. You can also use active recovery techniques, such as stretching, massage, or foam rolling⁶⁷.
		- Seek support and feedback from others. Having a workout buddy, a coach, a trainer, or a group can make your fitness journey more enjoyable and effective. They can provide you with encouragement, accountability, advice, and feedback. You can also share your goals, challenges, and achievements with them.
		`,
	},
	{
		id: '6',
		text: `
		Here are some tips for a healthy diet during pregnancy:

		- Eat a variety of foods from different food groups, including fruits, vegetables, whole grains, protein sources, and dairy products. These foods provide you with the essential nutrients that you and your baby need, such as vitamin A, vitamin C, folic acid, iron, calcium, and potassium¹⁴.
		- Avoid foods and beverages with added sugars, saturated fat, and sodium. These can increase your risk of gestational diabetes, high blood pressure, and excessive weight gain¹⁴.
		- Take a folic acid supplement of 400 micrograms (mcg) a day before conception and 600 to 1,000 mcg a day throughout pregnancy. This can help prevent neural tube defects, which are serious problems with the developing brain and spinal cord of your baby¹³.
		- Limit your intake of caffeine to no more than 200 milligrams (mg) a day. This is equivalent to about two cups of coffee or four cups of tea. Too much caffeine can cause miscarriage, low birth weight, or withdrawal symptoms in your baby⁴⁶.
		- Avoid alcohol and tobacco. These can harm your baby's development and increase the risk of birth defects, premature birth, and low birth weight⁴⁶.
		- Avoid raw or undercooked meat, poultry, eggs, fish, and shellfish. These can contain harmful bacteria or parasites that can cause food poisoning or infections in you or your baby⁴⁶.
		- Avoid unpasteurized milk, cheese, and juice. These can contain harmful bacteria that can cause listeriosis, a serious infection that can lead to miscarriage, stillbirth, or severe illness in your baby⁴⁶.
		- Avoid certain fish that are high in mercury, such as shark, swordfish, king mackerel, and tilefish. Mercury can damage your baby's nervous system. Choose fish that are low in mercury, such as salmon, trout, herring, and sardines⁴⁶.
		
		I hope these tips help you have a healthy diet during pregnancy. Remember to drink plenty of water and eat small frequent meals to avoid nausea and indigestion. You can also consult your health care provider or a registered dietitian for more advice on your specific needs.
		`,
	},
];

const BlogPost = () => {
	const { id } = useParams();

	const post = posts.find((post) => post.id === id);

	return (
		<div className='blogPost page'>
			{post &&
				post.text.split('\n').map((paragraph, index) => {
					if (index === post.text.split('\n').length - 1) {
						return (
							<p key={index}>
								{paragraph}
								<br />
							</p>
						);
					} else {
						return <p key={index}>{paragraph}</p>;
					}
				})}
		</div>
	);
};

export default BlogPost;
