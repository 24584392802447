import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../store/authSlice/authSlice';
import { toast } from 'react-toastify';
import './Login.scss';

const AdminLogin = () => {
	// page title

	window.document.title = 'Admin Login | Romatoo';

	// hooks

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);

	// states

	const [loginData, setLoginData] = React.useState({
		email: '',
		password: '',
	});
	const [error, setError] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	// effects

	React.useEffect(() => {
		if (auth.token) {
			toast.warning('You are already logged in');

			setTimeout(() => {
				navigate('/admin/dashboard');
			}, 2300);
		}
	}, []);

	// functions

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!loginData.email || !loginData.password) {
			setError('Please fill all fields');
			return;
		}

		console.log('login data : ', loginData);

		setError('');
		setLoading(true);

		const url = `${process.env.REACT_APP_API}/admin/login`;

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(loginData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('login response : ', res);

				if (res.success) {
					dispatch(login(res.token));
					toast.success(res.message);

					setTimeout(() => {
						navigate('/admin/dashboard');
					}, 2300);
				} else {
					setError(res.message);
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log('login error : ', err);
				toast.error('Something went wrong');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className='admin__login'>
			<div className='box'>
				<h1>Admin Login</h1>

				<form onSubmit={handleSubmit}>
					<div className='form__group'>
						<label htmlFor='email'>Email</label>
						<input
							type='email'
							name='email'
							id='email'
							value={loginData.email}
							onChange={(e) =>
								setLoginData({
									...loginData,
									email: e.target.value,
								})
							}
						/>
					</div>

					<div className='form__group'>
						<label htmlFor='password'>Password</label>
						<input
							type='password'
							name='password'
							id='password'
							value={loginData.password}
							onChange={(e) =>
								setLoginData({
									...loginData,
									password: e.target.value,
								})
							}
						/>
					</div>

					{error && <p className='error'>{error}</p>}

					<button type='submit'>Login</button>
				</form>
			</div>
		</div>
	);
};

export default AdminLogin;
