import React from 'react';
import './DummyPage.scss';

const DummyPage = () => {
	const [formData, setFormData] = React.useState({
		prescription: null,
		labReport: null,
		other: null,
		doctorName: '',
	});

	const handleSubmit = async () => {
		console.log('formData --> ', formData);

		const bodyData = new FormData();

		bodyData.append('prescription', formData.prescription);
		bodyData.append('labReport', formData.labReport);
		bodyData.append('other', formData.other);
		bodyData.append('doctorName', formData.doctorName);

		const req = await fetch('http://localhost:5000/api/upload', {
			method: 'POST',
			body: bodyData,
		});

		const res = await req.json();

		console.log(res);
	};

	return (
		<div className='dummy-page'>
			<br />

			<input
				type='file'
				name='prescription'
				onChange={(e) => {
					setFormData({
						...formData,
						prescription: e.target.files[0],
					});
				}}
			/>

			<br />

			<input
				type='file'
				name='labReport'
				onChange={(e) => {
					setFormData({
						...formData,
						labReport: e.target.files[0],
					});
				}}
			/>

			<br />

			<input
				type='file'
				name='other'
				onChange={(e) => {
					setFormData({
						...formData,
						other: e.target.files[0],
					});
				}}
			/>

			<br />

			<input
				type='text'
				name='doctorName'
				onChange={(e) => {
					setFormData({
						...formData,
						doctorName: e.target.value,
					});
				}}
			/>

			<br />
			<br />

			<button
				onClick={handleSubmit}
				className='px-8 py-2 bg-blue-500 text-white rounded-md'
			>
				Submit
			</button>
		</div>
	);
};

export default DummyPage;
