import React from 'react';

const DataComparing = () => {
	return (
		<div className='page'>
			<div className='details__concept'>
				<img src='/images/comparing.jpeg' alt='' />
				<div className='concept__container'>
					<h1>Cloud-based healthdata comparing platfrom</h1>
					<p>
						The Romatoo app will collect human health data worldwide
						using intelligence tools. The collected health data will
						be presented using technology such as artificial
						intelligence and data science so that a physician can
						analyze that data in a short time to cure a patient's
						disease. It can play an important role and, at the same
						time, give a patient a conscious idea about their
						health.
					</p>
				</div>
			</div>
		</div>
	);
};

export default DataComparing;
