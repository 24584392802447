import React from 'react';
import { FaPen } from 'react-icons/fa';
import { BsFillGridFill, BsPlusLg } from 'react-icons/bs';
import {
	IoAdd,
	IoTrashOutline,
	IoCreateOutline,
	IoCalendarClearOutline,
} from 'react-icons/io5';
import {
	eDisease,
	eAttach,
	eAI,
	presTest,
	eFolder,
	medData,
} from '../../assets';
import './EPrescription.scss';

const EPrescription = () => {
	const [patientInfo, setPatientInfo] = React.useState({
		name: '',
		age: '',
		gender: '',
		weight: '',
		height: '',
		disease: '',
	});
	const [selectedCategory, setSelectedCategory] = React.useState(
		presTest[0].category
	);

	const [medicines, setMedicines] = React.useState([
		{
			geneticName: '',
			brandName: '',
			dropdown: false,
			timeTable: [
				{
					time: 'breakfast',
					amount: '',
					editable: false,
				},
				{
					time: 'lunch',
					amount: '',
					editable: false,
				},
				{
					time: 'dinner',
					amount: '',
					editable: false,
				},
			],
			duration: '',
			intake: '',
			note: '',
		},
		{
			geneticName: '',
			brandName: '',
			timeTable: [
				{
					time: 'breakfast',
					amount: '',
					editable: false,
				},
				{
					time: 'lunch',
					amount: '',
					editable: false,
				},
				{
					time: 'dinner',
					amount: '',
					editable: false,
				},
			],
			duration: '',
			intake: '',
			note: '',
		},
	]);

	React.useEffect(() => {
		console.log(medicines);
	}, [medicines]);

	const handleCustomTime = (medIndex, timeIndex, value, type) => {
		if (type === 'time') {
			setMedicines((prev) => {
				prev[medIndex].timeTable[timeIndex].time = value;
				return [...prev];
			});
		} else {
			setMedicines((prev) => {
				prev[medIndex].timeTable[timeIndex].amount = value;
				return [...prev];
			});
		}
	};

	const handleGeneticNameChange = (e, medIndex) => {
		if (e.target.value) {
			setMedicines((prev) => {
				prev[medIndex].geneticName = e.target.value;
				return [...prev];
			});
		}
	};

	return (
		<div className='prescription'>
			<div className='box'>
				<h1 className='sec__header'>Patient Info</h1>

				<div className='sec__one'>
					<div className='left'>
						<div className='cover'>
							<img
								src='/my-profile/female-avatar.png'
								alt='avatar'
							/>
						</div>
					</div>

					<div className='right'>
						<h3>Nidhi Sunil</h3>

						<div className='disease'>
							<div className='add'>
								<img src={eDisease} alt='disease' />
								<p>Add Disease</p>
								<FaPen className='icon' />
							</div>
						</div>

						<div className='info'>
							<div className='item'>
								<p>Age</p>
								<span>27</span>
							</div>

							<div className='item'>
								<p>Gender</p>
								<span>Female</span>
							</div>

							<div className='item'>
								<p>Height</p>
								<span>5FT 4IN</span>
							</div>

							<div className='item'>
								<p>Weight</p>
								<span>57KG</span>
							</div>

							<div className='addItem'>
								<IoAdd className='icon' />
								<IoTrashOutline className='icon' />
							</div>
						</div>
					</div>
				</div>

				<h1 className='sec__header'>Past Medical Records</h1>

				<div className='sec__empty'>
					<h1>No Past Medical Records Found</h1>
				</div>

				<h1 className='sec__header'>Present Medical Section</h1>

				<div className='sec__two'>
					<div className='left'>
						<div className='block'>
							<div className='block__content'>
								<h3>Chief Complaints</h3>
								<textarea
									name='chiefComplaints'
									id='chiefComplaints'
									placeholder='Write here...'
								></textarea>
							</div>
						</div>

						<div className='block'>
							<div className='block__content'>
								<h3>Drug History</h3>
								<textarea
									name='drugHistory'
									id='drugHistory'
									placeholder='Write here...'
								></textarea>
							</div>
						</div>

						<div className='block'>
							<div className='block__content'>
								<h3>BP</h3>
								<textarea
									name='bp'
									id='bp'
									placeholder='Ex: 120/80'
								></textarea>
							</div>
						</div>
					</div>

					<div className='right'>
						<IoAdd className='icon' />
						<IoTrashOutline className='icon' />
					</div>
				</div>

				<h1 className='sec__header'>Investigation Chart</h1>

				<div className='sec__test'>
					<div className='left'>
						{presTest.map((item, index) => (
							<button
								key={index}
								onClick={() =>
									setSelectedCategory(item.category)
								}
								className={
									selectedCategory === item.category
										? 'category active'
										: 'category'
								}
							>
								{item.category}
							</button>
						))}
					</div>

					<div className='right'>
						<div className='tests'>
							<div className='tests__container'>
								{presTest
									.find(
										(item) =>
											item.category === selectedCategory
									)
									.tests.map((item, index) => (
										<label htmlFor={item} key={index}>
											<input
												type='checkbox'
												name={item}
												id={item}
											/>
											<span>{item}</span>
										</label>
									))}
							</div>

							<div className='actions'>
								<button>Test Overview</button>
							</div>
						</div>

						<div className='overview'>
							<h1>
								<img src={eAttach} alt='attach' />
								<span>Patient Attachment</span>
							</h1>

							<div className='folder__img'>
								<img src={eFolder} alt='folder' />
							</div>
						</div>
					</div>
				</div>

				<h1 className='ai__header'>
					<img src={eAI} alt='ai' />
					<p>Prescription</p>
				</h1>

				<div className='sec__three'>
					{medicines.map((medicine, medIndex) => (
						<div className='block' key={medIndex}>
							<div className='genetic'>
								<div className='left'>
									<label htmlFor='geneticName'>
										Genetic Name
									</label>
									<div className='input__field'>
										<FaPen className='icon' />
										<input
											type='text'
											name='geneticName'
											id='geneticName'
											placeholder='Add...'
											onChange={(e) => {
												handleGeneticNameChange(
													e,
													medIndex
												);
											}}
										/>
									</div>
								</div>

								<button
									className='right'
									onClick={() => {
										setMedicines((prev) => {
											if (prev.length === 1) {
												return prev;
											} else {
												const newMedicines = [...prev];
												newMedicines.splice(
													medIndex,
													1
												);
												return newMedicines;
											}
										});
									}}
								>
									<IoTrashOutline className='icon' />
								</button>
							</div>

							<div className='brandName'>
								<div className='left'>
									<label htmlFor='brandName'>
										Brand Name
									</label>
									<div className='input__field'>
										<FaPen className='icon' />
										<input
											type='text'
											name='brandName'
											id='brandName'
											placeholder='Add...'
										/>
									</div>
								</div>

								<div className='right'>
									<button
										className='btn-brand'
										onClick={() => {
											setMedicines((prev) => {
												const newMedicines = [...prev];
												newMedicines[
													medIndex
												].dropdown =
													!newMedicines[medIndex]
														.dropdown;
												return newMedicines;
											});
										}}
									>
										<BsFillGridFill className='icon' />
									</button>

									<ul
										className={
											medicine.dropdown
												? 'brand__dropdown active'
												: 'brand__dropdown'
										}
									>
										<li>Brand 1</li>
										<li>Brand 2</li>
										<li>Brand 3</li>
										<li>Brand 4</li>
										<li>Brand 5</li>
										<li>Brand 6</li>
										<li>Brand 7</li>
										<li>Brand 8</li>
										<li>Brand 9</li>
									</ul>
								</div>
							</div>

							<div className='timeTable'>
								<p>Time Table & Amount</p>

								<div className='times__container'>
									<div className='times'>
										{medicine.timeTable.map(
											(item, timeIndex) =>
												item.editable === false ? (
													<div
														className='time'
														key={timeIndex}
													>
														<label
															htmlFor={item.time}
														>
															{item.time
																.charAt(0)
																.toUpperCase() +
																item.time.slice(
																	1
																)}
														</label>

														<input
															type='number'
															name={item.time}
															id={item.time}
															placeholder='00'
															min='0'
															max='5'
														/>
													</div>
												) : (
													<div
														className='time'
														key={timeIndex}
													>
														<label
															htmlFor={item.time}
														>
															<input
																type='text'
																name={item.time}
																id={item.time}
																placeholder='Add...'
																onChange={(
																	e
																) => {
																	handleCustomTime(
																		medIndex,
																		timeIndex,
																		e.target
																			.value,
																		'time'
																	);
																}}
															/>
														</label>

														<input
															type='number'
															name={item.time}
															id={item.time}
															placeholder='00'
															min='0'
															max='5'
															onChange={(e) => {
																handleCustomTime(
																	medIndex,
																	timeIndex,
																	e.target
																		.value,
																	'amount'
																);
															}}
														/>
													</div>
												)
										)}
									</div>

									<button
										className='addTime'
										onClick={() => {
											setMedicines((prev) => {
												const newMed = [...prev];
												newMed[medIndex].timeTable.push(
													{
														time: `time${
															newMed[medIndex]
																.timeTable
																.length + 1
														}`,
														amount: '',
														editable: true,
													}
												);
												return newMed;
											});
										}}
									>
										<IoAdd className='icon' />
									</button>
								</div>
							</div>

							<div className='duration'>
								<label htmlFor='duration'>Duration</label>
								<div className='input__field'>
									<FaPen className='icon' />
									<input
										type='text'
										name='duration'
										id='duration'
										placeholder='Add...'
									/>
								</div>
							</div>

							<div className='intake'>
								<label htmlFor='intake'>Intake</label>
								<div className='input__field'>
									<FaPen className='icon' />
									<input
										type='text'
										name='intake'
										id='intake'
										placeholder='Add...'
									/>
								</div>
							</div>

							<div className='note'>
								<label htmlFor='note'>Note</label>
								<div className='input__field'>
									<FaPen className='icon' />
									<input
										type='text'
										name='note'
										id='note'
										placeholder='Add additional notes...'
									/>
								</div>
							</div>
						</div>
					))}
				</div>

				<div className='sec__three__sub'>
					<button
						onClick={() => {
							setMedicines((prev) => {
								const newMed = [...prev];
								newMed.push({
									geneticName: '',
									brandName: '',
									timeTable: [
										{
											time: 'breakfast',
											amount: '',
											editable: false,
										},
										{
											time: 'lunch',
											amount: '',
											editable: false,
										},
										{
											time: 'dinner',
											amount: '',
											editable: false,
										},
									],
									duration: '',
									intake: '',
									note: '',
								});
								return newMed;
							});
						}}
					>
						<span>Add Another</span>
						<BsPlusLg className='icon' />
					</button>
				</div>

				<div className='sec__four'>
					<div className='left'>
						<div className='block'>
							<label htmlFor='nextMeet'>
								<IoCalendarClearOutline className='icon' />
								<span>Next Meet</span>
							</label>

							<div className='input__field'>
								<FaPen className='icon' />
								<input
									type='text'
									name='nextMeet'
									id='nextMeet'
									placeholder='Ex: 30 Days'
								/>
							</div>
						</div>

						<div className='block'>
							<label htmlFor='expires'>
								<IoCalendarClearOutline className='icon' />
								<span>Expires</span>
							</label>

							<div className='input__field'>
								<FaPen className='icon' />
								<input
									type='text'
									name='expires'
									id='expires'
									placeholder='Ex: 25-03-2022'
								/>
							</div>
						</div>

						<div className='block'>
							<label htmlFor='signature'>
								<IoCreateOutline className='icon' />
								<span>Signature</span>
							</label>

							<div className='input__field'>
								<IoAdd className='icon' />
								<input
									type='file'
									name='signature'
									id='signature'
									placeholder='Add...'
								/>
							</div>
						</div>
					</div>
					<div className='right'>
						<button className='btn-create'>Create</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EPrescription;
