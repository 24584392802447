import React from 'react';
import { IoClose, IoImage } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useImageUpload from '../../../hooks/useImageUpload';

const EditPostPopup = (props) => {
	const { allPosts, setAllPosts, popupState, setPopupState, post } = props;

	const authInfo = useSelector((state) => state.auth.info);
	const authToken = useSelector((state) => state.auth.token);
	const { uploadImage, uploadLoading } = useImageUpload();

	const divRef = React.useRef(null);

	const [postText, setPostText] = React.useState(post.text);
	const [postImages, setPostImages] = React.useState(post.images);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		const handleClickOutside = (event) => {
			if (divRef.current && !divRef.current.contains(event.target)) {
				setPopupState({
					open: false,
					postId: '',
				});
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleEditPost = async () => {
		setLoading(true);

		const postData = {
			text: postText,
			images: postImages,
		};

		const url = `${process.env.REACT_APP_API}/doctor/post/${post._id}`;

		try {
			const req = await fetch(url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'x-access-token': authToken,
				},
				body: JSON.stringify(postData),
			});

			const res = await req.json();

			console.log('edit red -> ', res);

			if (res.success) {
				setAllPosts((prev) => {
					const newPosts = prev.map((p) => {
						if (p._id === post._id) {
							return res.data;
						}
						return p;
					});

					console.log('after edit posts -> ', newPosts);
					return [...newPosts];
				});
				setPostText('');
				setPostImages([]);
				toast.success('Post updated successfully');
			} else {
				toast.error(res.message);
			}
		} catch (error) {
			console.log(error);
			toast.error('Something went wrong');
		} finally {
			setLoading(false);
			setPopupState({
				open: false,
				postId: '',
			});
		}
	};

	return (
		<div className='createPostPopup'>
			<div className='createPostPopup__container' ref={divRef}>
				<div className='createPost__header'>
					<h1>Edit Post</h1>
					<button
						onClick={() =>
							setPopupState({
								open: false,
								postId: '',
							})
						}
					>
						<IoClose className='icon' />
					</button>
				</div>

				<div className='createPost__body'>
					<div className='body__top'>
						<div className='body_avatar'>
							<img
								src={
									authInfo.profilePicture
										? authInfo.profilePicture
										: authInfo.gender === 'Male'
										? '/my-profile/male-avatar.png'
										: '/my-profile/female-avatar.png'
								}
								alt='avatar'
							/>
						</div>

						<div className='info'>
							<h1>{authInfo.fullName}</h1>
							{authInfo.profileInfo?.title && (
								<p>{authInfo.profileInfo.title}</p>
							)}
						</div>
					</div>

					<div className='body__middle'>
						<textarea
							name='post'
							id='post'
							placeholder="What's on your mind?"
							value={postText}
							onChange={(e) => setPostText(e.target.value)}
						></textarea>
					</div>

					<div className='postImages'>
						{postImages.map((img, index) => (
							<div className='postImages__img' key={index}>
								<img src={img} alt='post' />

								<button
									className='postImages__img__overlay'
									onClick={() => {
										setPostImages((prev) => {
											const newImages = [...prev];
											newImages.splice(index, 1);
											return newImages;
										});
									}}
								>
									<IoClose className='icon' />
								</button>
							</div>
						))}
					</div>
				</div>

				<div className='createPost__footer'>
					<div className='footer__left'>
						<label htmlFor='uploadPostImg'>
							<IoImage className='icon' />
						</label>

						<input
							type='file'
							name='uploadPostImg'
							id='uploadPostImg'
							accept='image/jpeg, image/png, image/jpg'
							multiple={false}
							onChange={(e) => {
								uploadImage(e, setPostImages);
							}}
							className='hidden'
						/>
					</div>

					<button
						disabled={postText.trim().length === 0 && postImages.length === 0}
						className='btn-post'
						onClick={handleEditPost}
					>
						Update Post
					</button>
				</div>
			</div>
		</div>
	);
};

export default EditPostPopup;
