import React from 'react';
import './TermsOfUse.scss';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const TermsOfUse = () => {
	return (
		<div
			className='terms'
			style={{
				background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/footer-content/terms.jpg') no-repeat center center/cover`,
			}}
		>
			<div className='terms_content'>
				<div className='terms_nav'>
					<AiOutlineArrowRight className='text-4xl' />
				</div>
				<div>
					<h1 className='terms_title'>Terms of Use</h1>
				</div>
				<div className='details'>
					<p>
						Thank you for using our services. In order to govern the
						relationship resulting from your use of romatoo.com (the
						“Site”), our Romatoo application, (the “App”) and the
						services offered by Romatoo, these Terms of Use
						("Terms") constitute a legally binding agreement between
						you, either as an individual or on behalf of an
						organization or entity that you legally represent
						("you", "your", "yours"), and Romatoo, the owner and
						operator of the Romatoo website, ("Romatoo", "we",
						"our"). These terms apply to all users of the site,
						including without limitation browsers, vendors,
						customers, service providers and/or contributors of
						content ("Users"). You understand that Romatoo or any of
						its affiliates reserves the right to limit or
						discontinue your use of the online services if you do
						not comply with these terms or in Romatoo's sole
						discretion. By accessing or using any part of the site
						or application, you agree that you have read, understood
						and agree to be bound by these terms, as well as
						additional guidelines, policies or rules available as
						applicable on the site and application, including, for
						example Without limitation, Romatoo's Privacy Policy,
						which is incorporated by reference into these terms. IF
						YOU DO NOT AGREE TO BE BOUND BY THESE TERMS AND TO
						FOLLOW ALL APPLICABLE LAWS, GUIDELINES AND POLICIES, DO
						NOT ACCESS OR USE THE SITE.
					</p>

					<h2 className='font-bold'>
						The nature of the content appearing on our services
					</h2>

					<li>
						<b>Overview: </b>Our services may include text, data,
						graphics, images or other content (collectively
						"Content") created by us or by third parties, including
						those who are marketing contractors with Romatoo from
						healthcare providers and partners such as doctors,
						hospitals and polyclinics laboratories, radiology
						centers and pharmacies. Our services include this
						Website and Application allowing users to identify and
						book healthcare providers including clinics, hospitals
						or diagnostic laboratories for consultations and
						physical services. Our public and protected services are
						collectively referred to as the "Services". In other
						words, our services are only intended to help you search
						and book an appointment with a service provider. You are
						aware that some services are available under different
						names. You also understand that the services may be
						provided by (i) certain subsidiaries and affiliates of
						Romatoo or (ii) independent service providers.
					</li>

					<li>
						<b>General Services: </b>We make some services available
						without registration or password. We call them "Public
						Services." You may use the public services personally
						and non-commercially, as long as you comply with these
						terms. These services include directories
						("Directories") for physicians, medical consultants,
						branches of laboratories and radiology centers,
						collectively referred to as ("Service Providers"). Where
						these directories are presented for your convenience.
						The directories only include service providers who use
						our services and who have chosen to participate in the
						directories. The inclusion of a service provider in the
						directory does not constitute a recommendation for the
						services of this service provider or a guarantee of its
						certification or qualifications. Because all information
						is transmitted as provided by service providers, and
						while we make reasonable efforts to provide you with
						accurate content, we do not guarantee, represent or
						warrant the accuracy of any information regarding
						professional qualifications, expertise, quality of work,
						price, cost, insurance or any other content available
						through the services, whether express or implied. In no
						event will we be liable to you or anyone else for any
						decision or action you take in reliance on any such
						content. We do not endorse or recommend in any way any
						individual or entity listed or accessible through the
						services. While reservations may be made through our
						website, we cannot guarantee the availability of the
						relevant service provider, nor can we expect to cancel
						their appointments.
					</li>

					<li>
						<b>Protected Services: </b>Some of our services are
						protected by technical measures intended to protect the
						confidentiality, integrity, and accessibility of
						sensitive information that users store and share using
						our services; We call them "protected services". These
						safeguards require each user to properly authenticate by
						authorization ("credentials"), such as unique
						identifiers, usernames, passwords, etc. In order to
						obtain the credentials, you must provide certain
						information about yourself. If you are registering for a
						protected service, accessing or using a protected
						service, or attempting to access or use a protected
						service, on behalf of, or for, someone other than
						yourself - such as any of your family members ("Original
						Agent") - you must also specify and provide information
						about each actor. Other terms and conditions relating to
						user registration are noted in <a>Section 6</a>. These
						protected services are all services that create a
						personal medical record for you ("Medical Record"), such
						as receiving your medical information and results from
						providers such as laboratories, radiology centers, and
						physician accounts. This data is completely private and
						secured and we guarantee it. You are the sole data
						owner, and by clicking "I agree", you give the service
						providers the right to send information to you through
						your own account on the site. In order to assist you in
						creating your electronic medical record, you may consent
						to sending a copy of this data to the physician
						providing the service through our services, which will
						be protected and kept private as well as not shared with
						third parties. The doctor will keep this data in your
						name in his account to create your medical record at the
						clinic. In the event that you do not agree to keep a
						medical record of your data, you may send your request
						to Romatoo to indicate that you do not share it, and
						Romatoo will be obligated to comply with this request.
					</li>

					<li>
						<b>Content: </b>Our services allow you to access reviews
						and other forums where different users can share
						information, opinions, ratings and other content. We
						generally do not pre-screen or follow submitted content,
						and such content may simply represent the user's
						opinion. Our services may also include survey results,
						ratings, or testimonials (“Reviews”) from users who may
						endorse, recommend, criticize, analyze, rate or
						otherwise explain the service providers and the nature
						or quality of the services that such user receives.
						These reviews are direct anecdotal accounts of
						individual users, and do not constitute the judgment of
						the provider nor the product of medical science. You may
						be aware that the reviews on the site are from those who
						submit them, and that they do not reflect the opinions
						of Romatoo, and in no way constitute our endorsement or
						recommendation of Romatoo. Consequently, Romatoo will
						not be held responsible for any of the reviews posted.
						Furthermore, you should keep in mind that ratings are
						subject to errors and biases common to direct anecdotal
						accounts, and are not supposed to be reliable or
						error-free. Any content you obtain or receive from
						Romatoo, its employees, contractors, partners, sponsors,
						advertisers, licensors, or otherwise through the
						services, is for informational, regulatory and payment
						purposes only. Whereas, all information is for
						informational purposes only, and does not constitute
						medical advice. The information displayed on the site is
						not a substitute for medical advice by qualified
						healthcare professionals. If you rely on any content,
						including reviews, you do so at your own risk.
					</li>

					<h2 className='font-bold text-2xl'>
						Privacy and Security Policy
					</h2>

					<p>
						Romatoo and its affiliates consider the privacy of your
						health information to be one of the most important
						elements in our relationship with you. Our
						responsibility to maintain the confidentiality of your
						health information is a responsibility we take very
						seriously. We are required by law to maintain the
						privacy and security of your PHI. We will notify you
						immediately in the event of a breach that may disclose
						the privacy or security of your information. We will not
						use or share your information other than as described
						here. You are the sole owner of the data and you share
						it when you want through our site.
					</p>

					<p>
						To further protect the confidentiality, integrity and
						availability of information on Romatoo and its sharing,
						as well as the stability of our Services, you agree to
						the following additional safeguards. Accordingly, you
						agree that you will not and will not attempt to:
					</p>
					<li>
						Access, use, or publish our services or any information
						or files accessible through our services, in a manner
						that violates applicable laws and regulations or the
						rights of any individual or entity.
					</li>

					<li>
						Sell or transfer any information included in our
						services or use that information to market any product
						or service - including by sending or facilitating the
						sending of unsolicited email or SPAM;
					</li>

					<li>
						Search, scan or test for vulnerabilities in our
						services, or the system or network that supports our
						services, or circumvent security or authentication
						measures.
					</li>

					<li>
						Disable, omit, bypass, evade, remove, deactivate or
						otherwise circumvent any technical measures we put in
						place to protect the stability of our Services, or the
						confidentiality, integrity or availability of any
						information, content or data on our Services.
					</li>

					<li>
						Provide our Services to any software, code, or other
						device that (i) allows in any way to gain unauthorized
						access to our systems or any software, hardware, files
						or data on them, (ii) disrupts, damages, interferes with
						or adversely affects the operation of our systems or any
						software, hardware, files or data on it, or (iii)
						overburden or interfere with the functionality of our
						Services.
					</li>

					<li>De-compile or reverse engineer our Services.</li>

					<li>
						Obtain, retrieve, index or publish any part of our
						services unless you are a public search engine
						participating in public search services.
					</li>

					<li>
						Disable or circumvent safeguards for use of our API,
						including safeguards designed to regulate the nature or
						amount of data you are permitted to extract from our
						Services, or the frequency of access to such data or
						make calls to our API other than those authorized in our
						API documentation.
					</li>

					<li>
						Remove any copyright, trademark or other proprietary
						rights notices contained in our services.
					</li>

					<li>
						Engage in any other activity not expressly permitted in
						these terms.
					</li>

					<p>
						You can find more information regarding our privacy
						procedures in our{' '}
						<Link className='text-[#22B573]'>Privacy Policy</Link>
						&nbsp; documentation.
					</p>

					<h2 className='font-bold text-2xl'>Payment</h2>

					<p>
						You may choose to either (i) pay in cash at the service
						provider's website at the time of the consultation or
						(ii) pay online through our website or app. When
						collecting payments on behalf of service providers, you
						expressly agree to be bound by the payment terms of that
						service provider. And where applicable, we will include
						any taxes. We currently accept payments through
						#TODO![credit/debit cards, Fawry or digital wallets].
						You agree to make all such payments in a timely manner,
						and acknowledge that you are responsible for any amounts
						associated with your account. Depending on the service,
						we will collect payments before or after the service is
						provided.
					</p>

					<p>
						We reserve the right to set, amend or remove any fees,
						at our sole discretion. We may also offer promotions or
						discounts, which will change the amount paid, but only
						subject to the terms and conditions of such promotion or
						discount.
					</p>

					<h2 className='font-bold text-2xl'>Refund Policy</h2>

					<li>
						The fees paid by you are final and non-refundable,
						unless otherwise specified by Romatoo.
					</li>
					<li>
						Refunds will be made by the same method of payment, if
						any.
					</li>
					<li>
						Romatoo reserves the right to refund any amounts to
						users accounts for use in other services at its sole
						discretion.
					</li>
					<li>
						Refunds are not applicable if the user is not satisfied
						with the medical service provided.
					</li>
					<li>The user is entitled to a full refund if:</li>
					<li className='pl-5 list-none'>
						<li>
							The user canceled the reservation before the
							appointment date.
						</li>

						<li>
							The service provider canceled, or did not attend,
							the appointment for which the user had paid.
						</li>
					</li>

					<h2 className='font-bold text-2xl'>Compliance</h2>

					<p>
						You must comply with these Terms, any policies
						referenced on the Site and any laws, regulations, rules,
						licenses or restrictions approved by Romatoo.
					</p>

					<h2 className='font-bold text-2xl'>License</h2>

					<p>
						Subject to these Terms, you are granted a limited,
						revocable, non-exclusive right to use the services,
						content and materials on the site in the normal course
						of your use of the site. You may not use the
						intellectual property of others without their express
						written permission.
					</p>

					<p>
						Romatoo retains ownership of its intellectual property
						rights and you may not acquire any rights under these
						terms or otherwise, except as expressly provided in
						these terms. You may not use, copy, display, perform,
						create derivative works, distribute, transmit or
						sublicense from the materials or content available on
						the site, which may be reasonably necessary to use the
						services for their intended purpose and except as
						expressly provided in these terms.
					</p>

					<h2 className='font-bold text-2xl'>User registration</h2>

					<p>
						You do not have to register in order to visit the site.
						To access certain features of the services, you will
						need to register with Romatoo and create ("User
						Account") through the online registration process on the
						site. Your account gives you access to the services and
						functionality that we may establish and maintain from
						time to time and at our sole discretion. When creating
						an account, you must provide Romatoo with accurate and
						complete registration information, as required by the
						registration form. You must notify Romatoo immediately
						if any of this information changes. If you fail to
						provide or update this information, you will not be able
						to receive the requested information through the site.
						Romatoo may also terminate or prevent your use of the
						services.
					</p>

					<p>
						Once an account is created, you are required to
						expressly agree to these Terms. You also acknowledge
						that you are over 18 years of age to use our Services,
						and that we are not responsible for the use of our
						services if you are under 18 years of age.
					</p>

					<p>
						You must choose a password to access your user account
						and you shall keep it confidential at all times. You
						also understand that you will be liable for any
						actions/activities that take place on your user account
						by unauthorized parties. You must notify us if you
						strongly believe that your account has been hacked.
						Under no circumstances should you respond to a request
						for your password, in particular a request from a person
						claiming to be an employee of Romatoo. You may not
						delegate, assign or transfer your user account to any
						third party. You should be aware that Romatoo may deny
						access to your account if you fail to enter your
						password many in a row.
					</p>

					<h2 className='font-bold text-2xl'>
						ACCURACY, COMPLETENESS AND TIME OF THE INFORMATION
					</h2>

					<p>
						We are not responsible if information available on this
						website is not accurate, complete or current. The
						material on this site is provided for general
						information only and should not be relied upon or used
						as the sole basis for making decisions without
						consulting primary, more accurate, more complete or more
						recent sources of information. Any reliance on the
						materials on this website is at your own risk.
					</p>

					<p>
						This website may contain certain historical information.
						Historical information is not necessarily current but is
						provided for your reference only. We reserve the right
						to modify the contents of this website at any time, but
						we are under no obligation to update any information on
						our website. You agree that it is your responsibility to
						monitor changes to our site.
					</p>

					<h2 className='font-bold text-2xl'>Optional tools</h2>

					<p>
						We may provide you with access to third party tools over
						which we neither monitor nor have any control.
					</p>

					<p>
						You acknowledge and agree that we provide access to
						these tools “as is” and “as available” without any
						warranties, representations or conditions of any kind
						and without any endorsement. We will have no liability
						whatsoever arising from or related to your use of
						optional third-party tools.
					</p>

					<p>
						Any use by you of optional tools offered through the
						Site is entirely at your own risk and discretion, and
						you should ensure that you are familiar with and agree
						to the terms on which tools are provided by the relevant
						third party service providers.
					</p>

					<p>
						We may also, in the future, offer new services and/or
						features through the Website (including, the release of
						new tools and resources). These new features and/or
						services are also subject to these Terms of Service.
					</p>

					<h2 className='font-bold text-2xl'>Third Party Links</h2>

					<p>
						Certain content, products, and services available
						through our website may include material from third
						parties. Third-party links on this website may direct
						you to third-party websites that are not affiliated with
						us.
					</p>

					<p>
						We are not responsible for examining or evaluating the
						content or its accuracy and do not warrant and will not
						have any liability for any third-party materials or
						websites, or any other materials, products, or services
						of third parties.
					</p>

					<p>
						We are not liable for any harm or damage related to the
						use of the Services, Resources, Content, or any other
						transactions made in connection with any third party
						websites. Please review the third party's policies and
						practices carefully and ensure that you understand them
						before entering into any transaction. Complaints,
						claims, concerns, or questions regarding third-party
						products should be directed to the third-party itself.
					</p>

					<h2 className='font-bold text-2xl'>
						User comments, feedback and other suggestions
					</h2>

					<p>
						If, at our request, you submit certain specific
						proposals (e.g. contest entries) or without asking us,
						you submit ideas, suggestions, proposals, creative
						plans, or other materials, whether online, by email,
						postal mail, or otherwise. (collectively, "Comments' '),
						and you agree that we may, at any time, without
						limitation, modify, copy, publish, distribute, translate
						and use any Comments you send to us by any other means.
						We are under no obligation (i) to maintain the
						confidentiality of any comments; (2) to pay compensation
						for any comments; or (iii) to respond to any comments.
					</p>

					<p>
						We may, but are not obligated to, monitor, edit or
						remove content that we determine in our sole discretion
						are unlawful, offensive, threatening, defamatory,
						defamatory, obscene, or in violation of any party's
						intellectual property or these Terms of Use.
					</p>

					<p>
						You agree that your comments will not infringe any right
						of any third party, including copyright, trademark,
						privacy, personality, or any other personal or
						proprietary right. You further agree that your comments
						will not contain libelous, unlawful, abusive, or obscene
						material, or contain any computer virus or other malware
						that could in any way affect the operation of the
						Service or any related website. You may not use a false
						e-mail address, pretend to be someone other than
						yourself, or otherwise mislead us or others regarding
						any comments. You are solely responsible for any
						comments you post and for their accuracy. We are not
						responsible for any comments posted by you or by third
						parties.
					</p>

					<h2 className='font-bold text-2xl'>
						Errors, inaccuracies and omissions
					</h2>

					<p>
						Occasionally, there may be information on our Site that
						contains typographical errors, inaccuracies, or
						omissions that may relate to product descriptions,
						pricing, promotions, offers, product shipping charges,
						turnaround times, and availability. We reserve the right
						to correct any errors, inaccuracies, or omissions and to
						change or update information and cancel orders if any
						information in the service or on any related website is
						inaccurate at any time without prior notice.
					</p>

					<h2 className='font-bold text-2xl'>Prohibited uses</h2>

					<p>
						(a) For any unlawful purpose. (b) Incite others to
						engage in any unlawful acts. (c) Violate any local,
						international, federal, provincial or state regulations,
						rules, laws, or ordinances. (d) Infringe or violate our
						intellectual property rights or the intellectual
						property rights of others. (e) Harass, abuse, insult,
						harm, defame, disparage, intimidate, or discriminate
						based on gender, sexual orientation, religion, race,
						ethnicity, age, national origin, or disability. (f)
						Provide false or misleading information. (g) To upload
						or transmit viruses or any other type of malicious code
						that will or may be used in any way that will affect the
						functionality or operation of the service or any related
						website, other websites, or the internet. (h) To collect
						or track the personal information of others; (i) Sending
						spam or phishing, fraudulent pretext or network
						crawling. (j) For any obscene or immoral purpose. (k) To
						interfere with or circumvent the security features of
						the Service or any related website, other websites, or
						the internet. We reserve the right to terminate your use
						of the service or any related website for violating any
						of the prohibited uses.
					</p>

					<h2 className='font-bold text-2xl'>
						DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
					</h2>

					<p>
						We do not warrant that your use of our service will be
						uninterrupted, appropriate, secure or error-free. While
						we always strive to ensure that service providers are
						duly licensed in the relevant jurisdiction, we do not
						guarantee that our content will always be error-free,
						complete, accurate or up-to-date.
					</p>

					<p>
						WE DO NOT WARRANT THAT THE RESULTS YOU MAY OBTAIN FROM
						USING THE SERVICE WILL BE ACCURATE OR RELIABLE.
					</p>

					<p>
						You agree that from time to time we may terminate the
						Service for indefinite periods of time or cancel the
						Service at any time without notice to you.
					</p>

					<p>
						YOU EXPRESSLY AGREE THAT YOUR USE OF, OR INABILITY TO
						USE, THE SERVICE IS AT YOUR OWN RISK. The Services and
						all products and services provided to you through the
						service (except as expressly stated by us) are provided
						“as is” and “as available” for your use, without any
						representations, warranties or conditions of any kind,
						either express or implied, including: This includes all
						implied warranties or conditions of merchantability,
						merchantable quality, fitness for a particular purpose,
						continuity, title, and non-infringement.
					</p>

					<p>
						In no event shall we and our directors, officers,
						employees, affiliates, agents, contractors, resident
						physicians, suppliers, service providers or licensors be
						liable for any injury, loss, claim or any direct,
						indirect, incidental or punitive damages or special or
						consequential damages of any kind, including, without
						limitation, loss of profits, loss of revenue, loss of
						savings, loss of data, replacement costs, or similar
						damages, whether existing in contract, or tort
						(including negligence), strict liability or otherwise,
						arising out of your use of any of the services or any
						products purchased using the service or any other claim
						relating in any way to your use of the service or any
						product, including, without limitation without
						limitation, any errors or omissions in any content, and
						any diagnoses for medical advice, prescriptions, or any
						loss or damage of any kind incurred as a result of the
						use of the service or any content (or Product) posted,
						transmitted or otherwise made available via the service,
						even if we have been advised of the possibility.
					</p>

					<p>
						Romatoo shall not be liable for any damages to you
						including without limitation, direct, indirect, special,
						consequential, punitive or incidental damages, arising
						out of or in connection with any of the following acts
						committed by the Service Providers:
					</p>

					<li>Failure to provide patient etiquette.</li>
					<li>Unprofessional, unethical or immoral behavior.</li>
					<li>Wrong or late diagnosis of a medical condition.</li>
					<li>Wrong or late treatment or no treatment at all.</li>
					<li>Medical malpractice or negligence.</li>
					<li>
						Canceling the appointment, delaying the service
						provider, or not showing up at all for the examination
						appointment.
					</li>

					<p>
						We do not provide medical advice, diagnosis, or
						treatment, do not endorse or recommend a particular
						service provider, and do not intend to practice
						medicine. But we would just like to help you find the
						best provider and book an appointment according to your
						medical needs. Therefore, it is essential that you
						exercise the same caution and precaution that you would
						have applied even if you were not using our services.
						You understand and acknowledge that Romatoo shall not be
						liable for any damages, deaths, injuries, illnesses,
						losses, costs or expenses of any nature arising from the
						service provider.
					</p>

					<h2 className='font-bold text-2xl'>Modification</h2>

					<p>
						Internet technology and applicable laws, rules and
						regulations change frequently. Accordingly, Romatoo
						reserves the right to make changes to these terms at any
						time. Your continued use of the site constitutes
						acceptance of any new or modified provision of these
						terms that may be posted on the site. We will post the
						revised terms on this page and indicate at the top of
						the page the latest revision of these terms.
					</p>

					<h2 className='font-bold text-2xl'>
						Miscellaneous Provisions
					</h2>

					<p>
						You understand that your account or your use of the
						Website/Application may be deactivated or terminated at
						your request or in the sole discretion of Romatoo or any
						of its affiliates for non-compliance with these terms.
					</p>

					<p>
						We may freely assign these terms in connection with a
						merger, acquisition or sale of assets, at law or for any
						other reason.
					</p>

					<p>
						In the event any provision of these terms becomes
						unlawful, void or unenforceable, that provision shall be
						enforceable to the fullest extent permitted by
						applicable law, and the unenforceable portion shall be
						deemed severable from these Terms of Service, and this
						shall not affect the validity and enforceability of any
						of other provisions.
					</p>

					<p>
						Our failure to exercise or enforce any right or
						provision of these Terms shall not constitute a waiver
						of such right or provision.
					</p>

					<p>
						These Terms, together with the End User Agreement and
						Privacy Policy, constitute the entire agreement between
						you and Romatoo with respect to any Services we provide,
						and supersede all prior communications, representations
						or understandings, whether oral or written, with respect
						to the subject matter of the Agreement.
					</p>
				</div>
			</div>
		</div>
	);
};

export default TermsOfUse;
