import React from 'react';
import { SocketContext } from '../../context/SocketContext';
import { useSelector } from 'react-redux';
import { FcEndCall } from 'react-icons/fc';
import { HiOutlineVideoCameraSlash } from 'react-icons/hi2';
import { HiOutlineVideoCamera, HiOutlineDocumentAdd } from 'react-icons/hi';
import {
	IoMicOutline,
	IoMicOffOutline,
	IoVideocamOutline,
	IoVideocamOffOutline,
	IoDocumentTextOutline,
	IoCallOutline,
} from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './VideoCall.scss';

const VideoCall = () => {
	const {
		call,
		callAccepted,
		myVideo,
		userVideo,
		name,
		setName,
		callEnded,
		callUser,
		leaveCall,
		answerCall,
		setRoomId,
		toggleMyVideo,
		myVideoOn,
		toggleMyAudio,
		myAudioOn,
	} = React.useContext(SocketContext);

	const { conversationId } = useParams();
	const auth = useSelector((state) => state.auth);
	const user = useSelector((state) => state.auth.info);
	const navigate = useNavigate();

	const [conversation, setConversation] = React.useState(null);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		setName(user.fullName);
		setRoomId(conversationId);

		let url;

		if (auth?.info?.role === 'doctor') {
			url = `${process.env.REACT_APP_API}/doctor/conversations/${conversationId}`;
		} else {
			url = `${process.env.REACT_APP_API}/patient/conversations/${conversationId}`;
		}

		fetch(url, {
			method: 'GET',
			headers: {
				'x-access-token': auth?.token,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.success) {
					setConversation(res.data);
					console.log(res.data);
				} else {
					alert(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div className='videoCall'>
			<div className='videos'>
				{callAccepted && !callEnded ? (
					<div className='user-video'>
						<video playsInline ref={userVideo} autoPlay />

						<h3>
							{user.role === 'doctor'
								? conversation?.patient?.fullName
								: conversation?.doctor?.fullName}
						</h3>
					</div>
				) : null}

				<div className='my-video'>
					<video playsInline muted ref={myVideo} autoPlay />

					<h3>{name || 'Me'}</h3>
				</div>
			</div>

			{user?.role === 'patient' ? (
				call.isReceivingCall && !callAccepted ? (
					<div className='incoming-call'>
						<h1>{call.name} is calling...</h1>

						<button onClick={answerCall} className='btn-answer'>
							Answer
						</button>
					</div>
				) : null
			) : null}

			<div className='flex items-center gap-4 flex-wrap pb-5'>
				<button onClick={toggleMyAudio} className='btn-btns'>
					{myAudioOn ? (
						<IoMicOutline className='icon' />
					) : (
						<IoMicOffOutline className='icon' />
					)}
				</button>

				<button onClick={toggleMyVideo} className='btn-btns'>
					{myVideoOn ? (
						<IoVideocamOutline className='icon' />
					) : (
						<IoVideocamOffOutline className='icon' />
					)}
				</button>

				{auth.info?.role === 'doctor' ? (
					<Link
						to={`/prescription/${conversation?.patient?._id}`}
						target='_blank'
						rel='noopener noreferrer'
						className='btn-btns'
					>
						<IoDocumentTextOutline className='icon' />
					</Link>
				) : null}

				{callAccepted && !callEnded ? (
					<button onClick={leaveCall} className='btn-endCall'>
						<IoCallOutline className='icon' />
					</button>
				) : user.role === 'doctor' ? (
					<button onClick={() => callUser()} className='btn-call'>
						Call {conversation?.patient?.fullName}
					</button>
				) : null}
			</div>
		</div>
	);
};

export default VideoCall;
