import React from 'react';
import { useSelector } from 'react-redux';
import { IoAdd, IoImage, IoList } from 'react-icons/io5';
import { BsPlayBtnFill } from 'react-icons/bs';
import { TiInfoLarge } from 'react-icons/ti';
import { HiPlus, HiDotsHorizontal } from 'react-icons/hi';
import { RiThumbUpLine, RiSendPlaneLine } from 'react-icons/ri';
import { MdArticle, MdOutlineModeComment } from 'react-icons/md';
import { IoSend } from 'react-icons/io5';
import { AiOutlineRetweet } from 'react-icons/ai';
import './Feed.scss';
import { toast } from 'react-toastify';
import { dummyPosts } from '../../../data';
import CreatePostPopup from './CreatePostPopup';
import EditPostPopup from './EditPostPopup';
import { Link, useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LoadingSVG from '../../../components/LoadingSVG/LoadingSVG';

const Feed = () => {
	const navigate = useNavigate();

	const authToken = useSelector((state) => state.auth.token);
	const authInfo = useSelector((state) => state.auth.info);

	const [myProfileData, setMyProfileData] = React.useState(null);

	const [createPostPopupOpen, setCreatePostPopupOpen] = React.useState(false);

	const [comments, setComments] = React.useState([]);

	const [openComments, setOpenComments] = React.useState([]);
	const [openOptions, setOpenOptions] = React.useState({
		open: false,
		postId: '',
	});
	const [openEditPost, setOpenEditPost] = React.useState({
		open: false,
		postId: '',
	});

	const [allPosts, setAllPosts] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [loading2, setLoading2] = React.useState(false);
	const [profileLoading, setProfileLoading] = React.useState(true);

	const randomGenerator = (min = 100, max = 400) => {
		return Math.floor(Math.random() * (max - min + 1) + min);
	};

	React.useEffect(() => {
		const fetchPosts = async () => {
			const url = `${process.env.REACT_APP_API}/doctor/posts`;

			try {
				const req = await fetch(url);
				const res = await req.json();

				console.log('fetchPosts red -> ', res);

				setAllPosts(res.data);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		const fetchMyProfile = async () => {
			if (authInfo.role === 'doctor') {
				const url = `${process.env.REACT_APP_API}/doctor/my-profile`;
				const options = {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': authToken,
					},
				};

				try {
					const req = await fetch(url, options);
					const res = await req.json();

					console.log('fetchMyProfile red -> ', res);

					if (res.success) {
						setMyProfileData(res.data);
					} else {
						toast.error(res.message);
					}
				} catch (error) {
					console.log(error);
					toast.error('Something went wrong!');
				} finally {
					setProfileLoading(false);
				}
			} else if (authInfo.role === 'patient') {
				const url = `${process.env.REACT_APP_API}/patient/my-profile`;
				const options = {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'x-access-token': authToken,
					},
				};

				try {
					const req = await fetch(url, options);
					const res = await req.json();

					console.log('fetchMyProfile red -> ', res);

					if (res.success) {
						setMyProfileData(res.data);
					} else {
						toast.error(res.message);
					}
				} catch (error) {
					console.log(error);
					toast.error('Something went wrong!');
				} finally {
					setProfileLoading(false);
				}
			}
		};

		fetchMyProfile();
		fetchPosts();
	}, []);

	const toggleLike = (postId) => {
		setLoading2(true);

		const bodyData = {
			userId: authInfo.id,
			fullName: authInfo.fullName,
			profilePicture: authInfo.profilePicture,
		};

		const url = `${process.env.REACT_APP_API}/doctor/post/${postId}/like`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
			body: JSON.stringify(bodyData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('toggleLike red -> ', res);

				if (res.success) {
					const newPosts = allPosts.map((post) => {
						if (post._id === postId) {
							post.likeReact = res.data.like;
							post.loveReact = res.data.love;
							post.laughReact = res.data.laugh;
							return post;
						} else {
							return post;
						}
					});

					setAllPosts(newPosts);
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const toggleLove = (postId) => {
		setLoading2(true);

		const bodyData = {
			userId: authInfo.id,
			fullName: authInfo.fullName,
			profilePicture: authInfo.profilePicture,
		};

		const url = `${process.env.REACT_APP_API}/doctor/post/${postId}/love`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
			body: JSON.stringify(bodyData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('toggleLove red -> ', res);

				if (res.success) {
					const newPosts = allPosts.map((post) => {
						if (post._id === postId) {
							post.likeReact = res.data.like;
							post.loveReact = res.data.love;
							post.laughReact = res.data.laugh;
							return post;
						} else {
							return post;
						}
					});

					setAllPosts(newPosts);
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const toggleLaugh = (postId) => {
		setLoading2(true);

		const bodyData = {
			userId: authInfo.id,
			fullName: authInfo.fullName,
			profilePicture: authInfo.profilePicture,
		};

		const url = `${process.env.REACT_APP_API}/doctor/post/${postId}/laugh`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
			body: JSON.stringify(bodyData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('togglelaugh red -> ', res);

				if (res.success) {
					const newPosts = allPosts.map((post) => {
						if (post._id === postId) {
							post.likeReact = res.data.like;
							post.loveReact = res.data.love;
							post.laughReact = res.data.laugh;
							return post;
						} else {
							return post;
						}
					});

					setAllPosts(newPosts);
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const postComment = (postId) => {
		setLoading2(true);

		const bodyData = {
			author: authInfo.id,
			post: postId,
			text: comments.find((x) => x.postId === postId).comment,
			userRole: authInfo.role === 'doctor' ? 'Doctor' : 'Patient',
		};

		const url = `${process.env.REACT_APP_API}/doctor/post/comment/${postId}`;

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
			body: JSON.stringify(bodyData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('postComment red -> ', res);

				if (res.success) {
					setAllPosts((prev) => {
						const newPosts = prev.map((post) => {
							if (post._id === postId) {
								post.comments.push(res.data);
								return post;
							} else {
								return post;
							}
						});

						return newPosts;
					});
					setComments((prev) => {
						const newComments = prev.filter((x) => x.postId !== postId);
						return newComments;
					});
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const deletePost = (postId) => {
		setLoading2(true);

		const url = `${process.env.REACT_APP_API}/doctor/post/${postId}`;

		fetch(url, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('deletePost red -> ', res);

				if (res.success) {
					setAllPosts((prev) => prev.filter((x) => x._id !== postId));
					toast.success(res.message);
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const followUser = (userId, userRole) => {
		setLoading2(true);

		const bodyData = {
			userRole: userRole,
			userId: userId,
		};

		const url = `${process.env.REACT_APP_API}/doctor/follow`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
			body: JSON.stringify(bodyData),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('followUser red -> ', res);

				if (res.success) {
					setAllPosts((prev) => {
						const newPosts = prev.map((post) => {
							if (post.author._id === userId) {
								post.author.social.followers = res.data.user;
							}

							return post;
						});

						return newPosts;
					});
					toast.success(res.message);
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const savePost = (postId) => {
		setLoading2(true);

		const url = `${process.env.REACT_APP_API}/doctor/post/save/${postId}`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('savePost red -> ', res);

				if (res.success) {
					setMyProfileData((prev) => {
						const newSavedPosts = [...res.data];
						return { ...prev, savedPosts: newSavedPosts };
					});
					toast.success(res.message);
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	const hidePost = (postId) => {
		setLoading2(true);

		const url = `${process.env.REACT_APP_API}/doctor/post/hide/${postId}`;

		fetch(url, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'x-access-token': authToken,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.log('hidePost red -> ', res);

				if (res.success) {
					setMyProfileData((prev) => {
						const newHiddenPosts = [...res.data];
						return { ...prev, hiddenPosts: newHiddenPosts };
					});
					toast.success(res.message);
				} else {
					toast.error(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error('Something went wrong!');
			})
			.finally(() => setLoading2(false));
	};

	if (loading || profileLoading) {
		return <LoadingSVG />;
	}

	return (
		<div className='feed page'>
			<div className='feed__container'>
				<div className='w-full col-span-3 hidden sm:block'>
					<div className='my__profile'>
						<div className='top'>
							<div className='avatar'>
								<img
									src={
										authInfo.profilePicture
											? authInfo.profilePicture
											: authInfo.gender === 'Male'
											? '/my-profile/male-avatar.png'
											: '/my-profile/female-avatar.png'
									}
									alt='avatar'
								/>
							</div>

							<div className='info'>
								<h1>
									<Link
										to={`/social/profile/${authInfo.id}`}
										className='hover:underline'
									>
										{authInfo?.fullName}
									</Link>
								</h1>
								{authInfo.profileInfo?.title && (
									<p>{authInfo.profileInfo.title}</p>
								)}
								{authInfo?.profileInfo?.currentOrganization && (
									<p className='text-sm text-'>
										{authInfo.profileInfo.currentOrganization}
									</p>
								)}
								{authInfo?.profileInfo?.qualification && (
									<p className='text-sm'>
										{authInfo.profileInfo.qualification}
									</p>
								)}
							</div>
						</div>

						<div className='connections'>
							<p>
								<span>Connections</span>
								<span>50</span>
							</p>
							<p>
								<span>Pending Connections</span>
								<span>13</span>
							</p>
						</div>
					</div>
				</div>

				<div className='w-full col-span-12 px-2 sm:px-0 sm:col-span-6'>
					<div className='create__post'>
						<div className='top'>
							<div className='left'>
								<img
									src={
										authInfo.profilePicture
											? authInfo.profilePicture
											: authInfo.gender === 'Male'
											? '/my-profile/male-avatar.png'
											: '/my-profile/female-avatar.png'
									}
									alt='avatar'
								/>
							</div>

							<div className='right'>
								{/* <textarea
									name='create-post'
									id='create-post'
									placeholder='What is on your mind?'
								></textarea> */}
								<button onClick={() => setCreatePostPopupOpen(true)}>
									What is on your mind?
								</button>
							</div>
						</div>

						<div className='bottom'>
							<button className='block'>
								<IoImage className='icon photo' />
								<span>Photo</span>
							</button>

							<button className='block'>
								<BsPlayBtnFill className='icon video' />
								<span>Video</span>
							</button>

							<button className='block'>
								<MdArticle className='icon article' />
								<span>Article</span>
							</button>

							<button className='block'>
								<IoList className='icon problems' />
								<span>Problems</span>
							</button>
						</div>
					</div>

					<div className='posts'>
						{allPosts.map((post, index) =>
							myProfileData.hiddenPosts.includes(post._id) ? (
								<div className='post' key={index}>
									<div className='flex items-center justify-between p-4 rounded-md'>
										<p>Hidden Post</p>

										<button
											className='bg-emerald-100 px-4 py-1.5 rounded text-sm'
											onClick={() => {
												hidePost(post._id);
											}}
										>
											Undo
										</button>
									</div>
								</div>
							) : (
								<div className='post' key={index}>
									<div className='top'>
										<div
											className='avatar cursor-pointer'
											onClick={() =>
												navigate(`/social/profile/${post.author._id}`)
											}
										>
											<img
												src={
													post.author?.profileInfo?.profilePicture ||
													'/my-profile/male-avatar.png'
												}
												alt='avatar'
											/>
										</div>

										<div className='info cursor-pointer'>
											<h1
												onClick={() =>
													navigate(`/social/profile/${post.author._id}`)
												}
											>
												{post.author?.fullName}
											</h1>
											<p>{post.author?.profileInfo?.title}</p>

											<p>
												<span>
													{new Date(post.createdAt).toLocaleDateString(
														'en-US',
														{
															year: 'numeric',
															month: 'long',
															day: 'numeric',
														}
													)}
												</span>
												{' • '}
												<span>Public</span>
											</p>
										</div>

										{post.author._id !== authInfo.id ? (
											post.author.social.followers.find(
												(x) => x.userId === authInfo.id
											) ? null : (
												<button
													onClick={() =>
														followUser(post.author._id, post.userRole)
													}
												>
													<HiPlus className='icon' />
													<span>Follow</span>
												</button>
											)
										) : null}

										<div className='relative flex'>
											<button
												onClick={() => {
													setOpenOptions((prev) => {
														const newOpen =
															prev.postId === post._id ? !prev.open : true;

														const newPostId =
															post._id === prev.postId ? '' : post._id;

														console.log({ open: newOpen, postId: newPostId });
														return { open: newOpen, postId: newPostId };
													});
												}}
											>
												<HiDotsHorizontal className='icon' />
											</button>

											{openOptions.postId === post._id && openOptions.open ? (
												<div className='absolute top-full right-0 w-28 flex flex-col bg-white rounded shadow-md'>
													{post.author._id === authInfo.id ? (
														<>
															<button
																className='px-2 py-1.5 hover:bg-slate-200 text-sm'
																onClick={() => {
																	setOpenOptions((prev) => {
																		return { open: false, postId: '' };
																	});
																	setOpenEditPost((prev) => {
																		return { open: true, postId: post._id };
																	});
																}}
															>
																Edit Post
															</button>

															<button
																className='px-2 py-1.5 hover:bg-slate-200 text-sm'
																onClick={() => {
																	deletePost(post._id);
																	setOpenOptions((prev) => {
																		return { open: false, postId: '' };
																	});
																}}
															>
																Delete Post
															</button>
														</>
													) : (
														<>
															{post.author.social.followers.find(
																(x) => x.userId === authInfo.id
															) ? (
																<button
																	className='px-2 py-1.5 hover:bg-slate-200 text-sm'
																	onClick={() => {
																		followUser(post.author._id, post.userRole);
																		setOpenOptions((prev) => {
																			return { open: false, postId: '' };
																		});
																	}}
																>
																	Unfollow
																</button>
															) : null}

															<button
																className='px-2 py-1.5 hover:bg-slate-200 text-sm'
																onClick={() => {
																	savePost(post._id);
																	setOpenOptions((prev) => {
																		return { open: false, postId: '' };
																	});
																}}
															>
																{myProfileData.savedPosts.includes(post._id)
																	? 'Unsave Post'
																	: 'Save Post'}
															</button>

															<button
																className='px-2 py-1.5 hover:bg-slate-200 text-sm'
																onClick={() => {
																	hidePost(post._id);
																	setOpenOptions((prev) => {
																		return { open: false, postId: '' };
																	});
																}}
															>
																Hide Post
															</button>
														</>
													)}
													<CopyToClipboard
														text={`https://romatoo.com/social/post/${post._id}`}
														onCopy={() => {
															setOpenOptions({
																open: false,
																postId: '',
															});
															toast.success('Copied to clipboard');
														}}
													>
														<button className='px-2 py-1.5 hover:bg-slate-200 text-sm'>
															Copy Link
														</button>
													</CopyToClipboard>
												</div>
											) : null}
										</div>
									</div>

									<div className='content'>
										{post.text &&
											post.text.split('\n').map((item, key) => {
												if (key === post.text.split('\n').length - 1) {
													return (
														<p key={key}>
															{item}
															<br />
														</p>
													);
												} else {
													return <p key={key}>{item}</p>;
												}
											})}

										{post.images?.length > 0 ? (
											<div className='image'>
												<img src={post.images[0]} alt='post' />
											</div>
										) : null}

										{post?.video ? (
											<div className='video'>
												<video src={`/${post.video}`} controls></video>
											</div>
										) : null}
									</div>

									<div className='bottom'>
										<div className='stats'>
											<div className='left'>
												{/* <img src='/social/like.svg' alt='like' />
													<img src='/social/love.svg' alt='love' />
													<img src='/social/insightful.svg' alt='insightful' /> */}

												{post.likeReact.length > 0 ? (
													<img src='/social/like.svg' alt='like' />
												) : null}
												{post.loveReact.length > 0 ? (
													<img src='/social/love.svg' alt='love' />
												) : null}
												{post.laughReact.length > 0 ? (
													<img src='/social/laugh.png' alt='laugh' />
												) : null}
												<p>
													{/* {post.reactorName || 'Md Rakibul'} and{' '}
														{randomGenerator()} others */}

													{/* {post.likeReact.length > 0 ||
														post.loveReact.length > 0 ? (
															<>
																{post.likeReact[0]?.fullName ||
																	post.loveReact[0]?.fullName}{' '}
																{post.likeReact.length +
																	post.loveReact.length +
																	post.laughReact.length -
																	1}{' '}
																others
															</>
														) : (
															'Be the first to react'
														)} */}

													{post.likeReact.find(
														(x) => x.userId === authInfo.id
													) ||
													post.loveReact.find(
														(x) => x.userId === authInfo.id
													) ||
													post.laughReact.find(
														(x) => x.userId === authInfo.id
													) ? (
														<>
															You{' '}
															{post.likeReact.length +
																post.loveReact.length +
																post.laughReact.length -
																1 >
															0 ? (
																<>
																	and{' '}
																	{post.likeReact.length +
																		post.loveReact.length +
																		post.laughReact.length -
																		1}{' '}
																	others
																</>
															) : null}
														</>
													) : post.likeReact.length > 0 ||
													  post.loveReact.length > 0 ||
													  post.laughReact.length > 0 ? (
														<>
															{post.likeReact[0]?.fullName ||
																post.loveReact[0]?.fullName ||
																post.laughReact[0]?.fullName}{' '}
															{post.likeReact.length +
																post.loveReact.length +
																post.laughReact.length -
																1 >
															0 ? (
																<>
																	and{' '}
																	{post.likeReact.length +
																		post.loveReact.length +
																		post.laughReact.length -
																		1}{' '}
																	others
																</>
															) : null}
														</>
													) : (
														'Be the first to react'
													)}
												</p>
											</div>

											<div className='right'>
												<p>{post.comments?.length} comments</p>
												{' • '}
												<p>{post.retoo?.length || '0'} Retoo</p>
											</div>
										</div>

										<div className='actions'>
											<button
												onClick={() => {
													toggleLike(post._id);
												}}
												className={
													post.likeReact.find(
														(x) => x.userId === authInfo.id
													) ||
													post.loveReact.find(
														(x) => x.userId === authInfo.id
													) ||
													post.laughReact.find((x) => x.userId === authInfo.id)
														? 'btn-action active'
														: 'btn-action'
												}
											>
												{/* <RiThumbUpLine className='icon' /> */}
												{post.likeReact.find(
													(x) => x.userId === authInfo.id
												) ? (
													<img src='/social/like.png' alt='like' />
												) : post.loveReact.find(
														(x) => x.userId === authInfo.id
												  ) ? (
													<img src='/social/love.png' alt='love' />
												) : post.laughReact.find(
														(x) => x.userId === authInfo.id
												  ) ? (
													<img src='/social/laugh.png' alt='laugh' />
												) : (
													<img src='/social/like.png' alt='like' />
												)}
												<span>
													{post.likeReact.find((x) => x.userId === authInfo.id)
														? 'Like'
														: post.loveReact.find(
																(x) => x.userId === authInfo.id
														  )
														? 'Love'
														: post.laughReact.find(
																(x) => x.userId === authInfo.id
														  )
														? 'Laugh'
														: 'Like'}
												</span>

												<div className='all-reacts'>
													<span
														onClick={(e) => {
															e.stopPropagation();
															toggleLike(post._id);
														}}
														className={
															post.likeReact.find(
																(x) => x.userId === authInfo.id
															)
																? 'btn-reaction active'
																: 'btn-reaction'
														}
													>
														<img src='/social/like.png' alt='like' />
													</span>

													<span
														onClick={(e) => {
															e.stopPropagation();
															toggleLove(post._id);
														}}
														className={
															post.loveReact.find(
																(x) => x.userId === authInfo.id
															)
																? 'btn-reaction active'
																: 'btn-reaction'
														}
													>
														<img src='/social/love.png' alt='love' />
													</span>

													<span
														onClick={(e) => {
															e.stopPropagation();
															toggleLaugh(post._id);
														}}
														className={
															post.laughReact.find(
																(x) => x.userId === authInfo.id
															)
																? 'btn-reaction active'
																: 'btn-reaction'
														}
													>
														<img src='/social/laugh.png' alt='laugh' />
													</span>
												</div>
											</button>

											<button
												className='btn-action'
												onClick={() => {
													setOpenComments((prev) => {
														if (prev.includes(post._id)) {
															return prev.filter((x) => x !== post._id);
														} else {
															return [...prev, post._id];
														}
													});
												}}
											>
												{/* <MdOutlineModeComment className='icon' /> */}
												<img src='/social/comment.png' alt='comment' />
												<span>Comment</span>
											</button>

											<button className='btn-action'>
												<AiOutlineRetweet className='icon' />
												{/* <img src='/social/retoo.png' alt='retweet' /> */}
												<span>Retoo</span>
											</button>

											<button className='btn-action'>
												{/* <RiSendPlaneLine className='icon' /> */}
												<img src='/social/send.png' alt='send' />
												<span>Send</span>
											</button>
										</div>
									</div>

									{openComments.includes(post._id) ? (
										<div className='comment__part'>
											{post.comments?.length > 0 ? (
												<div className='comment__list'>
													{post.comments.map((comment) => (
														<div className='comment__item' key={comment._id}>
															<div
																className='comment__avatar cursor-pointer'
																onClick={() =>
																	navigate(`/social/profile/${post.author._id}`)
																}
															>
																<img
																	src={
																		comment.author?.profileInfo
																			?.profilePicture ||
																		'/my-profile/male-avatar.png'
																	}
																	alt='avatar'
																/>
															</div>

															<div className='comment__info'>
																<div className='author-info cursor-pointer'>
																	<h1
																		onClick={() =>
																			navigate(
																				`/social/profile/${post.author._id}`
																			)
																		}
																	>
																		{comment?.author?.fullName}
																	</h1>
																	<p>
																		{new Date(
																			comment.createdAt
																		).toLocaleDateString('en-US', {
																			year: 'numeric',
																			month: 'long',
																			day: 'numeric',
																		})}
																	</p>
																</div>

																<p>{comment?.text}</p>
															</div>
														</div>
													))}
												</div>
											) : null}

											<div className='comment__bottom'>
												<div className='comment__avatar'>
													<img
														src={
															authInfo?.profileInfo?.profilePicture ||
															'/my-profile/male-avatar.png'
														}
														alt='avatar'
													/>
												</div>

												<div className='comment__input'>
													<input
														type='text'
														placeholder='Write a comment...'
														autoFocus
														onChange={(e) => {
															setComments((prev) => {
																const exist = prev.find(
																	(x) => x.postId === post._id
																);
																if (exist) {
																	return prev.map((x) => {
																		if (x.postId === post._id) {
																			return {
																				...x,
																				comment: e.target.value,
																			};
																		} else {
																			return x;
																		}
																	});
																} else {
																	return [
																		...prev,
																		{
																			postId: post._id,
																			comment: e.target.value,
																		},
																	];
																}
															});
														}}
														value={
															comments.find((x) => x.postId === post._id)
																?.comment || ''
														}
														onKeyDown={(e) => {
															if (e.key === 'Enter') {
																postComment(post._id);
															}
														}}
													/>
												</div>

												<div className='comment__actions'>
													<button
														onClick={() => {
															postComment(post._id);
														}}
													>
														<IoSend className='icon' />
													</button>
												</div>
											</div>
										</div>
									) : null}

									{openEditPost.open && openEditPost.postId === post._id ? (
										<EditPostPopup
											post={post}
											allPosts={allPosts}
											setAllPosts={setAllPosts}
											popupState={openEditPost}
											setPopupState={setOpenEditPost}
										/>
									) : null}
								</div>
							)
						)}
					</div>
				</div>

				<div className='w-full col-span-3 hidden sm:block'>
					<div className='suggest'>
						<h1>
							<span>Add to your feed</span>
							<button>
								<TiInfoLarge className='icon' />
							</button>
						</h1>

						<div className='suggest__list'>
							<div className='suggest__item'>
								<div className='top'>
									<div className='avatar'>
										<img
											src='https://i.ibb.co/dkPCvnT/1643913840352.jpg'
											alt='avatar'
										/>
									</div>

									<h1>Khalid Farhan</h1>
								</div>

								<div className='info'>
									<p>
										Career Coach • Company • CEO at MMTP • CO-Founder, AjkerDeal
									</p>

									<button>
										<IoAdd className='icon' />
										<span>Follow</span>
									</button>
								</div>
							</div>

							<div className='suggest__item'>
								<div className='top'>
									<div className='avatar'>
										<img
											src='https://i.ibb.co/NY52TPD/1679584739323.jpg'
											alt='avatar'
										/>
									</div>
									<h1>Mahmuda Liya</h1>
								</div>

								<div className='info'>
									<p>
										Founder & CEO • Lead Developer • Trainer • Founder, Career
										Coaching
									</p>

									<button>
										<IoAdd className='icon' />
										<span>Follow</span>
									</button>
								</div>
							</div>

							<div className='suggest__item'>
								<div className='top'>
									<div className='avatar'>
										<img
											src='https://i.ibb.co/cySMVFx/1571823821873.jpg'
											alt='avatar'
										/>
									</div>
									<h1>Solaiman Shukhon</h1>
								</div>

								<div className='info'>
									<p>Founder & CEO at The Startup • Motivational Speaker</p>

									<button>
										<IoAdd className='icon' />
										<span>Follow</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{createPostPopupOpen ? (
				<CreatePostPopup
					allPosts={allPosts}
					setAllPosts={setAllPosts}
					popupState={createPostPopupOpen}
					setPopupState={setCreatePostPopupOpen}
				/>
			) : null}
		</div>
	);
};

export default Feed;
