import React from 'react';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	Title,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Filler,
} from 'chart.js';
import { last6Days } from '../../../utilities/date';

ChartJS.register(
	ArcElement,
	Tooltip,
	Title,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Filler
);

const LineChart = () => {
	const data = {
		labels: last6Days(),
		datasets: [
			{
				label: 'Appointments',
				data: [8, 18, 10, 21, 16, 26],
				fill: true,
				borderWidth: 1,
				backgroundColor: 'rgba(2, 255, 128, 0.3)',
				borderColor: 'rgba(2, 255, 128, 1)',
				tension: 0.1,
			},
		],
	};

	return (
		<div className='mt-4 p-3 w-full h-[300px] bg-emerald-100/50 rounded-lg shadow-lg shadow-slate-400/30'>
			<Line
				data={data}
				options={{
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						title: {
							display: true,
							text: 'Appointments',
							font: {
								size: 18,
								family: 'Signika Negative',
								weight: '500',
							},
						},
					},
					elements: {
						point: {
							radius: 0,
						},
					},
					scales: {
						y: {
							beginAtZero: true,
							grid: {
								display: false,
							},
							ticks: {
								stepSize: 5,
							},
						},
						x: {
							grid: {
								display: false,
							},
						},
					},
				}}
			/>
		</div>
	);
};

export default LineChart;
