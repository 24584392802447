import React from 'react';
import {
	IoCheckmarkCircle,
	IoEyeOffOutline,
	IoCloseSharp,
	IoEyeOutline,
} from 'react-icons/io5';
import { login } from '../../store/authSlice/authSlice';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './SignIn.scss';

const SignIn = () => {
	// page title

	window.document.title = 'Sign In | Romatoo';

	// hooks

	const navigate = useNavigate();
	const dispatch = useDispatch();

	// states

	const [userType, setUserType] = React.useState('patient');

	const [userData, setUserData] = React.useState({
		emailOrPhone: '',
		password: '',
	});

	const [error, setError] = React.useState('');
	const [showPass, setShowPass] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	// functions

	const handleSubmit = (e) => {
		e.preventDefault();

		// check if all fields are filled

		if (!userData.emailOrPhone || !userData.password) {
			setError('Please fill all fields');
			return;
		}

		setError('');
		setLoading(true);

		if (userType === 'patient') {
			// patient sign in

			const url = `${process.env.REACT_APP_API}/patient/login`;

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(userData),
			})
				.then((res) => res.json())
				.then((res) => {
					// console.log('patient sign in response : ', res);

					if (res.success) {
						toast.success(res.message);

						setTimeout(() => {
							dispatch(login(res.token));
							navigate('/');
						}, 2000);
					} else {
						toast.error(res.message);
						setError(res.message);
					}
				})
				.catch((err) => {
					console.log(err);
					toast.error('Something went wrong');
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			// doctor sign in

			const url = `${process.env.REACT_APP_API}/doctor/login`;

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(userData),
			})
				.then((res) => res.json())
				.then((res) => {
					// console.log('doctor sign in response : ', res);

					if (res.success) {
						toast.success(res.message);

						setTimeout(() => {
							dispatch(login(res.token));
							navigate('/social/feed');
						}, 2000);
					} else {
						toast.error(res.message);
						setError(res.message);
					}
				})
				.catch((err) => {
					console.log(err);
					toast.error('Something went wrong');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<section className='signIn page'>
			<div className='container'>
				<div className='user__type'>
					<button
						onClick={() => {
							setUserType('patient');
						}}
					>
						{userType === 'patient' && <IoCheckmarkCircle className='icon' />}
						I'm a User
					</button>
					<button
						onClick={() => {
							setUserType('doctor');
						}}
					>
						{userType === 'doctor' && <IoCheckmarkCircle className='icon' />}
						I'm a Health Care Professional
					</button>
				</div>

				<div className='user__form'>
					<div className='top'>
						<h1>Sign In</h1>
						<p>
							You are signing in as a
							{userType === 'patient' ? ' User' : ' Doctor'}
						</p>

						<button
							className='btn-close'
							onClick={() => {
								navigate('/');
							}}
						>
							<IoCloseSharp className='icon' />
						</button>
					</div>

					<form className='body' onSubmit={handleSubmit}>
						<div className='form__group'>
							<input
								type='text'
								placeholder='Enter Email or Phone'
								onChange={(e) => {
									setUserData((prev) => ({
										...prev,
										emailOrPhone: e.target.value,
									}));
								}}
							/>
						</div>

						<div className='form__group pass'>
							<input
								type={showPass ? 'text' : 'password'}
								placeholder='Enter Password'
								onChange={(e) => {
									setUserData((prev) => ({
										...prev,
										password: e.target.value,
									}));
								}}
							/>
							{showPass ? (
								<IoEyeOutline
									className='icon'
									onClick={() => setShowPass(false)}
								/>
							) : (
								<IoEyeOffOutline
									className='icon'
									onClick={() => setShowPass(true)}
								/>
							)}
						</div>

						{error && <p className='error'>{error}</p>}

						<button type='submit' className='btn-login'>
							Login
						</button>
					</form>
				</div>

				<p className='create'>
					Don't have an account? <Link to='/sign-up'>Create one</Link>
				</p>
			</div>
		</section>
	);
};

export default SignIn;
